import { UserShortInfoDownloadDto } from "collaboration-service";
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon, Image, SemanticCOLORS, SemanticICONS, Table } from 'semantic-ui-react';
import { getUri, switchToProfile, translateUsername } from '../../../services/Helpers';
import { IConnectedComponent, IState } from '../../../services/Interfaces';

type moreDataFunction = (user: UserShortInfoDownloadDto) => string;

interface IUserTileProps extends IConnectedComponent, RouteComponentProps {
    user: UserShortInfoDownloadDto;
    icon?: SemanticICONS;
    iconColor?: SemanticCOLORS;
    onIconClick?: (user: UserShortInfoDownloadDto) => void;
    linkEnabled?: boolean;
    hideEmail?: boolean;
    moreData?: moreDataFunction | moreDataFunction[];
    notCelled?: boolean;
    isChatAdmin?: boolean;
    compact?: boolean;
}
class UserTile extends React.Component<IUserTileProps> {
    public render() {
        return (
            this.props.linkEnabled ?
                (
                    <div onClick={this.goto}>
                        {this.getCell()}
                    </div>
                ) : (
                    this.getCell()
                )
        );
    }

    private getCell = () => {
        const { props } = this;
        const linkUri = getUri(props.user.avatar);
        let moreDataCount: number;
        let mData: moreDataFunction[];
        if (props.moreData) {
            if (props.moreData.constructor === Array) {
                moreDataCount = props.moreData.length;
                mData = props.moreData as moreDataFunction[];
            }
            else {
                moreDataCount = 1;
                mData = [props.moreData as moreDataFunction];
            }
        }
        else {
            moreDataCount = 0;
            mData = [];
        }


        return (
            <Table
                compact={this.props.compact ? 'very' : false}
                basic={this.props.compact ? 'very' : false}
                celled={this.props.notCelled ? false : true}
                unstackable={true} className="UserSuggestName"
            >
                <Table.Body>
                    <Table.Row>
                        <Table.Cell rowSpan={2 + moreDataCount} style={{ width: 25 }}>
                            <Image src={linkUri} className="RoundImage" size="mini" />
                        </Table.Cell>
                        <Table.Cell style={{ padding: 0 }}>
                            <div
                                className={"UserSuggestName" + (this.props.linkEnabled && !this.props.compact ? " CommunityLink" : "")}
                            >
                                {this.props.isChatAdmin && <Icon name="star" />} {translateUsername(props.user)}
                            </div>
                        </Table.Cell>
                        {props.icon &&
                            <Table.Cell rowSpan={2 + moreDataCount} style={{ width: 25 }}>
                                <Icon color={props.iconColor} name={props.icon} onClick={props.onIconClick ? this.onIconClick : undefined} />
                            </Table.Cell>
                        }
                    </Table.Row>
                    {this.props.hideEmail !== true &&
                        <Table.Row>
                            <Table.Cell>
                                <div className="UserSuggestMail">
                                    {props.user.email}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    }
                    {mData.map((v, i) =>
                        <Table.Row key={i}>
                            <Table.Cell>
                                <div className="UserSuggestMail">
                                    {v(props.user)}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        );
    }
    private onIconClick = () => {
        if (this.props.onIconClick)
            this.props.onIconClick(this.props.user);
    }

    private goto = () => {
        switchToProfile(this, this.props.user.id);
    }

}

export default withRouter(connect((state: IState) => ({ state }))(UserTile));