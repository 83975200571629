import { UserCommentDownloadDto } from 'collaboration-service';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import * as React from 'react';
import { Button, Grid } from "semantic-ui-react";
import { config } from 'services/Config';
import TubeComment from "./TubeComment";

interface TubeCommentsProps extends IIMGTranslatedComponent {
    whiteTheme?: boolean;
    expand?: boolean;
    comments: UserCommentDownloadDto[];
    showCommendForm: boolean;
    onDeleteComment: (idx: number) => void;
    onChangeComment: (idx: number, comment: string) => void;
    onAddComment: (comment: string) => void;
}

interface TubeCommentsState {
    newComment?: string;
}

class TubeComments extends React.Component<TubeCommentsProps, TubeCommentsState> {
    constructor(p: TubeCommentsProps) {
        super(p);
        this.state = {};
    }
    public render() {
        return (
            <div style={{ margin: "0 0 30px 0", color: this.props.whiteTheme ? "#313639" : "#fff" }}>
                <>
                    {this.props.showCommendForm &&
                        <Grid stackable columns={2} stretched>
                            <Grid.Column mobile={16} tablet={16} computer={12} textAlign="center">
                                <textarea
                                    placeholder={this.props.t("post a new comment ...")}
                                    onChange={this.onTextChange}
                                    maxLength={config.maxCommentLength}
                                    className={this.props.whiteTheme ? "VideoCommentTextAreaWhite" : "VideoCommentTextArea"}
                                    value={this.state.newComment}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={4} >
                                <Button
                                    disabled={!this.state.newComment || this.state.newComment.length <= 0}
                                    onClick={this.onAddComment}
                                    content={this.props.t('add comment')}
                                    labelPosition='left'
                                    floated="right"
                                    icon='plus'
                                    size="large"
                                    primary
                                    style={{ maxHeight: 50 }}
                                />
                            </Grid.Column>
                        </Grid>
                    }
                    {_.map(this.props.comments, ((val, idx) => {
                        return <TubeComment key={idx} comment={val} idx={idx} onDelete={this.props.onDeleteComment} onChangeComment={this.props.onChangeComment} whiteTheme />;
                    }))}
                </>
            </div>
        );
    }

    private onAddComment = () => {
        if (this.state.newComment) {
            this.props.onAddComment(this.state.newComment);
            this.setState({ newComment: "" })
        }
    }

    private onTextChange = (e: any) => {
        this.setState({ newComment: e.target.value });
    }
}

export default translate("tube")(TubeComments);