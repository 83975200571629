import { MediaDownloadDto, MediaUploadDto, AssetDownloadDto } from "collaboration-service";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import * as EXIF from "exif-js";
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Divider, Loader, Popup, SemanticSIZES } from 'semantic-ui-react';
import { config } from '../../../services/Config';
import { calcNewDimWithAlgo, checkMedia, getBrowser, getLinkForGlobalMedia, loadToCanvas, nop } from '../../../services/Helpers';
import { IConnectedComponent, IState } from '../../../services/Interfaces';
import ImageEdit, { checkNeedEdit } from '../ImageEdit/ImageEdit';
import Media, { IDownloadMedia, IFileMedia, IURLMedia } from './Media';
import AssetSelector from "../AssetSelector/AssetSelector";
import { BrowserInfo } from "detect-browser";

interface IMediaSelectionProps extends IConnectedComponent, IIMGTranslatedComponent {
    getInteractions?: (selectNew: (() => void) | undefined, edit: (() => void) | undefined, remove: (() => void) | undefined) => void;
    onMediaSelected: (file: any, mediaType: MediaUploadDto) => void;
    registerFileSelect?: (fileSelect: () => void) => void;
    onEditAbleChange?: (value: boolean) => void;
    noPreview?: boolean;
    aspect?: number;
    startEditing?: boolean;
    media?: MediaDownloadDto;
    imageOnly?: boolean;
    videoOnly?: boolean;
    rounded?: boolean;
    imageType?: string;
    hideButton?: boolean;
    clearable?: boolean;
    allowedFileTypes?: string;
    refName?: string;
    btnSize?: SemanticSIZES;
    btnBasic?: boolean;
    allowAssets?: boolean;

    contentClassName?: string;
    scaleWidthAlgorithm?: boolean;
    quality?: number;
    noImageMessage?: JSX.Element;
    buttonClassName?: string;
    callFromSlides?: boolean;
    videoScreenshotCreated?: (blob: Blob) => void;
    onRequestDeleteMedia?: () => void;

    extraClassName?: string;

}

interface IMediaSelectionState {
    fileSelector: any;
    selectedFile: any;
    file?: File;
    url?: string;
    loading: boolean;
    deleted?: boolean;
    edit?: boolean;
    openAssetList?: () => void;
    log: string;
}

class MediaSelection extends React.Component<IMediaSelectionProps, IMediaSelectionState> {
    private canvasRef = React.createRef<HTMLCanvasElement>();
    private curMedia: MediaUploadDto | undefined;
    private curFile: File | undefined;
    constructor(props: IMediaSelectionProps) {
        super(props);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.loadFile = this.loadFile.bind(this);
        this.handleCropImage = this.handleCropImage.bind(this);
        this.state = {
            fileSelector: this.buildFileSelector(),
            selectedFile: undefined,
            loading: false,
            file: undefined,
            deleted: false,
            log: "",
        };
    }

    public UNSAFE_componentWillMount() {
        if (this.props.registerFileSelect)
            this.props.registerFileSelect(() => this.handleFileSelect(null));

        if (this.props.getInteractions)
            this.props.getInteractions(() => this.handleFileSelect(null), this.props.imageOnly ? this.editMedia : nop, this.removeMedia);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IMediaSelectionProps) {
        if (nextProps.media !== this.props.media && !nextProps.media) {
            this.setState({ file: undefined });
        }
        if (nextProps.getInteractions !== this.props.getInteractions || nextProps.imageOnly !== this.props.imageOnly) {
            if (this.props.getInteractions)
                this.props.getInteractions(() => this.handleFileSelect(null), nextProps.imageOnly ? this.editMedia : nop, this.removeMedia);
        }
    }

    public renderImageContent() {
        if (!this.state.deleted && this.state.file && this.state.url) {
            if (this.state.file.type.startsWith("image")) {
                if (this.props.onEditAbleChange)
                    this.props.onEditAbleChange(true);
                return <ImageEdit
                    cropClassName={this.props.rounded ? "RoundedCrop" : "NormalImage"}
                    imgClassName={this.props.rounded ? "RoundedCropImage" : "NormalImage"}
                    src={this.state.url}
                    onCropFinish={this.handleCropImage}
                    onClick={this.handleFileSelect}
                    aspect={this.props.aspect}
                    imageType={this.props.imageType ?? this.state.file.type.split("/")[1]}
                    hideButton={this.props.hideButton}
                    buttonClassName={this.props.buttonClassName}
                    edit={this.state.edit}
                    startEditing={this.props.startEditing}
                    noPreview={this.props.noPreview}
                />;
            }
            else {
                const bw = getBrowser().name;
                if (this.props.noPreview)
                    return <div />;
                if (this.props.onEditAbleChange)
                    this.props.onEditAbleChange(false);
                const media: IFileMedia = { file: this.state.file, type: this.state.file.type };
                return <Media
                    media={media}
                    onClick={nop}
                    contentClassName={this.props.rounded ? "RoundedCropImage" : (this.props.contentClassName ? this.props.contentClassName : "NormalImage")}
                    onVideoCanPlay={this.onVideoCanPlay}
                    autoPlay={bw === "ios" ? true : false}
                />;

            }
        }
        else {
            if (this.props.noPreview)
                return <div />;
            if (this.props.onEditAbleChange)
                this.props.onEditAbleChange(false);
            let media: IDownloadMedia | IURLMedia | IFileMedia;
            if (!this.state.deleted && this.props.media && checkMedia(this.props.media, this.props.refName))
                media = { media: this.props.media, refName: this.props.refName ? this.props.refName : "self" };
            else {
                const link = getLinkForGlobalMedia(this, "no-img-available.png");
                const url = this.props.rounded ? config.tmpImages.missingProfilePicture : (link ? link.uri : "");
                media = { url, type: "image/png" };
            }
            if ((this.props.media && checkMedia(this.props.media, this.props.refName)) || !this.props.noImageMessage) {
                if (this.props.onEditAbleChange)
                    this.props.onEditAbleChange(false);
                return (
                    <Media
                        media={media}
                        onClick={this.handleFileSelect}
                        lazyLoading='off'
                        aspect={this.props.aspect}
                        contentClassName={this.props.rounded ? "RoundedCropImage" : (this.props.contentClassName ? this.props.contentClassName : "NormalImage")}
                    />
                );
            }
            else {
                if (this.props.onEditAbleChange)
                    this.props.onEditAbleChange(false);
                return <div onClick={this.handleFileSelect}>{this.props.noImageMessage}</div>;
            }
        }
    }

    public render() {
        return (
            <div className={this.props.extraClassName ? this.props.extraClassName : (!this.props.hideButton ? "MediaSelectionContainer" : "MediaSelectionContainerMinus5")}>
                <Dimmer active={this.state.loading}>
                    <Loader
                        size="huge"
                    />
                </Dimmer>
                <AssetSelector getOpenList={this.setOpenAssetList} onAssetSelected={this.assetSelected} restrict="media" />
                {this.renderImageContent()}
                <canvas ref={this.canvasRef} style={{ display: "none" }} />
                {!this.props.hideButton && !this.props.callFromSlides &&
                    <>
                        <Popup position="top right" content={this.props.t("upload media")} className='ComUserPopup' trigger={
                            <Button icon="upload" onClick={this.handleFileSelect}
                                color={this.props.btnBasic ? undefined : "red"}
                                className={this.props.buttonClassName + " " + (this.props.btnSize ? "plus2marginTop" : "")}
                                basic={this.props.btnBasic}
                                size={this.props.btnSize ? this.props.btnSize : undefined}
                            />
                        }
                        />

                        {this.props.clearable &&
                            <Popup position="top right" content={this.props.t("delete media")} className='ComUserPopup' trigger={
                                <Button icon="times" onClick={this.removeMedia}
                                    color={this.props.btnBasic ? undefined : "red"}
                                    className={this.props.buttonClassName + " " + (this.props.btnSize ? "plus2marginTop" : "")}
                                    basic={this.props.btnBasic}
                                    size={this.props.btnSize ? this.props.btnSize : undefined}
                                />
                            }
                            />
                        }
                        {!this.props.clearable && this.props.onRequestDeleteMedia &&
                            <Popup position="top right" content={this.props.t("delete media")} className='ComUserPopup' trigger={
                                <Button icon="times" onClick={this.props.onRequestDeleteMedia}
                                    color={this.props.btnBasic ? undefined : "red"}
                                    className={this.props.buttonClassName + " " + (this.props.btnSize ? "plus2marginTop" : "")}
                                    basic={this.props.btnBasic}
                                    size={this.props.btnSize ? this.props.btnSize : undefined}
                                />
                            }
                            />
                        }

                        {this.props.allowAssets && config.assetManagementFeatures.allowAssetSelects &&
                            <Popup content={this.props.t("get media from assets")} className='ComUserPopup' trigger={
                                <Button icon="list" onClick={this.openAssetList}
                                    color={this.props.btnBasic ? undefined : "red"}
                                    className={this.props.buttonClassName + " " + (this.props.btnSize ? "plus2marginTop" : "")}
                                    basic={this.props.btnBasic}
                                    size={this.props.btnSize ? this.props.btnSize : undefined}
                                />
                            }
                            />
                        }
                    </>
                }
                {!this.props.hideButton && this.props.callFromSlides &&
                    <>
                        <Divider />
                        <Button.Group size={this.props.btnSize ? this.props.btnSize : undefined} basic={this.props.btnBasic} floated="right" style={{ marginLeft: 10, marginBottom: 2 }}>
                            <Popup position="top right" content={this.props.t("upload media")} className='ComUserPopup' trigger={
                                <Button icon="upload" onClick={this.handleFileSelect} />
                            }
                            />
                            {this.props.clearable &&
                                <Popup position="top right" content={this.props.t("delete media")} className='ComUserPopup' trigger={
                                    <Button icon="times" onClick={this.removeMedia} />
                                }
                                />
                            }
                            {this.props.allowAssets && config.assetManagementFeatures.allowAssetSelects &&
                                <Popup content={this.props.t("get media from assets")} className='ComUserPopup' trigger={
                                    <Button icon="list" onClick={this.openAssetList} />
                                }
                                />
                            }
                        </Button.Group>
                    </>
                }
                {/* <div>
                    <div dangerouslySetInnerHTML={{ __html: this.state.log }} />
                </div> */}
            </div>
        );
    }

    private openAssetList = () => {
        if (this.state.openAssetList)
            this.state.openAssetList();
    }

    private setOpenAssetList = (openAssetList: () => void) => {
        this.setState({ openAssetList });
    }

    private assetSelected = (asset: AssetDownloadDto) => {
        const media: MediaUploadDto = asset.media as MediaUploadDto;
        media.copyByReference = true;
        media.changed = true;
        this.setState({ file: undefined, url: undefined, });
        this.onMediaSelected(undefined, media);
    }

    private editMedia = () => {
        this.setState({ edit: true });
    }
    private handleCropImage(data: any) {
        // console.log('data => ', data);
        if (data != null)
            loadToCanvas(data, (c) => {
                // console.log("canvas ====>");
                // console.log('c.width => ', c.width);
                // console.log('c.height => ', c.height);
                this.setState({ edit: false });
                const fileForBlob: File = Object.assign(data, { name: "upload.jpeg", lastModifiedData: new Date() });
                this.onMediaSelected(fileForBlob, { changed: true, mediaType: fileForBlob.type, fileName: fileForBlob.name, width: c.width, height: c.height } as MediaUploadDto);
            });

    }

    private handleFileSelect(e: any) {
        if (e)
            e.preventDefault();
        this.state.fileSelector.click();
    }

    private buildFileSelector(): any {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        let accept = '.jpg, .jpeg, .png';
        if (!this.props.imageOnly)
            accept = accept + ", .mp4, .mov";
        if (this.props.videoOnly)
            accept = ".mp4, .mov";
        // fileSelector.setAttribute('multiple', 'multiple');           // allow selection of multiple files
        this.props.allowedFileTypes ?
            fileSelector.setAttribute('accept', this.props.allowedFileTypes) :
            fileSelector.setAttribute('accept', accept); // restrict file selector to display only supported file types.
        fileSelector.addEventListener("change", this.loadFile);
        return fileSelector;
    }

    private loadFile() {
        //this.setState({ log: `${this.state.log}<br/>EXIF => ${EXIF}` });
        if (config.debug && EXIF)
            console.log(EXIF);
        if (this.state.fileSelector.files && this.state.fileSelector.files.length > 0)
            this.setState({ loading: true });
        _.forEach(this.state.fileSelector.files, (val: File, idx) => {
            let url = URL.createObjectURL(val);
            const media = { mediaType: val.type, changed: true } as MediaUploadDto;
            //console.log("va => ", val);
            //            console.log(media);
            if (val.type.startsWith("image")) {
                if (!this.props.videoOnly) {
                    loadToCanvas(val, (c, data) => {

                        //this.setState({ log: `${this.state.log}<br/>data => ${JSON.stringify(data)}` });
                        //this.setState({ log: `${this.state.log}<br/>data => ${JSON.stringify(data?.exif)}` });
                        const newDim = this.props.scaleWidthAlgorithm ? calcNewDimWithAlgo(c.width, c.height, 21.0 / 9.0, 1080) : ({ x: c.width, y: c.height });
                        const toBlob = (canvas: HTMLCanvasElement) => {
                            canvas.toBlob((blob) => {
                                if (blob) {
                                    const fileForBlob: File = Object.assign(blob as any, { name: "upload." + (this.props.imageType ? this.props.imageType : "jpeg"), lastModifiedData: new Date() });
                                    val = fileForBlob;
                                    url = URL.createObjectURL(fileForBlob);
                                    this.setState({ url: undefined, file: undefined });
                                    this.setState({ url, file: fileForBlob, loading: false, deleted: false });
                                    media.fileName = fileForBlob.name;
                                    if (!this.props.aspect || (!checkNeedEdit(this.props.aspect, c.width, c.height) && (!this.props.startEditing)))
                                        this.onMediaSelected(val, { ...media, width: c.width, height: c.height });
                                }
                            },
                                val.type, this.props.quality ? this.props.quality : undefined);
                        };

                        if (newDim.x !== c.width || newDim.y !== c.height) {
                            //                        console.log("scaling!!!");
                            loadToCanvas(val, (c1) => toBlob(c1), { orientation: true, maxWidth: newDim.x, maxHeight: newDim.y });
                        }
                        else
                            toBlob(c);
                    }, { /*orientation: true, meta: true*/ });
                }
                else {
                    this.setState({ loading: false });
                }
            }
            else {
                //if (config.debug)
                //                console.log(val);
                if (!this.props.imageOnly) {
                    //console.log("aha!");
                    //console.log(url);
                    this.setState({ url: undefined });
                    this.setState({ url, loading: false, file: val, deleted: false });
                    media.fileName = val?.name ?? "upload.jpg";
                    this.onMediaSelected(val, media, val !== undefined && val.type !== undefined && val.type.startsWith("video")); // do it after reading length!
                }
            }
        });
    }

    private removeMedia = () => {
        this.setState({ url: undefined, loading: false, file: undefined, deleted: true });
        this.onMediaSelected(undefined, { changed: true, reference: "", mediaType: "", fileName: "", thumbnails: [] });
        if (this.props.onRequestDeleteMedia)
            this.props.onRequestDeleteMedia();
    }

    private onVideoCanPlay = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const video = event.currentTarget;
        const canvas = this.canvasRef.current;

        if (video && this.curMedia && this.curFile) {
            this.curMedia.length = video.duration;
            this.onMediaSelected(this.curFile, this.curMedia);
        }

        if (this.props.videoScreenshotCreated && canvas) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const canvasContext = canvas.getContext('2d');
            if (canvasContext) {
                canvasContext.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                canvas.toBlob((blob) => {
                    if (this.props.videoScreenshotCreated && blob) {
                        this.props.videoScreenshotCreated(blob);
                    }
                });
            }
        }
    }

    private onMediaSelected = (file: File | undefined, media: MediaUploadDto, dontSend?: boolean) => {
        this.curFile = file;
        this.curMedia = media;
        // console.log('media => ', media);
        // console.log('dontSend => ', dontSend);
        if (!dontSend)
            this.props.onMediaSelected(file, media);
    }
}

export default translate("admin")(connect((state: IState) => ({ state }))(MediaSelection));