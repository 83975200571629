import * as React from 'react';
import LottieProgress from 'components/General/LottieProgress/LottieProgress';
import { getBrowser } from 'services/Helpers';

interface ScormLoaderDSJProps {
    style?: React.CSSProperties;
    speed?: number;
    onDone?: () => void;
}

export default class ScormLoaderDSJ extends React.Component<ScormLoaderDSJProps> {
    UNSAFE_componentWillReceiveProps(nextProps: ScormLoaderDSJProps) {
        if (this.props.onDone !== nextProps.onDone && nextProps.onDone && getBrowser().name === "ie")
            nextProps.onDone();

    }
    public render() {
        const isIe = (getBrowser().name === "ie");
        return (
            <div style={this.props.style}>
                {!isIe &&
                    <LottieProgress
                        preAnimation={"porsche_loader_part1.json"}
                        animation={"porsche_loader_part2.json"}
                        postAnimation={"porsche_loader_part3.json"}
                        animationSpeed={this.props.speed}
                        width={200}
                        height={200}
                        onDone={this.props.onDone}
                    />
                }
            </div>
        );
    }
}
