import { MediaController, MediaDownloadDto, MediaWithoutThumbnailsDownloadDto } from "collaboration-service";
import ImgI18N from "components/Utils/img-i18n";
import { LinkDto } from "imaginarity-azure";
import * as _ from "lodash";
import * as React from "react";
import LazyLoad from 'react-lazyload';
import { connect } from "react-redux";
import { SizeMeProps, withSize } from "react-sizeme";
import { Embed, Modal } from "semantic-ui-react";
import { IConnectedComponent, IState } from "services/Interfaces";
import { getBrowser, getLink, mediaEqual, getTranslatedValue } from "../../../services/Helpers";
import { config } from "services/Config";

type lazyLoadingMode = "overflow" | "off" | "on";
type MediaType = "image" | "video" | "vimeo" | "youtube";

// Media Types
export interface IFileMedia {
    file: File;
    type: string;
}

export interface IDownloadMedia {
    media: MediaWithoutThumbnailsDownloadDto;
    refName: string | undefined;
}

export interface IEmbedMedia {
    id: string;
    source: ('vimeo' | 'youtube');
}

export interface IURLMedia {
    url: string;
    type: string;
}

export interface IStorageMedia {
    container: string;
    path: string;
    type: string;
}

interface IMediaProps {
    media: IDownloadMedia | IFileMedia | IEmbedMedia | IURLMedia | IStorageMedia;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onFullScreenClick?: (ev: React.MouseEvent<HTMLElement>) => void;
    contentClassName?: string;
    controls?: boolean;
    lazyLoading?: lazyLoadingMode;
    onSizeDetected?: (width: number, height: number) => void;
    autoPlay?: boolean;
    overlay?: (width: number, height: number) => React.ReactNode;
    getImageCanvas?: (canvas: HTMLCanvasElement) => void;
    showFullScreen?: boolean;
    videoShift?: number;
    onVideoCanPlay?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
    inActive?: boolean;
    scaling?: number;
}

interface IMediaInnerProps extends IMediaProps, SizeMeProps, IConnectedComponent {
    aspect?: number;
}

interface IMediaState {
    showFullScreen: boolean;
    url?: string;
    type?: string;
    container?: any;
    width?: number;
    height?: number;
    subtitles?: MediaWithoutThumbnailsDownloadDto[];
}

class Media extends React.Component<IMediaInnerProps, IMediaState>
{
    private shiftVideo = true;
    public state: IMediaState = {
        showFullScreen: false,
        url: undefined,
        type: undefined,
    };

    private mediaProps = {};

    constructor(props: IMediaInnerProps) {
        super(props);
        this.showFullScreen = this.showFullScreen.bind(this);
        this.videoMetaLoaded = this.videoMetaLoaded.bind(this);
        this.imageMetaLoaded = this.imageMetaLoaded.bind(this);
    }

    public UNSAFE_componentWillMount() {
        this.setupState(this.props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IMediaInnerProps) {
        if (!mediaEqual(nextProps.media, this.props.media)) {
            this.setupState(nextProps);
        }
        if (nextProps.showFullScreen !== undefined && nextProps.showFullScreen !== this.props.showFullScreen) {
            this.setState({ showFullScreen: nextProps.showFullScreen });
        }
    }

    public showFullScreen(ev: React.MouseEvent<HTMLElement>) {
        if (!this.props.onFullScreenClick)
            this.setState({ showFullScreen: !this.state.showFullScreen });
        else
            this.props.onFullScreenClick(ev);
    }


    public renderMediaContent(url: string, mediaType: string) {
        let type = mediaType;
        if (mediaType.startsWith('video')) type = 'video';
        if (mediaType.startsWith('image')) type = 'image';

        const style = { cursor: (this.props.onClick ? "pointer" : "") };

        // Check Browser to fix whitespace issue in IE.
        const defaultClassName = (getBrowser().name === "ie") ? "FitImageAndVideoToContextForIE" : "FitImageAndVideoToContext";
        const mediaClassName = this.props.contentClassName ? this.props.contentClassName : defaultClassName;
        const w = this.props.size && this.props.size.width !== null ? this.props.size.width : undefined;
        const hs = (this.state.height && this.state.width && w ? this.state.height * (w / this.state.width) : undefined);
        const h = hs && this.props.scaling ? hs / this.props.scaling : hs;
        const lng = (ImgI18N.getInstance().language.toLowerCase() ?? ImgI18N.getInstance().getBrowserLanguage()?.value.toLowerCase()) ?? "en";
        const sdef = getTranslatedValue(this.state.subtitles, lng);

        switch (type) {
            case 'video':
                return (
                    <video
                        crossOrigin="anonymous"
                        key={url}
                        className={mediaClassName}
                        controls={this.props.controls === undefined ? true : this.props.controls}
                        controlsList="nodownload"
                        {...this.mediaProps}
                        autoPlay={this.props.autoPlay}
                        onLoadedMetadata={this.videoMetaLoaded}
                        onCanPlay={this.props.onVideoCanPlay}
                        onClick={!this.props.controls ? (this.props.onClick ? this.props.onClick : this.showFullScreen) : undefined}
                        playsInline
                    >
                        <source src={`${url}${this.props.videoShift ? "#t=" + this.props.videoShift : ""}`} type="video/mp4" />
                        {config.tubeFeatures.subtitle && _.map(this.state.subtitles, s => {
                            const l = _.find(s.links, l => l.ref === "self");
                            const d = _.find(ImgI18N.lngDefinitions, d => d.value.toLowerCase() === s.lng?.toLowerCase());
                            const label = d?.label;
                            if (l && d && label)
                                return (
                                    <track key={s.reference} src={l?.uri} label={label} kind="captions" srcLang={s.lng} default={sdef !== undefined && sdef.lng === s.lng} />
                                );
                            return null;
                        })}
                    </video>
                );
            case 'image':
                {
                    return (
                        <img
                            key={url}
                            src={url}
                            className={mediaClassName}
                            {...this.mediaProps}
                            onClick={this.props.onClick ? this.props.onClick : this.showFullScreen}
                            onLoad={this.imageMetaLoaded}
                            style={this.props.onClick ? style : undefined}
                            height={h}
                            alt=""
                        />
                    );
                }
            case 'vimeo':
                return (
                    <Embed id={url} source={type} autoplay={this.props.autoPlay} active={!this.props.inActive} />
                );
            case 'youtube':
                return (
                    <Embed id={url} source={type} autoplay={this.props.autoPlay} active={!this.props.inActive} />
                );
            default:
                return <div />;
        }
    }

    public renderContent(url: string, type: string): JSX.Element {
        const useLL = this.props.lazyLoading === undefined || this.props.lazyLoading === "on" || this.props.lazyLoading === "overflow";
        const overflow = this.props.lazyLoading !== undefined && this.props.lazyLoading === "overflow";
        if (useLL)
            return (
                <LazyLoad offset={[400, 400]} height={500} overflow={overflow ? overflow : undefined}>
                    {this.renderMediaContent(url, type)}
                </LazyLoad>
            );
        else
            return this.renderMediaContent(url, type);
    }

    public render(): JSX.Element {
        const { size } = this.props;
        const w = Math.ceil(size.width ? size.width : 0);
        const h = Math.ceil(size.height ? size.height : 0);
        if (h > w)
            this.mediaProps = { height: w, width: w };
        else
            this.mediaProps = { width: w };
        // console.log("RENDERMEDIA");
        // console.log(this.props.aspect);
        let height;
        if (this.state.container) {
            // console.log(this.props.aspect);
            const width = this.state.container.clientWidth;
            // console.log(width);
            height = this.props.aspect ? width / this.props.aspect : undefined;
        }
        if (this.props.scaling && height)
            height = height / this.props.scaling;
        // console.log(height);

        return (
            <div
                style={height && this.props.aspect && this.props.aspect !== 1 ? { width: '100%', height, overflow: 'hidden', position: 'relative' } : {}}
                ref={this.setContainerRef}
            >
                <Modal
                    basic={true}
                    closeIcon={this.state.type?.startsWith("video")}
                    open={this.props.showFullScreen !== undefined ? this.props.showFullScreen : this.state.showFullScreen}
                    onClose={this.showFullScreen}
                    size="fullscreen"
                    className="FullscreenImage"
                >
                    <div className='SpezialDiv'>
                        {this.renderFullscreen()}
                    </div>
                </Modal>
                {this.state.url && this.state.type && this.renderContent(this.state.url, this.state.type)}
                {this.getOverlay()}

            </div>
        );
    }

    private getOverlay = () => {
        const { width, height } = this.props.size;
        if (this.props.overlay)
            return this.props.overlay(width ? width : 0, height ? height : 0);
        else
            return null;
    }

    private setupState(props: IMediaInnerProps) {
        this.shiftVideo = true;
        if (props.media.hasOwnProperty("file")) {
            // IFileMedia
            const data: IFileMedia = props.media as IFileMedia;
            if (data.file && data.type)
                this.setState({ url: URL.createObjectURL(data.file), type: data.type, subtitles: [] });
        }
        else if (props.media.hasOwnProperty("id")) {
            // IEmbedMedia vimeo | youtube
            const data: IEmbedMedia = props.media as IEmbedMedia;
            if (data.id && data.source)
                this.setState({ url: data.id, type: data.source, subtitles: [] });
        }
        else if (props.media.hasOwnProperty("url")) {
            const data: IURLMedia = props.media as IURLMedia;
            this.setState({ url: data.url, type: data.type, subtitles: [] });
        }
        else if (props.media.hasOwnProperty("container")) {
            const data: IStorageMedia = props.media as IStorageMedia;
            MediaController.GetByContainerAndName({ mediaInfo: { container: data.container, file: data.path } },
                (image: LinkDto) => {
                    const url = getLink([image], "self");
                    if (url) {
                        this.setState({ url: url.uri, type: data.type, subtitles: [] });
                    }
                });
        }
        else {
            // MediaDownloadDto
            const data: IDownloadMedia = props.media as IDownloadMedia;
            if (data.media) {
                const m = data.media as MediaDownloadDto;
                const link = getLink(data.media.links, data.refName ? data.refName : "self");
                if (link)
                    this.setState({ url: link.uri, type: data.media.mediaType, width: data.media.width, height: data.media.height, subtitles: m?.subtitles ?? [] });
            }
        }

    }

    private setContainerRef = (ref: any) => {
        if (ref && ref !== null && !this.state.container) {
            this.setState({ container: ref });
        }
    }
    private videoMetaLoaded(event: React.SyntheticEvent<HTMLVideoElement>) {
        if (event && this.props.onSizeDetected) {
            const ct = event.currentTarget;
            if (ct !== null && ct !== undefined)
                ct.currentTime = 0.5;

            this.props.onSizeDetected(ct.videoWidth, ct.videoHeight);
        }
    }

    private imageMetaLoaded(event: React.SyntheticEvent<HTMLImageElement>) {
        if (event && this.props.onSizeDetected) {
            const ct = event.currentTarget;
            this.props.onSizeDetected(ct.naturalWidth, ct.naturalHeight);

        }
        if (event && this.props.getImageCanvas) {
            const ct = event.currentTarget;
            const canvas = document.createElement("canvas");
            canvas.width = ct.naturalWidth;
            canvas.height = ct.naturalHeight;
            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.drawImage(ct, 0, 0);
            }
            this.props.getImageCanvas(canvas);
        }

    }
    private renderFullscreen = () => {
        let type = "image";
        if (this.state.type?.startsWith('video')) type = 'video';
        if (this.state.type?.startsWith('image')) type = 'image';
        const lng = (ImgI18N.getInstance().language.toLowerCase() ?? ImgI18N.getInstance().getBrowserLanguage()?.value.toLowerCase()) ?? "en";
        const sdef = getTranslatedValue(this.state.subtitles, lng);
        switch (type) {
            case 'image':
                return <img src={this.state.url} className="FullscreenImage" onClick={this.showFullScreen} alt="" />;
            case 'video':
                return <video
                    className="FullscreenImage"
                    controlsList="nodownload"
                    controls
                    autoPlay={true}
                    onLoadedMetadata={this.videoMetaLoaded}
                    crossOrigin="anonymous"
                >
                    <source src={this.state.url + "#t=" + (this.props.videoShift ? this.props.videoShift : 0.5)} type="video/mp4" />
                    {config.tubeFeatures.subtitle && _.map(this.state.subtitles, s => {
                        const l = _.find(s.links, l => l.ref === "self");
                        const d = _.find(ImgI18N.lngDefinitions, d => d.value.toLowerCase() === s.lng?.toLowerCase());
                        const label = d?.label;
                        if (l && d && label)
                            return (
                                <track key={s.reference} src={l?.uri} label={label} kind="captions" srcLang={s.lng} default={sdef !== undefined && sdef.lng === s.lng} />
                            );
                        return null;
                    })}
                </video>;

        }
    }
}

const sizeConfig = { monitorHeight: true, monitorWidth: true };
const sizeMeHOC = withSize(sizeConfig);

export default connect((state: IState) => ({ state }))(sizeMeHOC(Media));
