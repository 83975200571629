import * as React from 'react';
import ScormHeader from './ScormHeader';
import ScormLoaderDSJ from './ScormLoaderDSJ';
import ScormFooter from './ScormFooter';

const ScormAppLoader: React.SFC<{}> = (props: {}) => {
    console.log("app loader");
    return (
        <div style={{ position: "relative" }}>
            <div className="ScormOuterContainer" style={{ backgroundImage: "url('./images/scorm/background.jpg')" }}>
                <ScormHeader head />
                <ScormLoaderDSJ style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100000 }} />

                <div className="ScormContentFixed" style={{ filter: "blur(50px)" }}>
                </div>
                <ScormFooter />
            </div>
        </div >);
};
export default ScormAppLoader;