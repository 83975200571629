import * as React from "react";
import "blueimp-canvas-to-blob";
import { Button, Image as SImage, ButtonGroup, Modal, Loader, Popup } from "semantic-ui-react";
import ImageCrop, { IImageCrop } from "./ImageCrop";
import { IIMGTranslatedComponent, translate } from "../../Utils/img-i18n";

export const checkNeedEdit = (a: number | undefined, width: number, height: number) => {
    if (!a)
        return false;
    const imgAspect = Math.floor((width / height) * 100.0);
    const aspect = Math.floor(a * 100.0);
    const epsilon = 0.0001;
    return Math.abs(imgAspect - aspect) > epsilon;
}

interface IImageCropProps extends IIMGTranslatedComponent {
    src: any;
    aspect?: number;
    cropClassName?: string;
    imgClassName?: string;
    imageType?: string;
    hideButton?: boolean;
    buttonClassName?: string;
    edit?: boolean;
    startEditing?: boolean;
    noPreview?: boolean;
    onClick?: (e: any) => void;
    onCropFinish(data: any): void;
}

interface IImageCropState {
    image?: any;
    update: boolean;
    src: any;
    editSrc: any;
    edit: boolean;
    loading: boolean;
    loadingMessage: string;
    imageCrop: IImageCrop | undefined;
    cropping: boolean;
}

class ImageEdit extends React.Component<IImageCropProps, IImageCropState>{
    constructor(props: IImageCropProps) {
        super(props);
        this.state = {
            src: this.props.src,
            editSrc: this.props.src,
            update: false,
            edit: false,
            loading: false,
            loadingMessage: "",
            imageCrop: undefined,
            cropping: false,
        };

    }
    public componentDidMount() {
        this.needEdit().then(edit => this.setState({ edit }));

    }
    public UNSAFE_componentWillReceiveProps(props: IImageCropProps) {
        if (props.src !== this.props.src) {
            this.needEdit().then(edit => this.setState({ src: props.src, edit }));
        }
        if (props.edit !== this.props.edit) {
            this.setState({ edit: props.edit !== undefined ? props.edit : this.state.edit });
        }
    }

    public render() {
        return (
            <>
                {/* <SImage onClick={this.props.onClick} src={this.state.src} className={this.props.imgClassName + (this.props.hideButton ? "" : " MarginBottom10")} /> */}
                {!this.props.noPreview && <SImage onClick={this.props.onClick} src={this.state.src} className={this.props.imgClassName + " MarginBottom10"} />}
                {!this.props.hideButton &&
                    <Popup content={this.props.t("edit media")} className='ComUserPopup' trigger={
                        <Button onClick={this.onEdit} icon="edit" basic size='tiny' />
                    } />
                }
                <Modal open={this.state.edit} className='ImageCropModal'>
                    <Loader size="large" disabled={!this.state.loading}>{this.props.t(this.state.loadingMessage)}</Loader>
                    <Modal.Content>
                        <div className={this.props.cropClassName + " CropContainer"}>
                            <ImageCrop imageType={this.props.imageType} aspect={this.props.aspect} src={this.state.editSrc} undoSrc={this.props.src} getImageCrop={this.getImageCrop} />
                        </div>
                        <ButtonGroup className="MarginRight10">
                            <Button disabled={this.state.loading && !this.state.imageCrop} color="red" className={this.props.buttonClassName} onClick={this.onUndo} icon="undo" />
                        </ButtonGroup>
                        <ButtonGroup className="MarginRight10">
                            {!this.props.aspect &&
                                <Button disabled={this.state.loading && !this.state.imageCrop} color="red" className={this.props.buttonClassName} onClick={this.onToggleCrop} icon="crop" />
                            }
                            <Button disabled={this.state.loading && !this.state.imageCrop} color="red" className={this.props.buttonClassName} onClick={this.onRotateImage} icon="sync" />
                            <Button disabled={this.state.loading && !this.state.imageCrop} color="red" className={this.props.buttonClassName} onClick={this.onSave} icon="check" />
                        </ButtonGroup>
                        {!this.props.aspect &&
                            <ButtonGroup>
                                <Button disabled={this.state.loading && !this.state.imageCrop} color="red" className={this.props.buttonClassName} onClick={this.cancelEditMode} icon="cancel" />
                            </ButtonGroup>
                        }
                    </Modal.Content>
                </Modal>
            </>
        );
    }



    private needEdit = (props?: IImageCropProps) => {
        return new Promise<boolean>(async res => {
            if (this.props.startEditing) {
                return res(true);
            }
            else {
                // const epsilon = 0.0001;
                const p = props ? props : this.props;
                if (p.aspect) {
                    const a = p.aspect;
                    const img = new Image();
                    img.src = p.src;
                    img.onload = () => {
                        res(checkNeedEdit(a, img.width, img.height));
                    }
                }
                else
                    res(false);
            }
        })
    }

    private onUndo = () => {
        if (this.state.imageCrop)
            this.state.imageCrop.undo();
    }

    private cancelEditMode = () => {
        this.setState({ edit: false });
    }

    private onEdit = () => {
        this.setState({ edit: true, editSrc: this.state.src });
    }

    private onSave = () => {
        this.saveImage();
    }

    private onRotateImage = () => {
        if (this.state.imageCrop)
            this.state.imageCrop.rotate90Right();
    }


    private getImageCrop = (imageCrop: IImageCrop) => {
        this.setState({ imageCrop });
    }

    private onToggleCrop = () => {

        if (this.state.imageCrop) {
            if (this.state.cropping) {
                this.state.imageCrop.cropStop();
                this.setState({ cropping: false });
            }
            else {
                this.state.imageCrop.cropStart();
                this.setState({ cropping: true });
            }
        }
    }

    private saveImage = () => {
        if (this.state.imageCrop) {
            this.setState({ loading: true });
            const ic = this.state.imageCrop;
            ic.getBlob().then((blob) => {
                this.setState({ loading: false });
                this.props.onCropFinish(blob);
            });
            ic.getURL().then((url) => this.setState({ src: url, edit: false }));
        }
    }
}

export default translate("admin")(ImageEdit);