import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { Button, Modal, SemanticICONS, SemanticWIDTHS, StrictModalProps } from 'semantic-ui-react';


interface IExciteModalProps extends StrictModalProps, IIMGTranslatedComponent {
    buttonClick?: () => void;
    width?: SemanticWIDTHS;
    icon?: SemanticICONS;
    closable?: boolean;
    gridClassName?: string;
    contentClassName?: string;
    headline?: string;
    className?: string;
    buttonClassName?: string;

    buttons?: JSX.Element[];
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
    hideTitle?: boolean;
    realFS?: boolean;
    extraClass?: string;
    closeOnDimmerClick?: boolean;
}

class PopupModal extends React.Component<IExciteModalProps, {}>{
    public render() {
        const { children, buttonClick, closable, contentClassName, gridClassName, t, buttonClassName, hideTitle, extraClass, realFS, ...rest } = this.props;
        const cls: boolean = closable === undefined || closable === true;
        return (
            <Modal
                //basic={true}
                dimmer='blurring'
                size={this.props.size ? this.props.size : 'small'}
                id={this.props.realFS ? "fullscreenModal" : undefined}
                closeOnDimmerClick={this.props.closeOnDimmerClick !== undefined ? this.props.closeOnDimmerClick : false}
                {...rest}
            >
                {!this.props.hideTitle &&
                    <Modal.Header>
                        {this.props.headline ? this.props.headline : this.props.t("Informations")}
                    </Modal.Header>
                }
                <Modal.Content scrolling className={this.props.extraClass}>
                    {/* <Modal.Description> */}
                    {this.props.children}
                    {/* </Modal.Description> */}
                </Modal.Content>
                {cls && !this.props.buttons &&
                    <Modal.Actions>
                        <Button color='red' icon='times' onClick={buttonClick} />
                    </Modal.Actions>
                }
                {this.props.buttons &&
                    <Modal.Actions>
                        {this.props.buttons.map((b) => b)}
                    </Modal.Actions>
                }
            </Modal>
        );
    }
}
export default translate("admin")(PopupModal);
