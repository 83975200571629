import * as React from 'react';
import styled from "styled-components";
import { sizeConditional, SizeConditionalComponent, ScreenBreakPoint } from 'components/General/SizeConditional/SizeConditional';
import ContentTile from './ContentTile';
import * as _ from "lodash";
import { ContentController, CategoryDownloadDto, PostController, PostDownloadDto, ContentPostDownloadDto, ControllerHelper } from 'collaboration-service';
import { Icon, Popup, Button, Input, SemanticICONS } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { IState, IConnectedComponent } from 'services/Interfaces';
import { translateUsername } from 'services/Helpers';
import { ActionCreators } from 'services/Actions';
import { config } from 'services/Config';
import { Link, RouteComponentProps } from 'react-router-dom';


export const breakpoints = {
    smallMobile: { width: 320 } as ScreenBreakPoint,
    mobile: { width: 375 } as ScreenBreakPoint,
    tablet: { width: 768 } as ScreenBreakPoint,
    lTablet: { width: 992 } as ScreenBreakPoint,
    xlTablet: { width: 1024 } as ScreenBreakPoint,
    desktop: { width: 1280 } as ScreenBreakPoint,
    xlDesktop: { width: 1440 } as ScreenBreakPoint,
    wide: { width: 1600 } as ScreenBreakPoint,
    ultraWide: { width: 1920 } as ScreenBreakPoint,
    extremlyWide: { width: 2560 } as ScreenBreakPoint
}

export type Breakpoints = keyof typeof breakpoints;

export const Container = styled.div<{ cols: number, count: number }>`
    margin: auto;
    padding-top:120px;
    display: grid;
    display: -ms-grid;
    width: ${props => 320 * props.cols}px;
    grid-template-columns: ${props => _.times(props.cols, x => "320px ")};
    -ms-grid-columns: ${props => _.times(props.cols, x => "320px ")};
    ${props => props.count > props.cols ? "grid-tempalte-rows: " + _.times(Math.floor(props.count / props.cols) + 1, x => "1fr ") : ""};
    ${props => props.count > props.cols ? "-ms-grid-rows: " + _.times(Math.floor(props.count / props.cols) + 1, x => "1fr ") : ""};
`;

export const ContentContainer = styled.div<{ col: number, cols: number }>`
    grid-column-start: ${props => (props.col % props.cols) + 1};
    -ms-grid-column: ${props => (props.col % props.cols) + 1};
    grid-column: ${props => (props.col % props.cols) + 1};
    grid-row-start: ${props => Math.floor(props.col / props.cols) + 1};
    -ms-grid-row: ${props => Math.floor(props.col / props.cols) + 1};
    grid-row: ${props => Math.floor(props.col / props.cols) + 1};
    padding: ${props => props.cols > 1 ? "10px" : "10px 0px"};
`;

export const Contents = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: url('https://imaginarity.com/gelb.svg'), url('https://imaginarity.com/grun.svg'), url('https://imaginarity.com/blau.svg');
    background-size: contain, contain, auto 100%;
    background-position: top left, bottom, top right;
    background-repeat: no-repeat;
    @import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow&display=swap');
    font-family: 'PT Sans Narrow', sans-serif;
    grid-template-columns: auto 320px 320px 320px 320px auto;
    color: #333F49;
    padding-bottom:150px;
`;

export const Headline = styled.div`
    font-size: 1.6rem;
    padding-left:20px;
    line-height:1.65rem;
    font-weight: bolder;
`;

export const Logo = styled.img`
    width:100%;
    max-width:375px;
    height: auto;  
    padding:20px;        
    @media only screen and (min-width: 376px) and (max-width: 414px) {
        max-width: 414px;
    };         
`;

export const Login = styled.div`
    width:100%;
    max-width:375px;
    font-size: 0.95rem;
    line-height:0.95rem;
    margin-top:25px;
    @media only screen and (min-width: 376px) and (max-width: 414px) {
        max-width: 414px;
    };
    .ui.circular.button{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height:36px;
    }
    .ui.button {
        border-top-left-radius: 10em;
        border-bottom-left-radius: 10em;
        height:36px;
    }
    .input {
        width: calc(100% - 42px);
        margin-bottom:5px;
    }
    .ui.input{
        color: #333F49;
    }
    .ui.blue.button, .ui.blue.buttons .button{
        background-color: #FECF48;
        color:#333F49;
        &:hover{
            background-color: #333F49;
            color:#FECF48;
        }
    }

`;

export const Legend = styled.div<{ color: string }>`
    width:100%;
    max-width:375px;
    padding:10px 20px;
    font-size: 1rem;
    margin-top:25px;
    color: ${props => props.color};
    border-radius:40px;
    text-align:center;
    background: #FFFFFFd1;
    @media only screen and (min-width: 376px) and (max-width: 414px) {
        max-width: 414px;
    };
    i.icon, i.icons{
        color: ${props => props.color};
    }
`;

export const LegendItem = styled.div`
    width: fit-content;
    padding:5px 15px;
    display: inline-block;
`;

export const InfoContainer = styled.div`
    float: left;
    width: 100%;
    max-width: 375px;
    padding: 0 10px;
    @media only screen and (min-width: 376px) and (max-width: 414px) {
        max-width: 414px;
    };
`;


export interface ContentAggregationOverviewProps extends IConnectedComponent, SizeConditionalComponent, RouteComponentProps<{ gid?: string }> {
}

interface ContentAggregationOverviewState {
    cats?: CategoryDownloadDto[];
    posts?: PostDownloadDto[];
    loggedIn: boolean;
}
class ContentAggregationOverview extends React.Component<ContentAggregationOverviewProps, ContentAggregationOverviewState> {

    public state: ContentAggregationOverviewState = { loggedIn: false };
    async componentDidMount() {
        const groupid = this.props.match.params.gid ?? this.props.state.userInfo?.userSettings.lastGroupId;
        if (groupid) {
            const cats = await ControllerHelper.singleCall({ groupid }, ContentController.GetCategories);
            const first = await ControllerHelper.singleCall({ filter: { onlyInGroups: [groupid], includedPostTypes: ["Quiz", "ContentPost", "SlideShow", "TubePost"] }, token: { token: "" } }, PostController.GetPagedForAllGroups);
            const loaded = first.elements;
            let token = first.data;
            while (token) {
                //console.log(token);
                const data = await ControllerHelper.singleCall({ filter: { onlyInGroups: [groupid], includedPostTypes: ["Quiz", "ContentPost", "SlideShow", "TubePost"] }, token: { token } }, PostController.GetPagedForAllGroups);
                loaded.push(...data.elements);
                token = data.data;
            }
            const posts = _.map(loaded, (e: any) => {
                if (!e.categories || e.categories.length === 0)
                    return ({ ...e, categories: [cats[0]] });
                return e;
            });
            this.setState({ cats, posts });
        }

    }
    public render() {
        const catPostMap: Array<{ posts: PostDownloadDto[], category: CategoryDownloadDto }> = [];
        _.forEach(this.state.cats, c => {
            const posts = _.filter(this.state.posts, p => (p as ContentPostDownloadDto).categories && (p as ContentPostDownloadDto).categories.length > 0 && (p as ContentPostDownloadDto).categories[0].id === c.id);
            if (posts && posts.length > 0)
                catPostMap.push({ posts, category: c });
        });
        const count = catPostMap.length;
        const cols = Math.min(Math.floor(this.props.width / 320), count);
        const colors = ["#a6b043", "#fecf48", "#78a9d1"];
        let admin = false;
        if (this.props.state.userInfo) {
            const find = _.find(this.props.state.userInfo.groupRoles, gr => gr.groupId === this.props.state.userInfo?.userSettings.lastGroupId);
            const globalFind = _.find(this.props.state.userInfo.groupRoles, gr => gr.groupId === "GLOBAL");
            admin = (globalFind !== undefined && _.findIndex(globalFind.roles, r => r === "Admin") >= 0) ||
                (find !== undefined && _.findIndex(find.roles, r => r === "Admin" || r === "Moderator") >= 0);
        }

        if (this.props.height !== 0 && this.state.cats && this.state.posts)
            return (
                <Contents>

                    <InfoContainer>
                        <Logo src="https://imaginarity.com/lampertheim.svg" />
                        <Headline>
                            Bewerberplattform<br />Verwaltungsfachwirt/-in
                        </Headline>
                        <Login>
                            {this.props.state.userInfo ?
                                <>
                                    <Icon name="user" size="big" style={{ float: "left", marginRight: 10, marginTop: -2 }} />
                                    <Popup className='ComUserPopup' content="Logout" position="bottom right"
                                        trigger={
                                            <Button color="red" floated="right" circular icon="log out" onClick={this.logOut} style={{ marginTop: -5 }} />
                                        } />
                                    {admin &&
                                        <Link to={config.routes.general.postsAdmin}>
                                            <Popup className='ComUserPopup' content="Administration" position="bottom left"
                                                trigger={
                                                    <Button icon="cogs" color="blue" floated="right" style={{ marginTop: -5 }} />
                                                } />
                                        </Link>
                                    }
                                    <div style={{ fontWeight: "bolder" }}>{translateUsername(this.props.state.userInfo)}</div>
                                    {this.props.state.userInfo.email}
                                </>
                                :
                                <>
                                    <Input icon='user' iconPosition='left' placeholder='Dein Name' fluid />
                                    <Input type="password" icon='key' iconPosition='left' placeholder='Dein Name' fluid />
                                    <Button color="red" circular icon="user" content="Bitte logge Dich ein" fluid size="large" onClick={this.logIn} />
                                </>
                            }
                        </Login>

                        <Legend color={colors[2]}>
                            <LegendItem><Icon name={config.onboardingIcons.video as SemanticICONS} />Video</LegendItem>
                            <LegendItem><Icon name={config.onboardingIcons.content as SemanticICONS} />Read</LegendItem>
                            <LegendItem><Icon name={config.onboardingIcons.slideshow as SemanticICONS} />Slideshow</LegendItem>
                            <LegendItem><Icon name={config.onboardingIcons.quizPlayable as SemanticICONS} />Test</LegendItem>
                            <LegendItem><Icon.Group><Icon name={config.onboardingIcons.quizPlayable as SemanticICONS} /><Icon corner="bottom left" name={config.onboardingIcons.quizUnplayable as SemanticICONS} /></Icon.Group>Test absolviert</LegendItem>
                        </Legend>
                    </InfoContainer>
                    <Container cols={cols} count={count}>
                        {_.map(catPostMap, (c, i) => (
                            <ContentContainer key={c.category.id} col={i} cols={cols}>
                                <ContentTile
                                    color={colors[i % colors.length]}
                                    category={c.category} posts={c.posts}
                                    width={this.props.width}
                                    height={this.props.height}
                                    currentBreakpoint={this.props.currentBreakpoint}
                                    breakpoints={this.props.breakpoints}
                                />
                            </ContentContainer>
                        ))}
                    </Container>
                </Contents>
            );
        else
            return <Container cols={1} count={1} />;
    }
    private logIn = () => this.setState({ loggedIn: true });
    private logOut = () => this.props.dispatch(ActionCreators.globalState.Logout());
}

export default connect((state: IState) => ({ state }))(sizeConditional({ updateSize: true, breakpoints, initialCurrentBreakpoint: "smallMobile", className: "sizeconditional-masterlayout" })(ContentAggregationOverview));