import "./wdyr";
import './semantic/dist/semantic.min.css';
import "@webscopeio/react-textarea-autocomplete/style.css";
import "babel-polyfill";
//import UserTile from "components/General/UserTile/UserTile";
import 'cropperjs/dist/cropper.css';
import "es6-string-polyfills";
import { PluginRegistry } from "imaginarity-platform-plugin-base";
import { defaultTheme } from "imaginarity-react-ui";
// import 'react-image-crop/dist/ReactCrop.css';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import * as ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-responsive-tabs/styles.css';
import "react-table/react-table.css";
import 'react-tabs/style/react-tabs.css';
import 'react-virtualized/styles.css';
import { getBrowser } from "services/Helpers";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
//import UserDetails from "views/Admin/GroupManagement/UserDetails";
import App from './App';
import './main.quiz.css';
// import './main.quiz.css';
import registerServiceWorker from './registerServiceWorker';
//import UserTile from "components/General/UserTile/UserTile";
// import 'react-image-crop/dist/ReactCrop.css';
//import UserDetails from "views/Admin/GroupManagement/UserDetails";
// import './main.quiz.css';


(global as any).SC_DISABLE_SPEEDY = true;

const browser = getBrowser();
defaultTheme.browser = browser.name;

window.React = require("react");
window.ReactDOM = require("react-dom");
window._ = require("lodash");
(window as any).styledComponents = require("styled-components");
(window as any).imgPlatformPluginBase = require("imaginarity-platform-plugin-base");
(window as any).axios = require("axios");
(window as any).redux = require("redux");
(window as any).imaginarityReactUI = require("imaginarity-react-ui");

const pluginRegistry = PluginRegistry.getInstance();
if (pluginRegistry) {
  //console.log("registering it!");
  //pluginRegistry.typeRendererRegistry.registerRenderer(i => i.UserShortInfoDownloadDto, (p) => <UserTile user={p.data} />);
  //pluginRegistry.typeRendererRegistry.registerRendererWithName("Details", i => i.UserShortInfoDownloadDto, (p) => <UserDetails user={p.data} />);
}


const promise = new Promise<void>(res => {
  const req = new XMLHttpRequest();
  if (browser.name !== "ie") {
    //    console.log("loading plugin for storage");
    req.open("GET", "/statics/img-blob-storage-uploader.plugin.js");
  }
  else {
    //    console.log("whatever");
    res();
  }
  req.onload = () => {
    //    console.log(req.status);
    if (req.status === 200) {
      //      console.log(req.responseText);
      // eslint-disable-next-line
      eval(req.responseText).default;
      res();
    }
  };
  req.send();
});
promise.then(() => {
  ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
  );
  registerServiceWorker();
});




interface Blueberry { strawberry: boolean; }
interface Car { vehicle: number; }

interface Interfaces {
  Blueberry: Blueberry;
  Car: Car;
}

//console.log(nameof<Interfaces>(i => i.Blueberry));