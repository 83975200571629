import * as React from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';

interface IYesOrNoModalProps {
    text: string;
    open?: boolean;
    onYes?: () => void;
    onNo?: () => void;
    onFinished?: (result: boolean) => void;
}

export default class YesOrNoModal extends React.Component<IYesOrNoModalProps, any> {
    public render() {
        const yes = () => {
            if (this.props.onFinished)
                this.props.onFinished(true);
            if (this.props.onYes)
                this.props.onYes();
        };
        const no = () => {
            if (this.props.onFinished)
                this.props.onFinished(false);
            if (this.props.onNo)
                this.props.onNo();
        };
        return (
            <Modal
                basic={false}
                open={this.props.open}
                onClose={no}
                size={"mini"}
                closeOnDimmerClick={false}
            >
                <Header icon='attention' color='red' content="" />
                <Modal.Content>
                    <h5>{this.props.text}</h5>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" onClick={yes} inverted={true} icon="checkmark" />
                    <Button color="red" onClick={no} inverted={true} icon="cancel" />
                </Modal.Actions>
            </Modal>
        );
    }
}
