import { ContentMediaUploadDto, ContentPostContentUploadDto, ControllerHelper, MediaUploadDto, PostControllerTube, TubePostDownloadDto, TubePostUploadDto, UserShortInfoDownloadDto } from "collaboration-service";
import FileSelector from "components/General/FileSelector/FileSelector";
import LanguageSelection from "components/General/LanguageSelection";
import MissingInput from "components/General/MissingInput/MissingInput";
import UserSearchSelection from "components/General/UserSearchSelection/UserSearchSelection";
import ImgI18N, { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { Button, fadeColor, Flag, getColor, Icon, Input, lightenColor, Modal, Progress } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { DragDropContext, DragStart, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { printBytes, uploadMediaToStorageForAsset } from "services/Helpers";
import styled from "styled-components";
import { Action, ActionFooter, AssetUploadContainer, Description, DescriptionFooter, FormRow, FormRowFooter } from "./AssetUploadSelection";
import DragList, { Title, UploadFile } from "./DragList";
import UploadFileEntry from "./UploadFileEntry";
interface AssetManagementTubeProps extends IIMGTranslatedComponent {
    onTubePostCreated: (tubePost: TubePostDownloadDto | undefined, readUsers: UserShortInfoDownloadDto[] | undefined, writeUsers: UserShortInfoDownloadDto[] | undefined) => void;
    onClose: () => void;
}

const resMap = {
    "SD": { width: 720, height: 576 },
    "HD": { width: 1920, height: 1080 },
    "4K": { width: 3840, height: 2160 },
}

const videoEndings = [
    "mp4",
    "mov",
]


const imageEndings = [
    "jpg",
    "png",
]

const vttEndings = [
    "vtt",
]

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    display: -ms-grid;
    grid-column-gap: 10px;

    //grid-template-rows: 40px 60px 60px 1fr;
    //-ms-grid-rows: 40px 60px 60px 1fr;

    //grid-template-rows: 0 0 0 auto;
    //-ms-grid-rows: 0 0 0 1fr;

    grid-template-columns: max-content 1fr 1fr;
    -ms-grid-columns: max-content 1fr 1fr;
    
`;

export const Col = styled.div<{ col: number, row?: number, padding?: string, colEnd?: number }>`
    grid-column-start: ${p => p.col};
    grid-column-end: ${p => p.colEnd ?? p.col + 1};
    -ms-grid-column: ${p => p.col};
    grid-column: ${p => p.col};
    ${p => p.row ? `grid-row: ${p.row}; -ms-grid-row: ${p.row};` : ""}
    ${p => p.padding ? "padding: " + p.padding : "padding: 0px"};
`;

export const Row = styled.div<{ row: number, padding?: string }>`
    width: 100%;
    height: 100%;
    grid-row: ${p => p.row};
    -ms-grid-row: ${p => p.row};
    ${p => p.padding ? "padding: " + p.padding : "padding: 0px"};
`;

const SubTitle = styled(Title)`
    margin-top: 10px;
`;

const SubTitleList = styled.div<{ highlight?: boolean }>`
    width: 100%;
    min-height: 44px;
    background: ${p => p.highlight ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 15) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 3)};
    transition: 0.2s all ease;
`;

const ListGrid = styled.div<{ buttons?: boolean }>`
    width: 100%;
    height: calc(100vh - 115px);
    display: grid;
    display: -ms-grid;
    grid-template-rows: 50px 1fr 0 ;
    -ms-grid-rows: 50px 1fr 0 ;
`;

const ListContainer = styled.div`
    width: 100%;
    height:100%;
    border: 1px solid ${p => getColor(p, p.theme.colors.lightGrey)}; 
    overflow-y: scroll;
    padding-left:10px; 
`;

const VideoEntry = styled.div<{ altColor?: boolean, highlight?: boolean }>`
    width: 100%;
    height: 40px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 25px 30px 1fr 18px;
    -ms-grid-columns: 25px 30px 1fr 18px;
    line-height: 40px;
    background-color: ${p => p.highlight ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 15) : p.altColor ? fadeColor(p, getColor(p, p.theme.colors.darkGrey), 3) : fadeColor(p, getColor(p, p.theme.colors.accentBlack), 3)};
    margin-top:1px;
    transition: 0.2s all ease;
`;

const ThumbEntry = styled.div<{ highlight?: boolean }>`
    width: 100%;
    height: 40px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 25px 30px 1fr 18px;
    -ms-grid-columns: 25px 30px 1fr 18px;
    line-height: 40px;
    margin-top:1px;
    background-color: ${p => p.highlight ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 15) : fadeColor(p, getColor(p, p.theme.colors.accentBlack), 3)};
    transition: 0.2s all ease;
`;

const SubTitleEntry = styled.div<{ highlight?: boolean }>`
    width: 100%;
    height: 44px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 25px  30px 1fr 1fr 18px;
    -ms-grid-columns: 25px  30px 1fr 1fr 18px;
    background: ${p => p.highlight ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 15) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 3)};
    margin-bottom: 1px;
    transition: 0.2s all ease;
`;

const PackList = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px 0 10px 10px;
    border: 1px solid ${p => getColor(p, p.theme.colors.lightGrey)};
    overflow: scroll;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
`;
const PackContainer = styled.div`
    width: 100%;
    padding: 5px;
    border: 1px solid ${p => getColor(p, p.theme.colors.lightGrey)};
    margin-bottom: 10px;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
`;

const Empty = styled.div<{ empty: boolean }>`
    color: ${p => p.empty ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.lightGrey)};
    width: 100%;
    height: 100%;
    line-height:40px;
`;
const New = styled.div`
    color: ${p => getColor(p, p.theme.colors.accentGreen)};
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, ${p => fadeColor(p, getColor(p, p.theme.colors.accentGreen), 7)} 15%, rgba(0,0,0,0) 100%);
    width: 100%;
    height: 40px;
    font-weight: bolder;
    transition: 0.2s all ease;
    margin-top:-3px;
    line-height: 40px;
    padding-top:2px;
    &::before { 
        float: left;
        content: ' ';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' view-box='0 0 30 30' stroke='%23149500' fill='none' stroke-width='2.167' stroke-linejoin='round' stroke-linecap='round'%3E%3Cpath d='M11.8,21.5l6.5-6.5l-6.5-6.5' /%3E%3C/svg%3E");
        background-size: 30px 30px;
        margin-top:5px;
        height: 30px;
        width: 30px;
        }
`;
const NewSubTitle = styled(New)`
    height: 44px;
    margin-top:0px;
    line-height: 44px;
    padding-top:0px;
`;

const Entry = styled.div`
    line-height: 40px;
`;
const EntrySubTitle = styled.div`
    line-height: 44px;
`;

const MissingItem = styled.div`
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, ${p => fadeColor(p, getColor(p, p.theme.colors.accentRed), 10)} 100%);
    border-right: 1px solid ${p => getColor(p, p.theme.colors.accentRed)};
    color: ${p => getColor(p, p.theme.colors.accentRed)};
    line-height:40px;
    padding-left: 10px;

    h5{
        color: ${p => getColor(p, p.theme.colors.accentRed)};
        text-transform: uppercase;
        line-height: 40px;
        width: calc(100% - 40px);
        float: right;
        text-align: right;
        padding-right: 10px;
    }

    svg{
        stroke: ${p => getColor(p, p.theme.colors.accentRed)};
    }
`;

interface SubTitle {
    lng: string;
    subtitles: File;
}

interface LngPack {
    lng: string;
    videos: { [resolution: string]: File | undefined };
    thumb?: File;
    subtitles: SubTitle[];
}

const PackHeader = styled.div`
    width: 100%;
    height: 55px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 50px 1fr 50px;
    -ms-grid-columns: 50px 1fr 50px;
    margin-bottom: -15px;
`;

const ToggleContainer = styled.div<{ visible: boolean; }>`
    display: ${p => p.visible ? "block" : "none"};
`;

const LngSelContainer = styled.div`
    margin-bottom: 10px;
    padding-top:1px;
`;

const NoFiles = styled.div`
    padding: 0 5px;
    line-height: 40px;
    color: ${p => getColor(p, p.theme.colors.lightGrey)};
    svg{
        stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
    }
`;

const FileSelectorContainer = styled.div<{ error: boolean }>`
    float: right;
    margin-right: -3px;
    .ui.basic.button, .ui.basic.buttons .button{
        height: 40px;
        background-color: ${p => p.error ? fadeColor(p, getColor(p, p.theme.colors.accentRed), 10) : "transparent"} !important;
        color:${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)} !important;
        box-shadow: none;
        border: 1px solid ${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)};
        color: ${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)};
        &:hover{
            color:${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)} !important;
            box-shadow: none;
            border: 1px solid ${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)};
            color: ${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)};
        }
    }
`;

const fileCmpStr = (file: File) => `${file.name}--${file.size}--${file.lastModified}`

const getNextLng = (cur: string, restriction: Array<{ lng: string }>) => {
    const lngs = _.filter(_.map(_.sortBy(ImgI18N.lngDefinitions, lx => lx.order), ll => ll.value), lll => _.findIndex(restriction, s => s.lng === lll) < 0);
    return cur && _.findIndex(restriction, s => s.lng === cur) < 0 ? cur : lngs[0];
}

const getFlag = (lng: string) => {
    const f = _.find(ImgI18N.lngDefinitions, ld => ld.value === lng);
    return f?.flag[0].toUpperCase() as any;
};

const initVideos = () => {
    const toRet: { [resolution: string]: File | undefined } = {};
    _.forEach(Object.keys(resMap), k => toRet[k] = undefined);
    return toRet;
}

const AssetManagementTube = (p: AssetManagementTubeProps) => {
    const [dragged, setDragged] = React.useState<string | undefined>();
    const [readUsers, setReadUsers] = React.useState<UserShortInfoDownloadDto[] | undefined>([]);
    const [writeUsers, setWriteUsers] = React.useState<UserShortInfoDownloadDto[] | undefined>([]);
    const [title, setTitle] = React.useState("");

    const [files, setFiles] = React.useState<UploadFile[]>([
        // dummies for debugging
        // { file: { name: "test1.mp4" } as File, progress: 0 },
        // { file: { name: "test2.mp4" } as File, progress: 50 },
        // { file: { name: "test3.mp4" } as File, progress: 20 },
        // { file: { name: "test4.mp4" } as File, progress: 0 },
        // { file: { name: "test1.jpg" } as File, progress: 30 },
        // { file: { name: "test2.jpg" } as File, progress: 0 },
        // { file: { name: "test3.jpg" } as File, progress: 0 },
        // { file: { name: "test1-de.vtt" } as File, progress: 100 },
        // { file: { name: "test1-fr.vtt" } as File, progress: 0 },
    ]);
    const [showProgressDlg, setShowProgressDlg] = React.useState(false);

    const [lngPacks, setLngPacks] = React.useState<LngPack[]>([
        {
            lng: "de",
            videos: initVideos(),
            subtitles: [],
        }
    ]);

    const onClose = () => {
        p.onClose();

    }

    const filesSelected = (uploadFiles: File[] | undefined) => {
        console.log(files);
        let f = _.clone(files);
        const nf = _.map(uploadFiles, file => ({ file, progress: 0 }));
        f.push(...nf);
        f = _.uniqBy(f, v => fileCmpStr(v.file));
        setFiles(f);
    }
    const titleChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(event?.target?.value ?? "")
    }
    const toHide = _.map(_.union(readUsers, writeUsers), u => u.id);
    const videos = _.sortBy(_.filter(files, f => _.find(videoEndings, v => f.file.name.toLowerCase().endsWith(v)) !== undefined), f => f.file.name);
    const images = _.sortBy(_.filter(files, f => _.find(imageEndings, i => f.file.name.toLowerCase().endsWith(i)) !== undefined), f => f.file.name);
    const subtitles = _.sortBy(_.filter(files, f => _.find(vttEndings, v => f.file.name.toLowerCase().endsWith(v)) !== undefined), f => f.file.name);
    const findFile = (file: File, fs?: UploadFile[]) => {
        const str = fileCmpStr(file);
        const idx = _.findIndex(fs ?? files, f => fileCmpStr(f.file) === str);
        return idx >= 0 ? idx : undefined;
    };

    let needed: number[] = [];
    _.forEach(lngPacks, lp => {
        _.forEach(lp.subtitles, s => {
            const idx = findFile(s.subtitles);
            if (idx !== undefined)
                needed.push(idx);
        });
        if (lp.thumb) {
            const idx = findFile(lp.thumb);
            if (idx !== undefined)
                needed.push(idx);
        }
        _.forEach(lp.videos, v => {
            if (v) {
                const idx = findFile(v);
                if (idx !== undefined)
                    needed.push(idx);
            }
        });
    });
    // console.log("before :", _.clone(needed));
    needed = _.sortBy(_.uniq(needed), v => v);
    // console.log("after :", _.clone(needed));

    const submit = async () => {
        setShowProgressDlg(true);
        const promises = _.map(needed, n => uploadMediaToStorageForAsset(files[n].file, (p) => {
            const f = _.clone(files);
            console.log("n => ", n, ", p => ", p);
            f[n].progress = p * 100;
            setFiles(f);
        }, files[n].abort));
        const pres = await Promise.all(promises);
        const fs = _.clone(files);
        _.forEach(pres, (px, i) => {
            fs[needed[i]].media = px;
        });

        const tube: TubePostUploadDto = {
            categories: [],
            content: [],
            group_id: "ASSET",
            headlines: [{
                lng: ImgI18N.getInstance().language,
                text: title
            }],
            tags: [],
            descriptions: [],
            stickyHours: 0,
            media: {} as MediaUploadDto,
            languageTags: [],
        };
        _.forEach(lngPacks, lp => {
            console.log("vids => ", lp.videos);
            _.forEach(lp.videos, (v, k) => {
                const res = (resMap as any)[k];
                if (v && res) {
                    const idx = findFile(v, fs);
                    if (idx) {
                        console.log("idx => ", idx);
                        const f = files[idx];
                        console.log("f => ", f)
                        if (f && f.media) {
                            const tidx = lp.thumb ? findFile(lp.thumb, fs) : undefined;
                            const t = tidx !== undefined ? files[tidx] : undefined;
                            const subs: ContentMediaUploadDto[] = [];
                            _.forEach(lp.subtitles, s => {
                                const sidx = findFile(s.subtitles, fs);
                                const sub = sidx ? files[sidx] : undefined;
                                if (sub && sub.media)
                                    subs.push({ ...sub.media, lng: s.lng, mediaType: "text/vtt" })
                            })

                            const cp: ContentPostContentUploadDto = {
                                lng: lp.lng,
                                useContent: true,
                                thumbnails: t && t.media ? [t.media] : [],
                                content: {
                                    ...f.media,
                                    subtitles: subs,
                                    ...res,
                                },
                                link: "",
                                contentAttribute: "SD",
                            };
                            tube.content.push(cp);
                        }
                    }
                }
            });
        });

        // console.log(tube);

        const saved = await ControllerHelper.singleCall({ data: tube, thumbnail: {}, content: {} }, PostControllerTube.PostTubePost, true);
        // console.log(saved);
        setShowProgressDlg(false);
        p.onTubePostCreated(saved, readUsers, writeUsers);
    }

    const dragEnd = (result: DropResult, provided: ResponderProvided) => {
        setDragged(undefined);
        // console.log(result);
        if (result.reason === "DROP") {
            let item: UploadFile | undefined;
            switch (result.source.droppableId) {
                case "VIDEOS":
                    item = videos[result.source.index];
                    break;
                case "IMAGES":
                    item = images[result.source.index];
                    break;
                case "SUBTITLES":
                    item = subtitles[result.source.index];
                    break;
            }
            if (item) {
                if (result.destination) {
                    const path = result.destination.droppableId.split("/");
                    //                    console.log(path);
                    switch (path[0]) {
                        case "pack":
                            {
                                const packs = _.clone(lngPacks);
                                const lp = _.find(packs, l => l.lng === path[1])
                                //                                console.log(lp);
                                if (lp && path[2] === "videos" && result.source.droppableId === "VIDEOS") {
                                    lp.videos[path[3]] = item.file;
                                    setLngPacks(packs)

                                }
                                if (lp && path[2] === "thumb" && result.source.droppableId === "IMAGES") {
                                    lp.thumb = item.file;
                                    setLngPacks(packs)
                                }
                                if (lp && path[2] === "sub" && result.source.droppableId === "SUBTITLES") {
                                    const lng = getNextLng(lp.lng, lp.subtitles);
                                    if (lng) {
                                        lp.subtitles.push({ lng, subtitles: item.file });
                                        setLngPacks(packs)
                                    }
                                }
                            }
                            break;
                    }
                }

            }
        }
    }
    const dragStart = (initial: DragStart, provided: ResponderProvided) => {
        //        console.log(initial);
        if (_.find(videoEndings, v => initial.draggableId.toLowerCase().endsWith(v))) {
            setDragged("video");
        }
        else if (_.find(imageEndings, v => initial.draggableId.toLowerCase().endsWith(v))) {
            setDragged("image");
        }
        else if (_.find(vttEndings, v => initial.draggableId.toLowerCase().endsWith(v))) {
            setDragged("vtt");
        }
        else
            setDragged(undefined);
    }

    const addPack = () => {
        const lng = getNextLng("", lngPacks);
        //        console.log(lng);
        if (lng) {
            const lp = _.clone(lngPacks);
            lp.push({
                lng,
                videos: initVideos(),
                subtitles: [],
            })
            setLngPacks(lp);
        }
    }

    const lpHL = _.map(lngPacks, l => l.lng);

    const missing: string[] = [];
    if (title.length < 3)
        missing.push(p.t("describing title missing"));
    if (lngPacks.length === 0)
        missing.push(p.t("no language packs defined"));
    _.forEach(lngPacks, lp => {
        const videos = _.filter(Object.values(lp.videos), v => v !== undefined);
        if (videos.length === 0)
            missing.push(p.t("missing video for language {{lng}}", { lng: lp.lng }));

    });

    let sumBytes = 0;
    let sumProgressBytes = 0;
    let done = 0;
    if (showProgressDlg) {
        sumBytes = _.sum(_.map(needed, n => files[n].file.size));
        sumProgressBytes = _.sum(_.map(needed, n => files[n].progress * files[n].file.size * 0.01));
        done = _.sum(_.map(needed, n => files[n].progress === 100 ? 1 : 0));
    }

    return (
        <AssetUploadContainer>
            <Modal
                active={showProgressDlg}
                width="default"
                height="full"
                header={p.t("uploading files") + " " + p.t("({{current}} of {{count}} files done)", { current: done, count: needed.length })}
                footer={<Progress
                    inline
                    value={sumBytes > 0 ? 100 * (sumProgressBytes / sumBytes) : 0}
                    valueMax={100}
                    icon="upload"
                    showValueAs="percent"
                    scheme="default"
                    labelInside={`(${printBytes(sumProgressBytes)} / ${printBytes(sumBytes)})`}
                />}
            >

                <ListContainer >
                    {_.map(needed, (n, i) => {
                        return (
                            <UploadFileEntry
                                icon="upload"
                                file={files[n].file}
                                index={i}
                                key={files[n].file.name}
                                progress={files[n].progress}
                            />
                        )
                    })}
                </ListContainer>
            </Modal>

            <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart}>
                <Container>
                    <Col col={1} row={1}>

                        <FormRow>
                            <Description>Title</Description>
                            <Action>
                                <Input
                                    width="100%"
                                    onChange={titleChanged}
                                    value={title}
                                    inline
                                    kind={title.length < 3 ? "error" : "default"}
                                />
                            </Action>
                        </FormRow>
                        <FormRow>
                            <Description>
                                {p.t("users to add (read only access)")}
                            </Description>
                            <Action>
                                <UserSearchSelection
                                    onUserChanged={setReadUsers}
                                    value={readUsers}
                                    clearable
                                    multi
                                    idsToHide={toHide}
                                />
                            </Action>
                        </FormRow>
                        <FormRow>
                            <Description>
                                {p.t("users to add (read/write access)")}
                            </Description>
                            <Action>
                                <UserSearchSelection
                                    onUserChanged={setWriteUsers}
                                    value={writeUsers}
                                    clearable
                                    multi
                                    idsToHide={toHide}
                                />
                            </Action>
                        </FormRow>
                        <FormRowFooter items={missing.length}>
                            <DescriptionFooter>
                                <Button kind="secondary" icon="chevron left double" floated="left" onClick={onClose} />
                                {missing.length > 0 && <MissingItem><h5>Missing inputs</h5></MissingItem>}
                            </DescriptionFooter>
                            <ActionFooter items={missing.length} >
                                {missing && missing.map((v, i) => <MissingItem key={i}>
                                    <Icon name="exclamation circle" style={{ float: "left", marginRight: 5 }} />
                                    {v}
                                </MissingItem>
                                )}
                                {missing.length === 0 &&
                                    <>
                                        <Button kind="sendButton" floated="right" icon="save" content={p.t("save")} onClick={submit} />
                                    </>
                                }
                            </ActionFooter>
                        </FormRowFooter>
                    </Col>
                    <Col col={2} row={1}>
                        <ListGrid buttons>
                            <Row row={1}>
                                <div style={{ float: "left", marginTop: 10, fontWeight: "bolder", textTransform: "uppercase" }}>
                                    {p.t("files to add")}
                                </div>
                                <FileSelectorContainer error={files.length === 0}>
                                    <FileSelector
                                        onFilesSelected={filesSelected}
                                        text={p.t("select files to upload")}
                                    />
                                </FileSelectorContainer>
                            </Row>
                            <Row row={2}>
                                <ListContainer>
                                    {files.length === 0 ?
                                        <NoFiles>{p.t("first select files to upload")}</NoFiles> :
                                        <>
                                            <DragList
                                                list={videos}
                                                title={p.t("videos")}
                                                icon="video player"
                                                droppableId="VIDEOS"
                                            />
                                            <DragList
                                                list={images}
                                                title={p.t("images")}
                                                icon="image"
                                                droppableId="IMAGES"
                                            />
                                            <DragList
                                                list={subtitles}
                                                title={p.t("subtitles")}
                                                icon="feed"
                                                droppableId="SUBTITLES"
                                            />
                                        </>
                                    }
                                </ListContainer>
                            </Row>
                        </ListGrid>
                    </Col>
                    <Col col={3} row={1}>
                        <ListGrid buttons>
                            <Row row={1}>
                                <div style={{ float: "left", marginTop: 10, fontWeight: "bolder", textTransform: "uppercase" }}>
                                    {p.t("groupings")}
                                </div>
                                <div style={{ float: "right" }}>
                                    <Button
                                        kind={lngPacks.length === 0 ? "primary" : "secondary"}
                                        bordered
                                        content={p.t("add new pack")}
                                        icon="add circle"
                                        onClick={addPack}
                                    />
                                </div>
                            </Row>

                            <Row row={2} style={{ overflowY: "hidden" }}>
                                <PackList style={{ overflow: "scroll" }}>
                                    {_.map(lngPacks, (lp, i) => {
                                        const lHL = _.filter(lpHL, ll => ll !== lp.lng);
                                        const chgLng = (lng: string) => {
                                            const lp = _.clone(lngPacks);
                                            lp[i].lng = lng;
                                            setLngPacks(lp);
                                        }
                                        const delLp = () => {
                                            const lp = _.clone(lngPacks);
                                            lp.splice(i, 1);
                                            setLngPacks(lp);
                                        }
                                        return (
                                            <PackContainer key={lp.lng}>
                                                <PackHeader>
                                                    <Col col={1} padding="0px">
                                                        <Button icon="delete empty" kind="tertiary" floated="left" onClick={delLp} />
                                                    </Col>
                                                    <Col col={2} padding="0px">
                                                        <LngSelContainer style={{ zIndex: 10000 - i }}>
                                                            <LanguageSelection value={lp.lng} hiddenLanguages={lHL} className="smallLngSel" onLanguageChanged={chgLng} />
                                                        </LngSelContainer>
                                                    </Col>
                                                    <Col col={3} padding="0px">
                                                        {/* noch inaktiv - es fehlt ein state zum togglen des ToggleContainer */}
                                                        <Button icon="chevron up" kind="cancelButton" floated="right" />
                                                    </Col>
                                                </PackHeader>
                                                <ToggleContainer visible>
                                                    <SubTitle>{p.t("videos")}</SubTitle>
                                                    {_.map(Object.keys(lp.videos), (v) => {
                                                        const cur = lp.videos[v];
                                                        return (
                                                            <Droppable key={v} droppableId={`pack/${lp.lng}/videos/${v}`}>
                                                                {(prov, snap) => {
                                                                    //                                                                console.log(snap);
                                                                    const set = snap.isDraggingOver && snap.draggingOverWith && _.find(videoEndings, v => snap.draggingOverWith?.toLowerCase().endsWith(v)) !== undefined;
                                                                    return (
                                                                        <VideoEntry
                                                                            ref={prov.innerRef}
                                                                            {...prov.droppableProps}
                                                                            highlight={dragged === "video"}
                                                                        >
                                                                            <Col col={1} padding="0px 5px">
                                                                                <Icon name="video player" size={18} />
                                                                            </Col>
                                                                            <Col col={2} padding="0px 1px">
                                                                                {v}
                                                                            </Col>
                                                                            <Col col={3} padding="0">
                                                                                {set ?
                                                                                    <New>{snap.draggingOverWith}</New>
                                                                                    :
                                                                                    (cur ?
                                                                                        <Entry>{cur.name}</Entry> :
                                                                                        <Empty empty={videos.length === 0}>{p.t("Not yet set!")}</Empty>
                                                                                    )
                                                                                }
                                                                            </Col>

                                                                            <div style={{ display: "none" }}>
                                                                                {prov.placeholder}
                                                                            </div>
                                                                        </VideoEntry>
                                                                    )
                                                                }}
                                                            </Droppable>
                                                        );
                                                    })}
                                                    <SubTitle>{p.t("tumbnail")}</SubTitle>

                                                    <Droppable key="thumb" droppableId={`pack/${lp.lng}/thumb`}>
                                                        {(prov, snap) => {
                                                            const set = snap.isDraggingOver && snap.draggingOverWith && _.find(imageEndings, v => snap.draggingOverWith?.toLowerCase().endsWith(v)) !== undefined;
                                                            return (
                                                                <ThumbEntry
                                                                    ref={prov.innerRef}
                                                                    {...prov.droppableProps}
                                                                    highlight={dragged === "image"}

                                                                >
                                                                    <Col col={1} padding="3px 5px">
                                                                        <Icon name="image" size={18} />
                                                                    </Col>
                                                                    <Col col={3} padding="3px 1px">
                                                                        {set ?
                                                                            <New>{snap.draggingOverWith}</New> :
                                                                            (lp.thumb ?
                                                                                <Entry>{lp.thumb.name}</Entry> :
                                                                                <Empty empty={false}>{p.t("Not yet set!")}</Empty>
                                                                            )
                                                                        }
                                                                    </Col>

                                                                    <div style={{ display: "none" }}>
                                                                        {prov.placeholder}
                                                                    </div>
                                                                </ThumbEntry>

                                                            );
                                                        }}
                                                    </Droppable>


                                                    <SubTitle>{p.t("subtitles")}</SubTitle>
                                                    <Droppable key="subtitles" droppableId={`pack/${lp.lng}/sub`}>
                                                        {(prov, snap) => {
                                                            const set = snap.isDraggingOver && snap.draggingOverWith && _.find(vttEndings, v => snap.draggingOverWith?.toLowerCase().endsWith(v)) !== undefined;
                                                            return (
                                                                <SubTitleList
                                                                    ref={prov.innerRef}
                                                                    {...prov.droppableProps}
                                                                    highlight={dragged === "vtt"}
                                                                >
                                                                    {_.map(lp.subtitles, (s, idx) => {
                                                                        const flag = getFlag(s.lng);
                                                                        //                                                                    console.log("flag => ", flag, ", s.lng => ", s.lng);
                                                                        const hl = _.filter(_.map(lp.subtitles, ss => ss.lng), ll => ll !== s.lng);

                                                                        const sLngChanged = (newLng: string) => {
                                                                            const lps = _.clone(lngPacks);
                                                                            const curLp = _.find(lps, l => l.lng === lp.lng);
                                                                            if (curLp) {
                                                                                const ss = curLp.subtitles[idx];
                                                                                if (ss) {
                                                                                    ss.lng = newLng;
                                                                                    setLngPacks(lps);
                                                                                }
                                                                            }
                                                                        }
                                                                        return (
                                                                            <SubTitleEntry
                                                                                key={s.lng}
                                                                            >
                                                                                <Col col={1} padding="8px 5px">
                                                                                    <Icon name="feed" size={18} />
                                                                                </Col>
                                                                                <Col col={2} padding="9px 0px">
                                                                                    <Flag name={flag} size={18} />
                                                                                </Col>
                                                                                <Col col={3} padding="0px 1px">
                                                                                    <EntrySubTitle>{s.subtitles.name}</EntrySubTitle>
                                                                                </Col>
                                                                                <Col col={4} padding="3px 1px">
                                                                                    <div style={{ zIndex: 1000 - idx }}>
                                                                                        <LanguageSelection className="smallLngSel" value={s.lng} onLanguageChanged={sLngChanged} hiddenLanguages={hl} />
                                                                                    </div>
                                                                                </Col>

                                                                                <div style={{ display: "none" }}>
                                                                                    {prov.placeholder}
                                                                                </div>
                                                                            </SubTitleEntry>
                                                                        )
                                                                    })}
                                                                    {set &&
                                                                        <SubTitleEntry
                                                                            key={"new"}
                                                                        >
                                                                            <Col col={1} padding="8px 5px">
                                                                                <Icon name="feed" size={18} />
                                                                            </Col>
                                                                            <Col col={3} padding="0px 1px">
                                                                                <NewSubTitle>{snap.draggingOverWith}</NewSubTitle>
                                                                            </Col>
                                                                            <div style={{ display: "none" }}>
                                                                                {prov.placeholder}
                                                                            </div>
                                                                        </SubTitleEntry>
                                                                    }
                                                                    <div style={{ display: "none" }}>
                                                                        {prov.placeholder}
                                                                    </div>
                                                                </SubTitleList>
                                                            );
                                                        }}
                                                    </Droppable>
                                                </ToggleContainer>
                                            </PackContainer>
                                        )
                                    })}
                                </PackList>
                            </Row>
                        </ListGrid>
                    </Col>
                </Container>
            </DragDropContext >
            <MissingInput missing={missing} />
        </AssetUploadContainer>
    )
}

export default translate("assets")(AssetManagementTube);