import styled from "styled-components";

export const ListEntryBase = styled.div<{ read?: boolean }>`
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
    background-color: ${props => props.read ? "#808080a0" : "#ffffffa0"};
    border-bottom-right-radius:45px;
    border-top-right-radius:45px;
    padding-right:20px;
    display:  grid;
    display: -ms-grid;
    grid-template-columns: 90px auto;
    -ms-grid-columns:  90px auto;
    cursor: pointer;
    &:hover{
        background: rgba(255, 255, 255, 0.9);
        transition: all 0.2s ease-in-out;
        box-shadow: 6px 0 8px -6px #fff;
    }
`;

export const ListEntryBaseImage169 = styled.div<{ read?: boolean }>`
    width: 90px;
    height: auto;
    padding: 0px;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    padding-top: calc((90px - (90px * 9 / 16)) / 2);
    background: rgba(51, 63, 73, 0.1);
`;

export const ListEntryBaseImage11 = styled.div<{ read?: boolean }>`
    width: 90px;
    height: 90px;
    padding: 0px;
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column: 1;
`;



export const ContainerInfo = styled.div<{ color: string }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right:5px;
    i.icon, i.icons{
        color: ${props => props.color};
    }
`;