import moment, { Moment } from 'moment';
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/nl";
import "moment/locale/pt";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import ImgI18N from 'components/Utils/img-i18n';

interface DateSelectProps {
    date?: Date;
    onDateChanged?: (date: Date) => void;
    containerClassName?: string;
    lng?: string;
    withTime?: boolean;
    noMinDate?: boolean;
    className?: string;
}

interface DateSelectState {
    date: Moment;
}

export default class DateSelect extends React.Component<DateSelectProps, DateSelectState> {
    constructor(props: DateSelectProps) {
        super(props);
        this.state = {
            date: props.date ? moment(props.date) : moment().subtract(1, "day"),
        };
    }

    public UNSAFE_componentWillMount() {
        this.sendDate(this.state.date);
    }

    public UNSAFE_componentWillReceiveProps(props: DateSelectProps) {
        if (props.date !== this.props.date)
            this.setState({ date: props.date ? moment(props.date) : moment().subtract(1, "day") });
    }

    public render() {
        return (
            <ReactDatePicker
                selected={this.state.date.toDate()}
                onChange={this.dateChanged}
                minDate={this.props.noMinDate ? undefined : moment().subtract(1, "day").toDate()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={25}
                locale={ImgI18N.getInstance().language === "en-US" ? "en" : ImgI18N.getInstance().language}
                showTimeSelect={this.props.withTime}
                dateFormat={this.props.withTime ? "Pp" : "P"}
                popperClassName={this.props.className}
            />
        );
    }

    private dateChanged = (date: Date | null) => {
        if (date !== null) {
            this.setState({ date: moment(date) });
            if (this.props.onDateChanged) {
                this.sendDate(moment(date));
            }
        }
    }

    private sendDate = (date: Moment) => {
        if (this.props.onDateChanged) {
            const d = date.toDate();
            if (!this.props.withTime)
                d.setHours(0, 0, 0, 0);
            this.props.onDateChanged(d);
        }
    }
}
