import * as React from 'react';
import { Header } from 'semantic-ui-react';

interface IExciteTOUProps {
}

export default class ExciteTOU extends React.Component<IExciteTOUProps, any> {
    public render() {
        return (
            <div>
                <Header as="h3">A. excite!</Header>
                excite! is a global and community driven website (hereinafter “Platform”) for Porsche internal use only. The platform is currently accessible via your web browser and is offered free-of-charge.
        <Header as="h4">1. Users and Registration</Header>
                The features of the Platform can be used with registration only. You will be invited to register for the Platform via an invitation email, which contains your personal temporary password. After your first login you will be asked to change your password. This new password you have chosen is a personal permanent password. Please note that you are obliged not to share your password with any other person.
        <Header as="h4">2. Content, Usage Limitations and System Requirements</Header>
                The Platform offers the possibility to watch and contribute personal content of specific Porsche excite! moments (“Porsche Moment”) as an employee at Porsche. These Porsche Moments are displayed in the newsfeed section in a closed group, which does require a profile registration after an invitation, and allows you to like, comment or share posts. You may contribute various content types by creating Porsche Moment posts such as texts, pictures or videos (“User Content”). Before you may contribute User Content for the first time, you have to create a profile to contribute with. Please consider that your User Content you are contributing has to comply with the legal terms agreed hereby.
        <Header as="h3">B. Conditions for the use of the Platform (the "Conditions")</Header>
                <Header as="h4">1. Important Legal Information</Header>
                The Platform is provided to you by Dr. Ing. h. c. F. Porsche AG, Porscheplatz 1, 70435 Stuttgart, Germany ("Porsche AG "). In addition to Porsche AG, third parties – such as other affiliates of Porsche AG, Porsche dealers, or further third parties – may provide you with additional content, services, and functions via the Platform.
                Please read these Conditions carefully. If you do not agree to these Conditions, please do not use the Platform.
                Additional terms may apply to some parts, services or functions of, or provided in connection with, or via the Platform, and such additional terms may supplement, or prevail over, these Conditions. You are allowed to use these parts, services or functions only if you have accepted the additional terms. Please note that such additional terms may, as the case may be, lead to a contractual relationship with another legal entity which is different to Porsche AG as identified above.
        <Header as="h4">2. Copyright and licenses</Header>
                Porsche AG, its licensors, and other third parties, where applicable, reserve all copyrights, exploitation rights, and other property rights to the Platform and the content of the Platform (including texts, presentations, design, graphics, layout, images, pictures, tools, audio, and video) and the selection as well as the positioning of such content and any other information on the Platform (collectively, "Platform Content"). The Platform Content may not be duplicated, reproduced, copied, distributed, published, leased, loaned, granted, presented, publicly broadcasted, publically made available, modified, amended, transformed or used in another way without the prior consent of Porsche AG and/or the respective right holder or as permitted by applicable law. You may only use the Platform Content in accordance with the purposes of the Platform, which purposes include reproducing Platform Content in connection with downloading and using the Platform. Any rights granted by Porsche AG to the Platform Content shall expire automatically with the termination of the provision of the Platform by Porsche AG.
                Porsche AG does not guarantee or warrants the comprehensiveness, accuracy, reliability, suitability or availability with respect to the Platform Content. Any reliance you place on Platform Content is therefore strictly at your own risk. In no occurrence, will Porsche AG be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Platform or the use of Platform Content.
                The Platform may contain third party content (software or content) which may only be used in compliance with the respective third party licenses ("Third Party Licenses"). In order to use that content, you must accept the Third Party Licenses as provided to you prior to your use. The Third Party Licenses may lead to separate direct legal relationships with the respective third parties, including rights (e.g., the right to use software or content) and obligations for you and the respective third parties. Porsche AG is not responsible with regard to your relationship with such third parties.
        <Header as="h4">3. Post and publications</Header>
                When posting User Content your User Content will be part of the Platform Content. You are fully responsible for your User Content and Porsche AG will not review any User Content before it is accessible via the Platform by others. You warrant that your User Content does not infringe any third party rights. By posting User Content you grant us rights to publish, change, amend, combine, copy and delete your User Content and you warrant that you are entitled to grant us these respective rights.
                Porsche AG and other users are allowed to use your User Content in the same way as other Platform Content.
        <Header as="h4">4. Rules for Your Use of the Platform</Header>
                The Platform provides you with the possibility to upload and post certain User Content, such as text, pictures and videos. Please note that you are responsible for the User Content you upload and post via the Platform. Porsche AG expects from you certain behavior when you use the Platform – especially when you post User Content. The traffic lights below show what we expect from you:
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Header as="h5" color="green">Drive:</Header>
                                <i>Feel free to post</i>
                            </td>
                            <td style={{ border: '1px solid #cfcfcf, paddingLeft:15' }}>
                                -	positive or neutral User Content;<br />
                                -	fact related and appreciative User Content;<br />
                                -	User Content you can identify with;<br />
                                -	User Content related to your Porsche Moment. <br />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Header color="orange" as="h5">Caution:</Header>
                                <i>Think twice before you post	</i>
                            </td>
                            <td style={{ border: '1px solid #cfcfcf, paddingLeft:15' }}>
                                -	ironic and sarcastic innuendos;<br />
                                -	responses to offences or abusive language;<br />
                                -	disrespectful or ambiguous User Content.<br />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Header color="red" as="h5">Stop:</Header>
                                <i>Don’t post or report to us	</i>
                            </td>
                            <td style={{ border: '1px solid #cfcfcf, paddingLeft:15' }}>
                                -	insulting, threating or defaming User Content;<br />
                                -	spam or unauthorized advertising;<br />
                                -	radical ideas, racism or incitement to violence;<br />
                                -	User Content infringing third parties rights (e.g., intellectual property rights).<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
                Please note that we may delete your User Content in case it does not comply with the Conditions (including this Section) or other legal requirements. You may only use the Platform in compliance with these Conditions and only for legitimate and appropriate purposes. You may not provide incorrect information about you in connection with the use of the Platform or use the Platform in a fraudulent way.
        <Header as="h4">5. Liability</Header>
                By accessing the Platform, a usage relationship is established. Porsche AG's liability for damages caused by slight negligence in this usage relationship shall, irrespective of its legal grounds, be limited as follows:
                <ul>
                    (a) Porsche AG shall be liable up to the amount of the foreseeable damages at the conclusion of this contract typical for this kind of usage relationship due to a breach of material contractual obligations (i.e., obligations enabling the appropriate use of the Platform and in which the user may regularly trust Porsche AG to observe);<br />
                    (b) except as described in Section 5(a), [Porsche AG shall not be liable in case of slight negligence.
                </ul>
                The aforesaid limitations of liability shall not apply to any mandatory statutory liability and liability for culpably caused personal injuries, as well as for damages caused by wilful misconduct or gross negligence. In addition, such limitations of liability shall not apply if and to the extent Porsche AG has assumed a specific guarantee.
                Porsche AG does not warrant that the access to the Platform is uninterrupted, or that the Platform is secure and free of errors or viruses.
        <Header as="h4">6. Links to external websites, External Functions</Header>
                The Platform references directly and indirectly (e.g., via links) external websites operated by third parties (e.g., by Porsche AG's affiliates), could contain third party content (e.g., dynamic content provided via application programming interfaces) or could start third platforms or trigger similar functions on your device ("External Functions"). The availability and the content of the External Functions are not subject to Porsche AG’s control and Porsche AG does not have any influence on the External Functions. Porsche AG will remove any links to External Functions as soon as it becomes aware of any violation of rights. Subject to Section 5 above, Porsche AG is not responsible for the content of the External Functions and does not warrant that the content of the External Functions is correct, complete, or up-to-date. In particular, Porsche AG does not warrant that the access to the External Functions is uninterrupted, or that the External Functions are secure and free of errors or viruses.
        <Header as="h4">7. Changes to the Platform</Header>
                Porsche AG may change, amend, or stop providing the Platform in whole or in part – including Platform Content –, if this is reasonably acceptable to you, including, by way of example, due to a change to applicable law or to ensure better functionality of the Platform.
        <Header as="h4">8. Miscellaneous</Header>
                For all disputes arising from or in relation to these Conditions, German law applies under the exclusion of the United Nations Convention on Contracts for the International Sale of Goods (CISG). The statutory provisions limiting the choice of law and, in particular, the application of statutory laws of the country in which the user as a consumer has its habitual residence, remain unaffected.
                <p>The exclusive place of jurisdiction for any disputes arising out of these Conditions and the use of the Platform is Stuttgart. If you are a consumer, you are also entitled to assert a claim against Porsche AG at any other statutory place of jurisdiction that applies to the company.</p>
                <p>If a provision of these Conditions is or will be determined to be unlawful and / or unenforceable, the validity of the rest of the Conditions shall remain unaffected.</p>
                <p>Porsche AG does not participate in dispute resolution proceedings before a consumer arbitration board. We prefer to solve your requests directly with you.</p>
            </div>
        );
    }
}
