import { GroupDownloadDto, ImportCheckResult, UserController, UserDownloadDto, UserImportInfo } from "collaboration-service";
import FileSelector from 'components/General/FileSelector/FileSelector';
import TreeGroupSelection from 'components/General/TreeGroupSelection/TreeGroupSelection';
import BoolEdit from "components/Posts/PostCloner/BoolEdit";
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import * as React from 'react';
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Button, Dimmer, Divider, Form, Grid, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { config } from "services/Config";
import { IConnectedComponent, IState } from 'services/Interfaces';

interface ExcelInviteProps extends IIMGTranslatedComponent, IConnectedComponent {
}

interface ExcelInviteState {
    group?: GroupDownloadDto;
    result?: ImportCheckResult<UserImportInfo>;
    loading?: boolean;
    excel?: File;
    invited?: UserDownloadDto[];
    specialSendTime?: boolean;
    date?: Date;
}

class ExcelInvite extends React.Component<ExcelInviteProps, ExcelInviteState> {
    constructor(props: ExcelInviteProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    public render() {
        // console.log(this.state.result);
        const nullToQ = (val?: string) => val ? val : <span className="ExcelInviteErrorText">???</span>;
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader>{this.props.t("loading")}</Loader>
                </Dimmer>





                <Form>
                    <Grid stackable stretched container columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label><Icon name="chevron right" color="red" />{this.props.t('group')}</label>
                                <TreeGroupSelection onGroupSelected={this.groupSelected} value={this.state.group} onLoading={this.setLoading} />
                            </Form.Field>
                        </Grid.Column>
                        {this.state.group &&
                            <>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{this.props.t('select excel file')}</label>
                                        <FileSelector filter=".xlsx" onFileSelected={this.excelSelected} text="select excel file" tooltip="select excel file" fluid />
                                    </Form.Field>
                                </Grid.Column>
                                {this.state.result && config.adminFeatures.excelInviteAtSpecificTime &&
                                    <Grid.Column>
                                        <Form.Field>
                                            <label><Icon name="chevron right" color="red" />{this.props.t('invite at a specific time')}</label>
                                            <BoolEdit value={this.state.specialSendTime} onValueChanged={this.toggleSpecialDate} onIcon="clock" offIcon="times" />
                                        </Form.Field>
                                    </Grid.Column>
                                }
                                {!this.state.specialSendTime &&
                                    <Grid.Column />
                                }
                                {this.state.specialSendTime &&
                                    <Grid.Column>
                                        <Form.Field>
                                            <label><Icon name="chevron right" color="red" />{this.props.t('select specific time')}</label>
                                            <ReactDatePicker
                                                selected={this.state.date}
                                                onChange={this.onDateChange}
                                                dateFormat="P p"
                                                showTimeSelect
                                                timeIntervals={15}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                }
                            </>
                        }
                        {this.state.group && this.state.result &&
                            <>
                                <Grid.Column width={16}>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{this.props.t('Result of check before import')}</label>
                                        {this.state.result.message !== "" && this.state.result.messageType === "Info" &&
                                            <Message size="mini">
                                                <Message.Header>{this.state.result.message}</Message.Header>
                                            </Message>
                                        }
                                        {this.state.result.message !== "" && this.state.result.messageType === "Warning" &&
                                            <Message size="mini" style={{ background: "#FFFFD9", borderColor: "#B2B247", color: "#656519", FontWeight: 900 }}>
                                                <Message.Header>{this.state.result.message}</Message.Header>
                                            </Message>
                                        }
                                        {this.state.result.message !== "" && this.state.result.messageType === "Error" &&
                                            <Message negative size="mini" style={{ borderLeft: "0px solid transparent", borderRight: "0px solid transparent" }}>
                                                <Message.Header>{this.state.result.message}</Message.Header>
                                            </Message>
                                        }
                                        {(this.state.result.message === "" || this.state.result.messageType === "None") &&
                                            < Message positive size="mini">
                                                <Message.Header>{this.props.t('import will work propperly')}.</Message.Header>
                                            </Message>
                                        }
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{this.props.t('Detailed information before import')}</label>
                                        <div className="ExcelInviteGridContainer">
                                            <table className="InviteTable" cellSpacing={0}>
                                                <thead>
                                                    <tr>
                                                        <th className="InviteTableTD">{this.props.t('ok')}?</th>
                                                        <th className="InviteTableTD">{this.props.t('Personal information')}</th>
                                                        <th className="InviteTableTD">{this.props.t('Information')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.map(this.state.result.checkResults, (cr, i) => {
                                                        const mt = cr.content.memberType ? (cr.content.memberType + "").toLowerCase() : "";
                                                        return (
                                                            <tr key={i}
                                                                className={cr.messageType === "None" ? "InviteTableTR" :
                                                                    (cr.messageType === "Warning" ? "InviteTableYellow InviteTableTR" :
                                                                        (cr.messageType === "Info" ? "InviteTableOrange InviteTableTR" :
                                                                            (cr.messageType === "Error" ? "InviteTableRed InviteTableTR" : "InviteTableTR"
                                                                            )))}
                                                            >
                                                                <td className="InviteTableTD">
                                                                    <Icon name={cr.isOk ? "check" : "times"} color={cr.isOk ? "green" : "red"} circular />
                                                                </td>
                                                                <td className="InviteTableTD">
                                                                    <div>
                                                                        <Icon name="user" />
                                                                        {(cr.content.title ? cr.content.title + " " : "")}{nullToQ(cr.content.secondName)}, {nullToQ(cr.content.firstName)} ({nullToQ(cr.content.email)})
                                                                    </div>
                                                                    {cr.content.position &&
                                                                        <div>
                                                                            <Icon name="crosshairs" />
                                                                            {cr.content.position}
                                                                        </div>
                                                                    }
                                                                    {cr.content.organisation &&
                                                                        <div>
                                                                            <Icon name="factory" />
                                                                            {cr.content.organisation}
                                                                        </div>
                                                                    }
                                                                    <div>
                                                                        {mt === "moderator" && <><Icon name="bullhorn" color="orange" />Moderator</>}
                                                                        {mt === "admin" && <><Icon name="briefcase" color="red" />Admin</>}
                                                                        {mt === "member" && <><Icon name="users" /> Member</>}
                                                                        {mt === "reader" && <><Icon name="eye" /> Member</>}
                                                                        {mt === "writer" && <><Icon name="pencil" /> Member</>}
                                                                        {mt !== "reader" && mt !== "writer" && mt !== "member" && mt !== "admin" && mt !== "moderator" && <><Icon name="users" /><span className="ExcelInviteErrorText">???</span></>}
                                                                    </div>
                                                                    {cr.content.supervisor &&
                                                                        <div>
                                                                            <Icon name="eye" />
                                                                            {cr.content.supervisor}
                                                                        </div>
                                                                    }
                                                                    {cr.content.userId &&
                                                                        <div>
                                                                            <Icon name="key" />
                                                                            {cr.content.userId}
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td className="InviteTableTD">
                                                                    {_.map(cr.messages, (m, j) => <div key={j}>{m}</div>)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td />
                                                        <td />
                                                        <td style={{ textAlign: "right" }}>
                                                            {this.state.group && this.state.result &&
                                                                <Button primary onClick={this.invite}>
                                                                    <Icon name="users" />
                                                                    {this.props.t("invite")}
                                                                </Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Form.Field>
                                </Grid.Column>
                            </>
                        }

                    </Grid>
                </Form>


                {this.state.invited &&
                    <Segment>
                        <Divider horizontal>Result of import</Divider>
                        <div className="ExcelInviteGridContainer">
                            <Grid className="ExcelInviteGrid">
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <div className="ExcelInvited">
                                            {this.props.t("Invited users")}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                {_.map(this.state.invited, (cr, i) => {
                                    return (
                                        <Grid.Column width={8} key={i}>
                                            <div className="ExcelInviteColCont">
                                                <div className="ExcelInvited">
                                                    {cr.secondName + ", " + cr.firstName + " (" + cr.email + ")"}
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    );
                                })}
                            </Grid>
                        </div>
                    </Segment>
                }
            </>
        );
    }

    private onDateChange = (date: Date) => {
        this.setState({ date });
    }
    private toggleSpecialDate = (val: boolean) => {
        this.setState({ specialSendTime: val });
    }
    private invite = () => {
        if (this.state.group && this.state.excel) {
            this.setState({ loading: true });
            UserController.ExcelInvite({ groupid: this.state.group.id, time: { time: this.state.specialSendTime ? this.state.date : undefined }, excelFile: this.state.excel },
                (invited) => this.setState({ invited, loading: false }),
                () => this.setState({ loading: false }));
        }
    }

    private excelSelected = (file: File | undefined) => {
        if (file && this.state.group) {
            this.setState({ loading: true, excel: file, invited: undefined, });
            UserController.CheckExcelInvite({ groupid: this.state.group.id, excelFile: file },
                (result) => this.setState({ result, loading: false }),
                () => this.setState({ loading: false }));
        }
    }
    private groupSelected = (group?: GroupDownloadDto) => {
        this.setState({ group }, () => {
            if (this.state.excel)
                this.excelSelected(this.state.excel);
        });
    }

    private setLoading = (loading: boolean) => this.setState({ loading });

}

export default translate("excelinvite")(connect((state: IState) => ({ state }))(ExcelInvite));
