import * as React from 'react';
import ReactSelect from "react-select";
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { Icon } from 'semantic-ui-react';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import { ValueType } from 'react-select/src/types';
import { getArrayFromValueType } from 'services/Helpers';

interface IRoleSelectionProps extends IIMGTranslatedComponent {
    placeholder?: string;
    options?: string[];
    multi?: boolean;
    value?: string;
    onRoleChange: (value: string) => void;
}

type ContentType = { value: string, label: string };

interface IRoleSelectionState {
    selectedRoles?: Array<ContentType>;
    roles: Array<ContentType>;
}

const roleValue: React.SFC<SingleValueProps<{ value: string, label: string }>> = (props: SingleValueProps<{ value: string, label: string }>) => {
    return <div><Icon name="address card" className="SelectIcon" /><span className="SelectText">{props.children}</span></div>;
};

const rolePlaceholder: React.SFC<PlaceholderProps<{ value: string, label: string }>> = (props: PlaceholderProps<{ value: string, label: string }>) => {
    return <div><Icon name="address card" className="SelectIcon" /><span className="SelectPlaceholder">{props.children}</span></div>;
};


class RoleSelection extends React.Component<IRoleSelectionProps, IRoleSelectionState> {
    constructor(props: IRoleSelectionProps) {
        super(props);
        this.state = {
            roles: [],
        };
    }

    public UNSAFE_componentWillMount() {
        this.setOptions(this.props);
        this.setValue(this.props.value);
    }

    public UNSAFE_componentWillReceiveProps(props: IRoleSelectionProps) {
        if (!_.isEqual(props.options, this.props.options))
            this.setOptions(props);
        if (!_.isEqual(props.value, this.props.value))
            this.setValue(props.value);
    }

    public render() {
        return (
            <ReactSelect
                isMulti={this.props.multi}
                isSearchable={true}
                options={this.state.roles}
                onChange={this.roleSelect}
                value={this.state.selectedRoles}
                components={{
                    SingleValue: roleValue,
                    Placeholder: rolePlaceholder
                }}
                placeholder={this.props.placeholder ? this.props.placeholder : this.props.t("Role...")}
                classNamePrefix="rselect"

            />
        );
    }
    private roleSelect = (r: ValueType<ContentType>) => {
        const selectedRoles = getArrayFromValueType(r);
        this.setState({ selectedRoles });
        let roles = "";
        if (this.props.multi) {
            for (let i = 0; i < selectedRoles.length; i++) {
                if (i > 0)
                    roles += ", ";
                roles += selectedRoles[i].value;

            }
        }
        else
            roles = selectedRoles[0].value;
        this.props.onRoleChange(roles);
    }

    private setOptions = (props: IRoleSelectionProps) => {
        const { t } = props;
        const options = props.options ? props.options : ["Admin", "Member"];
        const roles = options.map(v => ({ value: v, label: t(v) }));
        this.setState({ roles });

    }

    private setValue = (value?: string) => {
        if (!value)
            this.setState({ selectedRoles: undefined });
        else {
            const splits = value.split(",");
            const selectedRoles: ContentType[] = [];
            splits.forEach(v => {
                const vv = v.trim();
                selectedRoles.push({ value: vv, label: this.props.t(vv) });
            });
            this.setState({ selectedRoles });
        }
    }
}

export default translate()(RoleSelection);