import * as React from "react";

class LoadingImport extends React.Component<{}, {}>
{
    public render() {
        //        const str = JSON.stringify(this.props);
        //        return <div>{str}</div>;
        return (
            <div className="js-loader">
                <div className="js-loader-container">
                    <div className="lds-roller">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <br />
                    Loading app....
      </div>
            </div>
        );
    }
}

export default LoadingImport;