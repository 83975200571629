import * as _ from "lodash";
import * as React from 'react';
import { MediaUploadDto, UserShortInfoDownloadDto } from "collaboration-service";
import { config } from 'services/Config';
import Media from '../Media/Media';
import MediaSelection from '../Media/MediaSelection';

interface UserAvatarProps {
    editAble?: boolean;
    user: UserShortInfoDownloadDto;
    onAvatarSelected?: (file: File, media: MediaUploadDto) => void;
    fullScreenMode?: boolean;
}

export default class UserAvatar extends React.Component<UserAvatarProps> {
    public render() {
        const { onAvatarSelected, user, editAble } = this.props;
        const ava = user.avatar && _.findIndex(user.avatar.links, l => l.ref === "self") >= 0;
        const media = { url: config.tmpImages.missingProfilePicture, type: "image/png" };
        const no = () => {
            //
        };
        const os = (file: File, media: MediaUploadDto) => {
            console.log("os ==>", file);
            if (onAvatarSelected)
                onAvatarSelected(file, media);
        }
        return (
            <>
                {!editAble && !ava &&
                    <Media
                        media={media}
                        contentClassName={"RoundedCropImage"}
                        onFullScreenClick={no}
                        lazyLoading="off"

                    />
                }
                {!editAble && ava &&
                    <Media
                        media={{ media: user.avatar, refName: "self" }}
                        contentClassName={"RoundedCropImage"}
                        onFullScreenClick={this.props.fullScreenMode === true ? undefined : no}
                        lazyLoading="off"
                    />
                }
                {editAble &&
                    <MediaSelection
                        aspect={1}
                        imageOnly
                        media={user.avatar}
                        onMediaSelected={os}
                        hideButton
                        rounded
                        startEditing
                    />
                }
            </>
        );
    }
}
