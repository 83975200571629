import { AssetController, AssetDownloadDto, ControllerHelper } from "collaboration-service";
import { DefaultMenuProps } from "components/General/Mainframe/DefaultMainMenu";
import Mainframe from "components/General/Mainframe/Mainframe";
import { ScreenBreakPoint, sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import YesOrNoModal from "components/General/YesOrNoModal/YesOrNoModal";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { ComponentWithMappedApplicationState } from "imaginarity-platform-plugin-base";
import { getColor, Loader, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { config } from "services/Config";
import { getUri } from "services/Helpers";
import { IState } from "services/Interfaces";
import AssetListView from "./AssetListView";
import AssetSettings from "./AssetSettings";


const mapper = (state: IState) => ({ userInfo: state.userInfo })

interface AssetManagementProps extends IIMGTranslatedComponent, ComponentWithMappedApplicationState<typeof mapper>, SizeConditionalComponent, RouteComponentProps<any> {

}

interface AssetManagementState {
    assets?: AssetDownloadDto[];
    token?: string;
    loading?: boolean;
    showUpload?: boolean;
    assetToDelete?: AssetDownloadDto;
    assetToEdit?: AssetDownloadDto;

}

interface AssetMap {
    userAssets: { [ownerId: string]: AssetDownloadDto[] };
    groupAssets: { [groupId: string]: AssetDownloadDto[] };
    ownAssets: AssetDownloadDto[];

}

const AssetMgtContainer = styled.div<{ width: number, height: number }>`
    position: fixed;
    top: 40px;
    left:0;
    width: ${p => p.width}px;
    height: ${p => p.height}px;
    background-color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    padding: 10px;
    overflow-y: auto;
`;

export const createAssetMap = memoizee((assets?: AssetDownloadDto[], userId?: string) => {
    const toRet: AssetMap = {
        userAssets: {},
        groupAssets: {},
        ownAssets: [],
    };
    //        console.log("huhu! => ", assets, ", id => ", userId);
    _.forEach(assets, a => {
        if (a.owner.id !== userId) {
            if (!toRet.userAssets[a.owner.id])
                toRet.userAssets[a.owner.id] = [];
            toRet.userAssets[a.owner.id].push(a);
        }
        else {
            toRet.ownAssets.push(a);
        }
        _.forEach(a.groups, g => {
            if (!toRet.groupAssets[g.id])
                toRet.groupAssets[g.id] = [];
            toRet.groupAssets[g.id].push(a);
        });
    })
    return toRet;
}, { normalizer: (a) => JSON.stringify(a), max: 3 })

class AssetManagement extends React.Component<AssetManagementProps, AssetManagementState>{
    public state: AssetManagementState = {
        showUpload: false,
    };
    async componentDidMount() {
        await this.loadAssets();
    }

    render() {
        if (this.props.height === 0)
            return <div />;
        //        console.log(this.props);
        const h = this.props.height - 40;
        const w = this.props.width;

        const defProps: DefaultMenuProps = { additionalMenuBarEntries: [] };
        defProps.additionalMenuBarEntries?.push({
            icon: "plus",
            onClick: this.showUpload,
        });

        return (
            <div style={{ width: "100vw", height: "100vh", overflowY: "auto" }} >
                <Mainframe
                    fullWidthMode
                    className={'WhiteMainframe'}
                    title={"Asset Mgt"}
                    menuProps={defProps}

                >
                    <Loader active={this.state.loading} size="small" light />
                    <AssetMgtContainer height={h} width={w}>
                        <AssetListView
                            hasMoreAssets={this.state.token !== "" && this.state.token !== undefined}
                            assets={this.state.assets}
                            userId={this.props.userInfo?.id}
                            requestDelete={this.requestDelete}
                            requestEdit={this.requestEdit}
                            requestLoadAssets={this.loadAssets}
                            requestMoreAssets={this.loadMoreAssets}
                        />
                    </AssetMgtContainer>
                </Mainframe>
                <AssetSettings active={this.state.assetToEdit !== undefined} id={this.state.assetToEdit?.id} onClose={this.closeEdit} />
                <YesOrNoModal
                    open={this.state.assetToDelete !== undefined}
                    text={this.props.t("delete this asset?")}
                    onFinished={this.deleteAsset}
                />
            </div >

        );
    }
    private requestDelete = (assetToDelete: AssetDownloadDto) => this.setState({ assetToDelete });
    private requestEdit = (assetToEdit: AssetDownloadDto) => this.setState({ assetToEdit });

    private closeEdit = () => this.setState({ assetToEdit: undefined });
    private deleteAsset = (deleteIt: boolean) => {
        if (deleteIt && this.state.assetToDelete) {
            AssetController.DeleteAsset({ id: this.state.assetToDelete.id }, () => this.setState({ assetToDelete: undefined }, () => this.loadAssets()), () => this.setState({ assetToDelete: undefined }));
        }
        else
            this.setState({ assetToDelete: undefined });
    }
    private cancelUpload = () => {
        this.setState({ showUpload: false });
    }
    private showUpload = () => {
        //this.setState({ showUpload: true });
        this.props.history.push(config.routes.assets.add);
    }


    private download = (a: AssetDownloadDto) => () => {
        const url = getUri(a.media);
        const ele = document.createElement("a");
        ele.setAttribute("href", url);
        ele.style.display = "none";
        ele.setAttribute("download", a.media.fileName);
        document.body.appendChild(ele);
        ele.click();
        document.body.removeChild(ele);

    }

    private loadAssets = async () => {
        //        console.log(this.props.userInfo);
        var assetsRes = await ControllerHelper.singleCall({}, AssetController.GetAssets);
        //        console.log(assetsRes);
        this.setState({ assets: assetsRes.elements, token: assetsRes.data });
    }

    private loadMoreAssets = async () => {
        var assetsRes = await ControllerHelper.singleCall({ token: { token: this.state.token } }, AssetController.GetAssets);
        const assets = _.clone(this.state.assets ?? []);
        assets.push(...assetsRes.elements);
        this.setState({ assets, token: assetsRes.data });

    }

}

export const breakpoints = {
    smallMobile: { width: 320 } as ScreenBreakPoint,
    mobile: { width: 375 } as ScreenBreakPoint,
    tablet: { width: 768 } as ScreenBreakPoint,
    lTablet: { width: 992 } as ScreenBreakPoint,
    xlTablet: { width: 1024 } as ScreenBreakPoint,
    desktop: { width: 1280 } as ScreenBreakPoint,
    xlDesktop: { width: 1440 } as ScreenBreakPoint,
    wide: { width: 1600 } as ScreenBreakPoint,
    ultraWide: { width: 1920 } as ScreenBreakPoint,
    extremlyWide: { width: 2560 } as ScreenBreakPoint
}

export default translate("assets")(withRouter(sizeConditional({ updateSize: true, breakpoints, initialCurrentBreakpoint: "smallMobile", className: "sizeconditional-masterlayout" })(connect(mapper)(AssetManagement))));