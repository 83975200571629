import { SlideShowDownloadDto } from 'collaboration-service';
import Media from 'components/General/Media/Media';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { config } from 'services/Config';
import { getTranslated } from 'services/Helpers';
import styled from 'styled-components';
import { ListEntryBase, ListEntryBaseImage11, ContainerInfo } from './ListEntry';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
export interface ListSlideShowProps {
    post: SlideShowDownloadDto;
    color: string;
    saveClick: (id: string) => void;
}

interface ListSlideShowState {
    start?: boolean;
}

const Container = styled(ListEntryBase)`
    position:relative;
`;

const ImgContainer = styled(ListEntryBaseImage11)`
`;

const TitleContainer = styled.div`
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column: 2;
    padding:0 20px 0 0;
`;

const Title = styled.div`
    text-align: center;
    vertical-align: middle;
    transform: translateY(45px) translateY(-50%);
`;

export default class ListSlideShow extends React.Component<ListSlideShowProps, ListSlideShowState> {
    public state: ListSlideShowState = {};
    public render() {
        if (this.state.start)
            return <Redirect to={`${config.routes.slideshow.slide}/${this.props.post.id}`} push />;
        return (
            <Container onClick={this.start}>
                <ContainerInfo color={this.props.color}>
                    <Icon name={config.onboardingIcons.slideshow as SemanticICONS} size="large" />
                </ContainerInfo>
                <ImgContainer>
                    {this.props.post.media &&
                        <Media media={{ media: this.props.post.media, refName: "self" }} contentClassName="ListSlideShowImage" lazyLoading="off" />
                    }
                </ImgContainer>
                <TitleContainer>
                    <Title>
                        {getTranslated(this.props.post.headlines)}
                    </Title>
                </TitleContainer>
            </Container>
        );
    }
    private start = () => {
        this.props.saveClick(this.props.post.id);
        this.setState({ start: true });
    }
}
