import * as React from 'react';
import Axios, { AxiosRequestConfig } from 'axios';
import { connect } from 'react-redux';
import { IConnectedComponent, IState } from 'services/Interfaces';

export interface TestMobProps extends IConnectedComponent {
}

class TestMob extends React.Component<TestMobProps> {
    private x?: string;
    public UNSAFE_componentWillMount() {
        this.x = "not here....";
        let there = false;
        if ("webkit" in document) {
            this.x = "document => " + JSON.stringify((document as any).webkit);
            there = true;

        }
        if ("webkit" in window) {
            this.x = "window => " + JSON.stringify((window as any).webkit);
            // (window as any).webkit.messageHandlers.handler.postMessage({ buh: 17 });
            there = true;
        }
        const config: AxiosRequestConfig = {
            baseURL: "https://img-test.azurewebsites.net",
            url: "api/login",
            method: "POST",
            data: { login: "sascha.riexinger@imaginarity.de", password: "geheim" }
        };
        if (there)
            Axios.interceptors.request.use((c) => { (window as any).webkit.messageHandlers.handler.postMessage({ url: c.url, method: c.method }); return c; });
        Axios(config);

    }
    public render() {
        return (
            <div>
                <div>
                    {this.x}
                </div>
                <div>
                    {this.props.state.client.token}
                </div>
            </div>
        );
    }
}

export default connect((state: IState) => ({ state }))(TestMob);