import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ActionCreators } from './Actions';
import { GroupDownloadDto, UserVisitedGroupEventUploadDto } from "collaboration-service";
import { config } from './Config';
import { IConnectedComponent, IState } from './Interfaces';
import { ControllerHelper, UserController, GroupController } from "collaboration-service";

interface GroupHubProps extends IConnectedComponent, RouteComponentProps<any> {
    nothing?: any;
}

interface GroupHubState {
    whereToGo?: string;
    gid?: string;
    redirect?: string;
}
class GroupHub extends React.Component<GroupHubProps, GroupHubState>{
    public state: GroupHubState = {
        whereToGo: undefined,
    };

    public UNSAFE_componentWillMount() {
        this.checkWhereToGo();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: GroupHubProps) {
        if (this.getGroupId() !== this.getGroupId(nextProps))
            this.checkWhereToGo(nextProps);
    }

    public render() {
        this.changeGroup();
        if (this.state.redirect)
            return <Redirect to={this.state.redirect} />;
        if (this.state.whereToGo) {
            switch (this.state.whereToGo) {
                case 'TUBE':
                    return <Redirect to={config.routes.tube.main} />;
                case 'LANDING PAGE SMART MOBILITY':
                    return <Redirect to={config.routes.landingPage.new + "/" + this.state.gid} />;
                case 'EXCITE':
                case 'COMMUNITY':
                case 'SLIDESHOW':
                    return <Redirect to={config.routes.community.main} />;
                case 'SCORM':
                    return <Redirect to={config.routes.scorm.overview} />;  // is this ok as default?
                case 'CONTENT AGGREGATION':
                    return <Redirect to={config.routes.contentAggregation.overview} />;
                // case 'BEST PRACTICE':
                //     return <Redirect to={config.routes.bestpractice.main + "/" + this.state.gid} />;
                case 'QUIZ':
                    return config.quizFeatures.quizFeed ? <Redirect to={config.routes.quiz.quizFeed + "/" + this.state.gid} /> : <Redirect to={config.routes.community.main} />;
                default:
                    return <Redirect to={config.routes.community.main} />;
            }
        } else {
            return <div>&nbsp;</div>;
        }
    }

    private checkWhereToGo = (p?: GroupHubProps) => {
        const props = p ? p : this.props;
        const gid = this.getGroupId(props);
        if (gid !== "bookmarks") {
            const group = this.getGroup(gid, props);
            if (group && group.type) {
                this.checkforRedirect(gid, group, props);
            }
            else {
                GroupController.GetGroup({ groupid: gid && gid !== "" ? gid : "cleanup" },
                    (g) => {
                        this.checkforRedirect(g.id, g, props);
                    },
                    () => {
                        this.setState({ whereToGo: "EXCITE", gid: undefined }, this.changeGroup);
                    }
                );
            }
        }
    }

    private checkforRedirect = async (gid: string, group: GroupDownloadDto, props: GroupHubProps) => {
        if (group.groupSettings.redirectOnFirstVisit && group.groupSettings.redirectOnFirstVisit !== "" && props.state.userInfo) {
            if (_.findIndex(props.state.userInfo.userSettings.events, e => {
                if (e.name === "GroupVisited") {
                    const evData = e.data as UserVisitedGroupEventUploadDto;
                    return evData !== undefined && evData.group_id === group.id;
                }
                return false;
            }) < 0) {
                let redir = group.groupSettings.redirectOnFirstVisit;
                redir = redir.replace("{{groupid}}", group.id);
                this.setState({ whereToGo: group.groupType, gid, redirect: redir }, this.changeGroup);
                const data: UserVisitedGroupEventUploadDto = {
                    date: new Date(),
                    group_id: group.id
                };
                await ControllerHelper.singleCall({ eventData: data, name: "GroupVisited" }, UserController.AddUserVisitedGroupEvent, true);
            }
            else
                this.setState({ whereToGo: group.groupType, gid }, this.changeGroup);
        }
        else
            this.setState({ whereToGo: group.groupType, gid }, this.changeGroup);
    }

    private getGroupId = (p?: GroupHubProps) => {
        const props = p ? p : this.props;
        return props.match.params.groupId ? props.match.params.groupId :
            (props.state.userInfo ? props.state.userInfo.userSettings.lastGroupId : "");
    }

    private getGroup = (id: string, p?: GroupHubProps) => {
        const props = p ? p : this.props;
        if (props.state.groups)
            return _.find(props.state.groups.elements, (g: GroupDownloadDto) => g.id === id);
        return undefined;
    }

    private changeGroup = () => {
        if (this.state.gid && this.props.state.userInfo && this.state.gid !== this.props.state.userInfo.userSettings.lastGroupId) {
            this.props.dispatch(ActionCreators.globalState.ChangeGroup(this.state.gid));
        }
    }
}

export default connect((state: IState) => ({ state }))(GroupHub);