import { CollaborationServiceSetup, ContentPostDownloadDto } from 'collaboration-service';
import { SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import * as _ from "lodash";
import * as React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { config } from 'services/Config';
import { getTranslated, getTranslatedLinkForContentWithInfo, windowOpen } from 'services/Helpers';
import styled from 'styled-components';
import { breakpoints } from "./ContentAggregationOverview";
import { ContainerInfo, ListEntryBase, ListEntryBaseImage169 } from './ListEntry';

export function belowBreakpointOrEqual<T extends SizeConditionalComponent>(component: T, breakpoint: keyof typeof breakpoints) {
    const keys = Object.keys(breakpoints);
    keys.unshift("verySmall");
    const i1 = _.findIndex(keys, k => k === component.currentBreakpoint);
    const i2 = _.findIndex(keys, k => k === breakpoint);
    return i1 <= i2;
}
export interface ListContentPostProps extends SizeConditionalComponent {
    post: ContentPostDownloadDto;
    color: string;
    saveClick: (id: string) => void;
}

interface ListContentPostState {
    link?: string;
}

const Container = styled(ListEntryBase)`
    position:relative;
`;

const ImgContainer = styled(ListEntryBaseImage169)`
`;

const Img = styled.img`
    width:  90px;
    height: auto;
`;

const TitleContainer = styled.div`
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column: 2;
    padding:0 20px 0 0;
`;

const Title = styled.div`
    text-align: center;
    vertical-align: middle;
    transform: translateY(45px) translateY(-50%);
`;

const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
    border:none;
    box-shadow: 0 6px 8px -6px black;
`;


export default class ListContentPost extends React.Component<ListContentPostProps, ListContentPostState> {
    public state: ListContentPostState = {
    };
    public render() {
        const img = this.props.post.media && this.props.post.media.links && this.props.post.media.links.length > 0 ? this.props.post.media.links[0].uri : undefined;
        return (
            <Container onClick={this.state.link === undefined ? this.openLink : undefined}>
                <ContainerInfo color={this.props.color}>
                    <Icon name={config.onboardingIcons.content as SemanticICONS} size="large" />
                </ContainerInfo>
                <Modal
                    size="large"
                    basic
                    open={this.state.link !== undefined}
                    onClose={this.close}
                    closeIcon
                    className="myFullModal"
                >
                    <Modal.Content
                        style={{
                            height: belowBreakpointOrEqual(this.props, "tablet") ? "100vh" : "85vh",
                            width: belowBreakpointOrEqual(this.props, "tablet") ? "100vw" : "auto"
                        }}
                    >
                        <IFrame src={this.state.link} width="100%" height="100%" />
                    </Modal.Content>
                </Modal>
                <ImgContainer>
                    <Img src={img} />
                </ImgContainer>
                <TitleContainer>
                    <Title>
                        {getTranslated(this.props.post.headlines)}
                    </Title>
                </TitleContainer>
            </Container >
        );
    }

    private close = () => {
        this.setState({ link: undefined });
    }
    private openLink = () => {
        this.props.saveClick(this.props.post.id);
        let link = getTranslatedLinkForContentWithInfo(this.props.post.content);
        if (link) {
            if (link.useContent) {
                const l = link.link.replace("((token))", CollaborationServiceSetup.client.token);
                if (l)
                    this.setState({ link: l });
            } else {
                windowOpen(link.link, "_blank");
            }
        }
    }
}
