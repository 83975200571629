import HubConnection from "./HubConnection";

export class GameHubService {
    private hubConnection: HubConnection;
    private currentMainGameId?: string;
    private currentGameId?: string;
    private onDisconnected?: () => void;

    constructor() {
        this.hubConnection = new HubConnection();
    }

    public registerToGame(mainGameId: string, gameId: string, onDisconnected?: () => void) {
        this.deregisterToGame();
        this.onDisconnected = onDisconnected;
        this.currentMainGameId = mainGameId;
        this.currentGameId = gameId;
        this.hubConnection.invoke("gameHub", "register", mainGameId, gameId);
        this.hubConnection.registerForConnectionEvent("disconnected", () => this.disconnected());
    }

    public deregisterToGame() {
        if (this.currentMainGameId && this.currentGameId) {
            this.hubConnection.invoke("gameHub", "deregister", this.currentMainGameId, this.currentGameId);
            this.hubConnection.deregisterFromConnectionEvent("disconnected", () => this.disconnected());
        }
    }

    public registerCallback<T1 = undefined, T2 = undefined, T3 = undefined, T4 = undefined>(methodName: string, callBack: (p1: T1, p2: T2, p3: T3, p4: T4) => void) {
        this.hubConnection.register("gameHub", methodName, callBack);
    }

    public deregisterCallback<T1 = undefined, T2 = undefined, T3 = undefined, T4 = undefined>(methodName: string, callBack: (p1: T1, p2: T2, p3: T3, p4: T4) => void) {
        this.hubConnection.deregister("gameHub", methodName, callBack);
    }

    private disconnected = () => {
        if (this.onDisconnected)
            this.onDisconnected();
    }
}
