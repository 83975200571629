import { AssetController, AssetDownloadDto, ContentMediaUploadDto, ControllerHelper, MediaUploadDto, TubePostDownloadDto, UserShortInfoDownloadDto } from "collaboration-service";
import { DefaultMenuProps } from "components/General/Mainframe/DefaultMainMenu";
import Mainframe from "components/General/Mainframe/Mainframe";
import { sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { fadeColor, getColor, Icon, lightenColor, Loader, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { config } from "services/Config";
import { breakpoints } from "./AssetManagement";
import AssetManagementTube from "./AssetManagementTube";
import AssetUploadSelection from "./AssetUploadSelection";


const Container = styled.div<{ width: number, height: number }>`
    position: fixed;
    top: 40px;
    left: 0px;
    width: ${p => p.width}px;
    height: ${p => p.height}px;
    padding: 0 10px;
    background-color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;

const TabButton = styled.div<{ active: boolean }>`
    height: 40px;
    margin-top:5px;
    font-size: 1.1rem;
    padding: 0 20px;
    line-height: 40px;
    border-top: 1px solid ${p => p.active ? getColor(p, p.theme.colors.lightGrey) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 20)};
    border-left: 1px solid ${p => p.active ? getColor(p, p.theme.colors.lightGrey) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 20)};
    border-right: 1px solid ${p => p.active ? getColor(p, p.theme.colors.lightGrey) : fadeColor(p, getColor(p, p.theme.colors.lightGrey), 20)};
    border-bottom: 1px solid ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.lightGrey)};
    color: ${p => p.active ? getColor(p, p.theme.colors.darkGrey) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 80)};
    background: ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : lightenColor(p, getColor(p, p.theme.colors.veryLightGrey), 99)};
    display: inline-block;
    cursor: ${p => p.active ? "default" : "pointer"};
    transition: 0.2s all ease;
    font-weight: ${p => p.active ? "bolder" : "normal"};
    svg{
        stroke: ${p => p.active ? getColor(p, p.theme.colors.darkGrey) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 80)};
    }
    &:hover{
        color: ${p => p.active ? getColor(p, p.theme.colors.darkGrey) : getColor(p, p.theme.colors.accent)};
        transform: ${p => p.active ? "translateY(0)" : "translateY(-3px)"};
        border-bottom-color: ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : "transparent"};
        margin-top:${p => p.active ? "5px" : "2px"};
        svg{
            stroke: ${p => p.active ? getColor(p, p.theme.colors.darkGrey) : getColor(p, p.theme.colors.accent)};
        }
    }
    `;

interface AssetManagementAddProps extends IIMGTranslatedComponent, SizeConditionalComponent, RouteComponentProps<any> {

}

const AssetManagementAdd = (p: AssetManagementAddProps) => {
    const [loading, setLoading] = React.useState(false);
    const [tube, setTube] = React.useState(false);

    const cancelUpload = () => {
        p.history.push(config.routes.assets.mgt);
    }
    const filesUploaded = (medias: MediaUploadDto[], readUsers?: UserShortInfoDownloadDto[], writeUsers?: UserShortInfoDownloadDto[]) => {
        // console.log("readUsers => ", readUsers);
        // console.log("writeUsers => ", writeUsers);
        setLoading(true);
        console.log(medias);
        const p = _.map(medias, async m => {
            const asset = await ControllerHelper.singleCall({ data: { media: m, tags: [], referenceId: "" } }, AssetController.AddAsset);
            let asset1: AssetDownloadDto | undefined;
            let asset2: AssetDownloadDto | undefined;
            if (writeUsers && writeUsers.length > 0)
                asset1 = await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(writeUsers, u => u.id), writeAccess: true }, AssetController.AddUsersAccess, true);
            if (readUsers && readUsers.length > 0)
                asset2 = await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(readUsers, u => u.id), writeAccess: false }, AssetController.AddUsersAccess, true);
            return asset2 ?? (asset1 ?? asset);
        });
        Promise.all(p).then(cancelUpload, cancelUpload);
    }

    const tubePostCreated = async (tubePost: TubePostDownloadDto | undefined, readUsers: UserShortInfoDownloadDto[] | undefined, writeUsers: UserShortInfoDownloadDto[] | undefined) => {
        setLoading(true);
        if (tubePost) {
            const asset = await ControllerHelper.singleCall({ data: { media: undefined as any as ContentMediaUploadDto, tags: [], referenceId: tubePost.id } }, AssetController.AddAsset);
            let asset1: AssetDownloadDto | undefined;
            let asset2: AssetDownloadDto | undefined;
            if (writeUsers && writeUsers.length > 0)
                asset1 = await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(writeUsers, u => u.id), writeAccess: true }, AssetController.AddUsersAccess, true);
            if (readUsers && readUsers.length > 0)
                asset2 = await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(readUsers, u => u.id), writeAccess: false }, AssetController.AddUsersAccess, true);
        }
        cancelUpload();
    }

    const show = p.width !== undefined && p.height !== undefined && p.width >= 0 && p.height >= 0;
    const defProps: DefaultMenuProps = { additionalMenuBarEntries: [] };
    defProps.additionalMenuBarEntries?.push({
        icon: "times",
        onClick: cancelUpload
    });
    return (
        <div style={{ width: "100vw", height: "100vh", overflowY: "auto" }} >
            <Mainframe
                fullWidthMode
                className={'WhiteMainframe'}
                title={"Asset Mgt"}
                menuProps={defProps}
            >
                {show &&
                    <Container width={p.width} height={p.height}>
                        <TabButton active={!tube} onClick={() => setTube(false)}>
                            <Icon name="folder" style={{ float: "left", marginRight: 5 }} /> {p.t("add single file")}
                        </TabButton>
                        <TabButton active={tube} onClick={() => setTube(true)}>
                            <Icon name="video player" style={{ float: "left", marginRight: 5 }} /> {p.t("add tube package")}
                        </TabButton>
                        {tube ?
                            <AssetManagementTube onTubePostCreated={tubePostCreated} onClose={cancelUpload} />
                            :
                            <AssetUploadSelection onFilesUploaded={filesUploaded} onClose={cancelUpload} />
                        }
                    </Container>
                }
            </Mainframe>
        </div>
    )
}

export default translate("assets")(withRouter(sizeConditional({ updateSize: true, breakpoints, initialCurrentBreakpoint: "smallMobile", className: "sizeconditional-masterlayout" })(AssetManagementAdd)));