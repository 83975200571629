import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Segment } from 'semantic-ui-react';
import { IConnectedComponent, IState } from '../../services/Interfaces';
import { IIMGTranslatedComponent, translate } from '../Utils/img-i18n';
import Mainframe from './Mainframe/Mainframe';


interface IUnauthorizedProps extends IConnectedComponent, IIMGTranslatedComponent, RouteComponentProps<any> {
    // nothing
    nothing?: any;
}

const Unauthorized: React.SFC<IUnauthorizedProps> = (props: IUnauthorizedProps) => {
    return (
        <Mainframe >
            <Segment className='Headline'>Unauthorized</Segment>
        </Mainframe>
    );
};

export default translate("excite")(connect((state: IState) => ({ state }))(Unauthorized));
