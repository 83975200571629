// import * as QRCode from 'qrcode.react';
import { GroupDownloadDto } from "collaboration-service";
import { LinkDto } from "imaginarity-azure";
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import Slider, { Settings } from "react-slick";
import { Image, Popup } from 'semantic-ui-react';
import { config } from 'services/Config';
import { IConnectedComponent, IState } from 'services/Interfaces';

interface IGroupSelectionState {
}

interface IGroupSelectionProps extends IConnectedComponent {
    groups: GroupDownloadDto[];
    count?: number;
    groupid: string;
    filter?: string[];
    size?: number;
    onGroupSelected?: (group: GroupDownloadDto) => void;
}

interface IGroupSelectionState {
    groupId: string;
}

class HiddenArrow extends React.Component<any> {
    public render() {
        return <div style={{ display: "none" }} />;
    }
}
class IconGroupSelection extends React.Component<IGroupSelectionProps, IGroupSelectionState>{
    private slider?: Slider;
    constructor(props: IGroupSelectionProps & IConnectedComponent) {
        super(props);
        this.state = {
            groupId: "",
        };
    }

    public UNSAFE_componentWillMount() {
        this.setState({
            groupId: this.props.groupid
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IGroupSelectionProps) {
        if (this.props.groupid !== nextProps.groupid) {
            this.setState({ groupId: nextProps.groupid });
        }
    }
    public render() {
        const groupMap = _.filter(this.props.groups, (g: GroupDownloadDto) => this.props.filter ? _.findIndex(this.props.filter, f => f === g.groupType) >= 0 : true);
        // const groupMap = _.filter(this.props.groups, (g: GroupDownloadDto) => this.props.filter ? this.props.filter === g.groupType : true);

        const c = this.props.count ? this.props.count : 5;
        const scroll = Math.min(c, groupMap ? groupMap.length : 0);

        const settings: Settings = {
            dots: true,
            slidesToShow: scroll,
            slidesToScroll: scroll,
            infinite: false,
            speed: 500,
            variableWidth: true,
            nextArrow: <HiddenArrow />,
            prevArrow: <HiddenArrow />,
            focusOnSelect: true,
        };
        const size = this.props.size ? this.props.size : 55;
        return (
            <div style={{ width: "100%", height: size + 35 }}>
                <Slider
                    {...settings}
                    ref={this.setRef}
                >
                    {_.map(groupMap, (g, i) => {
                        const group = g as GroupDownloadDto;
                        const src = _.find(group.avatar ? group.avatar.links : [], (l: LinkDto) => l.ref === "self");
                        const click = () => {
                            if (this.props.onGroupSelected)
                                this.props.onGroupSelected(group);
                        };
                        if (src)
                            return (
                                <div style={{ width: size, background: 'transparent' }} key={i}>
                                    <Popup trigger={
                                        <div style={{ margin: 5, background: 'transparent' }}>
                                            <Image src={src.uri} className={"RoundImage" + (this.state.groupId === group.id ? " redborder" : "")} onClick={click} />
                                            <div className={"IGSGroupName" + (this.state.groupId === group.id ? " selected" : "")}> {group.name}</div>
                                        </div>}
                                        content={<div style={{ padding: 10 }}>{group.name}</div>}
                                        on='hover'
                                        position='top center'
                                    />
                                </div>
                            );
                        else
                            return (
                                <div style={{ width: size, }} key={i}>
                                    <Popup trigger={
                                        <div style={{ margin: 5, }}>
                                            <Image
                                                fluid={true}
                                                rounded={true}
                                                src={config.tmpImages.missingProfilePicture}
                                                className={"RoundImage" + (this.state.groupId === group.id ? " redborder" : "")}
                                                onClick={click}
                                            />
                                            <div className={"IGSGroupName" + (this.state.groupId === group.id ? " selected" : "")}> {group.name}</div>
                                        </div>}
                                        content={<div style={{ padding: 10 }}>{group.name}</div>}
                                        on='hover'
                                        position='top center'
                                    />
                                </div>
                            );
                    })}
                </Slider>
            </div>
        );
    }

    private setRef = (slider: Slider) => {
        this.slider = slider;
    }
}
export default connect((state: IState) => ({ state }))(IconGroupSelection);
