import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n'

interface ScormFooterProps extends IIMGTranslatedComponent {
}
class ScormFooter extends Component<ScormFooterProps, {}> {
    render() {
        return (
            <div className="ScormContentFooter">
                <div className="ScormContentFooterText">
                    <div style={{ display: "inline" }}>&copy; {this.props.t("2019 dr. ing. h.c. f. porsche ag.")} </div>
                    <div style={{ display: "inline", whiteSpace: "nowrap" }}>
                        <Link to="/legal" style={{ textDecoration: "underline", color: "white" }}>{this.props.t("imprint and legals")}</Link>.
                        <Link to="/legal" style={{ textDecoration: "underline", color: "white" }}>{this.props.t("data privacy")}</Link>.</div>
                </div>
            </div>
        )
    }
}
export default translate("dsj")(connect()(ScormFooter));

