import * as _ from "lodash";
import * as React from "react";

import locale_jp from "date-fns/locale/ja";
import locale_zhCN from "date-fns/locale/zh-CN";
import locale_zhTW from "date-fns/locale/zh-TW";
import locale_ko from "date-fns/locale/ko";
import locale_pt from "date-fns/locale/pt";
import locale_en from "date-fns/locale/en-GB";
import locale_enUS from "date-fns/locale/en-US";
import locale_es from "date-fns/locale/es";
import locale_fr from "date-fns/locale/fr";
import locale_it from "date-fns/locale/it";
import locale_nl from "date-fns/locale/nl";
import locale_ru from "date-fns/locale/ru";
import locale_de from "date-fns/locale/de";
import locale_ar from "date-fns/locale/ar-SA";
import locale_el from "date-fns/locale/el";
import locale_pl from "date-fns/locale/pl";
import locale_tr from "date-fns/locale/tr";
import { BaseClient } from "collaboration-service";
import { languages } from "./Languages";
import { countries } from "./Countries";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface IImgI18NConnected {
    onUpdateImgI18N: () => void;
    onLoadedImgI18N: (namespace: string) => void;
}

export interface ImgI18NConfig {
    saveMissing?: boolean;
    baseLanguage: string;
    initNamespace: string;
    loadPath: string;
    addPath?: string;
    client: BaseClient;
    initAllKeys?: boolean;
    debug?: number;
    searchPrefix?: string;
}

type i18nStateType = "loaded" | "init" | "loading";

interface MultiLanguageData {
    [key: string]: LanguageData;
}

export interface LanguageData {
    [key: string]: string;
}

export interface NamespaceData {
    data: LanguageData;
}

export interface LanguagesData {
    data: { [key: string]: NamespaceData };
    state: i18nStateType;
}

export interface LanguagesMap {
    [key: string]: LanguagesData;
}

export default class ImgI18N {

    public static lngDefinitions = [
        { label: "Japanese", value: "ja-JP", newCode: "ja-JP", flag: ["jp"], order: 'J', locale: locale_jp, tvalue: "ja", visible: true },
        { label: "Chinese (Simplified)", newCode: "zh-CN", value: "zh-CN", flag: ["cn"], order: 'C', locale: locale_zhCN, tvalue: "zh-Hans", visible: true },
        { label: "Chinese (Traditional)", newCode: "zh-TW", value: "zh-TW", flag: ["tw"], order: 'T', locale: locale_zhTW, tvalue: "zh-Hans", visible: true },
        { label: "Korean", value: "ko-KR", newCode: "ko-KR", flag: ["kr"], order: 'K', locale: locale_ko, tvalue: "ko", visible: true },
        { label: "Portuguese", value: "pt-BR", newCode: "pt-BR", flag: ["pt"], order: 'P', locale: locale_pt, tvalue: "pt-BR", visible: true },
        { label: "English", value: "en-GB", newCode: "en-GB", flag: ["gb"], order: 'A', locale: locale_en, tvalue: "en", visible: true },
        { label: "English", value: "en-US", newCode: "en-US", flag: ["us"], order: 'B', locale: locale_enUS, tvalue: "en", visible: true },
        { label: "English (korean)", newCode: "en-KR", value: "en-kr", flag: ["gb", "kr"], order: 'KR', locale: locale_en, tvalue: "en", visible: true },
        { label: "Spanish", value: "es-ES", newCode: "es-ES", flag: ["es"], order: 'S', locale: locale_es, tvalue: "es", visible: true },
        { label: "French", value: "fr-FR", newCode: "fr-FR", flag: ["fr"], order: 'F', locale: locale_fr, tvalue: "fr", visible: true },
        { label: "Italian", value: "it-IT", newCode: "it-IT", flag: ["it"], order: 'I', locale: locale_it, tvalue: "it", visible: true },
        { label: "Dutch", value: "nl-NL", newCode: "nl-NL", flag: ["nl"], order: 'D', locale: locale_nl, tvalue: "nl", visible: true },
        { label: "Russian", value: "ru-RU", newCode: "ru-RU", flag: ["ru"], order: 'R', locale: locale_ru, tvalue: "ru", visible: true },
        { label: "German", value: "de-DE", newCode: "de-DE", flag: ["de"], order: 'B1', locale: locale_de, tvalue: "de", visible: true },
        { label: "Swiss German", value: "de-CH", newCode: "de-CH", flag: ["de", "ch"], order: 'Sx1', locale: locale_de, tvalue: "de", visible: true },
        { label: "Swiss French", value: "fr-CH", newCode: "fr-CH", flag: ["fr", "ch"], order: 'Sx2', locale: locale_fr, tvalue: "fr", visible: true },
        { label: "Swiss Italian", value: "it-CH", newCode: "it-CH", flag: ["it", "ch"], order: 'Sx3', locale: locale_it, tvalue: "it", visible: true },
        // { label: "Greek", value: "gr", flag: ["gr"], order: 'B1', locale: locale_el, tvalue: "el", visible: true },
        // { label: "Arabic", value: "ar", flag: ["ar"], order: "B1", locale: locale_ar, tvalue: "ar", visible: true },
        // { label: "Turkish", value: "tr", flag: ["tr"], order: 'B1', locale: locale_tr, tvalue: "tr", visible: true },
        // { label: "Polish", value: "pl", flag: ["pl"], order: "B1", locale: locale_pl, tvalue: "pl", visible: true },
    ];

    private static instance?: ImgI18N;
    private languageMap: LanguagesMap = {};
    private _currentLanguage: string | undefined;


    private mapLanguages = (lng?: string) => {
        if (!lng)
            return this.getBrowserLanguage().value;
        // const split = lng.split("-");
        // if (split.length > 1) {
        //     switch (lng) {
        //         case "en-GB":
        //             return "en";
        //         case "ja-JP":
        //             return "jp";
        //         case "zh-TW":
        //         case "zh-CN":
        //         case "de-CH":
        //         case "fr-CH":
        //         case "it-CH":
        //             return lng;
        //         default:
        //             return split[0];
        //     }
        // }
        return lng;
    }

    private mapLanguagesOld = (lng?: string) => {
        if (!lng)
            return this.getBrowserLanguage().value;
        const split = lng.split("-");
        if (split.length > 1) {
            switch (lng) {
                case "en-GB":
                    return "en";
                case "ja-JP":
                    return "jp";
                case "zh-TW":
                case "zh-CN":
                case "de-CH":
                case "fr-CH":
                case "it-CH":
                    return lng;
                default:
                    return split[0];
            }
        }
        return lng;
    }

    private get currentLanguage() {
        return this.mapLanguages(this._currentLanguage);
    }

    private set currentLanguage(val: string) {
        this._currentLanguage = val;
    }
    private subscribers: IImgI18NConnected[] = [];
    private regex: RegExp;

    public static init(config: ImgI18NConfig) {
        this.instance = new ImgI18N(config);
        _.forEach(countries, (cd, ck) => {
            _.forEach(cd.languages, lcode => {
                const lng = (languages as any)[lcode];
                if (lng && ck !== "IM") {
                    const lf = _.find(ImgI18N.lngDefinitions, l => l.value.startsWith(lcode));
                    if (!lf)
                        ImgI18N.lngDefinitions.push({ label: lng.name, newCode: `${lng.name}-${ck}`, value: lcode, flag: [ck.toLowerCase()], order: "Z", locale: locale_en, tvalue: "", visible: false });
                }
            });
        });
    }

    public static getInstance(): ImgI18N {
        if (!this.instance)
            throw new Error("Not initialized!");
        return this.instance;
    }


    private constructor(private config: ImgI18NConfig) {
        if (this.debug > 0)
            console.log("ImgI18N: creating instance");
        if (config.saveMissing && !config.addPath)
            throw new Error("Add path not set!");
        this.config.initNamespace = this.config.initNamespace.toLowerCase().trim();
        this.regex = RegExp(/\{\{([a-zA-Z0-9_]+)\}\}/gi);
        this.initialize();
    }

    public getBrowserLanguage = () => {
        const userLang: string = navigator.language || (navigator as any).userLanguage;
        return _.find(ImgI18N.lngDefinitions, l => userLang.indexOf(l.value) >= 0 && l.visible) ??
            _.find(ImgI18N.lngDefinitions, l => userLang.indexOf(l.tvalue) >= 0 && l.visible) ??
            _.find(ImgI18N.lngDefinitions, l => l.value === this.config.baseLanguage && l.visible) ??
            ImgI18N.lngDefinitions[0];
    }
    public getDefinition = (lng: string) => {
        return _.find(ImgI18N.lngDefinitions, l => lng.indexOf(l.value) >= 0);
    }

    public getDefinitionExact = (lng: string) => {
        return _.find(ImgI18N.lngDefinitions, l => lng === l.value);
    }

    public getLanguageDefinitons = (withInvisible?: boolean) => {
        const toRet = [];
        _.forEach(ImgI18N.lngDefinitions, d => {
            if (d.visible || withInvisible)
                toRet.push(d);
        });
    }

    public changeLanguage(lng: string) {
        if (this.debug > 1)
            console.log("ImgI18N: try to change to " + lng);
        if (this._currentLanguage !== lng) {
            if (this.debug > 1)
                console.log("ImgI18N: changing language from \"" + this.currentLanguage + "\" to \"" + lng + "\"");
            this.currentLanguage = lng;
            this.updated();
        }
    }

    public subscribe(subscriber: IImgI18NConnected) {
        const idx = _.findIndex(this.subscribers, subscriber);
        if (idx === -1)
            this.subscribers.push(subscriber);
    }

    public unSubscribe(subscriber: IImgI18NConnected) {
        const idx = _.findIndex(this.subscribers, subscriber);
        if (idx >= 0)
            this.subscribers.splice(idx, 1);
    }

    public t(namespace: string | undefined, text: string, data: any): string {
        if (text === "")
            return text;
        text = text.toLowerCase();
        const ns = namespace ? namespace.toLowerCase().trim() : this.config.initNamespace;
        if (this.debug > 2)
            console.log("ImgI18N: getting translation for \"" + text + "\" - language \"" + this.currentLanguage + "\", namespace \"" + ns + "\"");

        if (!this.languageMap[this.currentLanguage] || this.languageMap[this.currentLanguage].state !== "loaded")
            return this.replacePlaceholders(text, data);

        let toRet: string | undefined =
            this.languageMap[this.currentLanguage].state === "loaded" ?
                this.languageMap[this.currentLanguage]?.data[ns]?.data[text] : undefined;
        if (!toRet)
            toRet = this.add(ns, text);

        toRet = this.replacePlaceholders(toRet, data);

        if (this.debug > 2)
            console.log("ImgI18N: translation for \"" + text + "\" is \"" + toRet + "\" - language \"" + this.currentLanguage + "\", namespace \"" + ns + "\"");
        return toRet;
    }

    public t_jsx(namespace: string | undefined, text: string, data: any): string | JSX.Element[] {
        if (text === "")
            return text;
        text = text.toLowerCase();
        const ns = namespace ? namespace.toLowerCase().trim() : this.config.initNamespace;
        if (this.debug > 2)
            console.log("ImgI18N: getting translation for \"" + text + "\" - language \"" + this.currentLanguage + "\", namespace \"" + ns + "\"");

        if (!this.languageMap[this.currentLanguage] || this.languageMap[this.currentLanguage].state !== "loaded")
            return this.replacePlaceholdersJSX(text, data);

        let toRet: string | JSX.Element[] | undefined =
            this.languageMap[this.currentLanguage].state === "loaded" ?
                this.languageMap[this.currentLanguage]?.data[ns]?.data[text] : undefined;
        if (!toRet)
            toRet = this.add(ns, text);

        toRet = this.replacePlaceholdersJSX(toRet, data);

        if (this.debug > 2)
            console.log("ImgI18N: translation for \"" + text + "\" is \"" + toRet + "\" - language \"" + this.currentLanguage + "\", namespace \"" + ns + "\"");
        return toRet;
    }
    public checkNamespace(namespace?: string): boolean {
        //        console.log("==================> ns =>", namespace, "cl => ", this.currentLanguage);
        const names = namespace ? namespace.toLowerCase().trim() : this.config.initNamespace;
        const lng = this.languageMap[this.currentLanguage] !== undefined;
        const ns = lng ? this.languageMap[this.currentLanguage].data[names] !== undefined : false;
        //        console.log("ns =>", namespace, " lng => ", lng, ", ns => ", ns);

        if (!lng || !ns) {
            if (this.debug > 1)
                console.log(`no namespace or no language found for lng ${this.currentLanguage} and ns ${names}`);
            if (!lng) {
                if (this.debug > 1)
                    console.log(`language not found for lng ${this.currentLanguage} and ns ${names}`);
                this.languageMap[this.currentLanguage] = { state: "init", data: {} };
            }
            else {
                if (this.debug > 1)
                    console.log(`namespace not found for lng ${this.currentLanguage} and ns ${names}`);
                this.languageMap[this.currentLanguage].data[names] = { data: {} };
                return true;
            }
            this.loadLanguage(this.currentLanguage, names);
            return false;
        }
        else {
            if (this.debug > 1)
                console.log(`returning loaded data for lng ${this.currentLanguage} and ns ${names}`);
            if (this.languageMap[this.currentLanguage].state === "loaded") {
                this.loaded(names);
                return true;
            }
        }
        /*        console.log(this.languageMap[this.currentLanguage].data[names].state === "loaded");
                console.log(this.languageMap);
                console.log(this.currentLanguage);
                console.log(this.languageMap[this.currentLanguage]);
                console.log(this.languageMap[this.currentLanguage].data);
                console.log(names);
                console.log(this.languageMap[this.currentLanguage].data[names]);*/
        return false;
    }

    public get debug(): number {
        return this.config.debug ? this.config.debug : 0;
    }

    public set debug(val: number) {
        this.config.debug = val;
    }

    public get language(): string {
        return this.currentLanguage;
    }

    public get languageDefinition() {
        const toRet = _.find(ImgI18N.lngDefinitions, l => l.value === this.currentLanguage);
        if (toRet)
            return toRet;
        const en = _.find(ImgI18N.lngDefinitions, l => l.value === "en");
        return en ? en : ImgI18N.lngDefinitions[0];
    }

    public get standardNamespace(): string {
        return this.config.initNamespace;
    }

    /* private methods */
    public replacePlaceholders(text: string, data: { [key: string]: string | number | undefined }): string {
        if (data) {
            // nothing
            let result = text;
            let res = this.regex.exec(text);
            while (res) {
                result = result.replace(res[0], data[res[1].trim()]?.toString() ?? "undefined");
                res = this.regex.exec(text);
            }
            return result;
        }
        else
            return text;
    }
    public replacePlaceholdersJSX(text: string, data: { [key: string]: JSX.Element | string | number | undefined }): string | JSX.Element[] {
        const toRet: JSX.Element[] = [];
        if (data) {
            // nothing
            let res = this.regex.exec(text);
            let lastI = 0;
            while (res) {
                const toSet = data[res[1].trim()];
                const i = text.indexOf(res[0], lastI);
                toRet.push(<span key={`${i}-base`}>{text.substr(lastI, i - lastI)}</span>);
                lastI = i + res[0].length;
                if (toSet) {
                    if (typeof toSet === "string" || typeof toSet === "number")
                        toRet.push(<span key={`${i}-data`}>{toSet.toString()}</span>);
                    else
                        toRet.push(toSet);
                }
                else {
                    toRet.push(<>undefined</>);
                }
                res = this.regex.exec(text);
            }
            return toRet;
        }
        else
            return text;
    }


    private add(namespace: string, text: string): string {
        if (this.languageMap[this.currentLanguage].state !== "loaded")
            return text;
        const lngPrefix = this.config.searchPrefix ? this.languageMap[this.currentLanguage]?.data[this.config.searchPrefix + namespace]?.data[text] : undefined;
        const baseLngPrefix = this.config.searchPrefix ? this.languageMap[this.config.baseLanguage]?.data[this.config.searchPrefix + namespace]?.data[text] : undefined;
        const baseLng = this.languageMap[this.config.baseLanguage].state !== "loaded" ? this.languageMap[this.config.baseLanguage]?.data[this.config.searchPrefix + namespace]?.data[text] : undefined;
        //console.log(`add => ns = ${namespace}, ${text} => lngPrefix = ${lngPrefix}, baseLngPrefix = ${baseLngPrefix}, baseLng = ${baseLng}`);
        // save for current language
        if (lngPrefix) {
            this.saveMissing(this.currentLanguage, namespace, text, lngPrefix);
            this.languageMap[this.currentLanguage].data[namespace].data[text] = lngPrefix;
        }
        else if (baseLngPrefix) {
            this.saveMissing(this.currentLanguage, namespace, text, baseLngPrefix);
            this.languageMap[this.currentLanguage].data[namespace].data[text] = baseLngPrefix;
        }
        else if (baseLng) {
            this.saveMissing(this.currentLanguage, namespace, text, baseLng);
            this.languageMap[this.currentLanguage].data[namespace].data[text] = baseLng;
        } else {
            this.saveMissing(this.currentLanguage, namespace, text, text);
            this.languageMap[this.currentLanguage].data[namespace].data[text] = text;
        }
        // save for base lng if needed
        if (!baseLng && baseLngPrefix) {
            this.saveMissing(this.config.baseLanguage, namespace, text, baseLngPrefix);
            if (!this.languageMap[this.config.baseLanguage].data[namespace])
                this.languageMap[this.config.baseLanguage].data[namespace] = { data: {} };
            this.languageMap[this.config.baseLanguage].data[namespace].data[text] = baseLngPrefix;
        }
        if (!baseLng && !baseLngPrefix) {
            this.saveMissing(this.config.baseLanguage, namespace, text, text);
            if (!this.languageMap[this.config.baseLanguage].data[namespace])
                this.languageMap[this.config.baseLanguage].data[namespace] = { data: {} };
            this.languageMap[this.config.baseLanguage].data[namespace].data[text] = text;
        }
        return this.languageMap[this.currentLanguage].data[namespace].data[text];
    }

    private saveMissing(lng: string, namespace: string, text: string, translation: string) {
        if (this.config.saveMissing && this.config.addPath) {
            const data = [{ key: text, value: translation }];

            this.config.client.callMethod<void>(
                {
                    uri: this.config.addPath,
                    method: "POST",
                    pathElements: [lng, namespace],
                    data
                });
            if (this.debug > 1)
                console.log("ImgI18N: adding :[" + lng + "] :: [" + namespace + "] = \"" + text + "\" --> \"" + translation + "\"");
        }
    }


    private loadLanguage(lng: string, namespace: string, throwIt?: boolean) {
        if (this.debug > 0)
            console.log("ImgI18N: trying to load language \"" + lng + "\"");
        if (this.languageMap[lng].state === "loading") {
            if (this.debug > 0)
                console.log("ImgI18N: skipping to load language \"" + lng + "\"");
            return;
        }
        this.languageMap[lng].state = "loading";
        const oldLng = this.mapLanguagesOld(lng);
        this.config.client.call<MultiLanguageData>(
            {
                uri: this.config.loadPath,
                method: "GET",
                data: undefined,
                pathElements: [oldLng], // namespace],
                onSuccess: (val: MultiLanguageData) => {
                    if (!this.languageMap[lng])
                        this.languageMap[lng] = { state: "loading", data: {} };
                    _.forEach(val, (data, key) => this.languageMap[lng].data[key] = { data });
                    if (!this.languageMap[lng].data[namespace])
                        this.languageMap[lng].data[namespace] = { data: {} };
                    // _.forEach(this.languageMap[lng].data, (x, key) => {
                    //     if (!this.languageMap[lng].data[key])
                    //         this.languageMap[lng].data[key] = { data: {} };
                    // });
                    this.languageMap[lng].state = "loaded";
                    if (this.debug > 0)
                        console.log("ImgI18N: loaded language \"" + lng + "\"");
                    _.forEach(this.languageMap[lng].data, (x, ns) => this.loaded(ns));
                },
                onError: () => {
                    if (throwIt)
                        throw new Error("could not load language: " + lng);

                    if (this.config.initAllKeys && this.languageMap[this.config.baseLanguage]) {
                        if (this.debug > 0)
                            console.log("ImgI18N: could not loaded namespace \"" + namespace + "\" for language \"" + lng + "\" creating copy from base language \"" + this.config.baseLanguage + "\"");
                        this.languageMap[lng] = Object.assign({}, this.languageMap[this.config.baseLanguage]);
                        this.loaded(namespace);
                    }
                    else {
                        if (this.debug > 0)
                            console.log("ImgI18N: could not loaded namespace \"" + namespace + "\" for language \"" + lng + "\" creating empty set");
                        this.loaded(namespace);
                    }
                }
            });
    }

    private initialize() {
        this.languageMap[this.config.baseLanguage] = { state: "init", data: {} };
        this.languageMap[this.config.baseLanguage].data[this.config.initNamespace] = { data: {} };
        this.loadLanguage(this.config.baseLanguage, this.config.initNamespace, true);
    }

    private updated() {
        _.forEach(this.subscribers, (s) => s.onUpdateImgI18N());
    }

    private loaded(namespace: string) {
        _.forEach(this.subscribers, (s) => s.onLoadedImgI18N(namespace));
    }
}


export type IMGTranslateFunction = (text: string, data?: { [key: string]: string | number | undefined }) => string;
export type IMGTranslateFunctionJSX = (text: string, data?: { [key: string]: string | number | JSX.Element | undefined }) => string | JSX.Element[];

export interface IIMGTranslatedComponent {
    t: IMGTranslateFunction;
    t_jsx: IMGTranslateFunctionJSX;
    i18n: ImgI18N;
    version: number;
}



interface IImgI18NWrapperClassState {
    id: number;
    instance: ImgI18N;
}

export function translate(namespace?: string) {
    const toRet = <P extends { [key: string]: any }>(cmp: React.ComponentClass<P> | React.StatelessComponent<P>) => {
        return class extends React.Component<Omit<P, keyof IIMGTranslatedComponent>, IImgI18NWrapperClassState> implements IImgI18NConnected {
            public noT = false;
            public state = {
                id: 0,
                instance: ImgI18N.getInstance()
            };
            public t = (text: string, data?: { [key: string]: string | number | undefined }) => {
                if (this.noT)
                    return "";
                //console.log(`t("${namespace}", "${text}",...)`);
                return ImgI18N.getInstance().t(namespace, text, data);
            }
            public t_jsx = (text: string, data?: { [key: string]: string | number | JSX.Element | undefined }) => {
                if (this.noT)
                    return "";
                //console.log(`t("${namespace}", "${text}",...)`);
                return ImgI18N.getInstance().t_jsx(namespace, text, data);
            }

            public UNSAFE_componentWillMount() {
                this.state.instance.subscribe(this);
                if (!this.state.instance.checkNamespace(namespace))
                    this.noT = true;
            }

            public componentWillUnmount() {
                this.state.instance.unSubscribe(this);
            }

            public onUpdateImgI18N() {
                if (this.state.instance.debug > 2)
                    console.log("ImgI18N: WrapperClass - onUpdateImgI18N");
                this.state.instance.checkNamespace(namespace);
            }

            public onLoadedImgI18N(ns: string) {
                const usedNs = (namespace ? namespace.toLowerCase() : this.state.instance.standardNamespace);
                if (ns === usedNs) {
                    if (this.state.instance.debug > 2)
                        console.log("ImgI18N: WrapperClass - onLoadedImgI18N namespace loaded \"" + ns + "\" namespace used \"" + usedNs + "\"");
                    this.noT = false;
                    this.setState(
                        {
                            id: this.state.id + 1,
                        });
                }
            }

            public render() {
                if (this.state.id === 0 && this.noT)
                    return <div />;
                const C = cmp;
                const props = { ...this.props, t: this.t, t_jsx: this.t_jsx, i18n: this.state.instance, version: this.state.id } as any as P;
                return <C {...props} />;
            }
        };
    };
    return toRet;
}
