import { AssetController, AssetDownloadDto, CollaborationServiceSetup } from 'collaboration-service';
import ButtonCancel from 'components/Posts/Buttons/ButtonCancel';
import ButtonSave from 'components/Posts/Buttons/ButtonSave';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, SemanticSIZES } from 'semantic-ui-react';
import { config } from 'services/Config';
import { IConnectedComponent, IState } from 'services/Interfaces';
import AssetListView from 'views/AssetManagement/AssetListView';
import PopupModal from '../PopupModal/PopupModal';

export type AssetRestriction = "reference" | "media" | "both";
export interface AssetSelectorProps extends IConnectedComponent, IIMGTranslatedComponent {
    disabled?: boolean;
    onAssetSelected: (asset: AssetDownloadDto) => void;
    getOpenList?: (openAssetList: () => void) => void;
    restrict?: AssetRestriction;
    size?: SemanticSIZES;
    buttonText?: string;
}

export interface AssetSelectorState {
    listOpen?: boolean;
    token?: string;
    assets?: AssetDownloadDto[];
    selected?: AssetDownloadDto;
}

class AssetSelector extends React.Component<AssetSelectorProps, AssetSelectorState> {
    constructor(props: AssetSelectorProps) {
        super(props);
        if (this.props.getOpenList)
            this.props.getOpenList(this.list(true));

        this.state = {
        }
    }

    componentDidMount() {
        this.getAssets();
    }

    public render() {
        if (!config.assetManagementFeatures.allowAssetSelects)
            return <div />
        return (
            <>
                {!this.props.getOpenList &&
                    <Button icon="list" basic color="green" onClick={this.list(true)} disabled={this.state.assets === undefined} size={this.props.size} content={this.props.buttonText} />
                }
                {this.state.assets &&
                    <>

                        <PopupModal
                            headline={this.props.t("assets")}
                            open={this.state.listOpen}
                            buttonClick={this.list(false)}
                            buttons={[
                                <ButtonSave key="save" onClick={this.submit} disabled={this.state.selected === undefined} />,
                                <ButtonCancel key="cancel" onClick={this.list(false)} />
                            ]}
                        >

                            <AssetListView
                                userId={this.props.state.userInfo?.id}
                                requestLoadAssets={this.getAssets}
                                requestMoreAssets={this.getAssets}
                                assets={this.state.assets}
                                hasMoreAssets={this.state.token !== "" && this.state.token !== undefined}
                                selected={this.selected}
                            />
                        </PopupModal>
                    </>
                }
            </>
        );
    }

    private selected = (selected: AssetDownloadDto) => this.setState({ selected });
    private submit = () => {
        if (this.state.assets && this.state.selected !== undefined) {
            this.props.onAssetSelected(this.state.selected);
        }
        this.list(false)();
    }

    private getAssets = () => {
        //console.log(this.props);
        if (this.props.state) {
            if (!CollaborationServiceSetup.client) {
                //                console.log("reinit client.");
                CollaborationServiceSetup.Initialize(this.props.state.client);
            }
            AssetController.GetAssets({ token: { token: this.state.token }, restrict: this.props.restrict }, res => {
                if (this.state.token && this.state.assets) {
                    const assets = this.state.assets;
                    assets.push(...res.elements);
                    this.setState({ assets, token: res.data });
                }
                else {
                    this.setState({ assets: res.elements, token: res.data });
                }
            });
        }
    }

    private list = (listOpen: boolean) => () => this.setState({ listOpen });
}

export default translate("assets")(connect((state: IState) => ({ state }))(AssetSelector));