import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Dimmer, Loader } from "semantic-ui-react";
import { PostDownloadDto, PostController, ControllerHelper } from "collaboration-service";
import { config } from "services/Config";
import { Redirect } from "react-router-dom";

interface PNHandlerProps extends RouteComponentProps<{ id: string }> {

}

interface PNHandlerState {
    post?: PostDownloadDto;
    type?: string;
    error?: boolean;
}

export default class PNHandler extends React.Component<PNHandlerProps, PNHandlerState>{
    public state: PNHandlerState = {};

    async componentDidMount() {
        const [type, post] = await ControllerHelper
            .addCall({ id: this.props.match.params.id }, PostController.GetElementType, true)
            .addCall({ id: this.props.match.params.id }, PostController.GetSinglePost, true)
            .execute<string, PostDownloadDto>();

        this.setState({ post, type, error: type ? false : true });

    }
    render() {
        if (this.state.error)
            return <Redirect to={config.routes.general.grouphub} />;
        if (this.state.type) {
            switch (this.state.type) {
                case "ContentPost":
                    return <Redirect to={`${config.routes.landingPage.main}/${this.state.post?.group_id}/${this.state.post?.id}/push`} />;
                case "TubePost":
                    return <Redirect to={`${config.routes.tube.video}/${this.state.post?.id}`} />;
                case "BestPracticePost":
                    return <Redirect to={`${config.routes.bestpractice.detail}/${this.state.post?.id}`} />;
                case "Chat":
                    return <Redirect to={`${config.routes.community.chat}/${this.props.match.params.id}`} />;
                default:
                    return <Redirect to={`${config.routes.community.singlePost}/${this.state.post?.id}`} />;
            }
        }
        else
            return (
                <Dimmer>
                    <Loader active={this.state.post === undefined} />
                </Dimmer>
            );
    }
}
