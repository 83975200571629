import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';
import pt from 'date-fns/locale/pt';
import ru from 'date-fns/locale/ru';
import zhCn from 'date-fns/locale/zh-CN';
import zhTw from 'date-fns/locale/zh-TW';
import * as React from 'react';
import { registerLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import MobileHelper from 'services/MobileHelper';
import "ts-polyfill";
import ImgI18N from "./components/Utils/img-i18n";
import InnerApp from './InnerApp';
import { store } from './services/Reducer';
import { ThemeProvider } from 'styled-components';
import { porscheTheme } from 'Theme';



registerLocale("de", de);
registerLocale("en", en);
registerLocale("en-us", enUS);
registerLocale("es", es);
registerLocale("fr", fr);
registerLocale("it", it);
registerLocale("ja", ja);
registerLocale("ko", ko);
registerLocale("nl", nl);
registerLocale("pt", pt);
registerLocale("ru", ru);
registerLocale("zh-cn", zhCn);
registerLocale("zh-tw", zhTw);


ImgI18N.init({
    addPath: "api/i18n",
    saveMissing: true,
    loadPath: "api/i18n",
    baseLanguage: "en",
    initNamespace: "collaboration",
    client: store.getState().client,
    debug: 0,
    searchPrefix: "@"
});

MobileHelper.getInstance().startup();

class App extends React.Component<{}, {}> {
    public render() {
        // register();
        return (
            <ThemeProvider theme={porscheTheme}>
                <Provider store={store}>
                    <div>
                        <InnerApp />
                    </div>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default App;
