import * as React from 'react';
import { TubePostDownloadDto } from 'collaboration-service';
import styled from 'styled-components';
import { ListEntryBase, ListEntryBaseImage169, ContainerInfo } from './ListEntry';
import { getTranslated, getTranslatedValue, getMediaForContent, nop } from 'services/Helpers';
import Media from 'components/General/Media/Media';
import * as _ from "lodash";
import { Modal, Icon } from 'semantic-ui-react';
import { config } from 'services/Config';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
export interface ListTubePostProps {
    post: TubePostDownloadDto;
    color: string;
    saveClick: (id: string) => void;
}

interface ListTubePostState {
    fullscreen: boolean;
}

const Container = styled(ListEntryBase)`
    position:relative;
`;

const ImgContainer = styled(ListEntryBaseImage169)`
`;

const TitleContainer = styled.div`
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column: 2;
    padding:0 20px 0 0;
`;

const Title = styled.div`
    text-align: center;
    vertical-align: middle;
    transform: translateY(45px) translateY(-50%);
`;

export const ContentAggregationFullscreen = styled.div`
    display: grid;
    display: -ms-grid;
    height: 100vh;
    width: 100%;
    grid-template-rows: 50px auto;
    -ms-grid-rows: 50px auto;
`;

export const ContentAggregationFullscreenTitle = styled.div`
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row: 1;
`;

export const ContentAggregationFullscreenVideo = styled.div`
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    width: 100%;
    height: 100%;
    margin: auto;
`;

export default class ListTubePost extends React.Component<ListTubePostProps, ListTubePostState> {
    public state: ListTubePostState = {
        fullscreen: false,
    }
    public render() {
        const cMedia = getTranslatedValue(this.props.post.content);
        let media;
        if (cMedia) {
            if (!this.state.fullscreen && cMedia.thumbnails && _.some(cMedia.thumbnails)) {
                media =
                {
                    media: cMedia.thumbnails[0],
                    refName: "self"
                };
            } else {
                media = getMediaForContent(cMedia);
            }
        }
        //        console.log(media);
        return (
            <Container>
                <ContainerInfo color={this.props.color}>
                    <Icon name={config.onboardingIcons.video as SemanticICONS} size="large" />
                </ContainerInfo>
                <Modal
                    size="large"
                    basic
                    open={this.state.fullscreen}
                    onClose={this.normal}
                    closeIcon
                    className="myFullModal"
                >
                    <Modal.Content >
                        {media &&
                            <div style={{ boxShadow: "0 6px 8px -6px black" }}>
                                <Media media={media} autoPlay onFullScreenClick={nop} showFullScreen={false} />
                            </div>
                        }
                    </Modal.Content>
                </Modal>
                <ImgContainer onClick={this.fullscreen}>
                    {media &&
                        <Media media={media} contentClassName="ListTubePostImage" lazyLoading="off" showFullScreen={false} />
                    }
                </ImgContainer>
                <TitleContainer onClick={this.fullscreen}>
                    <Title>
                        {getTranslated(this.props.post.headlines)}
                    </Title>
                </TitleContainer>
            </Container>
        );
    }
    private fullscreen = () => {
        this.props.saveClick(this.props.post.id);
        this.setState({ fullscreen: true });
    }
    private normal = () => this.setState({ fullscreen: false });
}
