import * as React from 'react';
import { UserCommentDownloadDto } from 'collaboration-service';
import UserAvatar from 'components/General/UserAvatar/UserAvatar';
import { translateUsername, toLocalDateString } from 'services/Helpers';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import { Icon, Button, Popup } from 'semantic-ui-react';
import { config } from 'services/Config';
import { connect } from 'react-redux';
import { IState, IConnectedComponent } from 'services/Interfaces';


export interface TubeCommentProps extends IIMGTranslatedComponent, IConnectedComponent {
    comment: UserCommentDownloadDto;
    idx: number;
    onDelete: (id: number) => void;
    onChangeComment: (id: number, comment: string) => void;
    whiteTheme?: boolean;
}

export interface TubeCommentState {
    edit: boolean;
    currentComment: string;
}



class TubeComment extends React.Component<TubeCommentProps, TubeCommentState> {
    constructor(props: TubeCommentProps) {
        super(props);

        this.state = {
            edit: false,
            currentComment: props.comment.comment
        }
    }

    public render() {
        const { props } = this;
        const delLink = props.state.client.canExecute(props.comment.links, "delete");
        const editLink = props.state.client.canExecute(props.comment.links, "update");

        return (
            <>
                <div style={{ width: "100%", height: 60, borderBottom: this.props.whiteTheme ? "1px solid rgba(0, 0, 0, 0.04)" : "1px solid rgba(255, 255, 255, 0.04)", marginTop: 5 }}>
                    <div style={{ width: 40, float: "left", marginRight: 10 }}>
                        <UserAvatar user={this.props.comment.user} />
                    </div>
                    <div style={{ float: "right", marginTop: 15 }}>
                        {this.state.edit &&
                            <>
                                <Popup className='ComUserPopup' content={this.props.t("your entry will be deleted permanently")}
                                    trigger={
                                        <Button icon size="mini" color="black" basic style={{ marginRight: 20 }} onClick={this.deleteDelegate(this.props.idx)}><Icon name="trash" color="red" /></Button>
                                    } />
                                <Popup className='ComUserPopup' content={this.props.t("cancel edit")}
                                    trigger={
                                        <Button icon size="mini" color="black" basic onClick={this.closeEdit} ><Icon name="undo" color="grey" /></Button>
                                    } />
                            </>
                        }
                        {!this.state.edit && editLink !== undefined &&
                            <>
                                <Popup className='ComUserPopup' content={this.props.t("edit your comment")}
                                    trigger={
                                        <Button icon size="mini" color="black" basic onClick={this.openEdit} ><Icon name="edit" color="grey" /></Button>
                                    } />
                            </>
                        }
                        {this.state.edit && delLink !== undefined &&
                            <> <Popup className='ComUserPopup' content={this.props.t("save your edited comment")}
                                trigger={
                                    <Button icon size="mini" color="black" basic onClick={this.editDelegate(this.props.idx, this.state.currentComment)}><Icon name="check" color="grey" /></Button>
                                } />
                            </>
                        }
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <span style={{ fontWeight: 500, color: this.props.whiteTheme ? "#313639" : "rgba(255, 255, 255, 0.9)", fontSize: "1em" }}>
                            {translateUsername(this.props.comment.user)}</span> <span style={{ fontSize: "0.8em", color: this.props.whiteTheme ? "#313639" : "rgba(255, 255, 255, 0.7)" }}>
                            {toLocalDateString(this.props.t, this.props.comment.created)}
                        </span>
                        <br />
                        {!this.state.edit &&
                            <>
                                <Icon name="quote right" color="red" /> {this.props.comment.comment}
                            </>
                        }
                    </div>
                </div>
                {this.state.edit &&
                    <textarea
                        value={this.state.currentComment}
                        onChange={this.onTextChange}
                        maxLength={config.maxCommentLength}
                        className="VideoCommentTextAreaEdit"
                    />
                }
            </>
        );
    }
    private openEdit = () => {
        this.setState({ edit: true, currentComment: this.props.comment.comment });
    }
    private closeEdit = () => {
        this.setState({ edit: false });
    }
    private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ currentComment: event.target.value });
    }

    private deleteDelegate = (id: number) => () => {
        this.setState({ edit: false }, () => this.props.onDelete(id));
    }
    private editDelegate = (id: number, comment: string) => () => {
        this.props.onChangeComment(id, comment);
        this.setState({ edit: false });
    }
}
export default translate("tube")(connect((state: IState) => ({ state }))(TubeComment));