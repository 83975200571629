import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ActionCreators } from "services/Actions";
import { IConnectedComponent, IState } from "services/Interfaces";
import styled from "styled-components";

const Container = styled.div`
    width: 100vw;
    height: calc(100vh - 50px);
    display: grid;
    grid-template-rows: auto 40px;
`;

// const IFrame = styled.iframe`
//     grid-row: 1;
//     height: calc(100vh - 90px);
//     width: 100vw;
//     border: 0px solid black;
//     padding: 0;
//     margin: 0;
// `;

const Button = styled.button`
    grid-row: 2;
    width: 100%;
    height: 40px;
    background-color: #1d2022;
    color: #fff;
    &:hover {
        background-color: #1d2022;
        color: #fff;
    }
    &:focus {
        background-color: #1d2022;
        color: #fff;

    }
`;
interface LinkPresenterProps extends IConnectedComponent, RouteComponentProps<any> {
    src: string;
}
interface LinkPresenterState {
    reload: boolean;
}
class LinkPresenter extends React.PureComponent<LinkPresenterProps, LinkPresenterState> {
    public state: LinkPresenterState = {
        reload: false
    };
    render() {
        const click = () => this.props.dispatch(ActionCreators.SetLinkAddress(undefined));
        if (!this.props.state.linkAddress)
            this.props.history.goBack();
        return (
            <Container>
                {/* <IFrame width="100%" height="100%" title="scorm" allowFullScreen src={this.props.state.linkAddress} /> */}
                <a download href={this.props.state.linkAddress} className="ContentLinkA">Link to content</a>
                <Button onClick={click}>
                    <Icon name="chevron left" />
                </Button>
            </Container>
        );
    }
}

export default withRouter(connect((state: IState) => ({ state }))(LinkPresenter));