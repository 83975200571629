import { AssetDownloadDto } from "collaboration-service";
import { Button, getColor, Icon, styled } from "imaginarity-react-ui";
import * as React from "react";
import { createPost, getTranslated } from "services/Helpers";
import { AssetTitle } from "./AssetListView";

interface ReferenceAssetProps {
    asset: AssetDownloadDto;
}

const Container = styled.div`
`;
const PreviewContainerCentered = styled.div`
    position: fixed;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background: black;
`;

const Title = styled.div`
width: 100%;
&:hover{
    cursor: pointer;
    color: ${p => getColor(p, p.theme.colors.accent)};
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
}
`;
const TitleGrid = styled.div<{ open: boolean }>`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 20px max-content 1fr ${p => p.open && "40px"};
    -ms-grid-columns: 20px max-content 1fr ${p => p.open && "40px"};
    width: 100%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
        color: ${p => getColor(p, p.theme.colors.accent)};
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
export const TGIcon = styled.div`
`;
const TGType = styled.div`
    font-weight: bolder;
    font-size: 0.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    color:${p => getColor(p, p.theme.colors.mainBackground)};
`;
const TGTypeInner = styled.div`
    background: ${p => getColor(p, p.theme.colors.accent)};
    float: left;
    line-height: 20px;
    margin-top: 10px;
    padding: 0px 5px;
`;

export const TGTitle = styled.div`
    padding: 0 10px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
export const TGButton = styled.div`
`;


const ReferenceAsset = (p: ReferenceAssetProps) => {
    const [open, setOpen] = React.useState(false);
    // console.log(open);
    // console.log(p.asset.reference);
    return (
        <Container>
            {open &&
                <div style={{ position: "relative" }}>
                    <PreviewContainerCentered onClick={() => setOpen(!open)}>
                        {createPost(p.asset.reference, undefined, undefined, undefined, { viewOnly: true, width: 300 })}
                    </PreviewContainerCentered>
                </div>
            }

            <AssetTitle>
                {/* <Title onClick={() => setOpen(!open)}> */}
                <TitleGrid open={open} onClick={() => setOpen(!open)}>
                    <TGIcon><Icon name="package" style={{ float: "left" }} /></TGIcon>
                    <TGType><TGTypeInner>Tubepack</TGTypeInner></TGType>
                    <TGTitle>{getTranslated(p.asset.reference.headlines)}</TGTitle>
                    {open &&
                        <TGButton><Button floated="right" kind="primary" icon="times" onClick={() => setOpen(!open)} /></TGButton>
                    }
                </TitleGrid>
                {/* </Title> */}
            </AssetTitle>
        </Container>
    );
}

export default ReferenceAsset;