import * as React from 'react';
import { Icon, List } from 'semantic-ui-react';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';

interface IMissingInputProps extends IIMGTranslatedComponent {
    missing: string[];
    onGetToggler?: (toggleShowMissing: () => void) => void;
}

interface IMissingInputState {
    showMissing?: boolean;
}

class MissingInput extends React.Component<IMissingInputProps, IMissingInputState> {
    constructor(props: IMissingInputProps) {
        super(props);
        this.state = {
            showMissing: true
        };
        if (props.onGetToggler)
            props.onGetToggler(this.toggleShowMissing);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IMissingInputProps) {
        if (nextProps.onGetToggler !== this.props.onGetToggler) {
            if (nextProps.onGetToggler)
                nextProps.onGetToggler(this.toggleShowMissing);
        }
    }

    public render() {
        return (
            <div>
                {this.props.missing.length > 0 &&
                    <div className='AddEditErrorFooter'>
                        {/* <Divider horizontal onClick={this.toggleShowMissing}>{this.props.t("missing inputs")} <Icon loading name='asterisk' /></Divider> */}
                        <List>
                            <List.Item onClick={this.toggleShowMissing} className="Clickable">
                                <List.Icon ><Icon name='asterisk' color='red' /></List.Icon>
                                <List.Content><span style={{ textTransform: 'uppercase', marginLeft: -7, fontWeight: 700 }}>{this.props.t("missing inputs")}</span></List.Content>
                            </List.Item>
                        </List>
                        {this.state.showMissing && this.props.missing.map((v, i) => <div className="" key={i}>
                            <List>
                                <List.Item>
                                    <List.Icon name="dot circle outline" />
                                    <List.Content>{v}</List.Content>
                                </List.Item>
                            </List>
                        </div>
                        )}
                    </div>
                }
            </div>);
    }

    private toggleShowMissing = () => {
        this.setState({ showMissing: !this.state.showMissing });
    }

}

export default translate("admin")(MissingInput);