import { MediaController } from 'collaboration-service';
import { LinkDto } from "imaginarity-azure";
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import { checkMobile } from 'views/Admin/Invite/Invite';
import { createHeadline, getLink } from '../../../services/Helpers';
import { IConnectedComponent, IState } from '../../../services/Interfaces';
import { IIMGTranslatedComponent, translate } from '../../Utils/img-i18n';
import DefaultMainMenu from './DefaultMainMenu';
import Footer, { IFooterProps } from './Footer';

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

export interface IButtonInformation {
    buttonContent?: string;
    disabled?: boolean;
    onClick?: (v: any) => any;
    renderer?: (key: string | number, info: IButtonInformation) => JSX.Element;
}


interface IMainframeProps extends IConnectedComponent, RouteComponentProps<any>, IIMGTranslatedComponent {
    loadNoGroups?: boolean;
    staticBackgroundImage?: string;
    backgroundImage?: string;
    header?: string;
    menu?: React.ComponentClass<any> | React.StatelessComponent<any>;
    //    sidebar?: React.ComponentClass<Q> | React.StatelessComponent<Q>;
    //    feedback?: React.ComponentClass<R> | React.StatelessComponent<R>;
    footer?: React.ComponentClass<IFooterProps> | React.StatelessComponent<IFooterProps>;
    showFooter?: boolean;
    menuSearch?: boolean;
    buttons?: Array<IButtonInformation | undefined>;
    loading?: boolean;
    fullWidthMode?: boolean;
    padding?: string;
    hideFeedback?: boolean;
    width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
    loadingMessage?: string;
    loadingDimmed?: boolean;
    onSideBarActiveItemChanged?: (active: string) => void;
    className?: string;
    gridClassName?: string;
    title?: string | JSX.Element;
    buttonClassName?: string;
    menuProps?: { [key: string]: any };
    footerProps?: { [key: string]: any };
    sidebarProps?: { [key: string]: any };
    feedbackProps?: { [key: string]: any };
    onResize?: (width: number, height: number) => void;
    setMobile?: (mobile: boolean) => void;
}

interface IMainframeState {
    footer: boolean;
    disabled: boolean;
    savedItem?: string;
    contextRef?: any;
    backgroundImage?: string;
}

class Mainframe extends React.Component<IMainframeProps, IMainframeState> {
    private mounted: boolean | undefined;
    private mobile?: boolean | undefined;
    constructor(props: IMainframeProps) {
        super(props);
        this.state = {
            footer: false,
            disabled: false,
        };
    }

    public componentDidMount() {
        this.mounted = true;

        if (this.props.staticBackgroundImage) {
            const backgroundImage = "url(\"" + this.props.staticBackgroundImage + "\")";
            this.setState({ backgroundImage });
        }
        if (this.props.backgroundImage) {
            MediaController.GetByContainerAndName({ mediaInfo: { container: "global", file: this.props.backgroundImage } },
                (image: LinkDto) => {
                    const url = getLink([image], "self");
                    if (url) {
                        const backgroundImage = "url(\"" + url.uri + "\")";
                        if (this.mounted)
                            this.setState({ backgroundImage });
                    }
                });
        }
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        const MainMenu = this.props.menu;
        const F = this.props.footer ? this.props.footer : Footer;

        const x: React.CSSProperties = {};
        x.filter = "";
        let className = this.props.className ? this.props.className : "Mainframe";
        className = className + (this.state.backgroundImage ? " MainframeBackgroundImage" : "");

        const style = { "--bg": this.state.backgroundImage } as React.CSSProperties;
        const mProps: any = { ...this.props.menuProps };
        return (
            /* Begin page content */
            <div className={className} ref={this.handleContextRef} style={style}>

                {
                    MainMenu && this.props.state.groups ? (
                        <MainMenu
                            {...mProps}
                        />
                    )
                        : <DefaultMainMenu
                            title={this.props.title}
                            {...this.props.menuProps}
                        />
                }
                {!this.props.fullWidthMode &&
                    <Grid className={"MainGrid" + (this.props.gridClassName ? " " + this.props.gridClassName : "")} centered={true} container={true} columns='equal'>
                        <Grid.Column mobile={16} tablet={16} computer={this.props.width ? this.props.width : 9}>

                            {this.props.loadingDimmed &&
                                <Dimmer active={this.props.loading}>
                                    <Loader
                                        size='big'
                                    >
                                        {this.props.loadingMessage ? this.props.loadingMessage : this.props.t("Loading")}
                                    </Loader>
                                </Dimmer>
                            }
                            {!this.props.loadingDimmed &&
                                <Loader
                                    inverted={true}
                                    active={this.props.loading}
                                    size='big'
                                >
                                    {this.props.loadingMessage ? this.props.loadingMessage : this.props.t("Loading")}
                                </Loader>
                            }
                            {this.props.header && (<Segment className="Headline">{createHeadline(this.props.header)}</Segment>)}
                            {!this.props.fullWidthMode && this.props.children}
                        </Grid.Column>
                    </Grid>
                }
                {
                    this.props.fullWidthMode &&
                    <div
                        style={
                            {
                                paddingRight: this.props.padding ? this.props.padding : "15%",
                                paddingLeft: this.props.padding ? this.props.padding : "15%"
                            }}>
                        {this.props.children}
                    </div>
                }

                {/* Begin Footer*/}
                {this.props.showFooter &&
                    <F
                        {...this.props.footerProps}
                        actions={_.map(this.props.buttons, b => b ? b.onClick : undefined)}>
                        {_.map(this.props.buttons, (button, index) => {
                            if (!button)
                                return (
                                    <Button
                                        key={index}
                                        className={"NavButtons" + (this.props.buttonClassName ? " " + this.props.buttonClassName : "")}
                                        color='red'
                                        disabled={true}
                                    />
                                );
                            if (button.renderer)
                                return button.renderer(index, button);
                            else
                                return (
                                    <Button
                                        key={index}
                                        className={"NavButtons" + (this.props.buttonClassName ? " " + this.props.buttonClassName : "")}
                                        size='large'
                                        onClick={button.onClick}
                                        icon={button.buttonContent}
                                        color='red'
                                        disabled={button.disabled}
                                    />);
                        })
                        }
                    </F>
                }
            </div>
        );
    }

    private handleContextRef = (contextRef: any) => {
        this.setState({ contextRef });
        if (contextRef)
            erdUltraFast.listenTo({}, contextRef, (ele: HTMLElement) => {
                if (this.props.onResize)
                    this.props.onResize(ele.offsetWidth, ele.offsetHeight);
                if (this.props.setMobile) {
                    const mobile = checkMobile(ele.offsetWidth, ele.offsetHeight);
                    if (this.mobile === undefined || this.mobile !== mobile) {
                        this.mobile = mobile;
                        this.props.setMobile(mobile);
                    }
                }
            });
    }
}

export default translate()(withRouter(connect((state: IState) => ({ state }))(Mainframe)));
