import * as React from 'react';
import { Icon, SemanticICONS, SemanticCOLORS } from 'semantic-ui-react';

interface IToggleButtonProps {
    value?: boolean;
    onChange?: (val: boolean) => void;
    offIcon?: SemanticICONS;
    onIcon?: SemanticICONS;
    onColor?: SemanticCOLORS;
    offColor?: SemanticCOLORS;
    eitherMode?: boolean;
    style?: React.CSSProperties;
}

const ToggleButton: React.SFC<IToggleButtonProps> = (props: IToggleButtonProps) => {
    const method = () => {
        if (props.onChange)
            props.onChange(props.value ? props.value : false);
    };
    return (
        <div className='ToggleButton' onClick={method} >
            {props.value
                ?
                <div className='On'><Icon name={props.onIcon ? props.onIcon : 'power'} color={props.onColor ? props.onColor : 'yellow'} /></div>
                :
                <div className={props.eitherMode ? "OffEither" : "Off"}><Icon name={props.offIcon ? props.offIcon : 'circle notched'} color={props.offColor ? props.offColor : 'black'} /></div>

            }
        </div >
    );
};

export default ToggleButton;
