import { TubePostDownloadDto } from "collaboration-service";
import Media from 'components/General/Media/Media';
import { LinkDto } from "imaginarity-azure";
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Rating, Image, Popup } from 'semantic-ui-react';
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { config } from 'services/Config';
import { getMediaForContent, getTranslated, getTranslatedValue, nop, toLocalDateString, translateUsername, getGroup } from 'services/Helpers';
import _ from "lodash";
import { connect } from "react-redux";
import { IState, IConnectedComponent } from "services/Interfaces";

interface TubePostProps extends IConnectedComponent, IIMGTranslatedComponent {
    post: TubePostDownloadDto;
    width: number;
    selected?: boolean;
    changeTheme?: boolean;
    language?: string;
    noThumbnail?: boolean;
    allowStartOnPreview?: boolean;
}
interface TubePostState {
    commentsOpen?: boolean;
    showDescription: boolean;
    deleteModalOpen: boolean;

}

class TubePost extends React.Component<TubePostProps, TubePostState> {
    constructor(props: TubePostProps) {
        super(props);
        this.state = {
            showDescription: false,
            deleteModalOpen: false
        };
    }
    public render() {
        // console.log("rating: ", this.props.post.rating);
        const p = this.props.post as TubePostDownloadDto;
        // console.log("post => ", p);
        // console.log("config => ", config.tubeFeatures);
        const imgStyle: React.CSSProperties = { transform: this.props.changeTheme ? "scale(0.92)" : undefined, width: this.props.width, height: this.props.width / 16 * 9 + 100, "--tubePostWidth": this.props.width / 16 * 9 + "px" } as React.CSSProperties;

        const cMedia = getTranslatedValue(this.props.post.content, this.props.language);
        let media;
        if (cMedia) {
            if (!this.props.noThumbnail && cMedia.thumbnails && _.some(cMedia.thumbnails)) {
                media =
                {
                    media: cMedia.thumbnails[0],
                    refName: "self"
                };
            } else {
                media = getMediaForContent(cMedia);
            }
        }
        const group = getGroup(this, this.props.post.group_id);
        const ava = group ? _.find(group.avatar ? group.avatar.links : [], (l: LinkDto) => l.ref === "self") : undefined;

        return (
            <div className={this.props.changeTheme ? "TubePostWhite" : "TubePost" + (this.props.selected ? " Hover" : "")} style={imgStyle} >
                {this.props.post.type === "TubePost" &&
                    <div className="TPImgContainer">
                        <div className="TPImg" >
                            {!this.props.allowStartOnPreview && <div className="TPOverlay" />}
                            {media && <div className="TPMediaContainer"><Media media={media} contentClassName="TPImgMedia" controls={false} lazyLoading="off" autoPlay={false} /></div>}
                            {this.props.post.descriptions && this.props.post.descriptions.length > 0 &&
                                <div className="VideoShowMediaDescriptions">
                                    {group &&
                                        <><span className="colorGroup">{group.name}</span> <Icon name="chevron right" color="red" size="small" /></>
                                    }
                                    {_.truncate(getTranslated(this.props.post.descriptions, this.props.language), { 'length': 130, 'separator': ' ' })}
                                </div>
                            }
                        </div>
                        {ava && group &&
                            <div className="TPVGroup">
                                <Popup className="ComUserPopup" position="left center" trigger={
                                    <Image src={ava.uri} className="TPVAvatar" />
                                }>
                                    {group.name}
                                </Popup>
                            </div>
                        }
                    </div>
                }
                {this.props.post.type !== "TubePost" &&
                    <div className="TPImg">
                        <Link to={config.routes.slideshow.slide + "/" + this.props.post.id}>
                            <Media media={{ media: this.props.post.media, refName: "self" }} onClick={nop} onFullScreenClick={nop} lazyLoading="off" />
                        </Link>
                    </div>
                }

                <div className="TPContent">
                    <div className="TPVideoIcon"><Icon name="toggle right" /></div>
                    <div className="TPHeader">
                        {getTranslated(this.props.post.headlines, this.props.language)}
                    </div>
                    <div className="TPDescBox" />
                    {this.props.post.isBookmarked &&
                        <div className="TPDescBookmark">
                            <Icon name="bookmark" color="red" />
                        </div>
                    }


                    <div className="TPRatings">
                        <Rating rating={Math.round(this.props.post.rating)} maxRating={5} disabled className="VideoRatingDark" />
                    </div>

                    {p.viewed && config.tubeFeatures.viewed &&
                        <Popup className='ComUserPopup' content={this.props.t("you've already watched the video")} position="left center" trigger={
                            <div className="TPwatched">
                                <Icon name="eye" />
                            </div>
                        } />
                    }

                    <div className="TPComments" onClick={this.openComments}>
                        <Icon name="user" /> {translateUsername(this.props.post.creator)}
                        <div style={{ height: 2 }} />
                        <Icon name="eye" /> {this.props.t("{{views}} views", { views: (p.views !== undefined ? p.views : 0) })}
                        <Icon style={{ marginLeft: 5 }} name={config.icons.comment as SemanticICONS} />{this.props.post.commentCount}
                        <Icon style={{ marginLeft: 5 }} name="calendar outline" />
                        {toLocalDateString(this.props.t, this.props.post.created)}

                    </div>
                </div>
            </div>
        );
    }

    private openComments = () => this.setState({ commentsOpen: true });
}

export default translate("tube")(connect((state: IState) => ({ state }))((TubePost)));
