import { MediaUploadDto, UserShortInfoDownloadDto } from "collaboration-service";
import FileSelector from "components/General/FileSelector/FileSelector";
import UserSearchSelection from "components/General/UserSearchSelection/UserSearchSelection";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { Button, fadeColor, lightenColor, getColor, Icon, Progress, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { byteSize, uploadMediaToStorageForAsset } from "services/Helpers";

interface AssetUploadSelectionProps extends IIMGTranslatedComponent {
    onFilesUploaded: (medias: MediaUploadDto[], readUser?: UserShortInfoDownloadDto[], writeUsers?: UserShortInfoDownloadDto[]) => void;
    onClose: () => void;
}

interface AssetUploadSelectionState {
    files?: File[];
    progress?: number[];
    writeUsers?: UserShortInfoDownloadDto[];
    readUsers?: UserShortInfoDownloadDto[];
}

export const AssetUploadContainer = styled.div`
    width: 100%;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
    padding: 10px;
    min-height: 500px;
    height: calc(100vh - 90px);
    margin-top:-1px;
    border: 1px solid ${p => getColor(p, p.theme.colors.lightGrey)};
`;
const FileList = styled.div`
    padding: 0px;

`;
const FileEntry = styled.div`
    width: 100%;
`;
export const FormRow = styled.div`
    width: auto;
    display: block;
    min-height: 40px;
    margin-bottom: 10px;
`;
export const FormRowFooter = styled.div<{ items: number }>`
    width: auto;
    padding-left: 0;
    display: block;
    min-height: 40px;
    height: calc(${p => p.items} * 40px);
    margin-bottom: 0;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    position: relative;
`;

export const Description = styled.div`
    width: 200px;
    line-height: 40px;
    float: left;
    
`;
export const DescriptionFooter = styled.div`
    width: 200px;
    position: absolute;
    bottom: 0px;
    left:0px;
`;

export const Action = styled.div`
    width: 320px;
    display: inline-block;    
`;
const ActionSelect = styled.div<{ error: boolean }>`
    width: 320px;
    display: inline-block;
    .ui.basic.button, .ui.basic.buttons .button{
        height: 40px;
        background-color: ${p => p.error ? fadeColor(p, getColor(p, p.theme.colors.accentRed), 10) : "transparent"} !important;
        color:${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)} !important;
        box-shadow: none;
        border: 1px solid ${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)};
        color: ${p => p.error ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.darkGrey)};
        &:hover{
            color:${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)} !important;
            box-shadow: none;
            border: 1px solid ${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)};
            color: ${p => p.error ? lightenColor(p, getColor(p, p.theme.colors.accentRed), 70) : getColor(p, p.theme.colors.accentBlack)};
        }
    }
`;

export const ActionFooter = styled.div<{ items: number }>`
    width: 320px;
    position: absolute;
    top: 0px;
    left:200px;
    height: calc(${p => p.items} * 40px);
    transition: 0.2s all ease;
`;
class AssetUploadSelection extends React.Component<AssetUploadSelectionProps, AssetUploadSelectionState>{
    public state: AssetUploadSelectionState = {};
    render() {
        const files = this.state.files ? this.state.files.length === 1 ? "file" : "files" : 0;
        const items = this.state.files ? this.state.files.length : 1;
        const toHide = _.map(_.union(this.state.readUsers, this.state.writeUsers), u => u.id);
        return (
            <AssetUploadContainer>
                <FormRow>
                    <Description>
                        {!this.state.files &&
                            <Icon name="exclamation circle" style={{ float: "left", marginRight: 5 }} />
                        }
                        {this.props.t("select file from your harddrive")}
                    </Description>
                    <ActionSelect error={this.state.files ? false : true}>
                        <FileSelector
                            fluid
                            onFilesSelected={this.filesSelected}
                            text={this.props.t("select files to upload")}
                        />
                    </ActionSelect>
                </FormRow>
                {this.state.files &&
                    <>
                        <FormRow>
                            <Description>
                                {this.props.t("users to add (read only access)")}
                            </Description>
                            <Action>
                                <UserSearchSelection
                                    onUserChanged={this.setReadUsers}
                                    value={this.state.readUsers}
                                    clearable
                                    multi
                                    idsToHide={toHide}
                                />
                            </Action>
                        </FormRow>
                        <FormRow>
                            <Description>
                                {this.props.t("users to add (read/write access)")}
                            </Description>
                            <Action>
                                <UserSearchSelection
                                    onUserChanged={this.setWriteUsers}
                                    value={this.state.writeUsers}
                                    clearable
                                    multi
                                    idsToHide={toHide}
                                />
                            </Action>
                        </FormRow>

                        <FormRow>
                            <Description>
                                {this.props.t("klick to upload your selected {files}")}
                            </Description>
                            <Action>
                                <Button fluid kind="secondary" icon="upload" onClick={this.upload} content={this.props.t("upload ") + files} />
                            </Action>
                        </FormRow>
                    </>
                }

                <FormRowFooter items={items}>
                    <DescriptionFooter>
                        <Button kind="secondary" icon="chevron left double" onClick={this.onClose} />
                    </DescriptionFooter>
                    <ActionFooter items={items}>
                        {this.state.files &&
                            <FileList>
                                {_.map(this.state.files, (f, i) => {
                                    const p = this.state.progress ? this.state.progress[i] : undefined;
                                    return (
                                        <FileEntry key={i}>
                                            <Progress
                                                inline
                                                showValueAs={p ? "percent" : "hidden"}
                                                valueMax={100} value={p ?? 100}
                                                labelInside={`${f.name} (${byteSize(f.size)})`}
                                                icon="upload"
                                                labelInsideAlign="left"
                                                scheme="light"
                                            />
                                        </FileEntry>
                                    );
                                }
                                )}
                            </FileList>
                        }
                    </ActionFooter>
                </FormRowFooter>
            </AssetUploadContainer>
        );
    }

    private setReadUsers = (readUsers?: UserShortInfoDownloadDto[]) => this.setState({ readUsers });
    private setWriteUsers = (writeUsers?: UserShortInfoDownloadDto[]) => this.setState({ writeUsers });
    private onClose = () => {
        this.setState({ files: undefined, progress: undefined }, () =>
            this.props.onClose());
    }
    private upload = async () => {
        if (this.state.files && this.state.files.length > 0) {
            this.setState({ progress: _.map(this.state.files, f => 0) });
            const p = _.map(this.state.files, (f, i) => uploadMediaToStorageForAsset(f, (p) => {
                const progress = _.clone(this.state.progress) ?? [];
                progress[i] = 100 * p;
                this.setState({ progress });
            }));
            const pres = await Promise.all(p);
            const toRet: MediaUploadDto[] = [];
            _.forEach(pres, px => {
                if (px)
                    toRet.push(px);
            });
            this.setState({ files: undefined, progress: undefined }, () =>
                this.props.onFilesUploaded(toRet, this.state.readUsers, this.state.writeUsers));
        }
    }
    private filesSelected = async (files: File[] | undefined) => {
        this.setState({ files });
    }
}

export default translate("assets")(AssetUploadSelection);