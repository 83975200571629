import { InAppMessageDownloadDto } from 'collaboration-service';
import { sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import UserAvatar from 'components/General/UserAvatar/UserAvatar';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import React, { Component } from 'react';
import { Button, Icon, List, Table } from 'semantic-ui-react';
import { formatDate, getTranslated, splitText, translateUsername } from 'services/Helpers';

interface InAppMessageMobileProps extends IIMGTranslatedComponent, SizeConditionalComponent {
    message: InAppMessageDownloadDto;
    toggleDone: (msg: InAppMessageDownloadDto) => void;
    toggleRead: (msg: InAppMessageDownloadDto) => void;
    deleteMessage: (msg: InAppMessageDownloadDto) => void;
}
interface InAppMessageMobileState {
    showMoreInfo: boolean;
    showHistory: boolean;
    showRecipients: boolean;
    showModalDelete: boolean;
}

class InAppMessageMobile extends Component<InAppMessageMobileProps, InAppMessageMobileState> {
    state = {
        showMoreInfo: false,
        showHistory: false,
        showRecipients: false,
        showModalDelete: false
    }

    render() {
        const { message } = this.props;
        const bodyTxt = getTranslated(message.body);
        const notIPhone5 = this.props.width >= 374;
        const footerBgColor = "linear-gradient(90deg, rgba(149, 149, 149, 0.6) 0%, rgba(149, 149, 149, 0.3) 20%, rgba(149, 149, 149, 0.25) 50%, rgba(149, 149, 149, 0.3) 80%, rgba(149, 149, 149, 0.6) 100%)";
        const activeBgColor = "linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgba(240, 240, 240, 0.95) 20%, rgb(255, 255, 255) 50%, rgba(240, 240, 240, 0.95) 80%, rgba(255, 255, 255, 0.9) 100%)";
        const inactiveBgColor = "linear-gradient(90deg, rgba(240, 240, 240, 0.85) 0%, rgba(240, 240, 240, 0.9) 20%, rgba(255, 255, 255, 0.9) 50%, rgba(240, 240, 240, 0.9) 80%, rgba(240, 240, 240, 0.85) 100%)";

        return (
            <>
                <Table
                    unstackable
                    compact
                    style={{
                        boxShadow: this.state.showMoreInfo ? "0 8px 6px -6px black" : "none",
                        marginTop: this.state.showMoreInfo ? 10 : 3,
                        marginBottom: this.state.showMoreInfo ? 10 : 0,
                    }}
                >
                    <Table.Body>
                        <Table.Row
                            verticalAlign="top"
                            style={{
                                fontWeight: message.read ? 100 : 700,
                                background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor
                            }}
                        >
                            <Table.Cell >
                                <div style={{ float: "right", textAlign: "right" }}>
                                    <div><Icon
                                        color={message.read ? undefined : "grey"}
                                        name={message.read ? "envelope open outline" : "envelope"}
                                        style={{ marginRight: 5 }}
                                        onClick={this.props.toggleRead(message)}
                                    />
                                    </div>
                                    {message.actions && message.actions.length === 0 &&
                                        <div>
                                            <Icon
                                                color={message.done ? undefined : "grey"}
                                                name={message.done ? "check square outline" : "square outline"}
                                                style={{ marginRight: 5 }}
                                                onClick={this.props.toggleDone(message)}
                                            />
                                        </div>
                                    }
                                </div>
                                {message.isSystemMessage ?
                                    <>
                                        <div className="InboxMobileTableAvatar">
                                            <Icon name='setting' size="small" circular />
                                        </div>
                                        {this.props.t("System")}
                                    </>
                                    :
                                    <>
                                        <div className="InboxMobileTableAvatar">
                                            <UserAvatar user={message.fromUser} />
                                        </div>
                                        {translateUsername(message.fromUser)}
                                    </>
                                }
                                <div style={{ fontSize: "0.8em" }}>
                                    {formatDate(message.created, "cccccc P")} - {formatDate(message.created, "p")}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row
                            verticalAlign="top"
                            style={{
                                fontWeight: message.read ? 100 : 700,
                                background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor
                            }}
                            onClick={this.toggleShowMoreInfo}
                        >
                            <Table.Cell >
                                <div style={{ float: "right", textAlign: "right" }}>
                                    <div>
                                        <Icon name={this.state.showMoreInfo ? "chevron up" : "chevron down"} />
                                    </div>
                                </div>
                                <div style={{
                                    fontSize: "1em",
                                    textTransform: "uppercase"
                                }}>
                                    <Icon
                                        name={this.state.showMoreInfo ? "envelope open outline" : "envelope outline"}
                                        color={this.state.showMoreInfo ? "red" : undefined}
                                        style={{ marginRight: 12 }}
                                    />
                                    {getTranslated(message.subject)}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        {this.state.showRecipients &&
                            <Table.Row
                                verticalAlign="top"
                                style={{ background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor }}
                            >
                                <Table.Cell >
                                    <List style={{ marginTop: 0 }}>
                                        {message.toUsers.length >= 1 && _.map(message.toUsers, u => {
                                            return (
                                                <List.Item key={u.id}>
                                                    <List.Icon name='user' style={{ paddingRight: 9 }} />
                                                    <List.Content>{translateUsername(u)}</List.Content>
                                                </List.Item>
                                            )
                                        })}
                                        {message.toGroups.length >= 1 && _.map(message.toGroups, g => {
                                            return (
                                                <List.Item key={g.id}>
                                                    <List.Icon name='users' style={{ paddingRight: 5 }} />
                                                    <List.Content>{g.name}</List.Content>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {this.state.showHistory &&
                            <Table.Row
                                verticalAlign="top"
                                style={{ background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor }}
                            >
                                <Table.Cell >
                                    <Icon
                                        name="history"
                                        style={{ float: "left", marginTop: 0 }}
                                    />
                                    <div className="InboxMobileHistoryBox">
                                        <Table basic='very' size="small" compact collapsing unstackable>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>03.02.2020</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>Hans Dampf</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>user accepted</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>01.02.2020</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>Peter Lustig</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>user not accepted</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>17.01.2020</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>Heidi Kabel</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>user accepted</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>15.01.2020</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>Bud Spencer</Table.Cell>
                                                    <Table.Cell collapsing style={{ padding: "0.2em 0" }}>user accepted</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {this.state.showMoreInfo &&
                            <Table.Row
                                verticalAlign="top"
                                style={{ background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor }}
                                onClick={this.toggleShowMoreInfo}
                            >
                                <Table.Cell >
                                    <Icon
                                        name="file text"
                                        style={{ float: "left", marginTop: 10 }}
                                    />
                                    <div className="InboxMobileMessageBox">
                                        {splitText(bodyTxt)}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                    {this.state.showMoreInfo &&
                        <Table.Footer>
                            <Table.Row style={{ borderRadius: 0 }}>
                                <Table.HeaderCell style={{ background: footerBgColor, borderRadius: 0 }}>
                                    <Button
                                        size={notIPhone5 ? undefined : "mini"}
                                        color={this.state.showRecipients ? undefined : "red"}
                                        inverted={this.state.showRecipients}
                                        active={this.state.showRecipients}
                                        icon
                                        onClick={this.toggleShowRecipients}
                                        floated="left"
                                    >
                                        {this.state.showRecipients ?
                                            <Icon.Group >
                                                <Icon name='users' />
                                                <Icon corner name='times' color="red" />
                                            </Icon.Group>
                                            :
                                            <Icon name="users" />
                                        }
                                    </Button>
                                    <Button
                                        size={notIPhone5 ? undefined : "mini"}
                                        color={this.state.showHistory ? undefined : "red"}
                                        inverted={this.state.showHistory}
                                        active={this.state.showHistory}
                                        icon
                                        onClick={this.toggleShowHistory}
                                        floated="left"
                                    >
                                        {this.state.showHistory ?
                                            <Icon.Group >
                                                <Icon name='history' />
                                                <Icon corner name='times' color="red" />
                                            </Icon.Group>
                                            :
                                            <Icon name="history" />
                                        }
                                    </Button>
                                    <Button size={notIPhone5 ? undefined : "mini"} color="red" icon="chevron up" onClick={this.toggleShowMoreInfo} floated="right" />
                                    <Button size={notIPhone5 ? undefined : "mini"} color="red" icon="trash" floated="right" onClick={this.toggleShowModalDelete} />
                                    {_.map(message.actions, (a, i) => {
                                        return (
                                            <Button size={notIPhone5 ? undefined : "mini"} key={i} color="red" content={a.caption} floated="right" />
                                        )
                                    })
                                    }
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
            </>
        )
    }
    private toggleShowMoreInfo = () => {
        this.setState({ showHistory: false });
        this.setState({ showRecipients: false });
        this.setState({ showMoreInfo: !this.state.showMoreInfo });
    }
    private toggleShowHistory = () => {
        this.setState({ showHistory: !this.state.showHistory });
    }
    private toggleShowRecipients = () => {
        this.setState({ showRecipients: !this.state.showRecipients });
    }
    private toggleShowModalDelete = () => {
        this.props.deleteMessage(this.props.message);
    }
}
export default sizeConditional()(translate("inbox")(InAppMessageMobile));
