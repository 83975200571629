import { ContentMediaUploadDto } from "collaboration-service";
import { fadeColor, getColor, Icon, ImgIcons, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import UploadFileEntry, { FileEntry } from "./UploadFileEntry";
export interface UploadFile {
    file: File;
    progress: number;
    media?: ContentMediaUploadDto;
    abort?: AbortSignal;
}
interface DragListProps {
    title: string;
    icon: ImgIcons;
    list: UploadFile[];
    droppableId: string;
}

const SubList = styled.div`
`;

const Clone = styled(FileEntry)`
    ~ div {
        transform: none !important;
    }
`;

export const Title = styled.div`
    width: 100%;
    font-weight: bolder;
    border-bottom: 1px solid ${p => getColor(p, p.theme.colors.darkGrey)};
    margin-top: 10px;
    background-color: ${p => fadeColor(p, getColor(p, p.theme.colors.darkGrey), 10)};
    padding-left:10px;
    line-height:40px;
`;

const DragContainer = styled.div`
    width: 60px;
    height: 60px;
    background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentGreen), 30)};
    border: 2px solid ${p => getColor(p, p.theme.colors.accentGreen)};
    box-shadow: 0 8px 8px -6px black;  
    padding: 10px;
    svg{
        stroke: ${p => getColor(p, p.theme.colors.accentGreen)};
    }
`;

const DragContainerInner = styled.div`
    width: 40px;
    height: 40px;
    text-align: center;
`;

const DragList = (p: DragListProps) => {
    return (
        <>
            {p.list.length > 0 &&
                <Droppable droppableId={p.droppableId} isDropDisabled={true}>
                    {(prov, snap) => (
                        <SubList
                            ref={prov.innerRef}
                        >
                            <Title>
                                {p.title}
                            </Title>
                            {_.map(p.list, (f, i) => {
                                return (
                                    <Draggable key={`${f.file.name}--${f.file.size}--${f.file.lastModified}`} draggableId={f.file.name} index={i} >
                                        {(iprov, isnap) => (
                                            <React.Fragment>
                                                <div
                                                    ref={iprov.innerRef}
                                                    {...iprov.dragHandleProps}
                                                    {...iprov.draggableProps}
                                                    style={iprov.draggableProps.style}
                                                >
                                                    {isnap.isDragging ?
                                                        <DragContainer>
                                                            <DragContainerInner>
                                                                <Icon name={p.icon} size="30px" />
                                                            </DragContainerInner>
                                                        </DragContainer>
                                                        :
                                                        <UploadFileEntry
                                                            icon={p.icon}
                                                            file={f.file}
                                                            key={f.file.name}
                                                            index={i}
                                                        //draggableProps={iprov.draggableProps}
                                                        //dragHandleProps={iprov.dragHandleProps}
                                                        />
                                                    }
                                                </div>
                                                {isnap.isDragging &&
                                                    <UploadFileEntry
                                                        index={i}
                                                        key={`clone-${f.file.name}`}
                                                        file={f.file}
                                                        icon={p.icon}
                                                        container={Clone}
                                                    />
                                                }
                                            </React.Fragment>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </SubList>
                    )}
                </Droppable>
            }
        </>
    )
}

export default DragList;