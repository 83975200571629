import ButtonUpload from 'components/Posts/Buttons/ButtonUpload';
import * as React from 'react';
import { SemanticSIZES, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import * as _ from "lodash";

interface IFileSelectorProps {
    onFileSelected?: (file: File | undefined) => void;
    onFilesSelected?: (file: File[] | undefined) => void;
    filter?: string;
    text?: string;
    tooltip?: string;
    size?: SemanticSIZES;
    fluid?: boolean;
    disabled?: boolean;
    color?: SemanticCOLORS;
    notBasic?: boolean;
    icon?: SemanticICONS;


}

export default class FileSelector extends React.Component<IFileSelectorProps, {}> {
    private selector: HTMLInputElement;
    constructor(props: IFileSelectorProps) {
        super(props);

        this.state = {
        };

        this.selector = document.createElement("input");
        if (this.props.onFilesSelected)
            this.selector.setAttribute("multiple", "true")
        this.selector.type = "file";
        if (this.props.filter)
            this.selector.accept = this.props.filter;
        this.selector.onchange = this.fileSelected;
    }

    public render() {
        const { tooltip, ...rest } = this.props;

        return (
            <ButtonUpload
                size={this.props.size ? this.props.size : undefined}
                // tooltip={tooltip ? tooltip : "upload media"}
                onClick={this.openFileDlg}
                tooltip={tooltip ? tooltip : undefined}
                color={this.props.color ? this.props.color : undefined}
                notBasic={this.props.notBasic ? true : false}
                icon={this.props.icon ? this.props.icon : 'upload'}
                {...rest}
            />
        );
    }

    private openFileDlg = () => {
        this.selector.click();
    }

    private fileSelected = (e: any) => {
        if (this.props.onFileSelected) {
            //        console.log(e.target.files);
            const val = e.target && e.target.files && e.target.files.length > 0 ? e.target.files[0] : undefined;
            this.props.onFileSelected(val);
            this.selector.value = "";
        }
        if (this.props.onFilesSelected) {
            const val = e.target && e.target.files && e.target.files.length > 0 ? _.map(e.target.files, f => f) : undefined;
            this.props.onFilesSelected(val);
            this.selector.value = "";
        }
    }
}
