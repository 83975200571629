import * as React from "react";
import { Button, Icon, Label, Transition, Popup, Loader, Dimmer } from "semantic-ui-react";
import { isMobileDevice } from "services/Helpers";
import { DiagramData, RenderDiagram } from "imaginarity-statistics";
import _ from "lodash";
import { ControllerHelper, DashboardElementTemplateDownloadDto } from "collaboration-service";
import memoizee from "memoizee";
import controllerFactory from "collaboration-service/dist/controllers/ControllersFactory";
import { translate, IIMGTranslatedComponent } from "components/Utils/img-i18n";

export interface DiagramCallProperties {
    controller: string;
    method: string;
    filter: string;
    mappings?: Mapping[];
}

interface DiagramContainerProps extends IIMGTranslatedComponent {
    template: DashboardElementTemplateDownloadDto;
    id: string;
    title: string;
    onResize?: (id: string, size: number) => void;
    onRemove?: (id: string) => void;
    onSettings?: (diagram: DiagramData) => void;
    onSaveElement?: (element: any) => void;
    onExportElement?: (id: string, filter: any) => void;
    diagram: DiagramData;
    diagramCall: DiagramCallProperties;
    language: string;
}

interface DiagramContainerState {
    showButtons: boolean;
    loading: boolean;
    diagramData: DiagramData;
}

interface BackendCallResolve {
    (data: any[]): void;
}
interface BackendCallReject {
    (error: any): void;
}

interface BackendCall {
    filter: any;
    mappings?: Mapping[];
    waiters?: Array<{ res: BackendCallResolve, rej: BackendCallReject }>;
    data?: any[];
}

interface Mapping {
    element: string;
    properties: PropertyMapping[];
}

interface PropertyMapping {
    type?: string;
    propertyName?: string;
    propertyPredicate?: string;
    targetName: string;
}

export class BackendCallCache {

    private static instance: BackendCallCache;

    public static getInstance = () => {
        if (!BackendCallCache.instance)
            BackendCallCache.instance = new BackendCallCache();
        return BackendCallCache.instance;
    }

    private executeBackend = (controller: string, method: string, filter: any, mappings?: Mapping[]): Promise<any[]> => {
        const newEle: BackendCall = {
            filter,
            mappings: mappings,
            waiters: []
        };
        return new Promise<any[]>((resOut, rejOut) => {
            const res = (data: any[]) => {
                resOut(data);
                _.forEach(newEle.waiters, w => w.res(data));
                newEle.waiters = [];
            };
            const rej = (reason: any) => {
                rejOut(reason);
                _.forEach(newEle.waiters, w => w.rej(reason));
                newEle.waiters = [];
            }
            const ctr = controllerFactory(controller);
            if (!ctr)
                rej(`could not find controller ${controller}`);
            const getData = ControllerHelper.promise<any, any>({ filter }, ctr[method]);
            if (mappings) {
                const fillTemplate = (templateString: string, templateVars: any) => {
                    // eslint-disable-next-line
                    return new Function("return `" + templateString + "`;").call(templateVars);
                };
                getData.then((data: any) => {
                    const mappingArray = mappings as Mapping[];
                    const newData: any[] = [];
                    _.forEach(data, d => {
                        const newDataElement: any = Object.assign({}, d);
                        _.forEach(mappingArray, m => {
                            if (d.hasOwnProperty(m.element)) {
                                const element = d[m.element];
                                _.forEach(m.properties, p => {
                                    if (newDataElement.hasOwnProperty(p.targetName))
                                        delete newDataElement[p.targetName];
                                    if (p.type === "stringFormat") {
                                        if (p.propertyPredicate)
                                            newDataElement[p.targetName] = fillTemplate(p.propertyPredicate, element);
                                    } else {
                                        if (p.propertyName)
                                            newDataElement[p.targetName] = element[p.propertyName];
                                    }
                                });
                            }
                        });
                        newData.push(newDataElement);
                    });
                    newEle.data = newData;
                    res(newData);
                }, rej);
            }
            else
                getData.then((data: any) => {
                    newEle.data = data;
                    res(data);
                }, rej);
        });
    }

    public execute = memoizee(this.executeBackend, {
        length: 4, maxAge: 60000, normalizer: (args: any[]) => {
            return args[0] + args[1] + JSON.stringify(args[2]);
        }
    });
}

class DiagramContainer extends React.Component<DiagramContainerProps, DiagramContainerState>
{
    private element: any;
    constructor(props: DiagramContainerProps) {
        super(props);
        this.state = {
            showButtons: isMobileDevice(),
            loading: true,
            diagramData: { ...props.diagram, url: [], title: props.title }

        };
    }

    // shouldComponentUpdate(nextProps: DiagramContainerProps, nextState: DiagramContainerState) {
    //     const propsChanged = _.isEqual(this.props, nextProps);
    //     const stateChanged = _.isEqual(this.state, nextState);
    //     console.log(propsChanged, stateChanged);
    //     // console.log(DiagramContainer.difference(nextState, this.state));
    //     return !propsChanged || !stateChanged;
    // }

    componentWillUnmount() {
        console.log("unmount");
    }

    public componentDidMount() {
        const diagramCall = this.props.diagramCall;
        BackendCallCache.getInstance()
            .execute(diagramCall.controller, diagramCall.method, diagramCall.filter, diagramCall.mappings)
            .then(data => {
                //                console.log("loaded", data);
                const diagramData: DiagramData = {
                    ...this.props.diagram,
                    url: data,
                    title: this.props.title
                }
                this.setState({ loading: false, diagramData })
            });
    }

    componentDidUpdate(prevProps: DiagramContainerProps, prevState: DiagramContainerState) {
        if (!_.isEqual(this.props.diagramCall, prevProps.diagramCall)) {
            this.setState({ loading: true });
            const diagramCall = this.props.diagramCall;
            BackendCallCache.getInstance()
                .execute(diagramCall.controller, diagramCall.method, diagramCall.filter, diagramCall.mappings)
                .then(data => {
                    //                    console.log("loaded", data);
                    const diagramData: DiagramData = {
                        ...this.props.diagram,
                        url: data,
                        title: this.props.title
                    }
                    this.setState({ loading: false, diagramData })
                });
        }
    }

    public render() {
        const { props } = this;
        const rem = () => {
            if (props.onRemove)
                props.onRemove(props.id);
        };
        //        console.log("render dia", this.state.diagramData);
        return (
            <div className="tile" onMouseOver={this.mouseOver} onMouseLeave={this.mouseLeave} style={{ padding: 15 }}>
                <Label size='large' className='dragButton tileRibbon chartOverview'>
                    <Icon name='expand arrows alternate' className="tileIcon" />
                    {props.title}
                </Label>
                <div className="tileContent" ref={this.saveRef}>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader indeterminate >
                            {this.props.t("Loading...")}
                        </Loader>
                    </Dimmer>
                    {
                        RenderDiagram({ dia: this.state.diagramData, lng: this.props.language, userData: this.props.template })
                    }
                </div>
                <Transition visible={this.state.showButtons} animation='vertical flip' duration={500}>
                    <Button.Group size='tiny' vertical style={{ position: "absolute", top: 0, right: 0, zIndex: 5001 }}>
                        <Popup className='ComUserPopup' content="delete KPI" position="left center"
                            trigger={
                                <Button color='grey' className=' onMouseOverChart' icon onClick={rem} style={{ borderBottom: "1px solid rgb(231, 232, 232)" }}>
                                    <Icon name='cancel' />
                                </Button>
                            }
                        />
                        {this.element && this.props.onSaveElement &&
                            <Popup className='ComUserPopup' content="save diagram" position="left center"
                                trigger={
                                    <Button color='grey' className=' onMouseOverChart' icon onClick={this.saveElement}>
                                        <Icon name='save' />
                                    </Button>
                                }
                            />
                        }
                        {this.element && this.props.onExportElement &&
                            <Popup className='ComUserPopup' content="save diagram" position="left center"
                                trigger={
                                    <Button color='grey' className=' onMouseOverChart' icon onClick={this.exportElement}>
                                        <Icon name='download' />
                                    </Button>
                                }
                            />
                        }
                    </Button.Group>
                </Transition>
            </div>
        );
    }

    private saveElement = () => {
        if (this.element && this.props.onSaveElement)
            this.props.onSaveElement(this.element);

    }
    private exportElement = () => {
        if (this.element && this.props.onExportElement)
            this.props.onExportElement(this.props.id, this.props.diagramCall.filter);

    }

    private saveRef = (element: any) => {
        this.element = element;
    }

    private mouseOver = () => {
        this.setState({ showButtons: true });
    }
    private mouseLeave = () => {
        this.setState({ showButtons: isMobileDevice() });
    }
}

export default translate("statistics")(DiagramContainer);