import * as React from 'react';
import { AppEntry } from 'imaginarity-platform-plugin-base';
import Mainframe from '../Mainframe/Mainframe';

export interface PluginContainerProps {
    data: AppEntry;
}

export default class PluginContainer extends React.Component<PluginContainerProps> {
    public render() {
        const fw = this.props.data.left?.hidden && this.props.data.right?.hidden;
        return (
            <Mainframe
                fullWidthMode={fw}
                padding={fw ? "0%" : undefined}
                title={this.props.data.name}
            >
                {this.props.data.contentRenderer()}
            </Mainframe>
        );
    }
}
