import * as React from 'react';
import { Rating } from 'semantic-ui-react';
import { UserCommentDownloadDto } from 'collaboration-service';
import UserAvatar from 'components/General/UserAvatar/UserAvatar';
import { translateUsername, toLocalDateString, splitText } from 'services/Helpers';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';

export interface TubeRatingProps extends IIMGTranslatedComponent {
    rating: UserCommentDownloadDto;
}

class TubeRating extends React.Component<TubeRatingProps> {
    public render() {
        return (
            <div style={{ color: "#fff", fontSize: "0.8em", borderBottom: "1px solid #950014", padding: "10px 0" }}>
                <Rating rating={this.props.rating.rating} maxRating={5} style={{ float: "right" }} disabled={true} className="VideoRatingDarkSingle" />
                <div style={{ width: 36, float: "left", marginRight: 10 }}>
                    <UserAvatar user={this.props.rating.user} />
                </div>
                {translateUsername(this.props.rating.user)} ({toLocalDateString(this.props.t, this.props.rating.created)})
                <p>{splitText(this.props.rating.comment)}</p>
            </div>
        );
    }
}

export default translate("tube")(TubeRating);