import { TubePostDownloadDto, UserCommentDownloadDto } from 'collaboration-service';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Icon, Menu, Rating, RatingProps } from 'semantic-ui-react';
import { config } from 'services/Config';
import { IConnectedComponent, IState } from 'services/Interfaces';
import TubeRating from './TubeRating';

interface TubeRatingsProps extends IIMGTranslatedComponent, IConnectedComponent {
    post: TubePostDownloadDto;
    ratings?: UserCommentDownloadDto[];
    onRequestClose: () => void;
    onAddRating: (comment: string, rating: number) => void;
}

interface TubeRatingsState {
    ratingText: string;
    rating?: number;
}

class TubeRatings extends React.Component<TubeRatingsProps, TubeRatingsState> {
    constructor(p: TubeRatingsProps) {
        super(p);
        this.state = {
            ratingText: "",
        };
    }
    public render() {
        const id = this.props.state.userInfo ? this.props.state.userInfo.id : undefined;
        const mayAdd = _.findIndex(this.props.ratings, r => r.user && r.user.id === id) < 0;
        return (
            <>
                <div className="VideoBoxOverlayContainer" onClick={this.props.onRequestClose} />
                <div className="VideoRatingsBox">
                    <div style={{ position: "absolute", textAlign: "center", top: 0, background: "black", width: "100%", color: "#fff", padding: 5 }}>
                        {mayAdd ? <h2>{this.props.t("rate this video")}</h2> : <h2>{this.props.t("ratings for this video")}</h2>}
                    </div>
                    <div style={{ position: "absolute", textAlign: "center", top: 50, width: "100%", color: "#fff", padding: 5 }}>
                        {mayAdd && <h4 style={{ margin: 0, padding: 0, marginBottom: 10, fontWeight: 500 }}>{this.props.t("and leave a comment")}</h4>}
                        {/* <Icon name="hand point right" size="big" style={{ marginTop: -12, color: "rgba(255,255,255,0.3", transform: "rotate(10deg)" }} /> */}
                        <Rating size="huge" rating={mayAdd ? this.state.rating : this.props.post.rating} maxRating={5} disabled={false} onRate={this.ratingValueChange} className="VideoRatingDarkSingleRate" />
                        {/* <Icon name="hand point left" size="big" style={{ marginTop: -12, marginLeft: 3, color: "rgba(255,255,255,0.3", transform: "rotate(-10deg)" }} /> */}
                    </div>
                    <div style={{ position: "absolute", top: 110, left: "5%", width: "90%", color: "#fff", padding: 5, textAlign: "center" }}>
                        {mayAdd &&
                            <textarea
                                placeholder={this.props.t("comment your rating ...")}
                                maxLength={config.maxCommentLength}
                                className="VideoCommentTextAreaRatingBox"
                                onChange={this.onRatingTextChange}
                                value={this.state.ratingText}
                            />
                        }
                        <h4 style={{ margin: 0, padding: 0, marginTop: 10, marginBottom: 10, fontWeight: 500 }}>{this.props.t("comments archive")}</h4>

                        <div className="VideoRatingCommentBox">
                            {_.map(this.props.ratings ? this.props.ratings : [], (r, i) => <TubeRating rating={r} key={i} />)}
                        </div>
                    </div>
                    <Menu inverted size="large" className="VideoMenuBottomBar" fluid widths="2"
                        style={{ position: "absolute", bottom: 0, boxShadow: "0 -6px 8px -6px #000" }} color="black">
                        <Menu.Item name='undo' onClick={this.props.onRequestClose}>
                            <Icon name='arrow left' style={{ color: "#DB2828" }} />
                        </Menu.Item>
                        {mayAdd &&
                            <Menu.Item disabled={this.state.rating === undefined || !this.state.ratingText} name='save' onClick={this.onAddRating}>
                                <Icon name={this.state.rating === undefined || !this.state.ratingText ? "times" : 'save'} style={{ color: "#DB2828" }} />
                            </Menu.Item>
                        }
                    </Menu>
                </div>
            </>);
    }

    private onRatingTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ ratingText: e.target.value });
    }

    private onAddRating = () => {
        if (this.state.ratingText && this.state.rating !== undefined) {
            this.props.onAddRating(this.state.ratingText, this.state.rating);
            this.setState({ ratingText: "", rating: undefined })
        }
    }

    private ratingValueChange = (e: any, data: RatingProps) => {
        this.setState({ rating: data.rating as any });
    }
}
export default translate("tube")(connect((state: IState) => ({ state }))(TubeRatings));