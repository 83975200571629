import * as React from 'react';

import styled from 'styled-components';
import { CategoryDownloadDto, PostDownloadDto, ContentPostDownloadDto, TubePostDownloadDto, QuizDownloadDto, SlideShowDownloadDto, GameController, GameResultDto, EventController, EventUploadDto } from 'collaboration-service';
import { getTranslated, getBrowser } from 'services/Helpers';
import * as _ from "lodash";
import ListContentPost from './ListContentPost';
import ListTubePost from './ListTubePost';
import ListQuizPost from './ListQuizPost';
import { Button, Progress } from 'semantic-ui-react';
import ListSlideShow from './ListSlideShow';
import { SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';

interface ContentTileProps extends SizeConditionalComponent {
    category: CategoryDownloadDto;
    posts: PostDownloadDto[];
    color: string;

}
interface ContentTileState {
    rotate: number;
    gameResults: GameResultDto[];
    resultPos: number;
}

const ContentContainer = styled.div<{ rotate: number, color: string }>`
    position: absolute;
    top: 0;
    left: 0;
    border: 6px solid ${props => props.color};
    background-color: #ffffff;
    height: 100%;
    max-width: 375px;
    width: 100%;
    margin: auto;    
    border-radius: 50px;
    color: #313639;
    filter: drop-shadow(0 5px 5px ${props => props.color});
    box-shadow: 0 8px 8px -6px #333F49;
    transition: all 1s ease-in-out;
    transform: rotateY(${props => props.rotate}deg);
    @supports (display: grid){
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
`;

const ContentContainerFront = styled(ContentContainer)`
    display: grid;
    display: -ms-grid;   
    grid-template-rows: 40px 80px auto 90px  150px;
    -ms-grid-rows: 40px 80px 248px 90px 150px;
    z-index: ${props => (360 - props.rotate) * 5};
`;

const ContentContainerBack = styled(ContentContainer) <{ color: string }>`
    background-color: ${props => props.color};
    transform: rotateY(${props => props.rotate + 180}deg);
    z-index: ${props => (180 + props.rotate) * 5};
    display: grid;
    display: -ms-grid;   
    grid-template-rows: 40px auto 60px 40px;
    -ms-grid-rows: 40px auto 60px 40px;
`;

const Title = styled.div`
    grid-row-start: 3;
    -ms-grid-row: 3;
    grid-row: 3;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    width: 90%;
    max-width: 90%;
    overflow:hidden;
    height:100%;
    padding: 5px;
    margin: auto;
`;

const Header = styled.div<{ color?: string }>`
    grid-row-start: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    color: ${props => props.color};
    font-weight: 900;
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    padding: 5px;
    margin: auto;
`;

const Img = styled.div<{ url?: string, color: string }>`
    grid-row-start: 5;
    -ms-grid-row: 5;
    grid-row: 5;
    background: ${props => props.color};
    background-image: ${props => "url(" + props.url + ")"};
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-radius: 0 0 44px 44px;
    border-top: 6px solid ${props => props.color};
    width: 100%;
    height: 150px;
    margin: auto;
`;

const ContentSurrounding = styled.div`
    height: 600px;
    max-width: 375px;
    width: 100%;
    position: relative;
    -webkit-perspective: 600px;
    perspective: 600px;
    -webkit-transform-style: preserve-3d;
    -webkit-transform-origin: center center;
    transform-style: preserve-3d;
    transform-origin: center center;
`;

const ListContainer = styled.div`
    margin: 50px auto;
    width:90%;
    height:410px;
    overflow-y: auto;

`;

const TurnButtonContainer = styled.div<{ color?: string }>`
    grid-row-start: 4;
    -ms-grid-row: 4;
    width: 75%;
    margin: auto;
    .ui.button{
        background: ${props => props.color};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow&display=swap');
        font-family: 'PT Sans Narrow', sans-serif;
        &:hover{
            background: #333F49;
            color: ${props => props.color};
        }
    }
`;

const TurnButtonContainerBack = styled.div<{ color?: string }>`
    grid-row-start: 3;
    -ms-grid-row: 3;
    width: 90%;
    margin: auto;
    .ui.button{
        background: rgba(0, 0, 0, 0.2);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow&display=swap');
        font-family: 'PT Sans Narrow', sans-serif;  
        &:hover{
            background: #333F49;
            color: ${props => props.color};
        }
    }
`;

const ResultsTitle = styled.div`
    text-align: center;
    padding:20px;
    font-size:1.1rem;
    font-weight: bolder;
`;
const ResultsContent = styled.div<{ color?: string }>`
    text-align: center;
    padding:0 20px;
    margin-top:15px;
    .ui.progress .bar {
        border-radius:12px !important;
        background: rgba(0,0,0,.6);
    }
    .ui.progress{
        border-radius:12px !important;
        background:${props => props.color};
        border: 1px solid rgba(0,0,0,.1);
    }
    .ui.progress .bar>.progress{
        font-weight: normal;
        color:${props => props.color};
        letter-spacing: 2px;
        font-size:0.8rem;
        width: fit-content;
        text-align: center;
        margin-left:10px;
    }
    .ui.progress>.label{
        color: rgba(0,0,0,.6);
        top: -100%;
        font-size:0.9rem;
    }
`;
const ResultEnd = styled.div`
        color: rgba(0,0,0,.6);
        font-size:0.9rem;
        margin-top:-30px;
    `;

const ResultContainer = styled.div<{ color?: string }>`
    background: ${props => props.color + "d1"};
    color: rgba(0,0,0,.6);
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 43px;
    border-bottom-right-radius: 43px;
    border:5px solid #fff;
`;

export default class ContentTile extends React.Component<ContentTileProps, ContentTileState> {
    private run: boolean = false;
    public state: ContentTileState = {
        rotate: 0,
        gameResults: [],
        resultPos: 0,
    }
    public componentDidMount() {
        _.forEach(this.props.posts, p => {
            if (p.type === "Quiz" && (p as QuizDownloadDto).maxRepeats < 20
            ) {
                GameController.GetGameResultsForQuiz({ quizId: p.id }, res => {
                    if (res && res.length > 0) {
                        const gameResults = [...this.state.gameResults];
                        const result = res.pop();
                        if (result)
                            gameResults.push(result);
                        this.setState({ gameResults });
                        if (!this.run) {
                            this.run = true;
                            this.start();
                        }
                    }
                })
            }
        })
    }
    public componentWillUnmount() {
        this.run = false;
    }

    public render() {
        const cat = this.props.category;
        const icon = cat.svgImage && cat.svgImage.links && cat.svgImage.links.length > 0 ? cat.svgImage.links[0].uri : undefined;
        const altIcon = cat.svgImageAlt && cat.svgImageAlt.links && cat.svgImageAlt.links.length > 0 ? cat.svgImageAlt.links[0].uri : undefined;
        const img = cat.media && cat.media.links && cat.media.links.length > 0 ? cat.media.links[0].uri : undefined;
        const m = icon ?? (altIcon ?? img);
        const h = getTranslated(this.props.category.names).split("\n");
        const header = h.shift();
        const desc = h.join("\n");


        return (
            <ContentSurrounding>
                <ContentContainerFront rotate={this.state.rotate} color={this.props.color}>
                    <Header color={this.props.color}>{header}</Header>
                    <Title>{desc}</Title>
                    <TurnButtonContainer onClick={this.rot} color={this.props.color}>
                        <Button circular content="klick" fluid size="large" />
                    </TurnButtonContainer>

                    {this.state.resultPos === 0 &&
                        <Img key={this.state.resultPos} url={m} color={this.props.color} className="TestResults" />
                    }
                    {this.state.resultPos >= 1 &&
                        <Img key={this.state.resultPos} url={m} color={this.props.color} className="TestResults">
                            {this.renderResult(this.state.resultPos - 1)}
                        </Img>
                    }
                </ContentContainerFront >
                <ContentContainerBack rotate={this.state.rotate} color={this.props.color}>
                    <ListContainer>
                        {_.map(this.props.posts, p => this.renderPost(p))}
                    </ListContainer>
                    <TurnButtonContainerBack color={this.props.color}>
                        <Button circular content="zurück" fluid size="large" onClick={this.rot} />
                    </TurnButtonContainerBack>
                </ContentContainerBack>
            </ContentSurrounding >
        );
    }

    private renderResult = (i: number) => {
        const g = this.state.gameResults[i];
        const percent = Math.round(100 * g.points / g.pointsPossible);
        return (
            <ResultContainer key={g.quiz.id} color={this.props.color}>
                <ResultsTitle>
                    {getTranslated(g.quiz.headlines)}
                </ResultsTitle>
                <ResultsContent>
                    <Progress percent={percent} progress label="Dein letztes Ergebnis:" />
                    <ResultEnd>Du hast <b>{g.points}</b> von <b>{g.pointsPossible}</b> möglichen Punkten</ResultEnd>
                </ResultsContent>
            </ResultContainer >);
    }

    private renderPost = (p: PostDownloadDto) => {
        const t = p.type ?? "";
        const { width, height, currentBreakpoint, breakpoints } = this.props;
        const pr = { width, height, currentBreakpoint, breakpoints };
        switch (t) {
            case "ContentPost":
                return <ListContentPost {...pr} key={p.id} post={p as ContentPostDownloadDto} color={this.props.color} saveClick={this.saveClick} />
            case "TubePost":
                return <ListTubePost {...pr} key={p.id} post={p as TubePostDownloadDto} color={this.props.color} saveClick={this.saveClick} />
            case "Quiz":
                return <ListQuizPost {...pr} key={p.id} post={p as QuizDownloadDto} color={this.props.color} saveClick={this.saveClick} />
            case "SlideShow":
                return <ListSlideShow {...pr} key={p.id} post={p as SlideShowDownloadDto} color={this.props.color} saveClick={this.saveClick} />
            default:
                return <ListContentPost {...pr} key={p.id} post={p as ContentPostDownloadDto} color={this.props.color} saveClick={this.saveClick} />
        }
    }

    private saveClick = (id: string) => {
        //        console.log("saveClick => ", id);
        const p = _.find(this.props.posts, p => p.id === id);
        if (p) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                payload: { PostId: p.id, browser: getBrowser() },
                group_id: p.group_id,
            };
            EventController.PostEvent({ ev });
        }
    }

    private rot = () => {
        if (this.state.rotate === 180)
            this.setState({ rotate: 0 });
        else
            this.setState({ rotate: 180 });
    }

    private start = () => {
        const randFigure = 1000 * (4.5 + Math.random());
        if (this.run && this.state.gameResults.length > 0) {
            setTimeout(() => {
                this.setState({ resultPos: ((this.state.resultPos + 1) % (this.state.gameResults.length + 1)) }, () => this.start());
            }, randFigure);
        }
    }
}

