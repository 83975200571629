import { GameController, GameDownloadDto, QuizDownloadDto } from 'collaboration-service';
import Media from 'components/General/Media/Media';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { config } from 'services/Config';
import { getTranslated } from 'services/Helpers';
import styled from 'styled-components';
import { ListEntryBase, ListEntryBaseImage169, ContainerInfo } from './ListEntry';
export interface ListQuizPostProps {
    post: QuizDownloadDto;
    color: string;
    saveClick: (id: string) => void;
}

interface ListQuizPostState {
    start?: boolean;
    continue?: boolean;
    game?: GameDownloadDto;
}

const Container = styled(ListEntryBase)`
    position:relative;
`;

const ContainerDeactivated = styled(ListEntryBase)`
    position: relative;
   cursor: not-allowed;
    &:hover{
        background: #c44f4f61;
        box-shadow: 0 0 0 0 transparent;
    }
`;

const ImgContainer = styled(ListEntryBaseImage169)`
    position: relative;
`;

const TitleContainer = styled.div`
    grid-column-start: 2;
    -ms-grid-column: 2;
    grid-column: 2;
    position: relative;
    padding:0 20px 0 0;
`;

const Title = styled.div`
    text-align: center;
    vertical-align: middle;
    transform: translateY(45px) translateY(-50%);
`;

const QuizInfo = styled.div`
    font-size: 0.8rem;
    color: #333F49;
    font-weight:500;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    text-align:center;
`;

export default class ListQuizPost extends React.Component<ListQuizPostProps, ListQuizPostState> {
    public state: ListQuizPostState = {};
    componentDidMount() {
        GameController.SearchGames({
            filter: {
                postId: this.props.post.id,
                state: "Unfinished",
                findReplayGames: false,
                findVersusGames: false
            }
        },
            (incompleted: GameDownloadDto[]) => {
                const game = incompleted.pop();
                if (game)
                    this.setState({ game })
            },
            (err) => console.log(err)
        );
    }
    public render() {
        if (this.state.start)
            return <Redirect to={`${config.routes.quiz.quiz}/${this.props.post.id}/single`} push />;
        if (this.state.continue && this.state.game)
            return <Redirect to={`${config.routes.quiz.quiz}/${this.state.game.id}/continue`} push />;
        return (
            <>
                {this.props.post.maxRepeats === 0 && !this.props.post.hasUnfinishedGames &&
                    <ContainerDeactivated>
                        <ContainerInfo color={this.props.color}>
                            <Icon.Group size="large"><Icon name={config.onboardingIcons.quizPlayable as SemanticICONS} /><Icon corner="bottom left" name={config.onboardingIcons.quizUnplayable as SemanticICONS} /></Icon.Group>
                        </ContainerInfo>
                        <ImgContainer>
                            {this.props.post.media &&
                                <Media media={{ media: this.props.post.media, refName: "self" }} contentClassName="ListQuizPostImage" />
                            }
                        </ImgContainer>
                        <TitleContainer>
                            <Title>
                                {getTranslated(this.props.post.headlines)}
                            </Title>
                            <QuizInfo>
                                <b>bereits absolviert</b>
                            </QuizInfo>
                        </TitleContainer>
                    </ContainerDeactivated>
                }
                {this.props.post.maxRepeats === 0 && this.props.post.hasUnfinishedGames &&
                    <Container onClick={this.continue}>
                        <ContainerInfo color={this.props.color}>
                            <Icon name={config.onboardingIcons.quizPlayable as SemanticICONS} size="large" />
                        </ContainerInfo>
                        <ImgContainer>
                            {this.props.post.media &&
                                <Media media={{ media: this.props.post.media, refName: "self" }} contentClassName="ListQuizPostImage" lazyLoading="off" />
                            }
                        </ImgContainer>
                        <TitleContainer>
                            <Title>
                                {getTranslated(this.props.post.headlines)}
                            </Title>
                            <QuizInfo>
                                <b>fortsetzen</b>
                            </QuizInfo>
                        </TitleContainer>
                    </Container>
                }
                {this.props.post.maxRepeats > 0 &&
                    <Container onClick={this.start}>
                        <ContainerInfo color={this.props.color}>
                            <Icon name={config.onboardingIcons.quizPlayable as SemanticICONS} size="large" />
                        </ContainerInfo>
                        <ImgContainer>
                            {this.props.post.media &&
                                <Media media={{ media: this.props.post.media, refName: "self" }} contentClassName="ListQuizPostImage" lazyLoading="off" />
                            }
                        </ImgContainer>
                        <TitleContainer>
                            <Title>
                                {getTranslated(this.props.post.headlines)}
                            </Title>
                            <QuizInfo>
                                {
                                    this.props.post.maxRepeats === 1 ?
                                        <div>noch <b>ein</b> Versuch</div> :
                                        this.props.post.maxRepeats < 20 ?
                                            <div>noch <b>{this.props.post.maxRepeats}</b> Versuche</div> :
                                            undefined
                                }
                            </QuizInfo>
                        </TitleContainer>
                    </Container>
                }
            </>
        );
    }
    private start = () => {
        this.props.saveClick(this.props.post.id);
        this.setState({ start: true });
    }
    private continue = () => {
        this.props.saveClick(this.props.post.id);
        this.setState({ continue: true });
    }
}
