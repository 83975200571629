import { GroupController, GroupDownloadDto } from "collaboration-service";
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import ReactSelect, { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from "react-select/src/types";
import { Icon } from 'semantic-ui-react';
import { getFirstFromValueType } from "services/Helpers";
import { IConnectedComponent, IState } from 'services/Interfaces';

interface ITreeGroupSelectionProps extends IConnectedComponent {
    groups?: GroupDownloadDto[];
    onLoading?: (loading: boolean) => void;
    groupSuffix?: (group: GroupDownloadDto) => React.ReactNode;
    groupPrefix?: (group: GroupDownloadDto) => React.ReactNode;
    onGroupSelected?: (group?: GroupDownloadDto) => void;
    children?: React.ReactNode | React.ReactNode[];
    segmentClassName?: string;
    linkClassName?: string;
    disabled?: boolean;
    value?: GroupDownloadDto;
    className?: string;

    getReloader?: (reloader: () => void) => void;
    clearable?: boolean;
}

interface ITreeGroupSelectionState {
    groups?: TreeElement[];
    selectedGroup?: GroupDownloadDto;
}


interface TreeElement {
    depth: number;
    value: GroupDownloadDto;
    label: string;
    instance: TreeGroupSelection;
}

const getIcon = (val: TreeElement) => {
    if (!val.value.childGroups || val.value.childGroups.length === 0)
        return (<Icon color="teal" name="folder open" />); // icon without childs
    return (<Icon color="blue" name="folder" />);
};


const LanguageOption: React.SFC<OptionProps<TreeElement>> = (props: OptionProps<TreeElement>) => {
    const x: TreeElement = (props as any).data;
    return (
        <components.Option {...props}>
            <div style={{ marginLeft: x.depth * 10, marginBottom: 0 }}>
                {x.instance.renderPrefix(x.value)}{getIcon(x)} {props.children}{x.instance.renderSuffix(x.value)}
            </div>
        </components.Option>);
};

const LanguageValue: React.SFC<SingleValueProps<TreeElement>> = (props: SingleValueProps<TreeElement>) => {
    const x: TreeElement = (props as any).data;
    return <div style={{ width: "80%" }}>{x.instance.renderPrefix(x.value)}{getIcon(x)} {props.children}{x.instance.renderSuffix(x.value)}</div>;
    // return <div>{x.instance.renderPrefix(x.value)}{getIcon(x)} {props.children}{x.instance.renderSuffix(x.value)}</div>;
};



class TreeGroupSelection extends React.Component<ITreeGroupSelectionProps, ITreeGroupSelectionState> {
    constructor(props: ITreeGroupSelectionProps) {
        super(props);

        this.state = {
        };
        if (this.props.getReloader)
            this.props.getReloader(this.reload);
    }

    public UNSAFE_componentWillMount() {
        this.reload();
    }

    public render() {
        let idx = -1;
        if (this.props.value) {
            const v = this.props.value;
            idx = _.findIndex(this.state.groups, g => g.value.id === v.id);
        }
        if (!this.props.clearable && this.state.groups && (this.state.groups.length === 1 && idx === -1)) {
            const g = this.state.groups[0];
            if (this.props.onGroupSelected)
                this.props.onGroupSelected(g.value);
        }
        const value: any = idx >= 0 && this.state.groups ? this.state.groups[idx] : "";
        return (
            <ReactSelect
                className={this.props.className}
                autoFocus
                isMulti={false}
                isSearchable={true}
                isClearable={true}
                options={this.state.groups}
                components={{ Option: LanguageOption, SingleValue: LanguageValue }}
                onChange={this.groupSelected}
                isDisabled={this.props.disabled}
                value={value}
                classNamePrefix="rselect"
                menuPortalTarget={document.body}
                styles={{ menuPortal: styles => ({ ...styles, zIndex: 2000 }) }}
            />
        );
    }

    public renderPrefix = (group: GroupDownloadDto) => {
        if (this.props.groupPrefix)
            return this.props.groupPrefix(group);
        return null;
    }

    public renderSuffix = (group: GroupDownloadDto) => {
        if (this.props.groupSuffix)
            return this.props.groupSuffix(group);
        return null;
    }

    private groupSelected = (val: ValueType<TreeElement>) => {
        const ele = getFirstFromValueType(val);
        if (this.props.onGroupSelected)
            this.props.onGroupSelected(ele ? ele.value : undefined);
    }

    private reload = () => {
        if (this.props.groups) {
            this.setupGroupList(this.props.groups);
            if (this.props.onLoading)
                this.props.onLoading(false);
        }
        else
            GroupController.GetHierarchy({},
                (groupsCollection) => {
                    this.setupGroupList(groupsCollection.elements);
                    if (this.props.onLoading)
                        this.props.onLoading(false);
                });
    }

    private mapGroup = (list: TreeElement[], groups: GroupDownloadDto[], depth: number) => {
        _.forEach(groups, g => {
            list.push({ label: g.name, value: g, depth, instance: this });
            this.mapGroup(list, g.childGroups, depth + 1);
        });
    }

    private setupGroupList = (groups: GroupDownloadDto[]) => {
        const groupList: TreeElement[] = [];
        this.mapGroup(groupList, groups, 0);
        this.setState({ groups: groupList });
    }
}

export default connect((state: IState) => ({ state }))(TreeGroupSelection);