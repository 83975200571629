import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IConnectedComponent } from 'services/Interfaces';
import ScormFooter from './ScormFooter';
import ScormHeader from './ScormHeader';

interface ScormLegalNotesProps extends RouteComponentProps<any>, IConnectedComponent {

}
interface ScormLegalNotesState {
}

class ScormLegalNotes extends Component<ScormLegalNotesProps, ScormLegalNotesState> {
    constructor(props: ScormLegalNotesProps) {
        super(props);
        this.state = {
        }
    };

    render() {
        return (
            <div style={{ position: "relative" }}>
                <div className="ScormOuterContainer" style={{ backgroundImage: "url('./images/scorm/background.jpg')" }}>
                    <ScormHeader head />
                    <div className="ScormContainerIntroLegalNotes" >
                        <div className="ScormContainerIntroLegalNotesBackButton" onClick={this.goBack}>back</div>
                        <h3>
                            Impressum
                        </h3>
                        <p >
                            Dr. Ing. h.c. F. Porsche AG<br />
                            Porscheplatz 1<br />
                            D-70435 Stuttgart
                            </p>
                        <p>
                            E-Mail: info@porsche.de<br />
                            Tel: (+49) 0711 911-0
                            </p>
                        <p>
                            vertreten durch den Vorstand:<br />
                            Oliver Blume, Vorsitzender<br />
                            Lutz Meschke, stv. Vorsitzender<br />
                            Andreas Haffner<br />
                            Detlev von Platen<br />
                            Albrecht Reimold<br />
                            Uwe-Karsten Städter<br />
                            Michael Steiner
                            </p>
                        <p>
                            Registergericht: Amtsgericht Stuttgart<br />
                            HRB-Nr. 730623<br />
                            USt.-Ident.-Nr. DE 147 799 625
                            </p>

                        <h3>Rechtliche Hinweise</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet!</p>
                        <h3>Hinweise zum Datenschutz</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic provident vel perferendis, laborum id iste minima porro repudiandae. Accusamus qui officia repudiandae a voluptatem provident. Accusamus autem quae a eveniet!</p>
                    </div>
                </div>
                <ScormFooter />
            </div>
        )
    }
    private goBack = () => {
        this.props.history.goBack();
    }
}
export default withRouter(connect()(ScormLegalNotes));