import { UserController, UserShortInfoDownloadDto } from "collaboration-service";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { ValueType } from "react-select/src/types";
import { Image, Popup } from "semantic-ui-react";
import { getArrayFromValueType, getUri, translateUsername, translateUsernameShort } from "services/Helpers";
import { IConnectedComponent, IState } from "services/Interfaces";
import UserTile from "../UserTile/UserTile";
interface IUserSearchSelectionProps extends IConnectedComponent {
    onUserChanged: (user?: UserShortInfoDownloadDto[]) => void;
    value?: UserShortInfoDownloadDto | UserShortInfoDownloadDto[] | null;
    disabled?: boolean;
    multi?: boolean;
    clearable?: boolean;
    idsToHide?: string[];
    loadOptions?: (val: string, callback: (options: any[]) => void) => void;
    className?: string;
    groupId?: string;
}

interface IUserSearchSelectionState {
    nothing?: any;
    userlist?: UserShortInfoDownloadDto[];
}

interface SelectData {
    value: string;
    label: string;
    data: UserShortInfoDownloadDto;
}

const UserOption: React.SFC<OptionProps<SelectData>> = (props: OptionProps<SelectData>) => {
    const x: any = props;
    return <components.Option {...props}><UserTile compact user={x.data.data} /></components.Option>;
};


const UserValue: React.SFC<SingleValueProps<SelectData>> = (props: SingleValueProps<SelectData>) => {
    const x: any = props;
    return <div className="Select-value"><UserTile compact user={x.data.data} /></div>;
};

interface MultiUserValueWithoutTranslateProps extends MultiValueProps<SelectData>, IIMGTranslatedComponent {
}

const MultiUserValueWithoutTranslate: React.SFC<MultiUserValueWithoutTranslateProps> = (props: MultiUserValueWithoutTranslateProps) => {
    const uri = getUri(props.data.data.avatar);
    return <div className="Select-value" style={{ width: "80%" }}>
        <Popup className='MiniUserPopup'
            content={props.t("{{username}}", { username: translateUsername(props.data.data) })}
            trigger={<Image avatar src={uri} />}
        />
    </div>;
};

const MultiUserValue = translate("")(MultiUserValueWithoutTranslate);


class UserSearchSelection extends React.Component<IUserSearchSelectionProps, IUserSearchSelectionState>
{
    public state: IUserSearchSelectionState =
        {
            nothing: undefined
        };

    public constructor(props: IUserSearchSelectionProps) {
        super(props);
        this.userSelected = this.userSelected.bind(this);
    }

    public render() {
        let sel: SelectData | SelectData[] | undefined;
        if (this.props.value) {
            const val = this.props.value;
            if (_.isArray(val)) {
                sel = _.map(val, this.mapToSelectData);
            }
            else
                sel = this.mapToSelectData(val);
        }
        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    components={{ Option: UserOption, SingleValue: UserValue, MultiValueLabel: MultiUserValue }}
                    isMulti={this.props.multi}
                    isClearable={this.props.clearable}
                    value={sel}
                    onChange={this.userSelected}
                    loadOptions={this.props.loadOptions ? this.props.loadOptions : this.loadOptions}
                    className={this.props.className ? this.props.className : ""}
                    classNamePrefix="rselect"
                />
            </div>
        );
    }

    private mapToSelectData = (u: UserShortInfoDownloadDto) => {
        return ({ value: u.id, label: translateUsernameShort(u), data: u });
    }

    private loadOptions = (val: string, callback: (options: any[]) => void) => {
        return UserController.SearchUsers({ searchInfo: { general: val, group: this.props.groupId } }, (users) => {
            if (!this.props.idsToHide)
                callback(_.map(users, this.mapToSelectData));
            else {
                const u = _.filter(users, uu => _.findIndex(this.props.idsToHide, i => i === uu.id) < 0);
                callback(_.map(u, this.mapToSelectData));
            }
        });

        /*        const vals = val.split(" ");
                if (vals.length < 1)
                    return;
                if (vals[0].length < 3 && vals[0].indexOf("@") < 0 && vals.length < 2)
                    return;
                const v0 = vals[0];
                const v1 = vals.length < 2 ? "" : vals[1];
                return new Promise<UserShortInfoDownloadDto[]>(resolve => {
                    this.props.dispatch(ActionCreators.local.SearchUsesForFirstNameLastName(v0, v1, (userlist => {
                        //                this.setState({ userlist });
                        console.log(userlist);
                        resolve(userlist);
                    })));
                });*/
    }

    // private userSelected(user?: SelectData[]) {
    private userSelected(user: ValueType<SelectData>) {
        if (!user)
            this.props.onUserChanged(undefined);
        else {
            const users = getArrayFromValueType(user);
            this.props.onUserChanged(_.map(users, u => u.data));
        }
    }
}

export default connect((state: IState) => ({ state }))(UserSearchSelection);