import { CalendarEntryDownloadDto, CategoryDownloadDto, ContentController, ControllerHelper, DashboardElementTemplateDownloadDto, DefaultController, GroupController, GroupDownloadDto, LoginController, MediaController, MediaDownloadDto, NotificationController, NotificationDownloadDto, PostDownloadDto, ScormRegistrationDownloadDto, SlideShowDownloadDto, UserController, UserDownloadDto, UserProfileSettingsDownloadDto, UserSettingsUploadDto } from "collaboration-service";
import ImgI18N from "components/Utils/img-i18n";
import { ExtendedDataCollection, Node } from "imaginarity-azure";
import { PluginActionTypes } from "imaginarity-platform-plugin-base";
import * as _ from "lodash";
import { AnyAction, Dispatch } from 'redux';
import { config } from './Config';
import { getBrowser } from "./Helpers";
import { IState } from './Interfaces';
import MobileHelper from "./MobileHelper";

export const InternalActionTypes =
{
    DEBUG: 0,
    INIT_STATE: 1,
    CREATED_CACHE: 2,
    BATCH_ACTIONS: 3,

    TOGGLE_STATE_MODALS: 50,
    TOGGLE_STATE_BUTTON_MORE_PRESSED: 51,

    TOKEN_RECEIVED: 200,

    USER_INFO_RECEIVED: 601,
    ADD_NEW_USER: 602,
    CHANGE_USER_PASSWORD: 603,
    UPDATE_USER_SETTINGS: 604,
    NOTIFICATIONS_RECEIVED: 605,
    GROUPS_RECEIVED: 606,
    GLOBAL_MEDIA_RECEIVED: 607,

    UPDATE_POSTS_LIKES: 701,

    LANGING_PAGE_SET_RESULT_ID: 800,

    RECEIVED_CONTENT_POSTS: 900,
    SET_SHOW_BRIEFING: 901,

    WARNED_ABOUT_UNSUPPORTED: 1000,

    // Diagram
    SET_DIAGRAM_CUR_STYLE: 1001,
    GET_ONE_STYLE: 1002,
    GOT_ONE_STYLE: 1003,

    GET_RING_RADIUS: 1004,
    GOT_RING_RADIUS: 1005,

    // COMMUNITY

    COMMUNITY_CALENDAR_LOADED: 2001,
    COMMUNITY_UNSEEN_MESSAGES: 2002,

    COMMUNITY_CALENDAR_REMOVE_ITEM: 2003,
    COMMUNITY_CALENDAR_UPDATE_ITEM: 2004,
    COMMUNITY_CALENDAR_CLEAR: 2005,

    SET_EDIT_LANGUAGE: 3000,
    SET_EDIT_SLIDESHOW: 3500,

    ADD_CATEGORIES_TO_MAP: 4000,

    SET_WORKING_GROUP: 5000,

    SET_SCORM_REGISTRATIONS: 6000,

    SET_LINK_ADDRESS: 6001,

    SET_HOME_LINK: 6002,

    SET_DB_TEMPLATES: 7000,
};

export const ActionTypes = _.mapValues(InternalActionTypes, (a) => {
    return a + PluginActionTypes.PLUGIN_ACTIONS_END;
});

export const ActionCreators =
{
    BatchActions(actions: AnyAction[]) {
        return { type: ActionTypes.BATCH_ACTIONS, actions };
    },
    SetDebugging(value: boolean): AnyAction {
        return { type: ActionTypes.DEBUG, debug: value };
    },
    SetComUnseenMessages(value: number): AnyAction {
        return { type: ActionTypes.COMMUNITY_UNSEEN_MESSAGES, data: value };
    },
    InitState(): AnyAction {
        return { type: ActionTypes.INIT_STATE };
    },
    TokenReceived(token?: string): AnyAction {
        return { type: ActionTypes.TOKEN_RECEIVED, token };
    },
    ReceivedUserInfo(userInfo?: UserDownloadDto) {
        return { type: ActionTypes.USER_INFO_RECEIVED, userInfo };
    },
    ReceivedNotifications(notifications?: NotificationDownloadDto[], token?: string) {
        return { type: ActionTypes.NOTIFICATIONS_RECEIVED, notifications, token };
    },
    ReceivedGroups(groups?: ExtendedDataCollection<GroupDownloadDto, UserProfileSettingsDownloadDto>) {
        return { type: ActionTypes.GROUPS_RECEIVED, groups };
    },
    ReceivedGlobalMedia(media: MediaDownloadDto[]) {
        return { type: ActionTypes.GLOBAL_MEDIA_RECEIVED, media };
    },
    CreatedCache(cache: Cache) {
        return { type: ActionTypes.CREATED_CACHE, cache };
    },
    ReceivedContentPosts(posts?: ExtendedDataCollection<PostDownloadDto, Node<string>>) {
        return { type: ActionTypes.RECEIVED_CONTENT_POSTS, posts };
    },
    SetShowBriefing(value: boolean) {
        return { type: ActionTypes.SET_SHOW_BRIEFING, value };
    },
    SetWarnedAboutUnsupported(value: boolean) {
        return { type: ActionTypes.WARNED_ABOUT_UNSUPPORTED, value };
    },
    SetDiagramCurStyle(curStyle?: any) {
        return { type: ActionTypes.SET_DIAGRAM_CUR_STYLE, curStyle };
    },
    GetOneStyle() {
        return { type: ActionTypes.GET_ONE_STYLE };
    },
    GetRingRadius() {
        return { type: ActionTypes.GET_RING_RADIUS };
    },
    GotOneStyle() {
        return { type: ActionTypes.GOT_ONE_STYLE };
    },
    GotRingRadius() {
        return { type: ActionTypes.GOT_RING_RADIUS };
    },
    CommunityCalendarLoaded(data: ExtendedDataCollection<CalendarEntryDownloadDto, string>) {
        return { type: ActionTypes.COMMUNITY_CALENDAR_LOADED, data };
    },
    CommunityCalendarClear() {
        return { type: ActionTypes.COMMUNITY_CALENDAR_CLEAR };
    },
    CommunityUnseenMessagesLoaded(data: number) {
        return { type: ActionTypes.COMMUNITY_UNSEEN_MESSAGES, data };
    },
    CommunityCalendarRemoveItem(entry: CalendarEntryDownloadDto) {
        return { type: ActionTypes.COMMUNITY_CALENDAR_REMOVE_ITEM, entry };
    },
    CommunityCalendarUpdateItem(entry: CalendarEntryDownloadDto) {
        return { type: ActionTypes.COMMUNITY_CALENDAR_UPDATE_ITEM, entry };
    },
    SetEditLanguage(lng?: string) {
        return { type: ActionTypes.SET_EDIT_LANGUAGE, lng };
    },
    SetEditSlideShow(slideShow?: SlideShowDownloadDto) {
        return { type: ActionTypes.SET_EDIT_SLIDESHOW, slideShow };
    },
    AddCategoriesToMap(groupType: string, categories: CategoryDownloadDto[]) {
        return { type: ActionTypes.ADD_CATEGORIES_TO_MAP, groupType, categories };
    },
    SetWorkingGroup(group?: GroupDownloadDto) {
        return { type: ActionTypes.SET_WORKING_GROUP, group };
    },
    SetScormRegistrations(registrations?: ScormRegistrationDownloadDto[]) {
        return { type: ActionTypes.SET_SCORM_REGISTRATIONS, registrations };
    },
    SetLinkAddress(address?: string) {
        return { type: ActionTypes.SET_LINK_ADDRESS, address };
    },
    SetHomeLink(homeLink?: string) {
        return { type: ActionTypes.SET_HOME_LINK, homeLink };
    },
    SetDashboardTemplates(templates: DashboardElementTemplateDownloadDto[]) {
        return { type: ActionTypes.SET_DB_TEMPLATES, templates };
    },
    landingPage: {
        SetSearchResultId(id: string): AnyAction {
            return { type: ActionTypes.LANGING_PAGE_SET_RESULT_ID, id };
        },
        ToggleStateModal(value: boolean): AnyAction {
            return { type: ActionTypes.TOGGLE_STATE_MODALS, openReasons: value };
        },
        ToggleStateButtonMore(value: boolean): AnyAction {
            return { type: ActionTypes.TOGGLE_STATE_BUTTON_MORE_PRESSED, moreButtonPressed: value };
        },
    },


    /********* Thunks *********/

    globalState:
    {
        Login(login: string, password: string, onSuccess?: (user: UserDownloadDto) => void, onError?: (reason: any) => void) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                LoginController.LoginWithBrowserInfo({ login: { login, password }, browserInfo: getBrowser() },
                    (val) => {
                        if (config.debug)
                            console.log("new token: ", val);
                        dispatch(ActionCreators.TokenReceived(val));
                        dispatch(ActionCreators.globalState.GetUserinfo(onSuccess, onError));
                    },
                    onError,
                    undefined,
                    "login");
            };
        },
        BecomeUser(token: string, onSuccess?: (user: UserDownloadDto) => void, onError?: (reason: any) => void) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                dispatch(ActionCreators.TokenReceived(token));
                dispatch(ActionCreators.globalState.GetUserinfo(onSuccess, onError));
            };
        },
        Logout(onSuccess?: () => void, onError?: (reason: any) => void) {
            return (dispatch: Dispatch<any>) => {
                MobileHelper.getInstance().logout();
                LoginController.Logout({},
                    () => {
                        dispatch(ActionCreators.TokenReceived(undefined));
                        dispatch(ActionCreators.ReceivedUserInfo(undefined));
                        if (onSuccess)
                            onSuccess();
                    },
                    (err) => {
                        dispatch(ActionCreators.TokenReceived(undefined));
                        dispatch(ActionCreators.ReceivedUserInfo(undefined));
                        if (onError)
                            onError(err);
                    },
                    undefined,
                    "login");
            };
        },
        GetUserinfo(onSuccess?: (userInfo: UserDownloadDto) => void, onError?: (reason: any) => void) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                const state = getState();
                UserController.GetUserInfo({},
                    async (val) => {
                        try {
                            // eslint-disable-next-line
                            const getUrl = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
                            const gtype = "COMMUNITY";
                            DefaultController.GetFeatures({ url: (getUrl.exec(document.location.href) ?? ["", "localhost"])[0].toLowerCase() }, (features) => {
                                _.forEach(features, (f, k) => {
                                    if (k.indexOf("Features") > 0) {
                                        //console.log("applying to => ", k);
                                        (config as any)[k] = f;
                                    }
                                });
                            });
                            //console.log("config => ", _.clone(config));
                            const [userinfo, groups, notifications, medias, categories] = await ControllerHelper
                                .addCall({}, UserController.GetUserInfo)
                                .addCall({}, GroupController.Get)
                                .addCall({ token: { token: "" } }, NotificationController.Get, true)
                                .addCall({}, MediaController.Get)
                                .addCall({ grouptype: gtype }, ContentController.GetCategoriesForAllGroupsByGroupType)
                                .execute<
                                    UserDownloadDto,
                                    ExtendedDataCollection<GroupDownloadDto, UserProfileSettingsDownloadDto>,
                                    ExtendedDataCollection<NotificationDownloadDto, string>,
                                    MediaDownloadDto[],
                                    CategoryDownloadDto[]
                                >();
                            if (!state.client.token) {
                                const token = await ControllerHelper.singleCall({}, LoginController.RenewToken, true);
                                if (token)
                                    dispatch(ActionCreators.TokenReceived(token));
                            }
                            dispatch(ActionCreators.ReceivedUserInfo(userinfo));
                            dispatch(ActionCreators.ReceivedGroups(groups));
                            if (notifications)
                                dispatch(ActionCreators.ReceivedNotifications(notifications.elements, notifications.data));
                            dispatch(ActionCreators.ReceivedGlobalMedia(medias));
                            dispatch(ActionCreators.AddCategoriesToMap(gtype, categories));

                            if (onSuccess)
                                onSuccess(userinfo);
                        }
                        catch (error) {
                            dispatch(ActionCreators.ReceivedUserInfo());
                            if (onError)
                                onError(error);
                        }
                    },
                    (err) => {
                        dispatch(ActionCreators.ReceivedUserInfo());
                        if (onError)
                            onError(err);
                    });
            };
        },
        RegisterUserPost(token: string, onError?: (reason: any) => void) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                const code = { code: token };
                const val = await ControllerHelper.singleCall({ code }, LoginController.RegisterPost, true);
                if (val) {
                    dispatch(ActionCreators.TokenReceived(val));
                    dispatch(ActionCreators.globalState.GetUserinfo());
                } else
                    if (onError)
                        onError("could not register!");
            };
        },
        ResetPassword(encodedString: string, onError?: (reason: any) => void) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                const val = await ControllerHelper.singleCall({ encodedString }, LoginController.Reset, true);
                if (val) {
                    dispatch(ActionCreators.TokenReceived(val));
                    dispatch(ActionCreators.globalState.GetUserinfo());
                }
            };
        },
        ChangeGroup(group: string) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                const state = getState();
                const ui = state.userInfo ? state.userInfo : { userSettings: { lastGroupId: "" } };
                ui.userSettings.lastGroupId = group;
                dispatch(ActionCreators.ReceivedUserInfo(ui as UserDownloadDto));
                if (!state.userInfo)
                    return;
                const settings: UserSettingsUploadDto = Object.assign({}, state.userInfo.userSettings);
                settings.lastGroupId = group;
                const id = state.userInfo.id;
                const val = await ControllerHelper.singleCall({ id, settings }, UserController.PostUserSettings, true);
                if (val)
                    dispatch(ActionCreators.ReceivedUserInfo(val));
            };
        },
        ChangeLanguage(lng: string) {
            return async (dispatch: Dispatch<any>, getState: () => IState) => {
                const state = getState();
                if (!state.userInfo)
                    return;
                const settings: UserSettingsUploadDto = Object.assign({}, state.userInfo.userSettings);
                const def = ImgI18N.getInstance().getDefinitionExact(lng);
                // console.log('lng => ', lng);
                // console.log('def => ', def);

                settings.language = def?.newCode ?? lng;
                const id = state.userInfo.id;
                const val = await ControllerHelper.singleCall({ id, settings }, UserController.PostUserSettings, true);
                if (val)
                    dispatch(ActionCreators.ReceivedUserInfo(val));
            };
        }
    },
};
