import { InAppMessageDownloadDto } from 'collaboration-service';
import UserAvatar from 'components/General/UserAvatar/UserAvatar';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import React, { Component } from 'react';
import { Button, Icon, List, Popup, Table } from 'semantic-ui-react';
import { formatDate, getTranslated, splitText, translateUsername } from 'services/Helpers';


interface InAppMessageProps extends IIMGTranslatedComponent {
    message: InAppMessageDownloadDto;
    toggleDone: (msg: InAppMessageDownloadDto) => void;
    toggleRead: (msg: InAppMessageDownloadDto) => void;
    deleteMessage: (msg: InAppMessageDownloadDto) => void;
}
interface InAppMessageState {
    showMoreInfo: boolean;
    showHistory: boolean;
    unreadMsg?: number
}

class InAppMessage extends Component<InAppMessageProps, InAppMessageState> {
    state = {
        showMoreInfo: false,
        showHistory: false
    }

    render() {
        const { message } = this.props;
        const bodyTxt = getTranslated(message.body);
        const activeBgColor = "linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0.9) 100%)";
        const inactiveBgColor = "linear-gradient(90deg, rgba(240, 240, 240, 0.85) 0%, rgb(255, 255, 255, 0.9) 50%, rgba(240, 240, 240, 0.85) 100%)";
        return (
            <>
                <Table.Row
                    key={message.id}
                    verticalAlign="top"
                    style={{
                        fontWeight: message.read ? 100 : 700,
                        background: this.state.showMoreInfo ? activeBgColor : inactiveBgColor,
                    }}
                    onClick={this.toggleShowMoreInfo}
                >
                    <Table.Cell collapsing textAlign="center" className="InboxTableCellBorder">
                        {message.isSystemMessage ?
                            <Icon name='setting' size="small" circular />
                            :
                            <div style={{ width: 20 }}>
                                <UserAvatar user={message.fromUser} />
                            </div>
                        }
                    </Table.Cell>
                    <Table.Cell collapsing className="InboxTableCellBorder">
                        {message.isSystemMessage ? "System" : translateUsername(message.fromUser)}
                    </Table.Cell>
                    <Table.Cell collapsing className="InboxTableCellBorder">{formatDate(message.created, "cccccc P")} - {formatDate(message.created, "p")}</Table.Cell>
                    <Table.Cell className="InboxTableCellBorder">{getTranslated(message.subject)}</Table.Cell>
                    <Popup className='ComUserPopup' content={message.read ? this.props.t("mark message as unread") : this.props.t("mark message as read")} trigger={
                        <Table.Cell collapsing verticalAlign="middle" onClick={this.props.toggleRead(message)} selectable textAlign="center" className="InboxTableCellBorder InboxTableCellCursor" >
                            <Icon color={message.read ? undefined : "grey"} name={message.read ? "envelope" : "envelope open outline"} style={{ margin: "0 10px" }} />
                        </Table.Cell>
                    } />
                    {message.actions && message.actions.length === 0 ?
                        <Popup className='ComUserPopup' content={message.done ? this.props.t("mark message as undone") : this.props.t("mark message as done")} trigger={
                            <Table.Cell collapsing verticalAlign="middle" onClick={this.props.toggleDone(message)} selectable textAlign="center" className="InboxTableCellBorder InboxTableCellCursor">
                                <Icon color={message.done ? undefined : "grey"} name={message.done ? "check square outline" : "square outline"} style={{ margin: "0 10px" }} />
                            </Table.Cell>
                        } /> : <Table.Cell className="InboxTableCellBorder" />
                    }
                    <Popup className='ComUserPopup' content={this.state.showMoreInfo ? this.props.t("show less informations") : this.props.t("show more informations")} trigger={
                        <Table.Cell collapsing verticalAlign="middle" selectable textAlign="center" className="InboxTableCellBorder InboxTableCellCursor" onClick={this.toggleShowMoreInfo}>
                            <Icon name={this.state.showMoreInfo ? "chevron up" : "chevron down"} style={{ margin: "0 10px" }} />
                        </Table.Cell>
                    } />
                </Table.Row>
                {this.state.showMoreInfo &&
                    <>
                        <Table.Row
                            verticalAlign="top"
                            style={{
                                background: activeBgColor,
                                marginTop: 0,
                                paddingTop: 0,
                            }}
                        >
                            <Table.Cell style={{ borderTop: "none" }} rowSpan='1' />
                            <Table.Cell colSpan='2' rowSpan='1' style={{ borderTop: "none" }}>
                                <List style={{ marginTop: 0 }}>
                                    {message.toUsers.length >= 1 && _.map(message.toUsers, u => {
                                        return (
                                            <List.Item key={u.id}>
                                                <List.Icon name='user' style={{ paddingRight: 8 }} />
                                                <List.Content>
                                                    {translateUsername(u)} (user role)
                                                </List.Content>
                                            </List.Item>
                                        )
                                    })}
                                    {message.toGroups.length >= 1 && _.map(message.toGroups, g => {
                                        return (
                                            <List.Item key={g.id}>
                                                <List.Icon name='users' />
                                                <List.Content>
                                                    {g.name}
                                                </List.Content>
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            </Table.Cell>
                            <Table.Cell colSpan='3' style={{ borderTop: "none" }}>
                                <div className="InboxTableMessageBox">
                                    {splitText(bodyTxt)}
                                </div>
                                <div>
                                    <Popup className='ComUserPopup' content={this.props.t("delete message")} trigger={
                                        <Button color="red" icon="trash" floated="left" onClick={this.toggleShowModalDelete} />
                                    } />
                                    <Popup className='ComUserPopup' content={this.state.showHistory ? this.props.t("hide history") : this.props.t("show history")} trigger={
                                        <Button color="red" icon="history" style={{ marginBottom: 10 }} content={this.state.showHistory ? this.props.t("hide history") : this.props.t("show history")} floated="left" onClick={this.toggleShowHistory} />
                                    } />
                                    {_.map(message.actions, (a, i) => {
                                        return (
                                            <Popup key={i} className='ComUserPopup' content={a.description} trigger={
                                                <Button color="red" content={a.caption} floated="right" />
                                            }
                                            />
                                        )
                                    })
                                    }
                                    {this.state.showHistory &&
                                        <div>
                                            <Table basic='very' compact striped collapsing>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell collapsing>03.02.2020</Table.Cell>
                                                        <Table.Cell collapsing>Hans Dampf</Table.Cell>
                                                        <Table.Cell collapsing>user accepted</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>01.02.2020</Table.Cell>
                                                        <Table.Cell>Peter Lustig</Table.Cell>
                                                        <Table.Cell>user not accepted</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>17.01.2020</Table.Cell>
                                                        <Table.Cell>Heidi Kabel</Table.Cell>
                                                        <Table.Cell>user accepted</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>15.01.2020</Table.Cell>
                                                        <Table.Cell>Bud Spencer</Table.Cell>
                                                        <Table.Cell>user accepted</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    }
                                </div>
                            </Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }} />
                        </Table.Row>
                    </>
                }
            </>
        )
    }
    private toggleShowMoreInfo = () => {
        this.setState({ showMoreInfo: !this.state.showMoreInfo });
        this.setState({ showHistory: false });
    }
    private toggleShowHistory = () => {
        this.setState({ showHistory: !this.state.showHistory });
    }
    private toggleShowModalDelete = () => {
        this.props.deleteMessage(this.props.message);
    }
}
export default translate("inbox")(InAppMessage);
