import * as React from 'react';
import { IPostInputFieldComponentProps } from '../PostInput/PostInput';
import { SemanticICONS } from 'semantic-ui-react';
import ToggleButton from '../Buttons/ToggleButton';

interface IBoolEditProps extends IPostInputFieldComponentProps {
    initialValue?: boolean;
    label?: string;
    offIcon?: SemanticICONS;
    onIcon?: SemanticICONS;
}

class BoolEdit extends React.Component<IBoolEditProps, any> {
    public UNSAFE_componentWillMount() {
        if (this.props.value === undefined && this.props.initialValue !== undefined) {
            this.props.onValueChanged(this.props.initialValue);
        }
    }

    public UNSAFE_componentWillReceiveProps(props: IBoolEditProps) {
        if (props.value === undefined && props.initialValue !== undefined) {
            props.onValueChanged(props.initialValue);
        }
    }
    public render() {
        const val = this.props.value !== undefined && (this.props.value === "true" || this.props.value === true) ? true : false;
        const call = () => this.props.onValueChanged(!val);
        return (
            <ToggleButton
                onChange={call}
                value={val}
                onIcon={this.props.onIcon}
                offIcon={this.props.offIcon}
            />
        );
    }
}

export default BoolEdit;
