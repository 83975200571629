import ContentAggregationOverview from 'components/ContentAggregation/ContentAggregationOverview';
import PluginContainer from 'components/General/PluginContainer/PluginContainer';
import LinkPresenter from 'components/LinkPresenter/LinkPresenter';
import * as _ from "lodash";
import * as React from 'react';
import { lazy, Suspense } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AdminInbox from 'views/Admin/Messaging/AdminInbox';
import PluginLandingPage from 'views/Plugin/PluginLandingPage';
import PNHandler from 'views/PNHandler';
import ScormLegalNotes from 'views/Scorm/ScormLegalNotes';
import ScormLogin from 'views/Scorm/ScormLogin';
import TestMob from 'views/TestIt/TestMob';
import TubeVideoLight from 'views/Tube/TubeVideoLight';
import LoadingImport from './components/General/LoadingImport/LoadingImport';
import LoginStd from './components/General/Login/Login';
import LoginLampertheim from './components/General/Login/LoginLampertheim';
import Unauthorized from './components/General/Unauthorized';
import { IIMGTranslatedComponent, translate } from './components/Utils/img-i18n';
import { ActionCreators } from './services/Actions';
import AuthorizedRoute from './services/AuthorizedRoute';
import { config } from './services/Config';
import GroupHub from './services/GroupHub';
import { getBrowser } from './services/Helpers';
import { IConnectedComponent, IState } from './services/Interfaces';
import ScormAppLoader from "./views/Scorm/ScormAppLoader";
import AssetManagement from 'views/AssetManagement/AssetManagement';
import AssetManagementAdd from 'views/AssetManagement/AssetManagementAdd';
import Imprint from 'views/General/Imprint';
import ExciteStats from 'views/Excite/ExciteStats';


const WaitingComponent = (Component: any, Loader: any) => {
    return (props: any) => (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
}
let lastDom: string | undefined = "";
let lastTitle: string | undefined = "";
export const setupForDomain = (dom?: string, title?: string) => {
    if (lastDom === dom && lastTitle === title)
        return;
    lastDom = dom;
    lastTitle = title;
    if (!dom && !title)
        return;
    if (title)
        document.title = title;
    //    console.log(document.head.children);
    // eslint-disable-next-line
    const regex = /(\/icons\/)([^\/]*)\//igm;
    if (dom)
        _.forEach(document.head.children, c => {
            if (c.tagName === "LINK") {
                const link: HTMLLinkElement = c as HTMLLinkElement;
                //                console.log("-------------------------------------------");
                //                console.log(link.href);
                const r = regex.exec(link.href);
                //                console.log(r);
                if (r) {
                    const nVal = link.href.replace(regex, `$1${dom}/`);
                    //                    console.log(" ========> ", nVal);
                    link.setAttribute("href", nVal);
                }

                if (link.rel === "manifest") {
                    link.setAttribute("href", `/manifest_${dom}.json`);
                }
            }
        });
}

const TaycanLogin = WaitingComponent(lazy(() => import("views/TaycanPcm/TaycanPcmLogin")), LoadingImport);

const getLoginAndSetupBrowserLook = () => {
    // eslint-disable-next-line
    const getUrl = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
    const curServer = getUrl.exec(document.location.href) ?? ["", "localhost"];
    const srv = curServer[1].toLowerCase();
    if (srv.indexOf("lampertheim.azurewebsites.net") >= 0) {
        document.title = "Lampertheim - Bewerberplattform";
        config.adminFeatures.groupManagement = false;
        config.adminFeatures.groupManagementInvite = false;
        config.termsOfUse = false;
        config.feedback = false;
        config.changeLanguage = false;
        config.hideFeed = true;
        config.adminFeatures.createNews = false;
        config.adminFeatures.exportContent = false;
        return LoginLampertheim;
    }
    else if (srv.indexOf("dealer-sales-journey.online") >= 0) {
        setupForDomain("dsj", "Dealer Sales Journey");
        return ScormLogin;
    }
    else if (srv.indexOf("excite.digital") >= 0) {
        setupForDomain("excite", "excite!");
    }
    else if (srv.indexOf("quiz-app.digital") >= 0) {
        setupForDomain("quiz", "Quiz App!");
    }
    else if (srv.indexOf("community-app.digital") >= 0) {
        setupForDomain("community", "Community App!");
    }
    else if (srv.indexOf("porsche-retail-impulse.com") >= 0) {
        setupForDomain("pri", "Porsche Reatil Impulse!");
    }
    else if (srv.indexOf("taycan-pcm.smart-mobility.info") >= 0) {
        setupForDomain("lp", "Smart Mobility World!");
        return TaycanLogin;
    }
    else if (srv.indexOf("smart-mobility.info") >= 0) {
        setupForDomain("lp", "Smart Mobility World!");
    }
    else if (srv.indexOf("porsche-tube.com") >= 0) {
        setupForDomain("tube", "Porsche Tube");
    }
    else if (srv.indexOf("localhost") >= 0 || srv.indexOf("192.168.178.56") >= 0) {
        setupForDomain("community", "Community App!");
    }
    return LoginStd;
}

const Login = getLoginAndSetupBrowserLook();

const Statistics = WaitingComponent(lazy(() => import('./views/Statistics/Statistics')), LoadingImport);

const BecomeUser = WaitingComponent(lazy(() => import('./views/Admin/BecomeUser')), LoadingImport);

const StatTest = WaitingComponent(lazy(() => import('./views/TestIt/StatTest')), LoadingImport);

const TestIt3 = WaitingComponent(lazy(() => import('./views/TestIt/TestIt3')), LoadingImport);

const ManageSlideShow = WaitingComponent(lazy(() => import('./components/SlideShow/ManageSlideShow')), LoadingImport);
const EditSlide = WaitingComponent(lazy(() => import('./components/SlideShow/EditSlide')), LoadingImport);

const Unsupported = WaitingComponent(lazy(() => import('./components/General/Unsupported')), LoadingImport);

const ExciteModal = WaitingComponent(lazy(() => import('./components/General/PopupModal/PopupModal')), LoadingImport);
const HLPMain = WaitingComponent(lazy(() => import('./views/HyperLandingPage/HLPMain')), LoadingImport);

const ChooseLocation = WaitingComponent(lazy(() => import('./components/Posts/PorscheMomentPost/Create/ChooseLocation')), LoadingImport);

const UISteps = WaitingComponent(lazy(() => import('./components/General/UISteps/UISteps')), LoadingImport);

const TranslateApp = WaitingComponent(lazy(() => import('./views/Admin/TranslateApp/TranslateApp')), LoadingImport);


const Invite = WaitingComponent(lazy(() => import('./views/Admin/Invite/Invite')), LoadingImport);

const ExcelInvite = WaitingComponent(lazy(() => import('./views/Admin/Invite/ExcelInvite')), LoadingImport);

const LandingPageMainView = WaitingComponent(lazy(() => import('./views/LandingPageMainView/LandingPageMainView')), LoadingImport);
const NewLandingPageMainView = WaitingComponent(lazy(() => import('./views/LandingPageMainView/NewLandingPageMainView')), LoadingImport);

const TestIt1 = WaitingComponent(lazy(() => import('./views/TestIt/TestIt1')), LoadingImport);

const TestIt2 = WaitingComponent(lazy(() => import('./views/TestIt/TestIt2')), LoadingImport);

const QuizResult = WaitingComponent(lazy(() => import('./views/Quiz/Result')), LoadingImport);


const GroupManagement = WaitingComponent(lazy(() => import('./views/Admin/GroupManagement/GroupManagement')), LoadingImport);

const PostCloner = WaitingComponent(lazy(() => import('./components/Posts/PostCloner/PostCloner')), LoadingImport);

const Open = WaitingComponent(lazy(() => import('./components/General/Open')), LoadingImport);

const Community = WaitingComponent(lazy(() => import('./views/Community/Community')), LoadingImport);

const SlideshowViewPublic = WaitingComponent(lazy(() => import('./views/SlideShow/SlideShowPublic')), LoadingImport);

const SlideshowNotPublic = WaitingComponent(lazy(() => import('./views/SlideShow/SlideShowNotPublic')), LoadingImport);

const SlideshowView = WaitingComponent(lazy(() => import('./views/SlideShow/Slideshow')), LoadingImport);

const CreateGame = WaitingComponent(lazy(() => import('./views/Quiz/CreateGame')), LoadingImport);

const QuizHome = WaitingComponent(lazy(() => import('./views/Quiz/QuizHome')), LoadingImport);

const QuizDetail = WaitingComponent(lazy(() => import('./views/Quiz/QuizDetail')), LoadingImport);

const JoinGame = WaitingComponent(lazy(() => import('./views/Quiz/JoinGame')), LoadingImport);

const AddPostRoute = WaitingComponent(lazy(() => import('./components/General/AddPostRoute/AddPostRoute')), LoadingImport);

const Mobile = WaitingComponent(lazy(() => import('./components/General/Mobile/Mobile')), LoadingImport);

const ScormOverview = WaitingComponent(lazy(() => import('./views/Scorm/ScormOverview')), ScormAppLoader);

const ScormPlayer = WaitingComponent(lazy(() => import('./views/Scorm/ScormPlayer')), ScormAppLoader);

const TubeVideo = WaitingComponent(lazy(() => import('views/Tube/TubeVideo')), LoadingImport);

const Tube = WaitingComponent(lazy(() => import('views/Tube/Tube')), LoadingImport);

const BestPractice = WaitingComponent(lazy(() => import('views/BestPractice/BestPracticeMain')), LoadingImport);

const AddBestPractice = WaitingComponent(lazy(() => import('views/BestPractice/AddBestPractice')), LoadingImport);

const BestPracticeDetail = WaitingComponent(lazy(() => import('views/BestPractice/BestPracticeDetail')), LoadingImport);

const SignUp = WaitingComponent(lazy(() => import("views/SignUp/SignUp")), LoadingImport);

const ConfirmSignUp = WaitingComponent(lazy(() => import("views/SignUp/ConfirmSignUp")), LoadingImport);

const GroupSignUp = WaitingComponent(lazy(() => import("views/SignUp/GroupSignUp")), LoadingImport);

const GroupConfirmSignUp = WaitingComponent(lazy(() => import("views/SignUp/GroupConfirmSignUp")), LoadingImport);

const RegisterRequests = WaitingComponent(lazy(() => import("views/SignUp/Admin/RegisterRequests")), LoadingImport);

const SetPasswordView = WaitingComponent(lazy(() => import("views/Platform/SetPasswordView")), LoadingImport);

const TokenLogin = WaitingComponent(lazy(() => import('services/TokenLogin')), LoadingImport);

interface InnerAppState {
    loadingDone: boolean;
}

class InnerApp extends React.Component<IConnectedComponent & IIMGTranslatedComponent, InnerAppState>
{
    private timeoutId?: any;

    public constructor(props: IConnectedComponent & IIMGTranslatedComponent) {
        super(props);
        this.timerFunction = this.timerFunction.bind(this);
        this.state = { loadingDone: true };
    }

    public componentDidMount() {
        this.props.dispatch(ActionCreators.SetDebugging(false));
        if (!this.timeoutId)
            this.timeoutId = setInterval(this.timerFunction, 60 * 60 * 1000);
        // const pluginRegistry = PluginRegistry.getInstance();
        // //pluginRegistry.addPluginToLoad("porscheicons", true);
        // pluginRegistry.addPluginToLoad("quiz", true);
        // pluginRegistry.loadPlugins({
        //     store
        // }).then(() => {
        //     // setTimeout(() => this.setState({ loadingDone: true }), 2000);
        //     setTimeout(() => this.setState({ loadingDone: true }), 0);
        // },
        //     () => this.setState({ loadingDone: true }));
    }

    public render() {
        if (this.props.state.userInfo && this.props.state.userInfo.userSettings.language) {
            const curLng = this.props.i18n.language;
            const ele = document.body;
            ele.setAttribute("lang", curLng);
            setDefaultLocale(curLng);
            if (curLng !== this.props.state.userInfo.userSettings.language)
                this.props.i18n.changeLanguage(this.props.state.userInfo.userSettings.language);
        }
        if (!this.state.loadingDone)
            return <div />;
        const browser = getBrowser();
        const showModal = browser.name === "ieeeee" && !this.props.state.warnedAboutUnsupported;
        if (showModal)
            return (
                <ExciteModal
                    open={true}
                    buttonClick={this.unsupportedClosed}
                    onClose={this.unsupportedClosed}
                    closable={false}
                >
                    <Unsupported onClose={this.unsupportedClosed} />
                </ExciteModal>
            );
        else {
            return (
                <Router>
                    <Switch>
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.grouphub + "/:groupId?"}
                            component={GroupHub}
                        />
                        {/* ContentAggregationOverview ***************************************************************************/}
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.contentAggregation.overview + "/:gid?"}
                            component={ContentAggregationOverview}
                        />
                        <AuthorizedRoute
                            path={config.routes.general.pushNotificationHandler + "/:id"}
                            exact={true}
                            component={PNHandler}
                            state={this.props.state}
                        />
                        {/* ADMIN ***************************************************************************/}
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.invitation}
                            component={Invite}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.excelInvitation}
                            component={ExcelInvite}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.translateAdmin}
                            component={TranslateApp}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.groupsAdmin}
                            component={GroupManagement}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.general.postsAdmin}
                            component={PostCloner}
                        />

                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path="/open/:itemId"
                            component={Open}
                        />
                        {/* ADMIN ***************************************************************************/}
                        <Route
                            path={config.routes.general.login + "/:token?"}
                            exact={true}
                            component={Login}
                        />
                        <Route
                            path={config.routes.general.tokenLogin + "/:token/:to?"}
                            exact={true}
                            component={TokenLogin}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            path={"/imprint"}
                            exact={true}
                            component={Imprint}
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            path={config.routes.excite.stats}
                            exact={true}
                            component={ExciteStats}
                        />
                        <Route
                            path="/"
                            exact={true}
                            component={Login}
                        />
                        <Route
                            path="/mobile/:prefix/:token?"
                            exact={true}
                            component={Mobile}
                        />
                        {process.env.NODE_ENV !== "production" &&
                            <Route
                                path="/map"
                                exact={true}
                                component={ChooseLocation}
                            />
                        }
                        <Route
                            path="/unauthorized"
                            exact={true}
                            component={Unauthorized}
                        />

                        <AuthorizedRoute
                            path="/uisteps/:stepid/:token"
                            exact={true}
                            component={UISteps}
                            state={this.props.state}
                        />
                        <AuthorizedRoute
                            path={config.routes.assets.mgt}
                            exact={true}
                            component={AssetManagement}
                            state={this.props.state}
                        />
                        <AuthorizedRoute
                            path={config.routes.assets.add}
                            exact={true}
                            component={AssetManagementAdd}
                            state={this.props.state}
                        />
                        <Route
                            path={config.routes.platform.resetPassword + "/:token?"}
                            exact={true}
                            component={SetPasswordView}
                        />
                        {/* LANDINGPAGE */}

                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.landingPage.main + "/:groupId?/:postId?/:linkSource?"}
                            component={LandingPageMainView}
                            dom="lp"
                            title="Smart Mobility World"
                        />
                        <AuthorizedRoute
                            state={this.props.state}
                            exact={true}
                            path={config.routes.landingPage.new + "/:groupId?/:postId?/:linkSource?"}
                            component={NewLandingPageMainView}
                            dom="lp"
                            title="Smart Mobility World"
                        />
                        <Route
                            path="/welcome/:stepid/:token"
                            exact={true}
                            component={UISteps}
                        />
                        <Route
                            path="/reset/:stepid/:token"
                            exact={true}
                            component={UISteps}
                        />
                        {process.env.NODE_ENV !== "production" &&
                            <Route
                                path="/testMob"
                                exact={true}
                                component={TestMob}
                            />
                        }
                        {process.env.NODE_ENV !== "production" &&
                            <Route
                                path="/test1"
                                exact={true}
                                component={TestIt1}
                            //   state={this.props.state}
                            />
                        }
                        <AuthorizedRoute
                            path="/test3/:id?"
                            exact={true}
                            component={TestIt3}
                            state={this.props.state}
                        />
                        <AuthorizedRoute
                            path={config.routes.tube.video + "/:id"}
                            exact={true}
                            component={TubeVideo}
                            state={this.props.state}
                            dom="tube"
                            title="Porsche Tube"
                        />
                        <AuthorizedRoute
                            path={config.routes.tube.main + "/:gid?/:only?"}
                            exact={true}
                            component={Tube}
                            state={this.props.state}
                            dom="tube"
                            title="Porsche Tube"
                        />
                        <AuthorizedRoute
                            path={config.routes.tube.videolight + "/:id"}
                            exact={true}
                            component={TubeVideoLight}
                            state={this.props.state}
                            dom="tube"
                            title="Porsche Tube"
                        />
                        {process.env.NODE_ENV !== "production" &&
                            <AuthorizedRoute
                                path="/test2"
                                exact={true}
                                component={TestIt2}
                                state={this.props.state}
                            />
                        }
                        <Route
                            path="/ppn/:token"
                            exact={true}
                            component={Login}
                        />

                        <AuthorizedRoute
                            path="/res"
                            exact={true}
                            component={QuizResult}
                            state={this.props.state}
                            dom="quiz"
                            title="Quiz App!"
                        />
                        {process.env.NODE_ENV !== "production" &&
                            <AuthorizedRoute
                                path="/statstest"
                                exact={true}
                                component={StatTest}
                                state={this.props.state}
                            />
                        }
                        <AuthorizedRoute
                            path="/stats"
                            exact={true}
                            component={Statistics}
                            state={this.props.state}
                        />

                        {/* quiz */}

                        <AuthorizedRoute
                            path={config.routes.quiz.quiz + "/:id/:mode?"}
                            exact={true}
                            component={CreateGame}
                            state={this.props.state}
                            dom="quiz"
                            title="Quiz App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.quiz.join + "/:gameid"}
                            exact={true}
                            component={JoinGame}
                            state={this.props.state}
                            dom="quiz"
                            title="Quiz App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.quiz.quizFeed + "/:id?"}
                            exact={true}
                            component={QuizHome}
                            state={this.props.state}
                            dom="quiz"
                            title="Quiz App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.quiz.detail + "/:quizId"}
                            exact={true}
                            component={QuizDetail}
                            state={this.props.state}
                            dom="quiz"
                            title="Quiz App!"
                        />

                        {/* community */}

                        <AuthorizedRoute
                            path={config.routes.community.createPost}
                            exact={true}
                            component={AddPostRoute}
                            state={this.props.state}
                            dom="excite"
                            title="excite!"
                        />

                        <AuthorizedRoute
                            path={config.routes.community.main + "/:target?/:id?"}
                            exact={true}
                            component={Community}
                            state={this.props.state}
                            dom="excite"
                            title="excite!"
                        />

                        {/* Hyperlandingpage */}
                        <Route
                            path={config.routes.hyperlandingpage.main}
                            exact={true}
                            component={HLPMain}
                        />

                        {/* SLIDESHOW */}
                        <Route
                            path={config.routes.slideshow.slidePublic + "/:slideShowId/:accessTokenId/:index?"}
                            exact={true}
                            component={SlideshowViewPublic}
                        //dom="community"
                        //title="Community App!"
                        />
                        <Route
                            path={config.routes.slideshow.slideNotPublic}
                            exact={true}
                            component={SlideshowNotPublic}
                        //dom="community"
                        //title="Community App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.slideshow.slide + "/:id/:index?"}
                            exact={true}
                            component={SlideshowView}
                            state={this.props.state}
                            dom="community"
                            title="Community App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.slideshow.adminSlideShow + "/:id"}
                            exact={true}
                            component={ManageSlideShow}
                            state={this.props.state}
                            dom="community"
                            title="Community App!"
                        />
                        <AuthorizedRoute
                            path={config.routes.slideshow.adminSlide + "/:slideShowId/:slideId"}
                            exact={true}
                            component={EditSlide}
                            state={this.props.state}
                            dom="community"
                            title="Community App!"
                        />
                        <Route
                            path={config.routes.general.becomeUser + "/:token"}
                            exact={true}
                            component={BecomeUser}
                        />

                        {/* scorm */}
                        <AuthorizedRoute
                            path={config.routes.scorm.overview + "/:id?"}
                            exact={true}
                            component={ScormOverview}
                            state={this.props.state}
                            dom="dsj"
                            title="Dealer Sales Journey!"
                        />
                        <Route
                            path={config.routes.scorm.player + "/:token/:regId"}
                            exact={true}
                            component={ScormPlayer}
                        // dom="dsj"
                        // title="Dealer Sales Journey!"
                        />
                        <Route
                            path={config.routes.scorm.legal}
                            exact={true}
                            component={ScormLegalNotes}
                        // dom="dsj"
                        // title="Dealer Sales Journey!"
                        />
                        <Route
                            path={config.routes.general.linkPresenter}
                            exact={true}
                            component={LinkPresenter}
                        />
                        {/* messagin */}
                        <AuthorizedRoute
                            path={config.routes.messaging.inbox}
                            exact={true}
                            component={AdminInbox}
                            state={this.props.state}
                        />

                        {/* BestPractice */}
                        <AuthorizedRoute
                            path={config.routes.bestpractice.main + "/:id?"}
                            exact={true}
                            component={BestPractice}
                            state={this.props.state}
                            dom="pri"
                            title="Porsche Retail Impulse"
                        />
                        <AuthorizedRoute
                            path={config.routes.bestpractice.add + "/:id?"}
                            exact={true}
                            component={AddBestPractice}
                            state={this.props.state}
                            dom="pri"
                            title="Porsche Retail Impulse"
                        />
                        <AuthorizedRoute
                            path={config.routes.bestpractice.detail + "/:id/:lng?"}
                            exact={true}
                            component={BestPracticeDetail}
                            state={this.props.state}
                            dom="pri"
                            title="Porsche Retail Impulse"
                        />

                        {/* SignUp */}
                        <Route
                            path={config.routes.signUp.signUp + "/:groupId"}
                            exact
                            component={SignUp}
                        />

                        <Route
                            path={config.routes.signUp.confirmSignUp + "/:id?"}
                            exact
                            component={ConfirmSignUp}
                        />
                        <Route
                            path={config.routes.general.signUp + "/:groupId"}
                            exact
                            component={GroupSignUp}
                        />
                        <Route
                            path={config.routes.general.confirmSignUp + "/:id?"}
                            exact
                            component={GroupConfirmSignUp}
                        />

                        <AuthorizedRoute
                            path={config.routes.signUp.registerRequests}
                            exact={true}
                            component={RegisterRequests}
                            state={this.props.state}
                        />

                        {/* Plugin */}
                        <AuthorizedRoute
                            path={config.routes.plugin.main}
                            exact={true}
                            component={PluginLandingPage}
                            state={this.props.state}
                            dom="pcm"
                            title="Porsche Connect Tutorial"
                        />
                        {_.map(this.props.state.appEntries, (a, k) => {
                            const r = "/plugin" + (a.route.startsWith("/") ? "" : "/") + a.route;
                            //console.log("adding route for " + r, " name = ", a.routeName);
                            //                          console.log(this.props.state.userInfo);
                            if (a.noLogin)
                                return (
                                    <Route
                                        key={r}
                                        path={r}
                                        exact={true}
                                        render={() => <PluginContainer data={a} />}
                                    />
                                );
                            else {
                                return (
                                    <AuthorizedRoute
                                        key={"a-" + r}
                                        path={r}
                                        exact={true}
                                        render={() => <PluginContainer data={a} />}
                                        state={this.props.state}
                                    />
                                );
                            }
                        })}
                        <Route component={Open} />
                    </Switch>
                </Router >
            );
        }
    }

    private unsupportedClosed = () => {
        this.props.dispatch(ActionCreators.SetWarnedAboutUnsupported(true));
    }

    private timerFunction() {
        this.props.dispatch(ActionCreators.globalState.GetUserinfo());
    }
}
export default translate()(connect((state: IState) => ({ state }))(InnerApp));
