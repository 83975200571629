import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router';
// router imports
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { config } from "./Config";
import { IState } from "./Interfaces";
import { setupForDomain } from "InnerApp";



interface IAuthorizedRouteProps extends RouteProps {
    /**
     * The communication channel to the back end.
     * Used to check whether authentication of the uesr.
     */
    dom?: string;
    title?: string;
    state: IState;

    /** Properties to forward to the given component after authorization. */
    componentProps?: object;

    unauthorized?: string;
    loginRoute?: string;
}

/** A Route wrapper that requires authorizarion before showing the given component. */
const AuthorizedRoute: React.SFC<IAuthorizedRouteProps & RouteComponentProps<any>> = (authRouteProps) => {
    const { dom, title, component: Component, render, ...rest } = authRouteProps;
    setupForDomain(dom, title);
    return (
        <Route
            // React router ignores 'render' if 'component' is available; forward all RouteProps
            // except 'component' because we want to use our own 'render' method.
            {...rest}
            render={(routeComponentProps: RouteComponentProps<any>): React.ReactNode => {
                const combinedProps = Object.assign(
                    {},
                    routeComponentProps,
                    authRouteProps.componentProps
                );
                config.debug && console.log(authRouteProps)
                const lr = authRouteProps.loginRoute ? authRouteProps.loginRoute : config.routes.general.login;
                if (authRouteProps.state.userInfo) {
                    if (Component)
                        return <Component {...combinedProps} />;
                    if (render)
                        return render(combinedProps);
                }
                return <Redirect to={{ pathname: (authRouteProps.unauthorized ? authRouteProps.unauthorized : lr), state: { from: routeComponentProps.location } }} />
            }}
        />
    );
};

export default withRouter(AuthorizedRoute);
/* tslint:enable */