import { LoginController } from 'collaboration-service';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Input, Loader, Modal, Segment } from 'semantic-ui-react';
import { isEmailAddress } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';

interface RequestPasswordResetProps extends IIMGTranslatedComponent, IConnectedComponent {
    username: string;
    open?: boolean;
    onClose: () => void;
}

interface RequestPasswordResetState {
    username: string;
    waiting: boolean;
    success: boolean;
}

class RequestPasswordReset extends React.Component<RequestPasswordResetProps, RequestPasswordResetState> {
    constructor(props: RequestPasswordResetProps) {
        super(props);
        this.state = {
            username: props.username,
            waiting: false,
            success: false
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: RequestPasswordResetProps) {
        if (this.props.username !== nextProps.username)
            this.setState({ username: nextProps.username, waiting: false, success: false });
        else
            if (this.props.open !== nextProps.open)
                this.setState({ waiting: false, success: false });
    }

    public render() {
        const { t } = this.props;
        return (
            <Modal open={this.props.open} basic>
                <Dimmer active={this.state.waiting}>
                    <Loader active={this.state.waiting} />
                </Dimmer>
                <Segment className="Headline">
                    {t("password reset")}
                </Segment>
                <Segment textAlign='center' className="LoginSegment" >
                    {!this.state.success &&
                        <Form>
                            <Form.Field>
                                <Input
                                    className="LoginInput"
                                    autoComplete="email"
                                    onKeyDown={this.handleUser}
                                    onChange={this.updateUser}
                                    icon='user'
                                    iconPosition='left'
                                    placeholder={t('Username')}
                                    value={this.state.username} />
                            </Form.Field>
                            <Button disabled={!isEmailAddress(this.state.username)} onClick={this.requestPassword} primary={true}>{t('send password request')}</Button>
                            <Button onClick={this.props.onClose} primary>{t('cancel')}</Button>
                        </Form>
                    }
                    {this.state.success &&
                        <>
                            <div style={{ marginBottom: 10, fontSize: "13   0%" }}>
                                {t("an reset password email was sent to your email address (if you don't get an email, please check your spam folder)")}
                            </div>
                            <Button onClick={this.props.onClose} primary>{t("ok")}</Button>
                        </>
                    }
                </Segment>
            </Modal>

        );
    }

    private requestPassword = () => {
        if (isEmailAddress(this.state.username)) {
            this.setState({ waiting: true });
            LoginController.LoginReset({ login: { login: this.state.username, password: "" } }, this.success, this.success);
        }
    }

    private handleUser = (e: any) => {
        if (e.which === 13) {
            e.preventDefault();
            this.requestPassword();
        }
    }

    private updateUser = (e: any) => {
        this.setState({ username: e.target.value });
    }

    private success = () => {
        this.setState({ waiting: false, success: true });
    }
}


export default translate("collaboration")(connect((state: IState) => ({ state }))(RequestPasswordReset));