import { fadeColor, getColor, Icon, ImgIcons, lightenColor, styled, Theme } from "imaginarity-react-ui";
import * as React from "react";
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { printBytes } from "services/Helpers";
import { StyledComponent } from "styled-components";
import { Col } from "./AssetManagementTube";

export const FileEntry = styled.div<{ altColor?: boolean }>`
    width: 100%;
    height: 36px;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 18px 1fr 1fr;
    -ms-grid-columns: 18px 1fr 1fr;
    background-color: ${p => p.altColor ? fadeColor(p, getColor(p, p.theme.colors.darkGrey), 3) : fadeColor(p, getColor(p, p.theme.colors.accentBlack), 3)};
    position: relative;
    margin-top: 1px;
`;

const Progress = styled.div<{ progress: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: ${p => p.progress}%;
    height: 36px;
    background-color: ${p => fadeColor(p, getColor(p, p.theme.colors.darkGrey), 20)};
    z-index: 1;
`;

interface UploadFileEntryProps {
    index: number;
    progress?: number;
    file: File;
    draggableProps?: DraggableProvidedDraggableProps;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    innerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;
    style?: React.CSSProperties;
    icon: ImgIcons;
    container?: StyledComponent<"div", Theme, { altColor?: boolean | undefined; }, never>;
}

const UploadFileEntry = (p: UploadFileEntryProps) => {
    const C = p.container ?? FileEntry;
    return (
        <C
            ref={p.innerRef}
            {...p.draggableProps}
            {...p.dragHandleProps}
            altColor={p.index % 2 === 1}
            key={p.file.name}
            style={p.style}
        >
            {p.progress !== undefined &&
                <Progress progress={p.progress} />
            }
            <Col col={1} padding="5px" style={{ zIndex: 2 }}>
                <Icon size={18} name={p.icon} />
            </Col>
            <Col col={2} padding="8px 10px" style={{ zIndex: 2 }}>
                {p.file.name}
            </Col>
            {p.progress !== undefined && p.progress > 0 &&
                <Col col={3} padding="8px 1px" style={{ zIndex: 2, textAlign: "right" }}>
                    {`${Math.floor(p.progress)}% (${printBytes(Math.floor(p.file.size * p.progress * 0.01))} /  ${printBytes(p.file.size)})`}
                </Col>
            }
        </C>
    )
}
export default UploadFileEntry;