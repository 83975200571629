import { GroupDownloadDto } from "collaboration-service";
import UsersGroupList from 'components/General/UsersGroupList/UsersGroupList';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Header, InputOnChangeData, Modal, ModalProps } from 'semantic-ui-react';
import { detectMobileDevice } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';

interface UserCommunitiesProps extends ModalProps, IConnectedComponent, IIMGTranslatedComponent {
    close: () => void;
    onEdit?: (group: GroupDownloadDto) => void;
    onDelete?: (group: GroupDownloadDto) => void;
}

interface UserCommunitiesState {
    filter: string;
}

class UserCommunities extends React.Component<UserCommunitiesProps, UserCommunitiesState> {
    constructor(props: UserCommunitiesProps) {
        super(props);

        this.state = {
            filter: ""
        };
    }

    public render() {
        const { close, dispatch, t, ...rest } = this.props;
        const user = this.props.state.userInfo;
        const groups = this.props.state.groups;
        if (!user || !groups)
            return <></>;
        return (
            <Modal {...rest} size={detectMobileDevice() ? "fullscreen" : undefined}>
                <Header>
                    {this.props.t("View your permissions")}
                </Header>
                <Modal.Actions>
                    <Form.Input width="14" fluid icon='filter' placeholder={this.props.t("... limit your search by filtering")} onChange={this.filter} />
                </Modal.Actions>
                <Modal.Content scrolling style={{ minHeight: "65vh" }}>
                    <p>
                        {this.props.t("This is an overview of all communities with your user roles you belong to. With the filter in the lower area, you can limit your search.")}
                    </p>
                    <UsersGroupList groups={groups.elements} user={user} filter={this.state.filter} />
                </Modal.Content>
                <Modal.Actions>
                    <Form.Button color="red" icon='times' onClick={this.props.close} />
                </Modal.Actions>
            </Modal>
        );
    }

    private filter = (ev: any, data: InputOnChangeData) => {
        this.setState({ filter: data.value });
    }
}

export default translate("platform")(connect((state: IState) => ({ state }))(UserCommunities));