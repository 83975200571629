import { ControllerHelper, DashboardController, DashboardElementTemplateDownloadDto, DashboardElementTemplateFilter, GroupController, GroupDownloadDto, UserShortInfoDownloadDto } from 'collaboration-service';
import DateSelect from 'components/General/DateSelect/DateSelect';
import Mainframe from 'components/General/Mainframe/Mainframe';
import Media from 'components/General/Media/Media';
import { BackendCallCache } from 'components/General/Statistics/DiagramContainer';
import IconGroupSelection from 'components/IconGroupSelection/IconGroupSelection';
import ImgI18N, { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import { format, formatDistanceToNow, subDays } from "date-fns";
import { subMonths } from 'date-fns/esm';
import { enGB } from "date-fns/locale";
import { DataCollection } from "imaginarity-azure";
import { Button, fadeColor, getColor, Icon, Label, styled } from 'imaginarity-react-ui';
import { DiagramData, ImgStatisticsConfig, RenderDiagram } from 'imaginarity-statistics';
import * as _ from "lodash";
import * as React from 'react';
import { Link } from "react-router-dom";
import SanitizedHTML from 'react-sanitized-html';
import { Icon as SIcon, Popup } from "semantic-ui-react";
import { config } from "services/Config";
import { detectSmallMobileDevice, getTranslated, visitGroups } from "services/Helpers";
import { sanitizedAllowedAttributesMediumSafety, sanitizedAllowedTagsMediumSafetyContent } from 'services/SanitizeHelper';
import "wicg-inert";

ImgStatisticsConfig.getInstance().setFormatter("lngString", (data: any, payload?: any) => {
    if (!data)
        return "Ø";
    if (!payload)
        return getTranslated(data);
    return getTranslated(data[payload]);
});

ImgStatisticsConfig.getInstance().setFormatter("date", (data: any, payload?: any) => {
    if (!data)
        return "Ø";
    const opt = { locale: ImgI18N.getInstance().languageDefinition.locale };
    if (!payload)
        return format(new Date(data), "Pp", opt);
    return format(new Date(data[payload.element]), payload.format, opt);
});

ImgStatisticsConfig.getInstance().setFormatter("formatDate", (data: any, payload?: any) => {
    if (!data)
        return "Ø";
    const opt = { locale: ImgI18N.getInstance().languageDefinition.locale };
    if (!payload)
        return format(new Date(data), "Pp", opt);
    else
        return format(new Date(data), payload.format, opt);
});

ImgStatisticsConfig.getInstance().setRenderer("media", (data: any, payload?: any) => {
    if (data === undefined)
        return <div />;
    return <div style={{ width: "100%", textAlign: "center" }}><Media media={{ media: data, refName: "self" }} contentClassName={payload && payload.rounded ? "RoundImageStatistics" : undefined} /></div>;
});
ImgStatisticsConfig.getInstance().setRenderer("lngString", (data: any, payload?: any) => {
    if (!data)
        return <span />;
    if (!payload)
        return <span>{getTranslated(data)}</span>;
    return <span>{getTranslated(data[payload])}</span>;
});
ImgStatisticsConfig.getInstance().setRenderer("date", (data: any, payload?: any) => {
    if (!data)
        return <span />;
    const opt = { locale: ImgI18N.getInstance().languageDefinition.locale };
    if (!payload)
        return <span>{format(new Date(data), "Pp", opt)}</span>;
    const d = payload.element ? data[payload.element] : data;
    return <span>{format(new Date(d), payload.format, opt)}</span>;

});
ImgStatisticsConfig.getInstance().setRenderer("timeAgo", (data: any, payload?: any) => {
    if (!data)
        return <span />;
    const opt = { locale: enGB };
    return (
        <Popup className='ComUserPopup' content={format(new Date(data), "Pp", opt)} position="right center"
            trigger={
                <span>{formatDistanceToNow(new Date(data), opt)} ago</span>
            } />
    );
});
ImgStatisticsConfig.getInstance().setRenderer("user", (data: any, payload?: any) => {
    if (!data)
        return <span />;
    const user = data as UserShortInfoDownloadDto;
    return <Link to={`${config.routes.community.otherprofile}/${user.id}`}>{user.firstName} {user.secondName}</Link>;
});


ImgStatisticsConfig.getInstance().setRenderer("deltaValue", (data: any, payload?: any) => {
    if (data === undefined)
        return <div />;
    return <div>
        {data > 0 && <SIcon name="triangle up" size="large" color="green" style={{ float: "left" }} />}
        {data < 0 && <SIcon name="triangle down" size="large" color="red" style={{ float: "left" }} />}
        <div style={{ float: "right" }}>
            <span>{data > 0 ? `+${data}` : `${data}`}</span>
        </div>
    </div>;
});

interface ExciteStatsProps extends IIMGTranslatedComponent {
}

const templateIds = [
    "1227361e-0b05-4cfd-b4f9-246d20b0a456",
    //"f7763748-ac79-46bf-bfb2-794e14d55ae0",
    //"ecfaeca4-ccbe-4b95-8f4c-00f67bf32bbd",
    "7e750bc7-f37c-4114-8c4b-329fdd47f19b",
    "89fb5f37-38ab-4870-8e69-89fcfcde39e6",
    "b575c7e4-5ec4-4570-bb88-66bec9765348",
    //"0036f7e8-62f9-4260-95e4-b7f457f42aa1",
    "01dd5ea4-bd7f-480e-9c9d-94b42145b459",
    //"47d4dcc6-88e5-440d-ae21-37974a33a8da",
    //"f7763748-ac79-46bf-bfb2-794e14d55ae0",
    //"a8ee754a-e131-4747-a106-814bf84b64cd",
    //"7a257eaa-f500-4b73-8126-5a430f4f41c9",
    //"1edbf47b-aede-4a6e-85a0-3c47bcc8a256",
    "39bb68ed-510b-4f2c-b18d-4eb4bdd5cc9b"

]
const cache = new BackendCallCache();
const ESContainer = styled.div`
    &:last-child {
        padding-bottom: 5px;
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
}
`;
const IC = styled.div<{ open: boolean, shown: boolean }>`
    display: ${p => p.shown ? "block" : "none"};
    color: ${p => getColor(p, p.theme.colors.accent)};
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    border-left: 3px solid ${p => p.open ? getColor(p, p.theme.colors.accent) : getColor(p, p.theme.colors.middleLightGrey)};
    margin-top: -5px;
    margin-bottom:  ${p => p.open ? "4px" : "0px"};
    width: 100%;
    min-height: 30px;
    max-height: 100px;
    box-shadow: ${p => p.open ? "none" : "0 4px 6px -4px" + getColor(p, p.theme.colors.darkGrey)};
    overflow-y: auto;
    padding: 10px;
    padding-left: 50px;
`;

const DC = styled.div<{ open: boolean }>`
    display: ${p => p.open ? "block" : "none"};
    transition: all 2s linear;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    border-left: 3px solid ${p => p.open ? getColor(p, p.theme.colors.accent) : getColor(p, p.theme.colors.middleLightGrey)};
    margin-top: -5px;
    width: 100%;
    height: 300px;
    max-height: 300px;
    box-shadow: 0 4px 6px -4px ${p => getColor(p, p.theme.colors.darkGrey)};
    margin-bottom:${p => p.open ? "20px" : 0};
`;

const IDC = styled.div<{ sf: number }>`
    width: ${p => 100 / p.sf}%;
    height: calc(${p => 300 / p.sf}px - 3px);
    max-height: calc(${p => 300 / p.sf}px - 3px);
    transform-origin: 0% 0%;
    transform: scale(${p => p.sf});
    overflow-y: auto;
    position: relative;
    padding: 0 10px;
`;

const HLtop = styled.div`
    margin-top: 10px;
    font-weight: 900;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
`;

const Header = styled.div<{ open: boolean }>`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 3px 40px 1fr 40px;
    -ms-grid-columns: 3px 40px 1fr 40px;
    grid-template-rows: 40px;
    -ms-grid-rows: 40px;
    margin-top: 5px;
    background: ${p => p.open ? getColor(p, p.theme.colors.middleLightGrey) : getColor(p, p.theme.colors.veryLightGrey)};
    transition: 0.2s ease-out;
    margin-bottom: 5px;
    width: 100%;
    cursor: pointer;
    &:hover{
        background: ${p => p.open ? fadeColor(p, getColor(p, p.theme.colors.darkGrey), 20) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 10)};
    }
`;

const LineLeft = styled.div<{ open: boolean }>`
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    background: ${p => p.open ? getColor(p, p.theme.colors.accent) : getColor(p, p.theme.colors.middleLightGrey)};
`;
const BtInfo = styled.div`
    grid-row: 1;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
`;
const Title = styled.div`
    grid-column: 3;
    grid-row: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    font-weight: 900;
    text-align: left;
    line-height: 40px;
    padding-left: 10px;
    height: 40px;
`;
const BtMore = styled.div`
    grid-row: 1;
    grid-column: 4;
    -ms-grid-row: 1;
    -ms-grid-column: 4;
`;

const NGrid = styled.div<{ cols: number }>`
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: ${p => _.times(p.cols, c => " 1fr")};
    -ms-grid-columns: ${p => _.times(p.cols, c => " 1fr")};
`;

const Col = styled.div<{ col: number }>`
    grid-column: ${p => p.col};
    -ms-grid-column: ${p => p.col};
    padding: 2px;
`;
const Hint = styled.div`
    color: ${p => getColor(p, p.theme.colors.accent)};
    width: 100%;
    font-weight: bolder;
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 5px;
`;

const Settings = styled.div`
    padding: 5px;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-top: 10px;
`;

const PieLeft = styled.div`
    position: absolute;
    bottom: 35px;
    left: 5%;
    text-align: left;
    color: ${p => getColor(p, p.theme.colors.darkGrey)};
    padding-left: 10px;
    border-left: 1px solid ${p => getColor(p, p.theme.colors.darkGrey)};
    height: 60px;
`;

const PieRight = styled.div`
    position: absolute;
    top: 35px;
    right: 5%;
    color: ${p => getColor(p, p.theme.colors.accent)};
    padding-right: 10px;
    border-right: 1px solid ${p => getColor(p, p.theme.colors.accent)};
    z-index: 1;
    text-align: right;
    height: 60px;
`;
const ExciteStats = (p: ExciteStatsProps) => {
    const [templates, setTemplates] = React.useState<DashboardElementTemplateDownloadDto[]>();
    const [data, setData] = React.useState<{ [key: string]: any[] }>();
    const [loading, setLoading] = React.useState(true);
    const [baseFilter, setBaseFilter] = React.useState<any>();
    const [groups, setGroups] = React.useState<GroupDownloadDto[]>();
    const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
    const [info, setInfo] = React.useState<{ [key: string]: boolean }>({});
    React.useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const filter: DashboardElementTemplateFilter = {
                groupTypes: ["EXCITE", "LANDING PAGE SMART MOBILITY"]
            };
            if (!groups || !templates) {
                const [t, g] = await ControllerHelper
                    .addCall({ filter }, DashboardController.GetDashboardElementTemplates)
                    .addCall({}, GroupController.GetHierarchy)
                    .execute<DashboardElementTemplateDownloadDto[], DataCollection<GroupDownloadDto>>();
                const eGroups: GroupDownloadDto[] = [];
                visitGroups(g.elements, gr => {
                    if (gr.groupType === "EXCITE")
                        eGroups.push(gr);
                    return true;
                });
                setGroups(eGroups);
                const filteredT = _.filter(t, tt => _.findIndex(templateIds, ti => ti === tt.id) >= 0);
                setTemplates(filteredT);
                const end = new Date();
                const start = subDays(new Date(), 7);
                setBaseFilter({
                    groupIds: [eGroups[0].id],
                    startTime: start,
                    endTime: end,
                    requestUriContains: "excite.digital"
                });
            }


        }
        getData();
    }, [])

    React.useEffect(() => {
        const getData = async () => {
            if (templates && baseFilter) {
                setLoading(true);
                const d: { [key: string]: any[] } = {};
                const ps = _.map(templates, async dt => {
                    const { settings, mappings, ...filter } = dt.elementConfiguration;
                    const dtd = await cache.execute(dt.controller, dt.controllerMethod, { ...filter, ...baseFilter }, mappings);
                    d[dt.id] = dtd;
                });
                await Promise.all(ps);
                // console.log("data => ", d);
                setData(d);
                setLoading(false);
            }
        };
        getData();
    }, [baseFilter, templates]);


    // console.log(templates);

    const selectGroup = (group: GroupDownloadDto) => {
        //console.log(group);
        const bf = _.clone(baseFilter);
        bf.groupIds = [group.id];
        setBaseFilter(bf);
    };

    const setInterval = (start: Date, end: Date) => {
        const bf = _.clone(baseFilter);
        bf.startTime = start;
        bf.endTime = end;
        setBaseFilter(bf);

    }

    const setStartDate = (date: Date) => {
        setInterval(date, baseFilter.endTime);
    }

    const setEndDate = (date: Date) => {
        setInterval(baseFilter.startTime, date);
    }

    const toggleOpen = (id: string) => () => {
        const op = _.clone(open);
        const o = op[id] === undefined ? false : op[id];
        op[id] = !o;
        setOpen(op);
    }
    const toggleInfo = (id: string) => () => {
        const op = _.clone(info);
        const o = op[id] === undefined ? false : op[id];
        op[id] = !o;
        setInfo(op);
    }

    const diaStuff = (dia: DiagramData) => {
        switch (dia.type) {
            case "Pie":
                //console.log(dia.url);
                const d: any[] = ((dia.url as any) as any[]) ?? [];
                return (
                    <>
                        <PieRight>
                            <h3 style={{ padding: 0, margin: 0 }}>{d[0].name}</h3>
                            <h1 style={{ padding: 0, margin: 0 }}>{d[0].value}</h1>
                        </PieRight>
                        <PieLeft>
                            <h1 style={{ padding: 0, margin: 0 }}>{d[1].value}</h1>
                            <h3 style={{ padding: 0, margin: 0 }}>{d[1].name}</h3>
                        </PieLeft>
                    </>
                );
            default:
                return <></>;
        }
    }

    return (
        <Mainframe loadingDimmed={loading} loading={loading} loadingMessage={p.t("loading data...")}>
            {loading && data == undefined && <div style={{ height: "calc(100vh - 40px)" }} />}
            {data && baseFilter &&
                <Settings>
                    <HLtop>{p.t("selected group for statistics")}</HLtop>
                    <IconGroupSelection
                        onGroupSelected={selectGroup}
                        filter={["EXCITE"]}
                        groupid={baseFilter.groupIds[0] ?? ""}
                        groups={groups ?? []}
                        count={Math.min(5, groups?.length ?? 5)}
                    />
                    <HLtop>{p.t("date range for statistics")}</HLtop>
                    <NGrid cols={3}>
                        <Col col={1}>
                            <Button kind="secondary" bordered content={p.t("past 7 days")} fluid onClick={() => setInterval(subDays(new Date(), 7), new Date())} />
                        </Col>
                        <Col col={2}>
                            <Button kind="secondary" bordered content={p.t("past 30 days")} fluid onClick={() => setInterval(subDays(new Date(), 30), new Date())} />
                        </Col>
                        <Col col={3}>
                            <Button kind="secondary" bordered content={p.t("past 6 month")} fluid onClick={() => setInterval(subMonths(new Date(), 6), new Date())} />
                        </Col>
                    </NGrid>

                    <NGrid cols={2}>
                        <Col col={1}>
                            {p.t("start date")}
                            <DateSelect
                                date={baseFilter.startTime}
                                onDateChanged={setStartDate}
                                withTime={false}
                                noMinDate={true} />
                        </Col>
                        <Col col={2}>
                            {p.t("end date")}
                            <DateSelect
                                date={baseFilter.endTime}
                                onDateChanged={setEndDate}
                                withTime={false}
                                noMinDate={true} />
                        </Col>
                    </NGrid>
                    {detectSmallMobileDevice() &&
                        <Hint>
                            <Icon name="info" color="#950014" marginTop={0} style={{ float: "left", marginRight: 5 }} />
                            {p.t("it is recommended to rotate the display on smaller mobile phones")}
                        </Hint>
                    }
                </Settings>
            }
            {data && _.map(templates, t => {
                const dia: DiagramData = _.clone(t.diagram);
                dia.url = data[t.id];
                // console.log(dia);
                const o = open[t.id];
                const i = info[t.id];
                const hasI = (t.infos?.length ?? 0) > 0;
                return (
                    <ESContainer key={t.id}>
                        <Header open={o}>
                            <LineLeft open={o} />
                            <Title onClick={toggleOpen(t.id)}>
                                {getTranslated(t.headlines)}
                            </Title>
                            {hasI &&
                                <BtInfo>
                                    <Button
                                        kind={i ? "cancelButton" : "halfTransparentButton"}
                                        icon="info"
                                        onClick={toggleInfo(t.id)}
                                        active={i}
                                    />
                                </BtInfo>
                            }
                            <BtMore >
                                <Button
                                    kind={o ? "cancelButton" : "halfTransparentButton"}
                                    icon={o ? "chevron up double" : "chevron down"}
                                    onClick={toggleOpen(t.id)}
                                    active={o}
                                />
                            </BtMore>
                        </Header>
                        {hasI &&
                            <IC shown={i} open={o}>
                                <SanitizedHTML
                                    allowedTags={sanitizedAllowedTagsMediumSafetyContent}
                                    allowedAttributes={sanitizedAllowedAttributesMediumSafety}
                                    html={getTranslated(t.infos)}
                                />
                            </IC>
                        }
                        <DC open={o} >
                            <IDC sf={detectSmallMobileDevice() ? 0.6 : 0.85}>
                                {diaStuff(dia)}
                                {RenderDiagram({ dia })}
                            </IDC>
                        </DC>
                    </ESContainer>
                )
            })}
        </Mainframe>
    );
}
//ExciteStats.whyDidYouRender = true;
export default translate("admin")(ExciteStats);