import * as _ from "lodash";
import * as React from 'react';
import { Card, Divider, Image, Icon } from 'semantic-ui-react';
import { GroupDownloadDto, UserDownloadDto } from "collaboration-service";
import { detectMobileDevice, getLink } from 'services/Helpers';
import { translate, IIMGTranslatedComponent } from "components/Utils/img-i18n";

interface UsersGroupListProps extends IIMGTranslatedComponent {
    groups: GroupDownloadDto[];
    user: UserDownloadDto;
    filter?: string;

    onEdit?: (group: GroupDownloadDto) => void;
    onDelete?: (group: GroupDownloadDto) => void;
}

class UsersGroupList extends React.Component<UsersGroupListProps, any> {
    public render() {
        const { onEdit, onDelete, groups, user, filter } = this.props;
        return (
            <Card.Group itemsPerRow={detectMobileDevice() === true ? 1 : 2} >
                {
                    _.map(user.groupRoles, (gr, idx) => {
                        const group = _.find(groups, ["id", gr.groupId]);
                        if (group) {
                            if (filter && !group.name.toLowerCase().includes(filter.toLowerCase()))
                                return <div key={idx} />;
                            const url = getLink(group.avatar.links, "self");
                            const edit = () => {
                                if (onEdit)
                                    onEdit(group);
                            };
                            const del = () => {
                                if (onDelete)
                                    onDelete(group);
                            };
                            if (url)
                                return (
                                    <Card key={idx} className='ComOverview' >
                                        <Card.Content>
                                            {url &&
                                                <Image src={url.uri}
                                                    size='tiny'
                                                    floated='right'
                                                    circular={detectMobileDevice() === true ? false : true}
                                                    style={{ border: detectMobileDevice() === true ? '0px solid #fff' : '1px solid rgba(34,36,38,.15)' }} />
                                            }
                                            <Card.Header style={{ marginTop: 6 }}>{group.name}</Card.Header>
                                            {/* <Card.Meta><span className='date'><Icon name='user' /> 22 {this.props.t("users in group")}</span></Card.Meta> */}
                                            <Divider />
                                            <Card.Header><span style={{ color: 'grey', fontSize: '0.8em', lineHeight: '1em' }}>{_.join(gr.roles, ", ")}</span></Card.Header>
                                            {(onDelete || onEdit) &&
                                                <>
                                                    <Divider />
                                                    <Card.Header>
                                                        <span style={{ color: 'grey', fontSize: '0.8em', lineHeight: '1em' }}>
                                                            {onDelete &&
                                                                <div onClick={edit} style={{ cursor: "pointer", display: "inline-block" }}>
                                                                    <Icon color="red" name="pencil" />{this.props.t("edit")}
                                                                </div>
                                                            }
                                                            {onDelete &&
                                                                <div onClick={del} style={{ cursor: "pointer", display: "inline-block" }}>
                                                                    <Icon color="red" name="times" />{this.props.t("delete")}
                                                                </div>
                                                            }
                                                        </span>
                                                    </Card.Header>
                                                </>
                                            }
                                        </Card.Content>
                                    </Card>
                                );
                        }
                        return <div key={idx} />;
                    })
                }
            </Card.Group>
        );
    }
}

export default translate()(UsersGroupList);