import * as React from 'react';
import { Modal, Icon, Form, Input } from 'semantic-ui-react';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';

export interface InviteUserProps extends IIMGTranslatedComponent {
}

class InviteUser extends React.Component<InviteUserProps, any> {
    public render() {
        return (
            <>
                <Modal.Header><Icon name="user plus" /> {this.props.t("invite user")}</Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        <Form.Field><Input placeholder={this.props.t('title')} icon='user md' iconPosition='left' /></Form.Field>
                        <Form.Field><Input required={true} placeholder={this.props.t('first name')} icon='user' iconPosition='left' /></Form.Field>
                        <Form.Field><Input required={true} placeholder={this.props.t('last name')} icon='users' iconPosition='left' /></Form.Field>
                        <Form.Field><Input required={true} type='email' placeholder={this.props.t('email')} icon='at' iconPosition='left' /></Form.Field>
                    </Form>
                </Modal.Content>
            </>
        );
    }
}
export default translate("admin")(InviteUser);
