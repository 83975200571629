import { ControllerHelper, InAppMessageController, InAppMessageDownloadDto } from 'collaboration-service';
import { DefaultMenuProps } from 'components/General/Mainframe/DefaultMainMenu';
import Mainframe from 'components/General/Mainframe/Mainframe';
import { sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from "lodash";
import React, { createRef } from 'react';
import { Button, Dropdown, Header, Icon, Modal, Table, Label, Popup } from 'semantic-ui-react';
import InAppMessage from './InAppMessage';
import InAppMessageMobile from './InAppMessageMobile';
export interface AdminInboxProps extends IIMGTranslatedComponent, SizeConditionalComponent {

}



export interface AdminInboxState {
    messages?: InAppMessageDownloadDto[];
    token?: string;
    showMoreInfo: boolean;
    selectedMessage?: InAppMessageDownloadDto;
    messageToDel?: InAppMessageDownloadDto;

}

class AdminInbox extends React.Component<AdminInboxProps, AdminInboxState> {
    constructor(props: AdminInboxProps) {
        super(props);

        this.state = {
            showMoreInfo: false,
        }
    }

    public async componentDidMount() {
        const inAppMsg = await ControllerHelper.singleCall({ token: {} }, InAppMessageController.GetInAppMessages);
        this.setState({ messages: inAppMsg.elements, token: inAppMsg.data });
    }

    contextRef = createRef();
    public render() {
        const unreadMsg = _.filter(this.state.messages, ur => { if (ur.read === false) return ur }).length;
        const undoneMsg = _.filter(this.state.messages, ud => { if (ud.done === false) return ud }).length;
        const defProps: DefaultMenuProps = {
            additionalDropdowns: [
                <Dropdown
                    key="dashboardsMenu"
                    item
                    className="DMM"
                    icon="filter">
                    <Dropdown.Menu style={{ float: "left" }}>
                        <Dropdown.Item
                            icon="chevron right"
                            text={this.props.t("show all messages")}
                        />
                        <Dropdown.Item
                            icon="chevron right"
                            text={this.props.t("show only unread messages")}
                        />
                        <Dropdown.Item
                            icon="chevron right"
                            text={this.props.t("show only read messages")}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            ]
        };
        return (
            <Mainframe
                fullWidthMode
                padding='0'
                className={'colorBackground'}
                menuProps={{
                    ...defProps,
                }}
            >
                {this.props.width >= 768 ?
                    <Table className="InboxTable">
                        <Table.Header>
                            <Table.Row >
                                <Table.HeaderCell colSpan='4' style={{ border: "none", borderRadius: 0 }}>
                                    <h3>
                                        <Icon name="mail" color="red" /> {this.props.t("Message Inbox")}
                                    </h3>
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ border: "none", borderRadius: 0 }}>
                                    <Popup
                                        className='ComUserPopup'
                                        content={unreadMsg > 0 ? unreadMsg + " " + (unreadMsg > 1 ? this.props.t("unread messages") : this.props.t("unread message")) : this.props.t("no unread messages")}
                                        trigger={
                                            <Label size="small" color={unreadMsg > 0 ? "red" : undefined} pointing="below"  >
                                                {unreadMsg}
                                            </Label>
                                        } />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ border: "none", borderRadius: 0 }}>
                                    <Popup
                                        className='ComUserPopup'
                                        content={undoneMsg > 0 ? undoneMsg + " " + (undoneMsg > 1 ? this.props.t("undone messages") : this.props.t("undone message")) : this.props.t("no undone messages")}
                                        trigger={
                                            <Label size="small" color={undoneMsg > 0 ? "red" : undefined} pointing="below"  >
                                                {undoneMsg}
                                            </Label>
                                        } />
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ border: "none", borderRadius: 0 }} />
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {_.map(this.state.messages, m => {
                                return (
                                    <InAppMessage
                                        key={m.id}
                                        message={m}
                                        toggleDone={this.toggleDone}
                                        toggleRead={this.toggleRead}
                                        deleteMessage={this.requestDeleteMessage}
                                    />
                                )
                            })}
                        </Table.Body>
                    </Table>
                    :
                    <div className="InboxMobileTableContainer">
                        <Table style={{ marginBottom: 0 }} unstackable>
                            <Table.Header>
                                <Table.Row >
                                    <Table.HeaderCell style={{ border: "none", borderRadius: 0 }}>
                                        <h3><Icon name="mail" color="red" /> {this.props.t("Message Inbox")}</h3>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ border: "none", borderRadius: 0 }} textAlign="right">
                                        <Label
                                            size="tiny"
                                            color={unreadMsg > 0 ? "red" : undefined}
                                        >
                                            <Icon name="eye" />{unreadMsg}
                                        </Label>
                                        <Label
                                            size="tiny"
                                            color={undoneMsg > 0 ? "red" : undefined}
                                        >
                                            <Icon name="check square" />{undoneMsg}
                                        </Label>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        {_.map(this.state.messages, m => {
                            return (
                                <InAppMessageMobile
                                    key={m.id}
                                    message={m}
                                    toggleDone={this.toggleDone}
                                    toggleRead={this.toggleRead}
                                    deleteMessage={this.requestDeleteMessage}
                                />
                            )
                        })}
                    </div>
                }
                <Modal
                    open={this.state.messageToDel ? true : false}
                    basic
                    size='tiny'
                >
                    <Header icon='trash' content={this.props.t("delete message?")} />
                    <Modal.Content>
                        <h3>{this.props.t("do you really want to delete this message?")}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.cancelDeleteMessage} inverted floated="left">
                            <Icon name='undo' /> {this.props.t("cancel")}
                        </Button>
                        <Button color='green' inverted onClick={this.deleteMessage}>
                            <Icon name='checkmark' /> {this.props.t("delete message?")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Mainframe >
        );
    }
    private toggleDone = (m: InAppMessageDownloadDto) => (event: React.MouseEvent<HTMLTableCellElement>) => {
        if (m.done)
            InAppMessageController.UnDoneInAppMessage({ id: m.id }, this.updateMessage);
        else
            InAppMessageController.DoneInAppMessage({ id: m.id }, this.updateMessage);
        event.stopPropagation();
    }
    private toggleRead = (m: InAppMessageDownloadDto) => (event: React.MouseEvent<HTMLTableCellElement>) => {
        if (m.read) {
            InAppMessageController.UnReadInAppMessage({ id: m.id }, this.updateMessage);
        }
        else
            InAppMessageController.ReadInAppMessage({ id: m.id }, this.updateMessage);
        event.stopPropagation();
    }
    private updateMessage = (m: InAppMessageDownloadDto) => {
        if (this.state.messages) {
            const messages = _.clone(this.state.messages);
            const idx = _.findIndex(messages, f => f.id === m.id);
            if (idx >= 0) {
                messages.splice(idx, 1, m);
                this.setState({ messages });
            }
        }
    }
    private requestDeleteMessage = (message: InAppMessageDownloadDto) => {
        this.setState({ messageToDel: message });
    }

    private cancelDeleteMessage = () => {
        this.setState({ messageToDel: undefined });
    }

    private deleteMessage = () => {
        if (this.state.messageToDel) {
            // löschen deaktiviert
            // InAppMessageController.DeleteInAppMessages({ id: this.state.messageToDel.id }, () => {
            const messages = _.filter(this.state.messages, m => !this.state.messageToDel || m.id !== this.state.messageToDel.id);
            this.setState({
                messageToDel: undefined, messages
            });
            // });            
        }
    }
}
export default sizeConditional()(translate("inbox")(AdminInbox));
