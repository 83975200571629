import * as React from 'react';
import * as _ from "lodash";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});
export interface ScreenBreakPoint {
    width: number;
    height?: number;
}
export interface SizeConditionalConfig {
    updateSize?: boolean;
    initialCurrentBreakpoint?: string;
    className?: string;
    style?: React.CSSProperties;
    breakpoints?: { [key: string]: ScreenBreakPoint };
}

const standardSizeConditionalConfig: SizeConditionalConfig = {
    breakpoints: {
        mobile: { width: 400 },
        tablet: { width: 768 },
        desktop: { width: 1024 },
        large: { width: 1280 },
        wide: { width: 1600 },
        ultraWide: { width: 1920 }
    },
    initialCurrentBreakpoint: "verysmall"
};


export interface SizeConditionalComponent {
    width: number;
    height: number;
    currentBreakpoint: string;
    breakpoints: { [key: string]: ScreenBreakPoint };
}

export interface SizeConditionalState {
    width: number;
    height: number;
    currentBreakpoint: string;
}

interface RenderTypeBreakPoint {
    name: string;
    breakpoint: ScreenBreakPoint;
}

export function sizeConditional(config?: SizeConditionalConfig) {
    const c = config ? config : standardSizeConditionalConfig;
    _.forEach(standardSizeConditionalConfig, (v, k) => {
        if (!(c as any)[k])
            (c as any)[k] = (standardSizeConditionalConfig as any)[k];
    });

    const brArray: RenderTypeBreakPoint[] = [];
    _.forEach(c.breakpoints, (breakpoint, name) => {
        brArray.push({ breakpoint, name });
    });

    _.sortBy(brArray, b => b.breakpoint.width);

    const toRet = <P extends SizeConditionalComponent>(cmp: React.ComponentClass<P> | React.StatelessComponent<P>) => {
        return class extends React.Component<Omit<P, keyof SizeConditionalComponent>, SizeConditionalState> {
            width: number = 0;
            height: number = 0;
            debounceState = _.debounce((val) => {
                this.setState(val);
            }, 500);
            first: boolean = true;
            constructor(props: Omit<P, keyof SizeConditionalComponent>) {
                super(props);
                this.state = {
                    width: 0,
                    height: 0,
                    currentBreakpoint: c.initialCurrentBreakpoint ? c.initialCurrentBreakpoint : "undefined" /* will never happen ;) */,
                };
            }
            render() {
                const C = cmp;
                const props = { ...this.props, currentBreakpoint: this.state.currentBreakpoint, width: this.state.width, height: this.state.height, breakpoints: c.breakpoints } as P;
                return (
                    <div ref={this.handleContextRef} className={c.className} style={c.style}>
                        <C {...props} />
                    </div>
                );
            }

            handleContextRef = (contextRef: any) => {
                if (contextRef)
                    erdUltraFast.listenTo({}, contextRef, (ele: HTMLElement) => {
                        this.onResize(ele.offsetWidth, ele.offsetHeight);
                    });
            }

            onResize = (width: number, height: number) => {
                const currentBreakpoint = this.calcRenderType(width, height);
                if (this.first || currentBreakpoint !== this.state.currentBreakpoint || ((width !== this.width || height !== this.height) && c.updateSize)) {
                    this.width = width;
                    this.height = height;
                    this.first = false;
                    this.debounceState({ currentBreakpoint, width, height });
                }
            }

            calcRenderType = (width: number, height: number) => {
                let bp: string = c.initialCurrentBreakpoint ? c.initialCurrentBreakpoint : "undefined";
                _.forEach(brArray, br => {
                    if (width >= br.breakpoint.width && (!br.breakpoint.height || height >= br.breakpoint.height))
                        bp = br.name;
                });
                return bp;
            }
        };
    };
    return toRet;
}

