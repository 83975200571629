import * as React from "react";
import { SizeMeProps, withSize } from "react-sizeme";
import Cropper from "react-cropper";
import { config } from "../../../services/Config";

export interface IImageCrop {
    rotate90Right: () => void;
    getBlob: () => Promise<Blob | undefined>;
    getURL: () => Promise<string>;
    setDragMode: (mode: Cropper.DragMode) => void;
    undo: () => Promise<void>;
    cropStart: () => void;
    cropStop: () => void;
}

interface IImageCropProps extends SizeMeProps {
    src: string;
    undoSrc: string;
    aspect?: number;
    getImageCrop?: (crop: IImageCrop) => void;
    imageType?: string;
}

interface IImageCropState {
    src: string;
    img: HTMLImageElement;
    loaded: boolean;
    rotated: boolean;
}

class ImageCrop extends React.Component<IImageCropProps, IImageCropState> implements IImageCrop {

    public state: IImageCropState =
        {
            img: new Image(),
            loaded: false,
            src: "",
            rotated: false,
        };

    private oldWidth: number | undefined;
    private cropper: Cropper | undefined;

    constructor(props: IImageCropProps) {
        super(props);
        this.state.img.src = props.src;
        this.state.img.onload = () => this.setState({ loaded: true });
        if (this.props.getImageCrop)
            this.props.getImageCrop(this as IImageCrop);
        this.oldWidth = undefined;
        this.state.src = props.src;
        this.getRef = this.getRef.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IImageCropProps) {
        if (this.props.src !== nextProps.src) {
            const img = new Image();
            img.src = nextProps.src;
            img.onload = () => this.setState({ loaded: true });
            this.setState({ loaded: false, src: nextProps.src, img });
        }
        if (nextProps.getImageCrop && this.props.getImageCrop !== nextProps.getImageCrop)
            nextProps.getImageCrop(this as IImageCrop);
    }

    public render() {
        const { size } = this.props;
        const w = size.width ? size.width : 0;
        if (this.cropper && (!this.oldWidth || this.oldWidth !== w)) {
            this.oldWidth = w;
            const cropper = this.cropper as Cropper;
            cropper.moveTo(0, 0);
        }
        return (
            <div className='DivSurroundingCropper'>
                {this.state.loaded &&
                    <Cropper
                        responsive={true}
                        restore={true}
                        autoCrop={this.props.aspect !== undefined}
                        ref={this.getRef}
                        dragMode="move"
                        rotatable={true}
                        aspectRatio={this.props.aspect}
                        src={this.state.src}
                        className='ActualCropper'
                    />
                }
            </div>
        );
    }

    public rotate90Right() {
        if (config.debug)
            console.log("rotate right...");
        const cropper: Cropper = this.cropper as Cropper;
        cropper.rotate(90);
        cropper.moveTo(0, 0);
        // this.setState({ rotated: !this.state.rotated });
    }

    public getBlob(): Promise<Blob | undefined> {
        return new Promise<Blob | undefined>((resolve) => {
            const cropper: Cropper = this.cropper as Cropper;
            const canvas = cropper.getCroppedCanvas();
            canvas.toBlob((blob) => {
                if (blob)
                    resolve(blob);
                else
                    resolve(undefined);
            }, this.props.imageType ? "image/" + this.props.imageType : "image/png", 1.0);
        });
    }
    public getURL(): Promise<string> {
        return new Promise<string>((resolve) => {
            const cropper: Cropper = this.cropper as Cropper;
            const canvas = cropper.getCroppedCanvas();
            const url = canvas.toDataURL(this.props.imageType ? "image/" + this.props.imageType : "image/png", 1.0);
            resolve(url);
        });
    }

    public setDragMode(mode: Cropper.DragMode) {
        const cropper: Cropper = this.cropper as Cropper;
        cropper.setDragMode(mode);
    }

    public undo(): Promise<void> {
        return new Promise<void>((resolve) => {
            const cropper: Cropper = this.cropper as Cropper;
            this.setState({ src: this.props.undoSrc });
            cropper.replace(this.props.undoSrc);
            resolve();
        });
    }

    public cropStart() {
        const cropper: Cropper = this.cropper as Cropper;
        if (config.debug) {
            console.log("cropStart");
            console.log(cropper);
        }
        cropper.crop();
    }

    public cropStop() {
        const cropper: Cropper = this.cropper as Cropper;
        if (config.debug) {
            console.log("cropStop");
            console.log(cropper);
        }
        cropper.clear();
    }

    private getRef(ref: any) {
        this.cropper = ref;
    }

}

const sizeConfig = { monitorHeight: true, monitorWidth: true };
const sizeMeHOC = withSize(sizeConfig);

export default sizeMeHOC(ImageCrop);