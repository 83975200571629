import * as React from "react";
import { BaseClient, CalendarEntryDownloadDto, CollaborationServiceSetup } from "collaboration-service";
import { ExtendedDataCollection } from "imaginarity-azure";
import { PluginActionTypes, pluginReducer } from "imaginarity-platform-plugin-base";
import * as _ from "lodash";
import { AnyAction, applyMiddleware, createStore } from 'redux';
import thunk from "redux-thunk";
import { ActionCreators, ActionTypes } from './Actions';
import { config } from './Config';
import { GameHubService } from "./GameHubService";
import { IState } from './Interfaces';
import PostHubHelper from './PostHubHelper';
import MobileHelper from "./MobileHelper";


const initState = (oldState?: IState): IState => {
    const client = new BaseClient(config.baseURL);
    CollaborationServiceSetup.Initialize(client);
    client.debug = false;
    client.setErrorHandler(401, () => {
        MobileHelper.getInstance().reset();
        const dispatch = store.dispatch;
        dispatch(ActionCreators.TokenReceived(undefined));
        dispatch(ActionCreators.ReceivedUserInfo(undefined));
    });
    return {
        appEntries: oldState ? oldState.appEntries : [],
        modules: oldState ? oldState.modules : [],
        activeModules: oldState ? oldState.activeModules : [],
        route: "",
        applicationName: "",

        postHubHelper: new PostHubHelper(),
        gameHubService: new GameHubService(),
        debug: false,
        client,
        openReasons: true,
        searchResult: '',
        moreButtonPressed: false,
        showBriefing: true,
        warnedAboutUnsupported: false,
        communityUnseenMessages: 0,
        categoryMap: {},
        renderer: () => <div />,
        templates: [],
    };
};

export const findName = (type: any): string => {
    let res: string = "";
    _.forEach(ActionTypes, (val, idx) => {
        if (type === val) {
            res = idx;
            return;
        }
    });
    return res;
};
export const reducer = (state: IState = initState(), action: AnyAction): IState => {
    let newState: IState | undefined;
    if (action.type < PluginActionTypes.PLUGIN_ACTIONS_END)
        return pluginReducer(state, action, reducer);
    if (state.debug || (action.type === ActionTypes.DEBUG && action.debug)) {
        //
    }
    switch (action.type) {
        case ActionTypes.BATCH_ACTIONS:
            let s = _.clone(state);
            _.forEach(action.actions, (a: AnyAction) => {
                s = reducer(s, a);
            });
            newState = s;
            break;
        case ActionTypes.DEBUG:
            if (state.debug !== action.debug)
                newState = Object.assign({}, state, { debug: action.debug });
            break;
        case ActionTypes.INIT_STATE:
            {
                const cache = state.cache;
                const warnedAboutUnsupported = state.warnedAboutUnsupported;
                newState = Object.assign({}, initState(state));
                newState.cache = cache;
                newState.warnedAboutUnsupported = warnedAboutUnsupported;
            }
            break;
        case ActionTypes.TOKEN_RECEIVED:
            if (state.cache) {
                state.cache.put("/token", new Response(action.token)).then(() => {
                    if (config.debug)
                        console.log("added new token");
                });
            }
            if (action.token !== state.client.token) {
                newState = Object.assign({}, state);
                newState.client.token = action.token;
            }
            break;
        case ActionTypes.USER_INFO_RECEIVED:
            newState = Object.assign({}, state, { userInfo: action.userInfo, user: action.userInfo });
            break;
        case ActionTypes.NOTIFICATIONS_RECEIVED:
            newState = Object.assign({}, state, { notifications: action.notifications, notificationsToken: action.token });
            break;
        case ActionTypes.GROUPS_RECEIVED:
            newState = Object.assign({}, state, { groups: action.groups });
            break;
        case ActionTypes.GLOBAL_MEDIA_RECEIVED:
            newState = Object.assign({}, state, { globalMedia: action.media });
            break;
        case ActionTypes.TOGGLE_STATE_MODALS:
            newState = Object.assign({}, state, { openReasons: action.openReasons });
            break;
        case ActionTypes.TOGGLE_STATE_BUTTON_MORE_PRESSED:
            newState = Object.assign({}, state, { moreButtonPressed: action.moreButtonPressed });
            break;
        case ActionTypes.LANGING_PAGE_SET_RESULT_ID:
            newState = Object.assign({}, state, { searchResult: action.id });
            break;
        case ActionTypes.CREATED_CACHE:
            newState = Object.assign({}, state, { cache: action.cache });
            break;
        case ActionTypes.RECEIVED_CONTENT_POSTS:
            {
                const posts = action.posts ? action.posts.elements : undefined;
                const trie = action.posts ? action.posts.data : undefined;
                newState = Object.assign({}, state, { contentPosts: posts, searchTrie: trie });
            }
            break;
        case ActionTypes.SET_SHOW_BRIEFING:
            newState = Object.assign({}, state, { showBriefing: action.value });
            break;
        case ActionTypes.WARNED_ABOUT_UNSUPPORTED:
            newState = Object.assign({}, state, { warnedAboutUnsupported: action.value });
            break;
        case ActionTypes.SET_DIAGRAM_CUR_STYLE:
            newState = Object.assign({}, state, { curStyle: action.curStyle });
            break;
        case ActionTypes.GET_ONE_STYLE:
            newState = Object.assign({}, state, { getOneStyle: true });
            break;
        case ActionTypes.GOT_ONE_STYLE:
            newState = Object.assign({}, state, { getOneStyle: false });
            break;
        case ActionTypes.GET_RING_RADIUS:
            newState = Object.assign({}, state, { getRingRadius: true });
            break;
        case ActionTypes.GOT_RING_RADIUS:
            newState = Object.assign({}, state, { getRingRadius: false });
            break;
        case ActionTypes.COMMUNITY_CALENDAR_LOADED:
            {
                const toSet = (_.clone(state.communityCalendarEntries) ?? { data: "", elements: [] as CalendarEntryDownloadDto[] }) as ExtendedDataCollection<CalendarEntryDownloadDto, string>;
                toSet.data = action.data.data;
                _.forEach(action.data.elements, (d: CalendarEntryDownloadDto) => {
                    const idx = _.findIndex(toSet.elements, (o: CalendarEntryDownloadDto) => o.id === d.id);
                    if (idx < 0)
                        toSet.elements.push(d);
                });
                toSet.elements = _.sortBy(toSet.elements, d => d.time);
                newState = { ...state, communityCalendarEntries: toSet };
            }
            break;
        case ActionTypes.COMMUNITY_CALENDAR_CLEAR:
            newState = { ...state, communityCalendarEntries: undefined };
            break;
        case ActionTypes.COMMUNITY_UNSEEN_MESSAGES:
            newState = Object.assign({}, state);
            newState.communityUnseenMessages = action.data;
            break;
        case ActionTypes.COMMUNITY_CALENDAR_REMOVE_ITEM:
            if (state.communityCalendarEntries) {
                const entries = _.clone(state.communityCalendarEntries);
                const idx = _.findIndex(entries.elements, e => e.id === action.entry.id);
                if (idx >= 0) {
                    entries.elements.splice(idx, 1);
                    newState = Object.assign({}, state);
                    newState.communityCalendarEntries = entries;
                }
            }
            break;
        case ActionTypes.COMMUNITY_CALENDAR_UPDATE_ITEM:
            if (state.communityCalendarEntries) {
                const entries = _.clone(state.communityCalendarEntries);
                const idx = _.findIndex(entries.elements, e => e.id === action.entry.id);
                if (idx >= 0) {

                    entries.elements.splice(idx, 1, action.entry);
                    newState = Object.assign({}, state);
                    newState.communityCalendarEntries = entries;
                }
            }
            break;
        case ActionTypes.SET_EDIT_LANGUAGE:
            newState = Object.assign({}, state);
            newState.editLanguage = action.lng;
            break;
        case ActionTypes.ADD_CATEGORIES_TO_MAP:
            newState = Object.assign({}, state);
            newState.categoryMap[action.groupType] = action.categories;
            break;
        case ActionTypes.SET_EDIT_SLIDESHOW:
            newState = Object.assign({}, state);
            newState.editSlideShow = action.slideShow;
            break;
        case ActionTypes.SET_WORKING_GROUP:
            // Dropdown preselect last group
            newState = Object.assign({}, state);
            newState.workingGroup = action.group;
            break;
        case ActionTypes.SET_SCORM_REGISTRATIONS:
            newState = Object.assign({}, state);
            newState.scormRegistrations = action.registrations;
            break;
        case ActionTypes.SET_LINK_ADDRESS:
            newState = { ...state, linkAddress: action.address };
            break;
        case ActionTypes.SET_HOME_LINK:
            newState = { ...state, homeLink: action.homeLink };
            break;
        case ActionTypes.SET_DB_TEMPLATES:
            newState = { ...state, templates: action.templates };
            break;
    }
    if (newState) {
        if (state.debug || newState.debug) {
            //
        }
        return newState;
    }
    else
        return state;

};

export const store = createStore(reducer, applyMiddleware(thunk));

