import { AssetDownloadDto } from "collaboration-service";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { ComponentWithMappedApplicationState } from "imaginarity-platform-plugin-base";
import { AutoGrid, AutoGridColumn, Button, getColor, Icon, lightenColor, styled } from "imaginarity-react-ui";
import sizeConditional, { SizeConditionalComponent } from 'imaginarity-react-ui/dist/components/SizeConditional';
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { copyToOwnAsset, getTranslated, getUri, translateUsername } from "services/Helpers";
import { IState } from "services/Interfaces";
import { createAssetMap } from "./AssetManagement";
import ReferenceAsset, { TGButton, TGIcon, TGTitle } from "./ReferenceAsset";

;
const mapper = (state: IState) => ({});

interface AssetListViewProps extends IIMGTranslatedComponent, SizeConditionalComponent, ComponentWithMappedApplicationState<typeof mapper> {
    hasMoreAssets?: boolean;
    assets?: AssetDownloadDto[];
    userId?: string;
    requestLoadAssets: () => void;
    requestMoreAssets: () => void;
    requestEdit?: (asset: AssetDownloadDto) => void;
    requestDelete?: (asset: AssetDownloadDto) => void;
    selected?: (asset: AssetDownloadDto) => void;
}

interface AssetListViewState {
    ownOpen?: boolean;
    openUserAssets: string[];
    openGroupAssets: string[];
}


const AssetListContainer = styled.div<{ open: boolean }>`
    border: 1px solid ${p => p.open ? getColor(p, p.theme.colors.darkGrey) : getColor(p, p.theme.colors.lightGrey)};
`;

const AssetListTitle = styled.div<{ open: boolean }>`
    width: 100%;
    padding: 10px;
    font-weight: ${p => p.open ? "bolder" : "normal"};
    font-size: 1.1rem;
    height: 60px;
    line-height: 40px;
    background-color: ${p => p.open ? getColor(p, p.theme.colors.darkGrey) : lightenColor(p, getColor(p, p.theme.colors.veryLightGrey), 98)};
    color:${p => p.open ? getColor(p, p.theme.colors.veryLightGrey) : getColor(p, p.theme.colors.darkGrey)};
`;
const AssetListTitleOwn = styled.div<{ open: boolean }>`
    width: 100%;
    padding: 10px;
    font-weight: ${p => p.open ? "bolder" : "normal"};
    font-size: 1.1rem;
    height: 60px;
    line-height: 40px;
    background-color: ${p => p.open ? getColor(p, p.theme.colors.darkGrey) : lightenColor(p, getColor(p, p.theme.colors.veryLightGrey), 95)};
    color:${p => p.open ? getColor(p, p.theme.colors.veryLightGrey) : getColor(p, p.theme.colors.darkGrey)};
`;

const AssetList = styled.div`
    width: 100%;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
    padding: 10px;
    max-height: 230px;
    overflow-y: auto;
`;

const AssetContainer = styled.div<{ btns: number }>`
    width: calc(100% - 0px);
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
    border-left: 3px solid ${p => getColor(p, p.theme.colors.mainBackground)};
    display: grid;
    display: -ms-grid;
    padding: 0px 0px 0px 20px;
    height: 40px;
    grid-template-columns:1fr ${p => p.btns * 40}px;
    -ms-grid-columns: 1fr ${p => p.btns * 40}px;
    margin-bottom:1px;
    transition: all 0.2s ease-in-out;
    position: relative;
    &:hover{
        background-color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
        border-left: 3px solid ${p => getColor(p, p.theme.colors.accent)};
    }
`;
const TitleGrid = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 20px max-content 1fr 40px;
    -ms-grid-columns: 20px max-content 1fr 40px;
    width: 100%;
    transition: all 0.2s ease-in-out;
`;

export const AssetTitle = styled.div<{ bgImageUrl?: string }>`
    grid-column: 1;
    -ms-grid-column: 1;
    line-height: 40px;
    //padding-right:10px;
    max-width: 100%;
    
    a{
        color: ${p => getColor(p, p.theme.colors.darkGrey)};
    #thumb{
        display: none;        
        }
    }
    a:hover{
        color: ${p => getColor(p, p.theme.colors.accent)};
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
        #thumb{
            display: unset;
            background-image: url('${p => p.bgImageUrl}');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 40px;
            height: 40px;
            float: right;
        }
    }
`;

const AssetActions = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
`;

const TGType = styled.div`
    font-weight: bolder;
    font-size: 0.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
`;




class AssetListView extends React.Component<AssetListViewProps, AssetListViewState>{
    public state: AssetListViewState = {
        ownOpen: true,
        openGroupAssets: [],
        openUserAssets: [],
    };
    render() {
        const groupAssets = createAssetMap(this.props.assets, this.props.userId);
        const colums = this.props.width <= 748 ? 1 : this.props.width <= 1260 ? 2 : 3;
        return (
            <>
                {this.props.hasMoreAssets &&
                    <div style={{ marginBottom: 10 }}>
                        <Button kind="secondary" fluid icon="reload" content={this.props.t("load more assets")} onClick={this.props.requestMoreAssets} />
                    </div>
                }
                <AutoGrid columns={colums} gap={10} >
                    <AutoGridColumn span={1}>
                        <AssetListContainer open={this.state.ownOpen ? true : false}>
                            <AssetListTitleOwn open={this.state.ownOpen ? true : false}>
                                {`own assets (${groupAssets.ownAssets.length})`}
                                <Button
                                    icon={this.state.ownOpen ? "chevron up" : "chevron down"}
                                    kind={this.state.ownOpen ? "primary" : "secondary"}
                                    bordered={this.state.ownOpen ? false : true}
                                    floated="right"
                                    onClick={this.toggleOwn}
                                />
                            </AssetListTitleOwn>
                            {this.state.ownOpen &&
                                <AssetList>
                                    {_.map(groupAssets.ownAssets, a => this.renderAsset(a))}
                                </AssetList>
                            }
                        </AssetListContainer>
                    </AutoGridColumn>
                    {_.map(groupAssets.userAssets, ua => {
                        const u = ua[0].owner;
                        const open = _.findIndex(this.state.openUserAssets, uid => uid === u.id) >= 0;
                        return (
                            <AutoGridColumn span={1} key={u.id}>
                                <AssetListContainer open={open ? true : false}>
                                    <AssetListTitle open={open ? true : false}>
                                        {`${translateUsername(u)}'s assets (${ua.length})`}
                                        <Button
                                            onClick={this.toggleUserAsset(u.id)}
                                            icon={open ? "chevron up" : "chevron down"}
                                            kind={open ? "primary" : "secondary"}
                                            bordered={open ? false : true}
                                            floated="right"
                                        />
                                    </AssetListTitle>
                                    {open &&
                                        <AssetList>
                                            {_.map(ua, a => this.renderAsset(a, true))}
                                        </AssetList>
                                    }
                                </AssetListContainer>
                            </AutoGridColumn>
                        );
                    })
                    }
                    {_.map(groupAssets.groupAssets, (ga, gid) => {
                        const g = _.find(ga[0].groups, g => g.id === gid);
                        const open = _.findIndex(this.state.openGroupAssets, gid => gid === g?.id) >= 0;
                        return (
                            <AutoGridColumn span={1} key={gid}>
                                <AssetListContainer open={open ? true : false}>
                                    <AssetListTitle open={open ? true : false}>
                                        {`assets of group ${g?.name} (${ga.length})`}
                                        <Button
                                            icon={open ? "chevron up" : "chevron down"}
                                            kind={open ? "primary" : "secondary"}
                                            bordered={open ? false : true}
                                            floated="right"
                                            onClick={this.toggleGroupAsset(g?.id)}
                                        />
                                    </AssetListTitle>
                                    {open &&
                                        <AssetList>
                                            {_.map(ga, a => this.renderAsset(a, true))}
                                        </AssetList>
                                    }
                                </AssetListContainer>
                            </AutoGridColumn>
                        );
                    })
                    }
                </AutoGrid>
            </>
        );
    }
    private toggleUserAsset = (id?: string) => () => {
        if (!id)
            return;
        const openUserAssets = _.clone(this.state.openUserAssets);
        const idx = _.findIndex(openUserAssets, u => u === id);
        if (idx >= 0)
            openUserAssets.splice(idx, 1);
        else
            openUserAssets.push(id);
        this.setState({ openUserAssets });
    }
    private toggleGroupAsset = (id?: string) => () => {
        if (!id)
            return;
        const openGroupAssets = _.clone(this.state.openGroupAssets);
        const idx = _.findIndex(openGroupAssets, g => g === id);
        if (idx >= 0)
            openGroupAssets.splice(idx, 1);
        else
            openGroupAssets.push(id);
        this.setState({ openGroupAssets });

    }
    private toggleOwn = () => this.setState({ ownOpen: !this.state.ownOpen });

    private renderAsset = (a: AssetDownloadDto, withCopy?: boolean) => {
        const url = getUri(a.media);
        const rd = this.props.requestDelete;
        const re = this.props.requestEdit;
        const se = this.props.selected;
        const del = rd ? () => rd(a) : undefined; // this.setState({ assetToDelete: a });
        const mod = re ? () => re(a) : undefined; // this.setState({ assetToEdit: a });
        const sel = se ? () => se(a) : undefined;
        const cpy = async () => {
            await copyToOwnAsset(a.media);
            await this.props.requestLoadAssets();
        };
        const co = (rd ? 1 : 0) + (re ? 1 : 0) + (se ? 1 : 0) + (withCopy ? 1 : 0);
        const fileType = !a.referenceAsset ? a.media.fileName.substring(a.media.fileName.lastIndexOf('.') + 1) : "";

        return (
            <AssetContainer key={a.id} btns={co}>
                {a.referenceAsset ?
                    <ReferenceAsset asset={a} />
                    :
                    <AssetTitle bgImageUrl={url ?? undefined}>
                        <a href={url} download={a.media.fileName} target="_blank" rel="noopener noreferrer" className="thumbnail" >
                            <TitleGrid>
                                <TGIcon>
                                    <Icon
                                        name={a.media.mediaType.startsWith('image') ? "image" : a.media.mediaType.startsWith('video') ? "video player" : "box"}
                                        style={{ float: "left" }}
                                    />
                                </TGIcon>
                                {/* <TGType>{a.media.mediaType}-File</TGType> */}
                                <TGType>{fileType}</TGType>
                                <TGTitle>
                                    {a.media.fileName}
                                </TGTitle>
                                {a.media.mediaType.startsWith('image') ?
                                    <TGButton><div id="thumb" /></TGButton>
                                    : <div />
                                }
                            </TitleGrid>
                        </a>
                    </AssetTitle>
                }
                <AssetActions>
                    {a.mayWrite &&
                        <>
                            {del && <Button kind="secondary" icon="delete" onClick={del} floated="right" />}
                            {mod && <Button kind="secondary" icon="cog" onClick={mod} floated="right" />}
                        </>
                    }
                    {sel && <Button kind="secondary" icon="check" onClick={sel} floated="right" />}
                    {withCopy && <Button kind="secondary" icon="copy" onClick={cpy} floated="right" />}
                </AssetActions>
            </AssetContainer>
        );
    }
}

export default translate("assets")(sizeConditional({ updateSize: true })(connect(mapper)(AssetListView)));