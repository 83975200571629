import * as React from 'react';
import { Button, Icon, Popup, Divider } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

interface IButtonSaveProps {
    text?: string;
    fluid?: boolean;
    size?: IconSizeProp;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
    basic?: boolean;
}


export default class ButtonSave extends React.Component<IButtonSaveProps, {}> {

    public render() {
        const Btn = (
            <Button
                size={this.props.size ? this.props.size : "mini"}
                fluid={this.props.fluid}
                color='green'
                icon
                disabled={this.props.disabled}
                basic={this.props.basic}
                onClick={this.props.onClick}
            >
                <Icon name='save' />
                {this.props.text}
            </Button>
        );
        return (
            <>
                {this.props.tooltip ? <Popup position='bottom left'
                    className='ComUserPopup'
                    color='green'
                    trigger={
                        Btn
                    }
                >
                    <div className='ButtonStyle'>
                        <Divider className='Save' horizontal>{this.props.tooltip}</Divider>
                    </div>
                </Popup>
                    :
                    Btn

                }

            </>
        );
    }
}
