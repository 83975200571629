import * as React from 'react';
import { Grid } from 'semantic-ui-react';

export interface IFooterProps {
  children?: React.ReactNode[] | React.ReactNode;
  actions: Array<((v: any) => any) | undefined>;
}

const Footer: React.SFC<IFooterProps> = (props: IFooterProps) => {
  return (
    <div className="SpaceFooter">
      <div className='Footer-Div'>
        <div className="ButtonBar">
          <Grid centered={true} >
            <Grid.Row centered={true}>
              {props.children}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Footer;
