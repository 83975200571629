import { MediaController } from 'collaboration-service';
import Lottie, { AnimationItem, AnimationEventName } from "lottie-web";
import * as React from 'react';

interface LottieProgressProps {
    width: number | string;
    height: number | string;
    preAnimation?: any;
    animation: any;
    postAnimation?: any;
    onDone?: () => void;
    preAnimationSpeed?: number;
    animationSpeed?: number;
    postAnimationSpeed?: number;
}

enum State {
    preAnimation,
    animation,
    postAnimation,
    done,
}


interface LottieProgressState {
    preAnimation?: AnimationItem;
    animation?: AnimationItem;
    postAnimation?: AnimationItem;
    state: State;
}

export default class LottieProgress extends React.Component<LottieProgressProps, LottieProgressState> {
    private mounted: boolean = false;
    private curAnim?: AnimationItem;
    constructor(props: LottieProgressProps) {
        super(props);
        this.state = {
            state: this.props.preAnimation ? State.preAnimation : State.animation,
        };
    }

    public UNSAFE_componentWillMount() {
        this.mounted = true;
    }

    public componentWillUnmount() {
        this.mounted = false;
        if (this.state.animation)
            this.state.animation.destroy();
        if (this.state.preAnimation)
            this.state.preAnimation.destroy();
        if (this.state.postAnimation)
            this.state.postAnimation.destroy();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: LottieProgressProps) {
        if (nextProps.onDone !== this.props.onDone && nextProps.onDone === undefined) {
            if (this.mounted)
                this.setState({ state: nextProps.preAnimation ? State.preAnimation : State.animation });
            this.curAnim = undefined;
        }
    }

    public animationDone = (type: AnimationEventName) => () => {
        //console.log("check for ", type, "state => ", this.state.state, this.props.onDone);
        switch (this.state.state) {
            case State.preAnimation:
                if (this.curAnim) {
                    this.curAnim.stop();
                    this.curAnim.removeEventListener(type, this.animationDone(type));
                }
                this.curAnim = this.state.animation;
                if (this.curAnim) {
                    this.curAnim.goToAndPlay(0, true);
                    this.curAnim.addEventListener("loopComplete", this.animationDone("loopComplete"));
                }
                if (this.mounted)
                    this.setState({ state: State.animation });
                break;
            case State.animation:
                if (this.props.onDone) {
                    if (this.curAnim) {
                        this.curAnim.stop();
                        this.curAnim.removeEventListener(type, this.animationDone(type));
                    }
                    this.curAnim = this.props.postAnimation ? this.state.postAnimation : undefined;
                    if (this.curAnim) {
                        this.curAnim.goToAndPlay(0, true);
                        this.curAnim.addEventListener("loopComplete", this.animationDone("loopComplete"));
                        if (this.mounted)
                            this.setState({ state: State.postAnimation });
                    }
                    else {
                        if (this.mounted)
                            this.setState({ state: State.done }, this.props.onDone);
                    }
                }
                break;
            case State.postAnimation:
                if (this.curAnim) {
                    this.curAnim.stop();
                    this.curAnim.removeEventListener(type, this.animationDone(type));
                }
                if (this.props.onDone) {
                    this.curAnim = undefined;
                    if (this.mounted)
                        this.setState({ state: State.done }, this.props.onDone);
                }
                break;
        }
    }

    public render() {
        //console.log("state => ", this.state.state);
        if (this.state.animation &&
            (this.props.preAnimation || !this.state.preAnimation) &&
            (this.props.postAnimation || !this.state.postAnimation)) {
            //console.log("here we go!");
            if (!this.curAnim) {
                this.curAnim = this.state.state === State.preAnimation ? this.state.preAnimation : this.state.animation;
                if (this.curAnim)
                    this.curAnim.goToAndPlay(0, true);
                if (this.curAnim) {
                    const animEvent: AnimationEventName = this.state.state === State.preAnimation ? "loopComplete" : "loopComplete";
                    this.curAnim.addEventListener(animEvent, this.animationDone(animEvent));
                }

            }
        }

        const style: React.CSSProperties = {};
        style.position = "relative";
        const { width, height } = this.props;
        style.width = width;
        style.height = height;

        const { state } = this.state;

        return (
            <div style={style}>
                {this.props.preAnimation &&
                    <div ref={this.setPreRef} style={{ position: "absolute", top: 0, left: 0, width, height, display: (state === State.preAnimation ? undefined : "none") }} />
                }
                <div ref={this.setRef} style={{ position: "absolute", top: 0, left: 0, width, height, display: (state === State.animation ? undefined : "none") }} />
                {this.props.preAnimation &&
                    <div ref={this.setPostRef} style={{ position: "absolute", top: 0, left: 0, width, height, display: (state === State.postAnimation ? undefined : "none") }} />
                }
            </div>
        );
    }

    private setPreRef = (div: HTMLDivElement) => {
        if (this.props.preAnimation)
            MediaController.GetByContainerAndNameJsonPublic({ mediaInfo: { container: "global", file: this.props.preAnimation } }, (animationData) => {
                const preAnimation = Lottie.loadAnimation({ animationData, autoplay: false, container: div, loop: true });
                if (this.props.preAnimationSpeed)
                    preAnimation.setSpeed(this.props.preAnimationSpeed);
                if (this.mounted)
                    this.setState({ preAnimation });
            });
    }

    private setRef = (div: HTMLDivElement) => {
        MediaController.GetByContainerAndNameJsonPublic({ mediaInfo: { container: "global", file: this.props.animation } }, (animationData) => {
            const animation = Lottie.loadAnimation({ animationData, autoplay: false, container: div, loop: true });
            if (this.props.animationSpeed)
                animation.setSpeed(this.props.animationSpeed);
            if (this.mounted)
                this.setState({ animation });
        });
    }

    private setPostRef = (div: HTMLDivElement) => {
        if (this.props.postAnimation)
            MediaController.GetByContainerAndNameJsonPublic({ mediaInfo: { container: "global", file: this.props.postAnimation } }, (animationData) => {
                const postAnimation = Lottie.loadAnimation({ animationData, autoplay: false, container: div, loop: true });
                if (this.props.postAnimationSpeed)
                    postAnimation.setSpeed(this.props.postAnimationSpeed);
                if (this.mounted)
                    this.setState({ postAnimation });
            });
    }
}
