import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Image } from 'semantic-ui-react'
import { ActionCreators } from 'services/Actions'
import { IConnectedComponent, IState } from 'services/Interfaces'

interface ScormHeaderProps extends IConnectedComponent {
    head?: boolean;
}
class ScormHeader extends Component<ScormHeaderProps, {}> {
    render() {
        return (
            <div className="ScormHeaderFixed">
                <Image src="./images/scorm/pag_logo.svg" className="ScormHeaderFixedLogo" />
                {this.props.head &&
                    <Image src="./images/scorm/profile_icon.png" className="ScormHeaderFixedProfile" onClick={this.linkToScormOverview} />
                }
            </div>
        )
    }
    private linkToScormOverview = () => {
        this.props.dispatch(ActionCreators.globalState.Logout());
    }
}
export default connect((state: IState) => ({ state }))(ScormHeader);

