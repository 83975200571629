import { BookmarkController, ControllerHelper, EventController, EventUploadDto, PostController, PostControllerTube, PostDownloadDto, PostInteraction, TubePostDownloadDto, UserCommentDownloadDto, UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats } from 'collaboration-service';
import { DefaultMenuProps } from 'components/General/Mainframe/DefaultMainMenu';
import Mainframe from 'components/General/Mainframe/Mainframe';
import Media, { IDownloadMedia, IEmbedMedia } from 'components/General/Media/Media';
import { sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import UserAvatar from 'components/General/UserAvatar/UserAvatar';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Container, Divider, Grid, Header, Icon, Menu, Rating, Popup } from 'semantic-ui-react';
import { getMediaForContent, getTranslated, getTranslatedValue, splitText, toLocalDateString, translateUsername, getBrowser } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';
import TubeComments from './TubeComments';
import TubeRatings from './TubeRatings';
import { config } from 'services/Config';
import { PostHubRegistration } from 'services/PostHubHelper';



interface TubeVideoRouteProps {
    id: string;
}

export interface TubeVideoLightProps extends IConnectedComponent, IIMGTranslatedComponent, SizeConditionalComponent, RouteComponentProps<TubeVideoRouteProps> {

}

export interface TubeVideoLightState {
    post?: TubePostDownloadDto;
    expand: boolean;
    comments?: UserCommentDownloadDto[];
    ratings?: UserCommentDownloadDto[];
    newComment: string;
    showRating: boolean;
    showCommentForm: boolean;
    showInfo: boolean;

}


class TubeVideoLight extends React.Component<TubeVideoLightProps, TubeVideoLightState> {
    private postHubReg: PostHubRegistration | undefined;

    constructor(props: TubeVideoLightProps) {
        super(props);

        this.state = {
            expand: false,
            newComment: "",
            showRating: false,
            showCommentForm: true,
            showInfo: false
        }
    }

    async UNSAFE_componentWillMount() {
        await this.loadComments();
    }

    UNSAFE_componentWillReceiveProps(nextProps: TubeVideoLightProps) {

    }

    componentWillUnmount() {
        this.postHubReg?.unsubscribe();
    }

    public render() {
        const ah = "100vh";
        const isBookmarked = this.state.post ? this.state.post.isBookmarked : false;

        const defProps: DefaultMenuProps = {
            additionalMenuBarEntries: [
                {
                    icon: 'arrow left',
                    onClick: this.goBack,
                },
                {
                    icon: "exchange",
                    onClick: this.toggleTheme,
                },
                {
                    icon: this.state.expand ? 'compress' : 'expand',
                    onClick: this.toggleExpand,
                    active: this.state.expand,
                    color: !this.state.expand ? undefined : "red"
                },
                // {
                //     icon: isBookmarked ? 'bookmark outline' : 'bookmark',
                //     onClick: this.setBookmark,
                //     active: isBookmarked,
                //     color: isBookmarked ? undefined : "red"
                // },
                {
                    icon: this.state.showCommentForm ? 'comment' : 'comment outline',
                    onClick: this.toggleCommentForm,
                    active: this.state.showCommentForm,
                    color: !this.state.showCommentForm ? undefined : "red"
                },
                // {
                //     icon: this.state.showRating ? 'star' : 'star outline',
                //     onClick: this.toggleRating,
                //     active: this.state.showRating,
                //     color: !this.state.showRating ? undefined : "red"
                // }

            ]
        }

        let media: IDownloadMedia | IEmbedMedia | undefined;
        if (this.state.post) {
            const content = getTranslatedValue(this.state.post.content);
            media = getMediaForContent(content);

        }
        console.log(media);
        return (
            <>
                {this.state.showRating && this.state.post &&
                    <TubeRatings post={this.state.post} onRequestClose={this.toggleRating} onAddRating={this.onAddRating} ratings={this.state.ratings} />
                }
                <Mainframe
                    menuProps={
                        this.props.width > 768 ?
                            {
                                ...defProps
                            }
                            : undefined
                    }
                    fullWidthMode
                    padding="0%"
                >
                    {this.props.width < 768 &&
                        <div style={{ width: "100vw", height: 100, position: "fixed", bottom: 0, left: 0, background: "#000", zIndex: 5 }}
                        />}
                    <div className="TubeContainerOuterWhite"
                        style={{
                            overflow: "hidden",
                            perspective: 600,
                            bottom: 0,
                            zIndex: 500,
                        }}
                    >
                        <div>
                            {this.props.width <= 768 &&
                                <Menu inverted fixed="bottom" size="large" className="VideoMenuBottomBar" fluid widths="6">
                                    <Menu.Item name='back' onClick={this.goBack}>
                                        <Icon name='arrow left' style={{ color: "#950014" }} />
                                    </Menu.Item>
                                    <Menu.Item name='exchange' onClick={this.toggleTheme}>
                                        <Icon name='exchange' style={{ color: "#950014" }} />
                                    </Menu.Item>
                                    {this.state.post &&
                                        <Menu.Item onClick={this.setBookmark} name='bookmark' style={{ cursor: "pointer" }} >
                                            <Icon
                                                name={this.state.post.isBookmarked ? "bookmark" : 'bookmark outline'}
                                                color={this.state.post.isBookmarked ? 'red' : undefined}
                                            />
                                        </Menu.Item>
                                    }
                                    <Menu.Item name='rating' onClick={this.toggleRating}>
                                        <Icon name='star' />
                                    </Menu.Item>
                                    <Menu.Item name='info' onClick={this.toggleInfo}>
                                        <Icon name='info circle' color={this.state.showInfo ? "red" : undefined} />
                                    </Menu.Item>
                                    <Menu.Item name='comment'
                                        onClick={this.toggleCommentForm}
                                    >
                                        <Icon name='comment' color={this.state.showCommentForm ? "red" : undefined} />
                                    </Menu.Item>
                                </Menu>


                            }
                        </div>
                        <div className="VideoSingleContainerInner"
                            style={{
                                marginTop: 20,
                                top: 0,
                                overflowX: "hidden",
                                overflowY: "auto",
                                height: ah,
                                // borderTop: "1px solid #363738",
                                // borderBottom: "1px solid #363738",
                                paddingBottom: 150,
                                paddingTop: 5
                            }}>
                            <Container style={{ width: "75vw" }}>
                                {this.state.post && media &&
                                    <>
                                        <Grid stackable columns={this.state.expand ? 1 : 2} stretched>
                                            <Grid.Column mobile={16} tablet={16} computer={this.state.expand ? 16 : 12} textAlign="center">
                                                <Media media={media} contentClassName="VideoSingle" videoShift={0.1} autoPlay />
                                            </Grid.Column>

                                            {!this.state.expand && this.props.width > 768 &&
                                                <Grid.Column mobile={16} tablet={16} computer={4}>
                                                    <div style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        position: "relative",
                                                        left: 0,
                                                    }}>

                                                        <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }} >
                                                            <Header as="h2" style={{ marginTop: 10 }}>
                                                                <Icon name="toggle right" style={{ float: "left", paddingBottom: 10, marginTop: 0, color: "#950014" }} />
                                                                {getTranslated(this.state.post.headlines)}
                                                            </Header>
                                                            <div style={{ width: "100%", background: "#00000022", color: "#313639", padding: 10, marginTop: 10 }}>
                                                                {splitText(getTranslated(this.state.post.descriptions))}
                                                            </div>
                                                        </div>

                                                        <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }} >

                                                            {this.state.expand && <Divider />}
                                                            {
                                                                this.state.post.tags.length > 0 &&
                                                                <>
                                                                    {_.map(this.state.post.tags, (e, i) => {
                                                                        return (
                                                                            this.state.post &&
                                                                            <Button key={i} size="mini" basic color="grey" content={this.state.post.tags[i]} />
                                                                        );
                                                                    }
                                                                    )}
                                                                    <Divider />
                                                                </>
                                                            }

                                                            <div style={{ marginLeft: 10, color: "#313639", fontSize: "0.8em" }}>
                                                                <div style={{ width: 24, float: "left", marginRight: 10, marginLeft: -10 }}>
                                                                    <UserAvatar user={this.state.post.creator} />
                                                                </div>
                                                                {translateUsername(this.state.post.creator)} ({toLocalDateString(this.props.t, this.state.post.created)})
                                                            </div>
                                                            <Divider />
                                                            {/* <Rating rating={Math.round(this.state.post.rating)} maxRating={5} disabled={false} className="VideoRatingDarkSingle" /> */}
                                                            <Popup className='ComUserPopup' content={this.props.t("rate this video")}
                                                                trigger={
                                                                    <Rating rating={Math.round(this.state.post.rating)} maxRating={5} disabled={false} className="VideoRatingDarkSingle" onClick={this.toggleRating} />
                                                                } />
                                                            <Popup className='ComUserPopup' content={this.props.t("bookmark this video")}
                                                                trigger={
                                                                    <Button onClick={this.setBookmark} size="mini" basic color="black" icon floated="right" style={{ marginTop: -6, marginLeft: 6 }} >
                                                                        <Icon color={isBookmarked ? "red" : "grey"} name={isBookmarked ? 'bookmark' : 'bookmark outline'} />
                                                                    </Button>
                                                                } />
                                                            <div style={{ color: "#313639", fontSize: "0.9em", float: "right" }}>
                                                                {this.props.t("{{views}} views", { views: this.state.post && this.state.post.views ? this.state.post.views : 0 })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid.Column>
                                            }
                                        </Grid>
                                        {this.state.showInfo &&
                                            <div style={{ color: "#313639", fontSize: "0.8em", width: "100%", padding: 10 }}>
                                                <Divider clearing />
                                                <div style={{ width: 24, float: "left", marginRight: 10 }}>
                                                    <UserAvatar user={this.state.post.creator} />
                                                </div>
                                                {translateUsername(this.state.post.creator)} ({toLocalDateString(this.props.t, this.state.post.created)})<br />
                                                {/* <Rating rating={Math.round(this.state.post.rating)} maxRating={5} disabled={false} className="VideoRatingDarkSingle" /> */}
                                                <Popup className='ComUserPopup' content={this.props.t("rate this video")}
                                                    trigger={
                                                        <Rating rating={Math.round(this.state.post.rating)} maxRating={5} disabled={false} className="VideoRatingDarkSingle" onClick={this.toggleRating} />
                                                    } />
                                                <Popup className='ComUserPopup' content={this.props.t("bookmark this video")}
                                                    trigger={
                                                        <Button onClick={this.setBookmark} size="mini" basic color="black" icon floated="right" style={{ marginTop: -10, marginLeft: 6 }} >
                                                            <Icon color={isBookmarked ? "red" : "grey"} name={isBookmarked ? 'bookmark' : 'bookmark outline'} />
                                                        </Button>
                                                    } />
                                                <div style={{ color: "#313639", float: "right", marginTop: -5 }}>
                                                    {this.props.t("{{views}} views", { views: this.state.post && this.state.post.views ? this.state.post.views : 0 })}
                                                </div>
                                                <Divider clearing />
                                                {
                                                    this.state.post.tags.length > 0 &&
                                                    <>
                                                        {_.map(this.state.post.tags, (e, i) => {
                                                            return (
                                                                this.state.post &&
                                                                <Button key={i} size="mini" basic color="grey" content={this.state.post.tags[i]} />
                                                            );
                                                        }
                                                        )}
                                                        <Divider />
                                                    </>
                                                }
                                            </div>
                                        }
                                        {this.state.comments &&
                                            <TubeComments
                                                expand={this.state.expand}
                                                comments={this.state.comments}
                                                onChangeComment={this.onChangeComment}
                                                onDeleteComment={this.onDeleteComment}
                                                onAddComment={this.addComment}
                                                showCommendForm={this.state.showCommentForm}
                                                whiteTheme={true}
                                            />}

                                    </>
                                }

                            </Container>
                        </div>
                    </div>
                </Mainframe >
            </>
        );
    }
    private goBack = () => {
        // this.props.history.goBack();
        this.props.history.push(`${config.routes.tube.main}/light`);
    }
    private toggleTheme = () => {
        this.props.history.push(`${config.routes.tube.video}/${this.props.match.params.id}`);
    }
    private toggleExpand = () => {
        this.setState({ expand: !this.state.expand });
        this.state.expand ? this.setState({ showInfo: false }) : this.setState({ showInfo: true });

    }

    private setBookmark = () => {
        if (this.state.post) {
            if (this.state.post.isBookmarked)
                BookmarkController.RemoveBookmark({ postid: this.state.post.id }, post => {
                    this.setState({ post: post as TubePostDownloadDto })
                });
            else
                BookmarkController.PostBookmark({ postid: this.state.post.id }, post => {
                    this.setState({ post: post as TubePostDownloadDto })
                });
        }
    }

    private addComment = (comment: string) => {
        const post = {} as PostDownloadDto;
        if (this.state.post) {
            post.id = this.state.post.id;
            PostInteraction.AddPostComment({
                id: post.id,
                comment: {
                    comment, rating: 0
                }
            },
                (c) => {
                    const comments = c && c.comments ? c.comments.reverse() : [];
                    this.setState({ comments, newComment: "" });
                },
                () => { });
        }
    }

    private onAddRating = (comment: string, rating: number) => {
        //        console.log("c => ", comment, ", r => ", rating);
        const post = {} as PostDownloadDto;
        if (this.state.post) {
            post.id = this.state.post.id;
            PostInteraction.AddPostRating({
                id: post.id,
                comment: {
                    comment, rating
                }
            },
                (c) => {
                    const ratings = c && c.comments ? c.comments.reverse() : [];
                    this.setState({ ratings, newComment: "" });
                },
                () => { });
        }
    }

    private onDeleteComment = (idx: number) => {
        if (this.state.comments && this.state.post) {
            const comments = _.cloneDeep(this.state.comments);
            const commentElement = comments.splice(idx, 1);

            PostInteraction.DeletePostComment({ id: this.state.post.id, commentToDelete: commentElement[0] },
                (c) => {
                    const comments = c && c.comments ? c.comments.reverse() : [];
                    this.setState({ comments, newComment: "" });
                },
                () => { });
        }
    }
    private onChangeComment = (idx: number, comment: string) => {
        if (this.state.comments && this.state.post) {
            const comments = _.cloneDeep(this.state.comments);
            const commentElement = comments[idx];
            commentElement.comment = comment;

            PostInteraction.UpdatePostComment({ id: this.state.post.id, commentToPatch: commentElement },
                (c) => {
                    const comments = c && c.comments ? c.comments.reverse() : [];
                    this.setState({ comments, newComment: "" });
                },
                () => { });
        }
    }
    private toggleRating = () => {
        this.setState({ showRating: !this.state.showRating });
    }
    private toggleInfo = () => {
        this.setState({ showInfo: !this.state.showInfo });
    }
    private toggleCommentForm = () => {
        this.setState({ showCommentForm: !this.state.showCommentForm });
    }

    private loadComments = async (props?: TubeVideoLightProps) => {
        const p = props ? props : this.props;
        const id = p.match.params.id;
        const [post, comments, ratings, vs] = await ControllerHelper
            .addCall({ id }, PostController.GetSinglePost)
            .addCall({ id }, PostInteraction.GetPostComments)
            .addCall({ id }, PostInteraction.GetPostRatings)
            .addCall({ postIds: [id] }, PostControllerTube.GetPostsViews)
            .execute<PostDownloadDto, UserCommentsDownloadDto, UserRatingsDownloadDto, ViewStats[]>();
        if (post) {
            let views = 0;
            if (vs && vs.length > 0 && vs[0].views)
                views = vs[0].views;
            (post as TubePostDownloadDto).views = views;
            this.setState({
                post: post as TubePostDownloadDto,
                comments: comments.comments.reverse(),
                ratings: ratings.comments.reverse()
            });

            this.postHubReg = this.props.state.postHubHelper.subscribe(this.onUpdatePost);
            await this.postHubReg.signForIds([post.id]);

            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: post.group_id,
                payload: { "PostId": post.id, browser: getBrowser() }
            };
            EventController.PostEvent({ ev });
        }
    }

    private onUpdatePost = (post: PostDownloadDto) => {
        if (!this.state.post || post.commentCount !== this.state.post.commentCount)
            this.loadComments();
        else
            this.setState({ post: post as TubePostDownloadDto });
    }
}
export default sizeConditional()(withRouter(translate("tube")(connect((state: IState) => ({ state }))(TubeVideoLight))));