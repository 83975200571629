import { EventController, UserDownloadDto, ScormController } from 'collaboration-service';
import RequestPasswordReset from 'components/General/RequestPasswordReset/RequestPasswordReset';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Icon, Image } from 'semantic-ui-react';
import { ActionCreators } from 'services/Actions';
import { config } from 'services/Config';
import { checkForCredentials, getBrowser } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';
import MobileHelper from 'services/MobileHelper';
import ScormFooter from './ScormFooter';
import ScormHeader from './ScormHeader';
import ScormLoaderDSJ from './ScormLoaderDSJ';

interface ScormLoginProps extends RouteComponentProps<any>, IConnectedComponent {

}
interface ScormLoginState {
    showAlert: boolean;
    keepMeSignedIn: boolean;
    showPassword: boolean;
    loginFail: boolean;
    email: string,
    password: string,
    waiting: boolean;
    ready: boolean;
    requestResetPassword: boolean;
    onDone?: () => void;
}

class ScormLogin extends Component<ScormLoginProps, ScormLoginState> {
    private mounted: boolean | undefined;
    private inputPwd: any;
    private inputUser: any;

    constructor(props: ScormLoginProps) {
        super(props);
        this.state = {
            showAlert: false,
            keepMeSignedIn: false,
            showPassword: false,
            loginFail: false,
            email: "", //"sascha.riexinger@imaginarity.de",
            password: "", // "geheim",
            waiting: false,
            ready: false,
            requestResetPassword: false
        }
    };
    public UNSAFE_componentWillMount() {
        this.mounted = true;
        this.setState({ onDone: undefined });
        checkForCredentials(this, this.inputUser, (v) => {
            if (!v && this.props.state.userInfo)
                ScormController.RegisterForAllCourses({}, (registrations) => {
                    this.props.dispatch(ActionCreators.SetScormRegistrations(registrations));
                });
            if (this.mounted)
                this.setState({ ready: !v });
        });
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if (this.props.state.userInfo && !this.state.waiting && this.props.state.scormRegistrations) {
            if (!this.props.state.userInfo.registered || this.props.state.userInfo.showGroupWelcome) {
                return (<Redirect to="/welcome/-/-" />);
            }
            else {
                const to = this.whereToGo();
                if (!this.state.ready) {
                    EventController.PostEvent({
                        ev: {
                            eventType: "AutoLogin",
                            payload: { browser: getBrowser() },
                        }
                    });
                }
                return (<Redirect to={to} />);
            }
        }

        return (
            <>
                <div className="ScormOuterContainer" style={{ backgroundImage: "url('./images/scorm/background.jpg')", position: "relative" }}>
                    <ScormHeader />
                    {this.state.waiting && <ScormLoaderDSJ speed={4} onDone={this.state.onDone} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100000 }} />}
                    <div className="ScormContentFixed" style={{ filter: (this.state.waiting ? "blur(50px)" : "none") }}>
                        <>
                            <div className="ScormContainerTitleBig">Dealers Sales Journey</div>
                            <div className="ScormContainerTitleSmall">Login</div>
                            <div className="ScormContainerIntro">Use your Porsche e-mail address and your password from the Porsche e-learning platform.</div>
                            <div className="ScormContainerLoginEmailInput">
                                <input
                                    placeholder="E-mail address"
                                    value={this.state.email}
                                    className="InputLogin"
                                    onKeyDown={this.handleUser}
                                    ref={this.handleRefUser}
                                    onChange={this.updateUser}

                                />
                            </div>
                            <div className="ScormContainerLoginPasswordInput">
                                <input
                                    type={!this.state.showPassword ? "password" : "text"}
                                    value={this.state.password}
                                    placeholder="Password"
                                    className="InputPassword"
                                    onKeyDown={this.handlePassword}
                                    ref={this.handleRefPwd}
                                    onChange={this.updatePassword}
                                />
                                <div className="ScormContainerLoginPasswordInputImage" onClick={this.toggleShowPassword}>
                                    <Image src={this.state.showPassword ? "./images/scorm/eyecopen.png" : "./images/scorm/eyeclosed.png"} className="InputPasswordEyeImage" />
                                </div>
                            </div>
                            <div className="ContainerKeepMeSignedIn">
                                <div className="ContainerKeepMeSignedInCheckbox" onClick={this.toggleKeepMeSignedIn}>
                                    {this.state.keepMeSignedIn &&
                                        <Image src="./images/scorm/checkmark.svg" className="CheckmarkImage" />
                                    }
                                </div>
                                <div className="ContainerKeepMeSignedInText" onClick={this.toggleKeepMeSignedIn}>
                                    Keep me signed in
                                </div>
                                <div className="ContainerForgotPasswordLink" onClick={() => this.setState({ requestResetPassword: true })}>
                                    <Image src="./images/scorm/arrow_secondary_button.svg" className="ArrowRightForgotImage" />
                                    Forgot Password?
                                </div>
                            </div>
                            <div className="ContainerLoginButtonSend">
                                <Button color="blue" disabled={this.state.email === "" || this.state.password === ""} className="ScormLoginButton" onClick={this.handleLogin}>
                                    Submit
                                    </Button>
                            </div>
                        </>
                    </div>
                    <ScormFooter />
                </div>
                {
                    this.state.showAlert &&
                    <div className="ScormContentAlert" onClick={this.toggleShowAlert}>
                        The password or e-mail address you entered is not correct. Please try again. <Icon name="times" style={{ float: "right" }} />
                    </div>
                }
                <RequestPasswordReset username={this.state.email} open={this.state.requestResetPassword} onClose={this.onDoneRequestPassword} />

            </>
        )
    }

    private onDoneRequestPassword = () => {
        this.setState({ requestResetPassword: false });
    }

    private whereToGo = () => {
        if (this.props.location && this.props.location.state)
            return (this.props.location.state as any).from;
        else
            return config.routes.scorm.overview;
    }
    private toggleKeepMeSignedIn = () => {
        if (this.mounted) this.setState({ keepMeSignedIn: !this.state.keepMeSignedIn });
    }
    private toggleShowPassword = () => {
        if (this.mounted) this.setState({ showPassword: !this.state.showPassword });
    }
    private toggleShowAlert = () => {
        if (this.mounted) this.setState({ showAlert: !this.state.showAlert });
    }

    private handleRefUser = (c: any) => { this.inputUser = c; };
    private handleRefPwd = (c: any) => { this.inputPwd = c; };
    private focusPwd = () => { this.inputPwd.focus(); };

    private handleUser = (e: any) => {
        if (e.which === 13) {
            e.preventDefault();
            this.focusPwd();
        }
    }

    private handlePassword = (e: any) => {
        if (e.which === 13) {
            e.preventDefault();
            this.handleLogin();
        }
    }

    private handleLogin = () => {
        this.props.dispatch(ActionCreators.InitState());
        if (this.mounted)
            this.setState({ waiting: true, showAlert: false, onDone: undefined });
        if (MobileHelper.getInstance().mobileClient()) {
            MobileHelper.getInstance().login(this.state.email.trim(), this.state.password);
        }
        else {
            this.props.dispatch(ActionCreators.globalState.Login(this.state.email.trim(), this.state.password,
                this.loginSuccessUser,
                this.loginError
            ));
        }
    }

    private loginSuccessUser = (user: UserDownloadDto) => {
        if (this.mounted)
            ScormController.RegisterForAllCourses({}, (registrations) => {
                this.props.dispatch(ActionCreators.SetScormRegistrations(registrations));
                this.setState({
                    showAlert: false, onDone: () => {
                        this.props.history.push(this.whereToGo());
                    }
                });
            });
    }
    private loginError = () => {
        if (!this.mounted)
            return;
        this.setState({ waiting: false, showAlert: true });
    }

    private updateUser = (e: any) => {
        if (this.mounted)
            this.setState({ email: e.target.value });
    }

    private updatePassword = (e: any) => {
        if (this.mounted)
            this.setState({ password: e.target.value });
    }
}
export default withRouter(connect((state: IState) => ({ state }))(ScormLogin));