import { EventController, PublicKeyDownloadDto, UserController, UserDownloadDto } from "collaboration-service";
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, Input, Loader, Segment } from 'semantic-ui-react';
import MobileHelper from "services/MobileHelper";
import { ActionCreators } from '../../../services/Actions';
import { config } from '../../../services/Config';
import { checkForCredentials, createMainframeButtons, getBrowser } from '../../../services/Helpers';
import { IConnectedComponent, IState } from '../../../services/Interfaces';
import ImgI18N, { IIMGTranslatedComponent, translate } from '../../Utils/img-i18n';
import Mainframe, { IButtonInformation } from '../Mainframe/Mainframe';
import RequestPasswordReset from "../RequestPasswordReset/RequestPasswordReset";

const logPush = false;

interface ILoginProps extends IConnectedComponent, IIMGTranslatedComponent {
    // nothing
    nothing?: any;
}

interface ILoginState {
    username: string;
    password: string;
    inputpwd: boolean;
    loginFail: boolean;
    resetPwdModal: boolean;
    waiting: boolean;
    ready: boolean;
    requestResetPassword?: boolean;
}

class Login extends React.Component<ILoginProps & RouteComponentProps<any>, ILoginState>{
    public state: ILoginState = {
        username: "", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de", // "sascha.riexinger@imaginarity.de",
        password: "", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim", // "geheim",
        inputpwd: false,
        loginFail: false,
        resetPwdModal: false,
        waiting: false,
        ready: false,
    };

    private mounted: boolean | undefined;
    private inputPwd: any;
    private inputUser: any;

    public openFullscreen = () => {
        const elem: any = document.getElementById("body");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.requestFullScreen) {
            elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    public UNSAFE_componentWillMount = () => {
        this.mounted = true;
        MobileHelper.getInstance().loginErrorHandler = this.loginError;
        MobileHelper.getInstance().loginSuccessHandler = this.loginSuccess;
        checkForCredentials(this, this.inputUser, (v) => {
            if (this.mounted)
                this.setState({ ready: !v });
        });

        const lng = ImgI18N.getInstance().getBrowserLanguage();
        if (lng)
            this.props.i18n.changeLanguage(lng.value);
    }

    public componentWillUnmount() {
        this.mounted = false;
        MobileHelper.getInstance().loginErrorHandler = undefined;
        MobileHelper.getInstance().loginSuccessHandler = undefined;
    }

    public render = () => {
        const x: Array<undefined | IButtonInformation> = createMainframeButtons(this,
            { button2: { buttonContent: config.icons.next, onClick: () => console.log(x) } });

        const { t } = this.props;
        if (this.props.state.userInfo) {
            MobileHelper.getInstance().autoLogin();
            if (!this.props.state.userInfo.registered || this.props.state.userInfo.showGroupWelcome) {
                return (<Redirect to="/welcome/-/-" />);
            }
            else {
                if (this.props.location) {
                    if (this.props.location.state) {
                        const { from } = this.props.location.state as any;
                        if (!this.state.ready) {
                            EventController.PostEvent({
                                ev: {
                                    eventType: "AutoLogin",
                                    payload: { browser: getBrowser() },
                                }
                            });
                        }
                        return (<Redirect to={from} />);
                    }
                    else {
                        if (!this.state.ready) {
                            EventController.PostEvent({
                                ev: {
                                    eventType: "AutoLogin",
                                    payload: { browser: getBrowser() },
                                }
                            });
                        }
                        return (<Redirect to={config.routes.general.grouphub} />);
                    }
                }
            }
        }

        if (!this.state.ready)
            return null;
        // console.log("request login!!");
        MobileHelper.getInstance().requestLogin();
        return (
            <div className="LoginMFBackgroundStyle">
                <Mainframe buttons={x} className="LoginMainFrame" staticBackgroundImage="itc/PAGfront.jpg">
                    {/* <div style={{ color: "black" }}>token=#{this.props.state.client.token}#</div>
                    <div style={{ color: "black" }}>info=#{MobileHelper.getInstance().showInfo()}#</div> */}
                    {this.state.loginFail &&
                        <div className="LoginFailedContainer" onClick={this.toggleLoginFail}>
                            {t('Login failed')}
                        </div>
                    }

                    <Segment className={this.state.loginFail ? "Headline LoginSegment LoginFailedContainerMargin" : "Headline LoginSegment"}>
                        {this.props.t('login mask header')}
                    </Segment>
                    {/* <Mainframe buttons={x} header={this.props.t('login mask header')} backgroundImage="http://cmotive.mossig.de/LoginBG.png"> */}
                    <Segment textAlign='center' className="LoginSegment" >
                        <Form>
                            <h1 className="Invite-Label">{t('Login')}</h1>
                            <Form.Field>
                                <Input
                                    className="LoginInput"
                                    autoComplete="email"
                                    onKeyDown={this.handleUser}
                                    onChange={this.updateUser}
                                    ref={this.handleRefUser}
                                    icon='user'
                                    iconPosition='left'
                                    placeholder={t('Username')}
                                    value={this.state.username} />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    className="LoginInput"
                                    autoComplete="current-password"
                                    onKeyDown={this.handlePassword}
                                    onChange={this.updatePassword}
                                    ref={this.handleRefPwd}
                                    icon='key'
                                    iconPosition='left'
                                    placeholder={t('Password')}
                                    type="password"
                                    value={this.state.password} />
                            </Form.Field>
                            <p className='ForgotPwd' onClick={this.onRequestPassword}>{t('Forgot password?')}</p>
                            <Button disabled={this.state.waiting} onClick={this.handleLogin} primary={true}>{t('Login')}</Button>
                            <RequestPasswordReset username={this.state.username} open={this.state.requestResetPassword} onClose={this.onDoneRequestPassword} />
                            {/* <PopupModal open={this.state.loginFail} centered={true} buttonClick={this.toggleLoginFail}
                                headline={this.props.t("Login")}
                            >
                                {t('Login failed')}
                            </PopupModal> */}
                        </Form>
                    </Segment>
                    {
                        (this.state.waiting) &&
                        <Loader inverted={true} inline={true} active={true} >{t('Logging in')}</Loader>
                    }
                </Mainframe >
            </div>
        );
    }

    private onRequestPassword = () => this.setState({ requestResetPassword: true });
    private onDoneRequestPassword = () => this.setState({ requestResetPassword: false });

    private doneResetPwd = () => {
        this.setState({ resetPwdModal: false, waiting: false });
    }


    private openResetPwdDialog = () => {
        this.setState({ resetPwdModal: true });
    }

    private updateSubscriptionOnServer = (subscription: PushSubscription, key: PublicKeyDownloadDto) => {
        // TODO: Send subscription to application server
        if (subscription) {
            if (logPush) {
                console.log(JSON.stringify(subscription));
                console.log(subscription);
            }
            UserController.PostPush({ pushData: subscription, applicationType: "Excite", publicKeyId: key.id });
        }
        else {
            if (logPush)
                console.log("registration on server failed...");
        }
    }

    private urlB64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            // eslint-disable-next-line
            .replace(/\-/g, '+')
            // eslint-disable-next-line
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    private subscribeUser = (swRegistration: ServiceWorkerRegistration, key: PublicKeyDownloadDto) => {
        const applicationServerKey = this.urlB64ToUint8Array(key.key);
        swRegistration.pushManager.subscribe(
            {
                userVisibleOnly: true,
                applicationServerKey
            })
            .then((subscription: PushSubscription) => {
                if (logPush)
                    console.log('User is subscribed.');
                this.updateSubscriptionOnServer(subscription, key);
            })
            .catch((err) => {
                if (logPush)
                    console.log('Failed to subscribe the user: ', err);
            });
    }

    private checkPushSubscription = (swRegistration: ServiceWorkerRegistration, key: PublicKeyDownloadDto) => {
        // Set the initial subscription value
        swRegistration.pushManager.getSubscription()
            .then((subscription) => {
                if (subscription === null) {
                    if (logPush)
                        console.log("not subscribed - subscribe.");
                    this.subscribeUser(swRegistration, key);
                }
                else {
                    if (logPush)
                        console.log("already subscribed - unsubscribe, subscribe.");
                    subscription.unsubscribe().then(
                        () => this.subscribeUser(swRegistration, key),
                        () => this.subscribeUser(swRegistration, key));
                }
            }, (reason: any) => {
                if (logPush) {
                    console.log("getting subscriptions failed");
                    console.log(reason);
                }
            });
    }

    private initPushNotifications = (val: PublicKeyDownloadDto) => {
        if (logPush)
            console.log("login done");
        if (navigator && navigator.serviceWorker) {
            if (logPush)
                console.log("navigator and so on is there");
            navigator.serviceWorker.getRegistration().then((swreg) => {
                if (logPush) {
                    console.log("got service worker");
                    console.log(swreg);
                }
                if (swreg)
                    this.checkPushSubscription(swreg, val);
            },
                (reason) => {
                    if (logPush)

                        console.log("cannot get servic worker registration");
                });
        }
    }

    private handleLogin = () => {
        this.props.dispatch(ActionCreators.InitState());
        this.setState({ waiting: true });
        if (MobileHelper.getInstance().mobileClient()) {
            MobileHelper.getInstance().login(this.state.username.trim(), this.state.password);
        }
        else {
            this.props.dispatch(ActionCreators.globalState.Login(this.state.username.trim(), this.state.password,
                this.loginSuccessUser,
                this.loginError
            ));
        }
    }
    private loginSuccess = (token: string) => {
        if (!this.mounted)
            return;
        this.props.dispatch(ActionCreators.TokenReceived(token));
        this.props.dispatch(ActionCreators.globalState.GetUserinfo(this.loginSuccessUser, this.loginError));
    }

    private loginSuccessUser = (user: UserDownloadDto) => {
        if (logPush)
            console.log("lsu => ", user, ", mt =", this.mounted);
        if (this.mounted)
            this.setState({ waiting: false });
        if (!MobileHelper.getInstance().mobileClient()) {
            if (logPush)
                console.log("1");
            if (logPush)
                console.log("2");
            UserController.GetPublicKey({}, (publickey) => this.initPushNotifications(publickey));
        }
        else
            MobileHelper.getInstance().registerPushNotifications();
    }
    private loginError = () => {
        if (!this.mounted)
            return;
        this.setState({ waiting: false });
        this.toggleLoginFail();
    }

    private toggleLoginFail = () => {
        this.setState({ loginFail: true, password: '' });
    }


    /*   FOCUS HANDLING   */
    private handleRefUser = (c: any) => { this.inputUser = c; };
    private handleRefPwd = (c: any) => { this.inputPwd = c; };
    private focusPwd = () => { this.inputPwd.focus(); };

    private handleUser = (e: any) => {
        if (e.which === 13) {
            e.preventDefault();
            this.focusPwd();
        }
    }

    private handlePassword = (e: any) => {
        if (e.which === 13) {
            e.preventDefault();
            this.handleLogin();
        }
    }

    private updateUser = (e: any) => {
        this.setState({ username: e.target.value });
    }

    private updatePassword = (e: any) => {
        this.setState({ password: e.target.value });
    }
}

export default translate("login")(withRouter((connect((state: IState) => ({ state }))(Login))));
