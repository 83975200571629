import * as React from 'react';
import { Button, Divider, Popup, SemanticFLOATS } from 'semantic-ui-react';
import { SemanticSIZES, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface IButtonUploadProps {
    text?: string;
    fluid?: boolean;
    size?: SemanticSIZES;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
    floated?: SemanticFLOATS;
    color?: SemanticCOLORS;
    notBasic?: boolean;
    icon?: SemanticICONS;
}

export default class ButtonUpload extends React.Component<IButtonUploadProps, {}> {

    public render() {
        const Btn = (
            <Button
                size={this.props.size ? this.props.size : undefined}
                fluid={this.props.fluid}
                basic={this.props.notBasic ? false : true}
                color={this.props.color ? this.props.color : undefined}
                icon={this.props.icon ? this.props.icon : 'upload'}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                floated={this.props.floated}
                content={this.props.text}
            />
        );
        return (
            <>
                {this.props.tooltip ?
                    <Popup position='right center'
                        className='ComUserPopup'
                        color={this.props.color ? this.props.color : 'green'}
                        trigger={
                            Btn
                        }
                    >
                        <div className='ButtonStyle'>
                            <Divider className='Upload' horizontal>{this.props.tooltip}</Divider>
                        </div>
                    </Popup>
                    :
                    Btn

                }
            </>
        );
    }
}
