import { ControllerHelper, GroupController, GroupDownloadDto, OrganisationController, PositionController, UserController, UserShortInfoDownloadDto, UserUploadDto } from "collaboration-service";
import MissingInput from 'components/General/MissingInput/MissingInput';
import RoleSelection from 'components/General/RoleSelection/RoleSelection';
import TreeGroupSelection from 'components/General/TreeGroupSelection/TreeGroupSelection';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import { Button, Dimmer, Form, Grid, Icon, Input, Loader, Modal, Segment, Tab } from 'semantic-ui-react';
import { config } from 'services/Config';
import Mainframe from '../../../components/General/Mainframe/Mainframe';
import { IIMGTranslatedComponent, translate } from '../../../components/Utils/img-i18n';
import { getFirstFromValueType, isEmailAddress } from '../../../services/Helpers';
import { IConnectedComponent, IState } from '../../../services/Interfaces';
import ExcelInvite from './ExcelInvite';

interface UserValuePair {
    value: UserShortInfoDownloadDto;
    label: string;
}

interface IInviteState {
    email: string;
    firstName: string;
    secondName: string;
    title: string;
    mobile: boolean;
    groups?: GroupDownloadDto[];
    selectedGroup?: GroupDownloadDto;
    selectedRole: string;
    position?: string;
    organization?: string;
    supervisor?: UserShortInfoDownloadDto;
    submitted: boolean;
    success: boolean;
    failed: boolean;
    msg: string;
    loading?: boolean;

    organisations?: ValueLabelStringPair[];
    positions?: ValueLabelStringPair[];
    userList?: UserValuePair[];

    inviteAllowed: boolean;
    width: number;
    height: number;
}

interface IInviteProps extends IIMGTranslatedComponent, IConnectedComponent {
    // nothing
    nothing?: any;
}

export interface ValueLabelStringPair {
    label: string;
    value: string;
}

const organisationValue: React.SFC<SingleValueProps<ValueLabelStringPair>> = (props: SingleValueProps<ValueLabelStringPair>) => {
    return <div><Icon name="factory" className="SelectIcon" /><span className="SelectText">{props.children}</span></div>;
};

const organisationPlaceholder: React.SFC<PlaceholderProps<ValueLabelStringPair>> = (props: PlaceholderProps<ValueLabelStringPair>) => {
    return <div><Icon name="factory" className="SelectIcon" /><span className="SelectPlaceholder">{props.children}</span></div>;
};

const positionValue: React.SFC<SingleValueProps<ValueLabelStringPair>> = (props: SingleValueProps<ValueLabelStringPair>) => {
    return <div><Icon name="briefcase" className="SelectIcon" /><span className="SelectText">{props.children}</span></div>;
};

const positionPlaceholder: React.SFC<PlaceholderProps<ValueLabelStringPair>> = (props: PlaceholderProps<ValueLabelStringPair>) => {
    return <div><Icon name="briefcase" className="SelectIcon" /><span className="SelectPlaceholder">{props.children}</span></div>;
};

const bossValue: React.SFC<SingleValueProps<UserValuePair>> = (props: SingleValueProps<UserValuePair>) => {
    return <div><Icon name="address card" className="SelectIcon" /><span className="SelectText">{props.children}</span></div>;
};

const bossPlaceholder: React.SFC<PlaceholderProps<UserValuePair>> = (props: PlaceholderProps<UserValuePair>) => {
    return <div><Icon name="address card" className="SelectIcon" /><span className="SelectPlaceholder">{props.children}</span></div>;
};

export const checkMobile = (width: number, height: number) => {
    return width < 1000; // || height < 700;
};

// address card
class Invite extends React.Component<IInviteProps & RouteComponentProps<any>, IInviteState>{

    constructor(props: IInviteProps & IConnectedComponent & RouteComponentProps<any>) {
        super(props);
        this.state = {
            email: "",
            firstName: "",
            secondName: "",
            title: "",
            selectedRole: "",
            submitted: false,
            success: false,
            failed: false,
            msg: '',
            inviteAllowed: false,
            mobile: false,
            width: 0,
            height: 0,
            loading: true
        };
    }

    public UNSAFE_componentWillMount() {
        GroupController.GetHierarchy({}, (val) => this.setState({ groups: val.elements }));
    }

    public render() {
        const { t } = this.props;
        const missing = this.checkInviteAllowed();

        const panes = [
            {
                menuItem: t('single user'), render: () =>
                    <Tab.Pane attached={false}>
                        <Dimmer active={this.state.loading}>
                            <Loader>{t("loading")}</Loader>
                        </Dimmer>
                        <Form>
                            <Grid stackable stretched container columns={2}>

                                {this.state.groups &&
                                    <Grid.Column>
                                        <Form.Field>
                                            <label><Icon name="chevron right" color="red" />{t('group')}</label>
                                            <TreeGroupSelection
                                                onGroupSelected={this.groupSelect}
                                                value={this.state.selectedGroup}
                                                onLoading={this.setLoading}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                }
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{t('user role')}</label>
                                        <RoleSelection
                                            onRoleChange={this.roleSelect}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field >
                                        <label><Icon name="chevron right" color="grey" /><span style={{ color: "#989A9C" }}>{t('title')}</span></label>
                                        <Input type='text' placeholder={t('title')} onChange={this.handleTitleChange} className="InviteInputIconColor" icon='user md' iconPosition='left' />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field >
                                        <label><Icon name="chevron right" color="red" />{t('first name')}</label>
                                        <Input required={true} type='text' placeholder={t('first name')} onChange={this.handleFirstNameChange} icon='user' className="InviteInputIconColor" iconPosition='left' />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{t('last name')}</label>
                                        <Input required={true} type='text' placeholder={t('last name')} onChange={this.handleSecondNameChange} icon='users' className="InviteInputIconColor" iconPosition='left' />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="red" />{t('email')}</label>
                                        <Input required={true} type='email' placeholder={t('email')} className="InviteInputIconColor" onChange={this.handleEmailChange} icon='at' iconPosition='left' />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="grey" /><span style={{ color: "#989A9C" }}>{t('organization')}</span></label>
                                        <CreatableSelect
                                            options={this.state.organisations}
                                            placeholder={t("organization")}
                                            components={{
                                                SingleValue: organisationValue,
                                                Placeholder: organisationPlaceholder
                                            }}
                                            formatCreateLabel={this.formatCreateLabel}
                                            onChange={this.handleOrganizationChange}
                                            styles={{ option: (base) => ({ ...base }) }}
                                            classNamePrefix="rselect"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="grey" /><span style={{ color: "#989A9C" }}>{t('position')}</span></label>
                                        <CreatableSelect
                                            options={this.state.positions}
                                            placeholder={t("position")}
                                            components={{
                                                SingleValue: positionValue,
                                                Placeholder: positionPlaceholder,
                                            }}
                                            styles={{ option: (base) => ({ ...base }) }}
                                            formatCreateLabel={this.formatCreateLabel}
                                            onChange={this.handlePositionChange}
                                            classNamePrefix="rselect"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="grey" /><span style={{ color: "#989A9C" }}>{t('supervisor')}</span></label>
                                        <ReactSelect
                                            options={this.state.userList}
                                            placeholder={t("supervisor")}
                                            components={{
                                                SingleValue: bossValue,
                                                Placeholder: bossPlaceholder
                                            }}
                                            styles={{ option: (base) => ({ ...base }) }}
                                            onChange={this.handleSupervisorChange}
                                            classNamePrefix="rselect"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label><Icon name="chevron right" color="grey" /><span style={{ color: "#989A9C" }}>{t('controls')}</span></label>
                                        <Button icon="chevron left" labelPosition="left" basic onClick={this.goBack} content={t('back')} floated="left" style={{ width: "45%" }} />
                                        {this.state.inviteAllowed ?
                                            <Button icon="user plus" content={t('invite user')} type='submit'
                                                onClick={this.handleInvite}
                                                basic={!this.state.inviteAllowed}
                                                floated="right"
                                                labelPosition="left"
                                                color={this.state.inviteAllowed ? "green" : undefined}
                                                style={{ width: "45%" }}
                                            />
                                            :
                                            <Button
                                                color="red"
                                                basic
                                                disabled
                                                style={{ width: "45%" }}
                                                labelPosition="left"
                                                floated="right"
                                                icon="user plus" content={t('missing input')}
                                            />
                                        }
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </Form>
                        <MissingInput missing={missing} />

                    </Tab.Pane>
            },
        ];

        if (config.adminFeatures.excelInvite)
            panes.push({
                menuItem: 'excel import', render: () =>
                    <Tab.Pane attached={false}>
                        <ExcelInvite />
                    </Tab.Pane>
            });

        return (
            <div className="MFBackgroundStyle" style={{ minHeight: "100vh" }}>
                <div className="colorBackground" style={{ minHeight: 0 }} />
                <Mainframe
                    title={t('invite user!')}
                    onResize={this.onResize}
                    padding='0'
                    width={16}
                // className='MainFeed'
                >
                    <Segment>
                        <Tab menu={{ secondary: true, pointing: true }} panes={panes} className="redBorder" />
                    </Segment>
                    <Modal
                        dimmer="blurring"
                        open={this.state.success || this.state.failed}
                        closeOnDimmerClick={true}
                        closeOnDocumentClick={true}
                        onClose={this.onModalClose}
                        size='mini'
                    >
                        <Modal.Header>
                            {this.state.success && t('success')}
                            {this.state.failed && t('error')}
                        </Modal.Header>
                        <Modal.Content>
                            {this.state.failed &&
                                <Modal.Description>
                                    {this.state.success && t('User has been successfully added!')}
                                    {this.state.failed && t('An error occurred! User has not been added!')}
                                    {JSON.stringify(this.state.msg)}
                                </Modal.Description>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            {this.state.success &&
                                <Button
                                    color='green'
                                    onClick={this.onInviteTried}
                                    icon='checkmark'
                                    inverted />
                            }
                            {this.state.failed &&
                                <Button
                                    color='red'
                                    onClick={this.onInviteTried}
                                    icon='checkmark'
                                    inverted />
                            }
                        </Modal.Actions>
                    </Modal>
                </Mainframe>
            </div>
        );
    }

    private formatCreateLabel = (val: string) => {
        return <div>{val}</div>;
    }
    private roleSelect = (selectedRole: string) => {
        this.setState({ selectedRole });
    }

    private groupSelect = async (val: ValueType<GroupDownloadDto>) => {
        const selectedGroup = getFirstFromValueType(val);
        if (selectedGroup) {
            this.setState({ selectedGroup, organisations: undefined, positions: undefined, userList: undefined });
            if (selectedGroup && selectedGroup.id) {
                const groupid = selectedGroup.id;
                const [users, organisations, positions] = await ControllerHelper
                    .addCall({ groupid }, UserController.GetUserListForGroup)
                    .addCall({ groupid }, OrganisationController.Get)
                    .addCall({ groupid }, PositionController.Get)
                    .execute<UserShortInfoDownloadDto[], string[], string[]>();
                this.setState({
                    userList: users.map(v => ({ value: v, label: v.firstName + " " + v.secondName })),
                    organisations: organisations.map((v) => ({ label: v, value: v })),
                    positions: positions.map(v => ({ value: v, label: v }))
                });
            }
        }
    }

    private handleEmailChange = (e: any) => {
        this.setState({
            email: e.target.value
        });
    }

    private handleOrganizationChange = (val: ValueType<ValueLabelStringPair>) => {
        const e = getFirstFromValueType(val);
        if (e)
            this.setState({ organization: e.label });
    }

    private handlePositionChange = (val: ValueType<ValueLabelStringPair>) => {
        const e = getFirstFromValueType(val);
        if (e)
            this.setState({ position: e.label });
    }

    private handleSupervisorChange = (val: ValueType<UserValuePair>) => {
        const e = getFirstFromValueType(val);
        if (e)
            this.setState({ supervisor: e.value });
    }
    private handleTitleChange = (e: any) => {
        this.setState({
            title: e.target.value,
        });

    }

    private handleFirstNameChange = (e: any) => {
        this.setState({
            firstName: e.target.value,
        });

    }

    private handleSecondNameChange = (e: any) => {
        this.setState({
            secondName: e.target.value,
        });

    }

    private handleInvite = () => {
        this.setState({ submitted: true });
        if (this.state.inviteAllowed) {
            const user: UserUploadDto = {
                firstName: this.state.firstName,
                secondName: this.state.secondName,
                title: this.state.title,
                email: this.state.email,
                organisation: this.state.organization ? this.state.organization : "",
                position: this.state.position ? this.state.position : "",
                boss: this.state.supervisor,
                additionalPositions: [],
                organisationUnits: [],
                phone: "",
                additionalInformation: {},

            };
            if (this.state.selectedGroup)
                UserController.Post({ groupid: this.state.selectedGroup.id, role: this.state.selectedRole, user },
                    (val) => { this.setState({ success: true }); },
                    (err) => { this.setState({ failed: true, msg: JSON.stringify(err) }); }
                );
        }
    }

    private checkInviteAllowed = () => {
        const missing: string[] = [];
        if (!this.state.selectedGroup)
            missing.push(this.props.t("select a group"));
        if (!this.state.selectedRole)
            missing.push(this.props.t("select a role"));
        if (!this.state.firstName || this.state.firstName.length === 0)
            missing.push(this.props.t("enter user's first name"));
        if (!this.state.secondName || this.state.secondName.length === 0)
            missing.push(this.props.t("enter user's second name"));
        if (!this.state.email || this.state.email.length === 0)
            missing.push(this.props.t("enter user's email address"));
        if (this.state.email && this.state.email.length > 0 &&
            !isEmailAddress(this.state.email))
            missing.push(this.props.t("email address is invalid"));
        const inviteAllowed = missing.length === 0;
        if (inviteAllowed !== this.state.inviteAllowed)
            this.setState({
                inviteAllowed
            });
        return missing;
    }

    private onModalClose = () => {
        this.setState({ success: false, failed: false, email: "", firstName: "", secondName: "", selectedGroup: undefined, selectedRole: "" });
    }

    private onInviteTried = () => {
        this.setState({ success: false, email: "", firstName: "", secondName: "", selectedGroup: undefined, selectedRole: "" });
        this.props.history.goBack();
    }
    private onResize = (width: number, height: number) => {
        this.setState({ mobile: checkMobile(width, height), width, height });
    }
    private goBack = () => {
        this.props.history.goBack();
    }

    private setLoading = (loading: boolean) => this.setState({ loading });

}
export default translate("excite")(connect((state: IState) => ({ state }))(Invite));
