import { AssetController, AssetDownloadDto, ControllerHelper, UserShortInfoDownloadDto } from "collaboration-service";
import UserSearchSelection from "components/General/UserSearchSelection/UserSearchSelection";
import UserTile from "components/General/UserTile/UserTile";
import { IIMGTranslatedComponent, translate } from "components/Utils/img-i18n";
import { Button, ButtonGroup, getColor, Icon, Modal, styled } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { getTranslated } from "services/Helpers";
import { Action, Description, FormRow } from "./AssetUploadSelection";


interface AssetSettingsProps extends IIMGTranslatedComponent {
    id?: string;
    active?: boolean;
    onClose: () => void;
}

interface AssetSettingsState {
    asset?: AssetDownloadDto;
    newReadUser?: UserShortInfoDownloadDto;
    newWriteUser?: UserShortInfoDownloadDto;
    writeUsers?: UserShortInfoDownloadDto[];
    readUsers?: UserShortInfoDownloadDto[];
}

const UserList = styled.div`
    width: calc(100% + 60px);
    margin-top:10px;
    min-height: 40px;
    max-height: 152px;
    border: 1px solid lightgrey;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
    overflow-y: auto;
    padding: 5px 5px 0 5px;
`;

const UserEntry = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 80px;
    -ms-grid-columns: 1fr 80px;
    background-color: ${p => getColor(p, p.theme.colors.mainBackground)};
    margin-bottom: 5px;
`;

const User = styled.div`
    grid-column: 1;
    -ms-grid-column: 1;
`;

const UserBtns = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
`;
const NoUserSelected = styled.div`
    padding: 5px;
    color: ${p => getColor(p, p.theme.colors.lightGrey)};
    svg{
        stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
    }
`;
const KlickAdd = styled.div`
    padding: 5px;
    color: ${p => getColor(p, p.theme.colors.lightGrey)};
    svg{
        stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
    }
`;


const AddUserRow = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 80px;
    -ms-grid-columns: 1fr 80px;
`;

const AddUserSelect = styled.div`
    grid-column: 1;
    -ms-grid-column: 1;
`;
const AddUserBtns = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
`;

class AssetSettings extends React.Component<AssetSettingsProps, AssetSettingsState>{
    public state: AssetSettingsState = {};

    async componentDidMount() {
        if (this.props.id) {
            this.loadAsset();
        }
        else
            this.setState({ asset: undefined });
    }

    componentDidUpdate(prevProps: AssetSettingsProps, prevState: AssetSettingsState) {
        if (this.props.id !== prevProps.id) {
            this.loadAsset();
        }
    }
    render() {
        const { writeUsers, readUsers } = this.state;
        const toHide = _.union(_.map(writeUsers, u => u.id), _.map(readUsers, u => u.id));
        return (
            <Modal
                active={this.props.active}
                header={this.props.t("edit asset")}
                width="default"
                height="full"
                fixed
                footer={[
                    <ButtonGroup key="btngroup" spacing="5px" floated="right">
                        <Button key="save" kind="secondary" icon="save" content={this.props.t("save changes")} onClick={this.save} floated="right" />
                        <Button key="close" kind="secondary" icon="times" content={this.props.t("close")} onClick={this.props.onClose} floated="left" />
                    </ButtonGroup>
                ]}
            >
                <div >
                    {this.state.asset?.referenceAsset ?
                        <>
                            <FormRow>
                                <Description>
                                    <Icon name="feed" style={{ float: "left", marginRight: 5 }} />
                                    {this.props.t("title")}
                                </Description>
                                <Action style={{ cursor: "no-drop", width: "calc(100% - 200px)", height: 40, lineHeight: "40px", border: "1px solid lightgrey", padding: "0 5px" }}>
                                    {getTranslated(this.state.asset?.reference?.headlines)}
                                </Action>
                            </FormRow>
                            <FormRow>
                                <Description>
                                    <Icon name="feed" style={{ float: "left", marginRight: 5 }} />
                                    {this.props.t("type")}
                                </Description>
                                <Action style={{ cursor: "not-allowed", width: "calc(100% - 200px)", height: 40, lineHeight: "40px", border: "1px solid lightgrey", padding: "0 5px" }}>
                                    {this.state.asset?.reference.type}
                                </Action>
                            </FormRow>
                        </>
                        :
                        <>
                            <FormRow>
                                <Description>
                                    <Icon name="box" style={{ float: "left", marginRight: 5 }} />
                                    {this.props.t("file name")}
                                </Description>
                                <Action style={{ cursor: "no-drop", width: "calc(100% - 200px)", height: 40, lineHeight: "40px", border: "1px solid lightgrey", padding: "0 5px" }}>
                                    {this.state.asset?.media.fileName}
                                </Action>
                            </FormRow>
                            <FormRow>
                                <Description>
                                    <Icon name="box" style={{ float: "left", marginRight: 5 }} />
                                    {this.props.t("media type")}
                                </Description>
                                <Action style={{ cursor: "no-drop", width: "calc(100% - 200px)", height: 40, lineHeight: "40px", border: "1px solid lightgrey", padding: "0 5px" }}>
                                    {this.state.asset?.media.mediaType}
                                </Action>
                            </FormRow>
                        </>
                    }
                    <FormRow>
                        <Description>
                            <Icon name="users" style={{ float: "left", marginRight: 5 }} />
                            {this.props.t("add users (read and write)")}
                        </Description>
                        {this.state.newWriteUser !== undefined &&
                            <Button icon="add" kind="primary" size="large" onClick={this.addWriteUser} floated="right" />
                        }
                        <Action style={{ height: "40px", width: "calc(100% - 260px)", marginTop: 1 }}>
                            <UserSearchSelection
                                onUserChanged={this.newWriteUserSelected}
                                value={this.state.newWriteUser}
                                clearable
                                idsToHide={toHide}
                            />
                            <UserList>
                                {writeUsers && writeUsers.length > 0 ?
                                    _.map(writeUsers, u => this.renderUser(u, false))
                                    :
                                    this.state.newWriteUser !== undefined ?
                                        <KlickAdd>
                                            {this.props.t("klick to add read and write user")}
                                            <Icon name="add square" marginTop={0} style={{ float: "left", marginRight: 5 }} />
                                        </KlickAdd>
                                        :
                                        <NoUserSelected>
                                            {this.props.t("no read and write user added")}
                                            <Icon name="info" marginTop={0} style={{ float: "left", marginRight: 5 }} />
                                        </NoUserSelected>

                                }
                            </UserList>
                        </Action>
                    </FormRow>
                    <FormRow>
                        <Description>
                            <Icon name="users" style={{ float: "left", marginRight: 5 }} />
                            {this.props.t("add users (read only)")}
                        </Description>
                        {this.state.newReadUser !== undefined &&
                            <Button icon="add" kind="primary" size="large" onClick={this.addReadUser} floated="right" />
                        }
                        <Action style={{ height: "40px", width: "calc(100% - 260px)", marginTop: 1 }}>
                            <UserSearchSelection
                                onUserChanged={this.newReadUserSelected}
                                value={this.state.newReadUser}
                                clearable
                                idsToHide={toHide}
                            />
                            <UserList>
                                {readUsers && readUsers.length > 0 ?
                                    _.map(readUsers, u => this.renderUser(u, true))
                                    :
                                    this.state.newReadUser !== undefined ?
                                        <KlickAdd>
                                            {this.props.t("klick to add read only user")}
                                            <Icon name="add square" marginTop={0} style={{ float: "left", marginRight: 5 }} />
                                        </KlickAdd>
                                        :
                                        <NoUserSelected>
                                            {this.props.t("no read only user added")}
                                            <Icon name="info" marginTop={0} style={{ float: "left", marginRight: 5 }} />
                                        </NoUserSelected>
                                }
                            </UserList>
                        </Action>
                    </FormRow>
                </div>
            </Modal>
        );
    }

    private loadAsset = () => {
        this.setState({ asset: undefined, writeUsers: undefined, readUsers: undefined });
        if (this.props.id)
            AssetController.GetAsset({ id: this.props.id }, (asset) => {
                const readUsers: UserShortInfoDownloadDto[] = [];
                const writeUsers: UserShortInfoDownloadDto[] = [];
                _.forEach(asset.users, u => {
                    if (_.findIndex(asset.writingUsers, w => w === u.id) >= 0)
                        writeUsers.push(u);
                    else
                        readUsers.push(u);

                });
                this.setState({ asset, writeUsers, readUsers });
            });

    }
    private addReadUser = () => {
        if (this.state.newReadUser) {
            const readUsers = _.clone(this.state.readUsers) ?? [];
            readUsers.push(this.state.newReadUser);
            this.setState({ readUsers, newReadUser: undefined });
        }
    }

    private addWriteUser = () => {
        if (this.state.newWriteUser) {
            const writeUsers = _.clone(this.state.writeUsers) ?? [];
            writeUsers.push(this.state.newWriteUser);
            this.setState({ writeUsers, newWriteUser: undefined });
        }
    }

    private save = async () => {
        if (this.state.asset && this.state.writeUsers && this.state.readUsers) {
            const { asset, readUsers, writeUsers } = this.state;
            await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(readUsers, r => r.id), writeAccess: false }, AssetController.AddUsersAccess);
            await ControllerHelper.singleCall({ id: asset.id, userIds: _.map(writeUsers, r => r.id), writeAccess: true }, AssetController.AddUsersAccess);
            const users = _.clone(asset.users);
            const newUsers = _.union(readUsers, writeUsers);
            const toDel: string[] = [];
            _.forEach(users, u => {
                if (_.findIndex(newUsers, uu => uu.id === u.id) < 0)
                    toDel.push(u.id);
            });
            await ControllerHelper.singleCall({ id: asset.id, userIds: toDel }, AssetController.RemoveUsersAccess);
            this.loadAsset();
        }
        //        if (this.state.newUsers && this.state.newUsers.length > 0) {
        //            const asset = await ControllerHelper.singleCall({}, AssetController.AddUsersAccess)
        //        }
    }
    private renderUser = (u: UserShortInfoDownloadDto, promote?: boolean) => {
        const mod = () => {
            const readUsers = _.clone(this.state.readUsers) ?? [];
            const writeUsers = _.clone(this.state.writeUsers) ?? [];
            if (promote) {
                const idx = _.findIndex(readUsers, r => r.id === u.id);
                if (idx >= 0) {
                    writeUsers.push(...readUsers.splice(idx, 1));
                    this.setState({ readUsers, writeUsers });
                }
            }
            else {
                const idx = _.findIndex(writeUsers, r => r.id === u.id);
                if (idx >= 0) {
                    readUsers.push(...writeUsers.splice(idx, 1));
                    this.setState({ readUsers, writeUsers });
                }
            }
        }
        const del = () => {
            if (promote) {
                const readUsers = _.clone(this.state.readUsers) ?? [];
                const idx = _.findIndex(readUsers, r => r.id === u.id);
                if (idx >= 0) {
                    readUsers.splice(idx, 1);
                    this.setState({ readUsers });
                }
            }
            else {
                const writeUsers = _.clone(this.state.writeUsers) ?? [];
                const idx = _.findIndex(writeUsers, r => r.id === u.id);
                if (idx >= 0) {
                    writeUsers.splice(idx, 1);
                    this.setState({ writeUsers });
                }

            }
        }
        return (
            <UserEntry>
                <User>
                    <UserTile
                        compact
                        user={u}

                    />
                </User>
                <UserBtns>
                    <Button kind="secondary" icon={promote ? "star full" : "star empty"} onClick={mod} />
                    <Button kind="secondary" icon="delete" onClick={del} />
                </UserBtns>
            </UserEntry>
        );
    }

    private newReadUserSelected = (newReadUser?: UserShortInfoDownloadDto[]) => {
        this.setState({ newReadUser: newReadUser ? newReadUser[0] : undefined });
    }
    private newWriteUserSelected = (newWriteUser?: UserShortInfoDownloadDto[]) => {
        this.setState({ newWriteUser: newWriteUser ? newWriteUser[0] : undefined });
    }
}

export default translate("assets")(AssetSettings);