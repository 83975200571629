import { LoginController } from 'collaboration-service';
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Header, Icon, Input, InputOnChangeData, Loader, Modal, ModalProps } from 'semantic-ui-react';
import { ActionCreators } from 'services/Actions';
import { checkPasswords } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';

interface UserChangePasswordProps extends ModalProps, IConnectedComponent, IIMGTranslatedComponent {
    id?: string;
    close: () => void;
    ignoreOldPassword?: boolean;
}

interface UserChangePasswordState {
    oldPw: string;
    newPw: string;
    confirmation: string;
    success: boolean;
    confirmationDialogOpen: boolean;
    loading: boolean;
    open: boolean;
}

class UserChangePassword extends React.Component<UserChangePasswordProps, UserChangePasswordState> {
    constructor(props: UserChangePasswordProps) {
        super(props);

        this.state = {
            oldPw: "",
            newPw: "",
            confirmation: "",
            success: false,
            confirmationDialogOpen: false,
            loading: false,
            open: false,
        };
    }
    static getDerivedStateFromProps(props: UserChangePasswordProps, state: UserChangePasswordState) {
        if (state.open !== props.open)
            return { ...state, oldPw: "", newPw: "", confirmation: "", confirmationDialogOpen: false, success: false, loading: false, open: props.open };
        return state;
    }
    public render() {
        const { userInfo, groups } = this.props.state;
        const { ignoreOldPassword, close, t, dispatch, ...rest } = this.props;

        if (!userInfo || !groups)
            return <></>;

        const checkRes = this.checkPassword();
        const maySave = checkRes.length === 0 &&
            (this.props.ignoreOldPassword ?
                this.state.newPw === this.state.confirmation && this.state.newPw !== "" :
                this.state.oldPw !== "" && this.state.newPw === this.state.confirmation && this.state.newPw !== "");

        return (
            <Modal {...rest}>
                <Modal open={this.state.confirmationDialogOpen} size='mini'>
                    <Header>
                        <Icon name={this.state.success ? "check" : "close"} />
                        {this.state.success ? t("Success") : t("Something went wrong")}
                    </Header>
                    <Modal.Content>
                        {this.state.success ? t("Your password has been changed.") : t("An error occured. Please try again")}
                    </Modal.Content>
                    <Modal.Actions>
                        {
                            this.state.success ?
                                <Button color={'green'} onClick={close} disabled={this.state.newPw !== this.state.confirmation || this.state.newPw === ""}>
                                    <Icon name='checkmark' />
                                    {t("Close")}
                                </Button>
                                :
                                <Button color={'red'} onClick={this.closeConfirmation} disabled={this.state.newPw !== this.state.confirmation || this.state.newPw === ""}>
                                    <Icon name='close' />
                                    {t("Retry")}
                                </Button>
                        }
                    </Modal.Actions>
                </Modal>
                <Header icon='spy' content={t("change your password")} />
                <Modal.Content scrolling>
                    <Dimmer active={this.state.loading}>
                        <Loader active={this.state.loading} />
                    </Dimmer>
                    <p>
                        {t("Enter your current password and then your new one. The new password has to be confirmed. The password must consist of at least 8 characters.")}
                    </p>
                    <Form >
                        {!ignoreOldPassword &&
                            <Form.Field inline>
                                <label>{t("old password")}</label>
                                <Input
                                    type="password"
                                    value={this.state.oldPw}
                                    style={{ float: 'right', marginTop: -10 }}
                                    size='small'
                                    onChange={this.inputOldPassword}
                                    autoFocus
                                />
                            </Form.Field>
                        }
                        <Form.Field inline style={{ marginTop: 25 }}>
                            <label>{t("new password")}</label>
                            <Input
                                type="password"
                                value={this.state.newPw}
                                style={{ float: 'right', marginTop: -10 }}
                                size='small'
                                onChange={this.inputNewPassword}
                                error={this.state.newPw !== this.state.confirmation}
                                autoFocus={this.props.ignoreOldPassword}
                            />
                        </Form.Field>
                        <Form.Field inline style={{ marginTop: 25 }}>
                            <label>{t("confirm password")}</label>
                            <Input
                                type="password"
                                value={this.state.confirmation}
                                style={{ float: 'right', marginTop: -10 }}
                                size='small'
                                onChange={this.inputConfirmPassword}
                                error={this.state.newPw !== this.state.confirmation}
                            />
                        </Form.Field>
                        <div style={{ height: "2rem" }} />
                    </Form>
                    {checkRes.length > 0 &&
                        <>
                            {checkRes.map((i, j) => (<div key={j} className="PWChangeInfo"><div className="PWChangeInfoText"><Icon name="exclamation circle" color="red" />{i}</div></div>))}
                        </>


                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' icon='remove' onClick={close} style={{ position: "absolute", left: 10 }} />
                    <Button color='red' icon='checkmark' content={this.props.t("Save")} onClick={this.changePassword} disabled={!maySave} />
                </Modal.Actions>
            </Modal>
        );
    }

    private inputOldPassword = (e: any, data: InputOnChangeData) => {
        this.setState({ oldPw: data.value });
    }
    private inputNewPassword = (e: any, data: InputOnChangeData) => {
        this.setState({ newPw: data.value });
    }
    private inputConfirmPassword = (e: any, data: InputOnChangeData) => {
        this.setState({ confirmation: data.value });
    }
    private changePassword = () => {
        this.setState({ loading: true });

        if (!this.props.id) {

            if (this.props.ignoreOldPassword)
                LoginController.ChangePassword({ login: { login: "", password: this.state.newPw } },
                    () => this.props.dispatch(ActionCreators.globalState.GetUserinfo(
                        () => this.setState({ loading: false, success: true, confirmationDialogOpen: true }),
                        () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true }),
                    )),
                    () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true }),
                );
            else
                LoginController.LoginCheck({ login: { login: "", password: this.state.oldPw } },
                    () => {
                        LoginController.ChangePassword({ login: { login: "", password: this.state.newPw } },
                            () => this.props.dispatch(ActionCreators.globalState.GetUserinfo(
                                () => this.setState({ loading: false, success: true, confirmationDialogOpen: true }),
                                () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true }),
                            )),
                            () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true }),
                        );
                    },
                    () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true })
                );
        }
        else {
            LoginController.ChangePasswordForId({ id: this.props.id, login: { login: "", password: this.state.newPw } },
                () => this.setState({ loading: false, success: true, confirmationDialogOpen: true }),
                () => this.setState({ oldPw: "", loading: false, success: false, confirmationDialogOpen: true }),
            );
        }
    }
    private closeConfirmation = () => {
        this.setState({ confirmationDialogOpen: false });
    }

    private checkPassword = () => {
        const res = checkPasswords(this.state.newPw, this.state.confirmation, this.props.t);
        const toRet: string[] = [];
        const { t } = this.props;
        if (!res.lengthOk)
            toRet.push(t("new password is to short"));
        if (!res.valid)
            toRet.push(t("new password is too simple"));
        if (!res.equal)
            toRet.push(t("password and confirmation are not equal"));
        return toRet;
    }
}

export default translate("platform")(connect((state: IState) => ({ state }))(UserChangePassword));