import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import ReactSelect, { components } from "react-select";
import { OptionProps } from "react-select/src/components/Option";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { Flag } from "semantic-ui-react";
import { IConnectedComponent, IState } from "../../services/Interfaces";
import ImgI18N, { IIMGTranslatedComponent, translate } from "../Utils/img-i18n";

interface ILanguageSelectProps extends IIMGTranslatedComponent, IConnectedComponent {
    onLanguageChanged?: (newLng: string) => void;
    value?: string;
    disabled?: boolean;
    hiddenLanguages?: string[];
    isClearable?: boolean;
    entryPostfixRenderer?: (lng: string) => JSX.Element | null | undefined;
    className?: string;
    addOriginal?: boolean;

    includeInvisible?: boolean;
}

interface ILanguageSelectState {
    languages?: any[];
    lng: any;
}

const LanguageOptionWithPostFixRenderer = (p: ILanguageSelectProps): React.SFC<OptionProps<string>> => {
    return (props: OptionProps<string>) => {
        const x = props;
        return <components.Option {...props}>{_.map(x.data.flag, (v, i) => <Flag key={i} name={v} />)}{props.children}{p.entryPostfixRenderer ? p.entryPostfixRenderer(x.data.value) : undefined}</components.Option>;
    };
};

const LanguageValueWithPostFixRenderer = (p: ILanguageSelectProps): React.SFC<SingleValueProps<string>> => {
    return (props: SingleValueProps<string>) => {
        const x: any = props;
        return <div >{_.map(x.data.flag, (v, i) => <Flag key={i} name={v} />)}{props.children}{p.entryPostfixRenderer ? p.entryPostfixRenderer(x.data.value) : undefined}</div>;
    };
};

class LanguageSelect extends React.Component<ILanguageSelectProps, ILanguageSelectState>
{

    /*
JP, ZH-CN, KO, PT, EN, ES, FR, IT, NL, ZH-TW, RU. 
    */


    public state: ILanguageSelectState =
        {
            languages: undefined,
            lng: undefined,
        };


    public constructor(props: ILanguageSelectProps) {
        super(props);
        this.languageSelected = this.languageSelected.bind(this);
    }

    public UNSAFE_componentWillMount() {
        this.setupElements();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ILanguageSelectProps) {
        if ((nextProps.value !== this.state.lng.value) ||
            (!_.isEqual(nextProps.hiddenLanguages, this.props.hiddenLanguages)))
            this.setupElements(nextProps);
    }

    public setupElements = (props?: ILanguageSelectProps) => {
        const p = props ? props : this.props;
        //        console.log("p = ", p);
        let languages = _.sortBy(_.filter(ImgI18N.lngDefinitions, ld => this.props.includeInvisible || ld.visible), (v => v.order));
        if (p.hiddenLanguages)
            languages = _.filter(languages, v => _.findIndex(p.hiddenLanguages, h => h === v.value) < 0);
        const value = p.value ? p.value : p.i18n.language;
        const lngPos = _.findIndex(languages, v => v.value === value);
        const sel = languages[lngPos >= 0 ? lngPos : 0];
        //        console.log("sel.value = ", sel.value, " = ? = ", p.value, " = p.value");
        if (sel.value !== p.value && p.onLanguageChanged)
            p.onLanguageChanged(sel.value);
        this.setState({ languages, lng: languages[lngPos >= 0 ? lngPos : 0] });
    }

    public render() {
        return (this.state.languages &&
            <ReactSelect
                className={this.props.className ? this.props.className : "zindexLanguageSelectLang"}
                isMulti={false}
                options={this.state.languages}
                components={{ Option: LanguageOptionWithPostFixRenderer(this.props), SingleValue: LanguageValueWithPostFixRenderer(this.props) }}
                value={this.state.lng}
                onChange={this.languageSelected}
                isDisabled={this.props.disabled}
                isClearable={this.props.isClearable}
                classNamePrefix="rselect"

            />
        );
    }

    private languageSelected(selectedLng: any) {
        this.setState({ lng: selectedLng });
        //        this.props.i18n.changeLanguage(selectedLng.value);
        if (this.props.onLanguageChanged)
            this.props.onLanguageChanged(selectedLng ? selectedLng.value : undefined);
    }
}

export default translate()(connect((state: IState) => ({ state }))(LanguageSelect));