import { UserController, UserDownloadDto, UserSettingsUploadDto } from "collaboration-service";
import { IIMGTranslatedComponent, translate } from 'components/Utils/img-i18n';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Dropdown, Icon, Menu, Modal, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { ActionCreators } from 'services/Actions';
import { config } from 'services/Config';
import { checkMedia, getGroup, getGroupId, isInGroup, isInGroupAdmin, openURLLink, windowOpen } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';
import MobileHelper from "services/MobileHelper";
import InviteUser from 'views/Admin/Invite/InviteUser';
import UserChangePassword from 'views/Platform/UserChangePassword';
import UserCommunities from 'views/Platform/UserCommunities';
import LanguageSelection from '../LanguageSelection';
import UserAvatar from '../UserAvatar/UserAvatar';
import ExciteTOU from './ExciteTermsOfUse';

export interface DefaultMenuEntry {
    icon: SemanticICONS;
    caption: string;
    onClick: () => void;
    color?: SemanticCOLORS;
}

export interface DefaultMenuBarEntry {
    icon: SemanticICONS;
    active?: boolean;
    onClick: () => void;
    color?: SemanticCOLORS;
    disabled?: boolean;
}

export interface DefaultMenuProps {
    title?: string | JSX.Element;
    barStyle?: string;
    barContent?: JSX.Element;
    allowAllGroups?: boolean;
    additionalModEntries?: DefaultMenuEntry[];
    additionalAdminEntries?: DefaultMenuEntry[];
    additionalUserEntries?: DefaultMenuEntry[];
    additionalMenuBarEntries?: DefaultMenuBarEntry[];
    additionalDropdowns?: JSX.Element[];
}

export interface IDefaultMainMenuProps extends DefaultMenuProps, IIMGTranslatedComponent, IConnectedComponent, RouteComponentProps<any> {
}

interface IDefaultMainMenuState {
    modalOpen: boolean;
    showLngSelect: boolean;
    showPWDchange: boolean;
    showUserCommunities: boolean;
    newLng: string;
    showFeedback: boolean;
    menuOpen: boolean;
    menuAdminOpen: boolean;
    showTOU?: boolean;
    openModalInvite?: boolean;
    redirect: boolean;
}
class DefaultMainMenu extends React.Component<IDefaultMainMenuProps, IDefaultMainMenuState> {

    constructor(props: IDefaultMainMenuProps) {
        super(props);


        this.state = {
            modalOpen: true,
            showLngSelect: false,
            showPWDchange: false,
            showUserCommunities: false,
            newLng: props.i18n.language,
            showTOU: false,
            showFeedback: false,
            menuOpen: false,
            menuAdminOpen: false,
            openModalInvite: false,
            redirect: false
        };
    }

    public render() {
        if (this.state.redirect) {
            return <Redirect push to={config.routes.community.profile} />;
        }
        const disableEntries = MobileHelper.getInstance().mobileClient();
        const { t } = this.props;
        let admin = config.adminFeatures.adminMode;
        let moder = config.adminFeatures.adminMode;
        let isTubeAdmin = false;
        if (config.adminFeatures.adminMode && this.props.state.userInfo) {
            const info = this.props.state.userInfo;
            const idxA = _.findIndex(info.groupRoles, (v) => v.roles.indexOf("Admin") >= 0);
            const idxM = _.findIndex(info.groupRoles, (v) => v.roles.indexOf("Moderator") >= 0);
            admin = admin && idxA >= 0;
            moder = moder && idxM >= 0;
            const tubeGroups = _.filter(this.props.state.groups?.elements, g => g.groupType === "TUBE");
            isTubeAdmin = _.findIndex(info.groupRoles, g => (g.roles.indexOf("Admin") >= 0 || g.roles.indexOf("Moderator") >= 0) && (g.groupId === "GLOBAL" || _.findIndex(tubeGroups, tg => g.groupId === tg.id) >= 0)) >= 0;
        }

        moder = admin || moder;

        const gid = getGroupId(this);
        const group = getGroup(this, gid);
        const ui: UserDownloadDto | undefined = this.props.state.userInfo;
        let iop = false;
        if (ui)
            iop = ui.passwordChanged === undefined || ui.passwordChanged === null;
        return (
            <>
                <Menu attached='top' className={this.props.barStyle ? this.props.barStyle : (this.props.title ? 'MainMenu' : "MainMenuWithLogo")}>
                    {
                        this.props.title && <div className='ExciteMenu'>{this.props.title}</div>
                    }
                    <Menu.Menu position='right' >
                        {_.map(this.props.additionalMenuBarEntries, (e, i) =>
                            <Menu.Item key={i} icon onClick={e.onClick} active={e.active} disabled={e.disabled}>
                                <Icon name={e.icon} color={e.color} />
                            </Menu.Item>
                        )}
                        {this.props.additionalDropdowns}
                        {moder && this.props.state.userInfo &&
                            <Dropdown
                                open={this.state.menuAdminOpen}
                                onMouseOver={this.showAdminMenu}
                                onMouseLeave={this.hideAdminMenu}
                                onClick={this.state.menuAdminOpen ? this.hideAdminMenu : this.showAdminMenu}
                                item
                                className="DMM"
                                icon="setting">
                                <Dropdown.Menu style={{ width: 250, float: "left" }}>
                                    <>
                                        <div style={{ paddingLeft: 15, paddingTop: 15 }}>
                                            <Dropdown.Header><b>{t("administration")}</b></Dropdown.Header>
                                        </div>
                                        <Dropdown.Divider />
                                    </>
                                    {this.props.state.userInfo.groupRoles.length > 1 &&
                                        <Dropdown.Item icon='group' text={t("show groups")} onClick={this.showUserCommunities} />
                                    }
                                    {
                                        config.adminFeatures.groupManagement && admin &&
                                        <Dropdown.Item onClick={this.goToGroupManagement} icon='group' text={t("edit groups")} />
                                    }
                                    {
                                        config.adminFeatures.postManagement &&
                                        <Dropdown.Item onClick={this.goToPostManagement} icon='newspaper' text={t("edit posts")} />
                                    }
                                    {
                                        config.adminFeatures.groupManagementInvite && admin &&
                                        <Dropdown.Item onClick={this.goToInvitationManagement} icon='user plus' text={t("invite user(s)")} />
                                    }
                                    {
                                        admin &&
                                        <Dropdown.Item onClick={this.goToStatistics} icon='chart bar' text={t("statistics")} />
                                    }
                                    {
                                        isInGroupAdmin("EXCITE") &&
                                        <Dropdown.Item onClick={this.goToExciteStatistics} icon='chart bar' text={t("excite statistics")} />
                                    }
                                    {
                                        config.translationModule && admin &&
                                        <Dropdown.Item onClick={this.goToTranslationManagement} icon='translate' text={t("translate")} />
                                    }
                                    {
                                        config.assetManagementFeatures.allowAssetSelects && isTubeAdmin &&
                                        <Dropdown.Item onClick={this.goToAssets} icon='box' text={t("assets")} />
                                    }
                                    {_.map(this.props.additionalModEntries, (e, i) => <Dropdown.Item key={"mod-" + i} icon={e.icon} text={t(e.caption)} onClick={e.onClick} />)}
                                    {_.map(this.props.additionalAdminEntries, (e, i) => <Dropdown.Item key={"ad-" + i} icon={e.icon} text={t(e.caption)} onClick={e.onClick} />)}

                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        {this.props.state.userInfo &&
                            <Dropdown
                                open={this.state.menuOpen}
                                onMouseOver={this.showMenu}
                                onMouseLeave={this.hideMenu}
                                onClick={this.state.menuOpen ? this.hideMenu : this.showMenu}
                                item
                                className="DMM"
                                icon={this.props.state.userInfo && checkMedia(this.props.state.userInfo.avatar) ?
                                    (config.showUserAvatarAsMenuIcon ?
                                        (
                                            <div style={{ width: 28, height: 28 }}>
                                                <UserAvatar user={this.props.state.userInfo} />
                                            </div>
                                        )
                                        :
                                        "bars"
                                    )
                                    :
                                    "bars"
                                }
                            >
                                <Dropdown.Menu style={{ width: 300, zIndex: 2147483637 }}>
                                    {!config.hideFeed && (isInGroup("COMMUNITY") || isInGroup("EXCITE") || isInGroup("SLIDESHOW")) &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "COMMUNITY")}
                                            image='/images/MenuCommunity.svg' text={t("excite! Community")} onClick={this.goToCom} />
                                    }
                                    {/*isInGroup("LANDING PAGE SMART MOBILITY") &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "LANDING PAGE SMART MOBILITY")}
                                            image='/images/MenuSMW.svg' text={t("Smart Mobility World")} onClick={this.goToSMW} />
                                    */}
                                    {isInGroup("LANDING PAGE SMART MOBILITY") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "LANDING PAGE SMART MOBILITY")}
                                            image='/images/MenuSmw.png' text={t("Smart Mobility World")} onClick={this.goToSMWnew} />
                                    }
                                    {isInGroup("TUBE") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "TUBE")}
                                            image='/images/MenuPTube.svg' text={t("Porsche Tube")} onClick={this.goToTB} />
                                    }
                                    {config.quizFeatures.quizFeed && isInGroup("QUIZ") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "QUIZ")}
                                            image='/images/MenuPQuiz.svg' text={t("Porsche Quiz")} onClick={this.goToQZ} />
                                    }
                                    {isInGroup("SCORM") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "SCORM")}
                                            image='/images/MenuLearning.png' text={t("learnings")} onClick={this.goToScorm} />
                                    }
                                    {isInGroup("BEST PRACTICE") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "BEST PRACTICE")}
                                            image='/images/MenuBestPractice.svg' text={t("porsche retail impulse")} onClick={this.goToPRI} />
                                    }
                                    {isInGroup("CONTENT AGGREGATION") && !disableEntries &&
                                        <Dropdown.Item
                                            active={group && (group.groupType === "CONTENT AGGREGATION")}
                                            image='/images/MenuContentAgg.svg' text={t("Content Aggregation")} onClick={this.goToCA} />
                                    }
                                    <Dropdown.Item
                                        image='images/ppx-campus-menu.svg' text={t("PPX Campus")} onClick={this.gotoPPX} />

                                    <Dropdown.Divider style={{ paddingtop: 0, margintop: 0 }} />
                                    {config.changeLanguage &&
                                        <Dropdown.Item icon='translate' text={t("change language")} onClick={this.showLngSelect} />
                                    }
                                    <Dropdown.Item icon='spy' text={t("change password")} onClick={this.showPWDchange} />
                                    {config.feedback &&
                                        <Dropdown.Item icon='mail' text={t("Feedback")} onClick={this.showModalFeedback} />
                                    }
                                    {config.termsOfUse &&
                                        <>
                                            <Dropdown.Item icon='legal' text={t("Terms of Use")} onClick={this.showModalTOU} />
                                            <Dropdown.Item icon='legal' text={t("imprint & privacy notice")} onClick={this.goToImprint} />
                                        </>
                                    }
                                    {_.map(this.props.additionalUserEntries, (e, i) => <Dropdown.Item key={"u-" + i} icon={e.icon} text={t(e.caption)} onClick={e.onClick} />)}

                                    {this.props.state.userInfo &&
                                        <>
                                            <Dropdown.Divider style={{ paddingtop: 0, margintop: 0 }} />
                                            {isInGroup("LANDING PAGE SMART MOBILITY") &&
                                                <>
                                                    <div style={{ width: 30, height: 30, float: "right", margin: 10, marginTop: 3 }}>
                                                        <UserAvatar user={this.props.state.userInfo} />
                                                    </div>
                                                    <Dropdown.Item icon='settings' text={t("edit your profile")} onClick={this.gotoPersonalPage} />
                                                </>
                                            }
                                        </>
                                    }
                                    <Dropdown.Item onClick={this.logOut} >
                                        <Icon name='log out' color="red" /> {t("logout")} {this.props.state.userInfo.firstName} {this.props.state.userInfo.secondName}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </Menu.Menu>
                    {this.props.barContent}
                </Menu>

                <Modal dimmer='blurring' size='mini' open={this.state.showLngSelect}>
                    <Modal.Header icon='translate'>{t("select language")}</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <p>{this.props.t("Here you can change your language, select the language you want to use.")}</p>
                            <LanguageSelection
                                value={this.state.newLng}
                                onLanguageChanged={this.lngChanged}
                            />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            icon='times'
                            color='red'
                            onClick={this.hideLngSelect}
                            style={{ position: "absolute", left: 10 }}
                        />
                        <Button
                            icon='checkmark'
                            color='red'
                            content={t("change")}
                            onClick={this.applyLngSelect}
                        />
                    </Modal.Actions>
                </Modal>

                <UserChangePassword
                    dimmer='blurring'
                    size='mini'
                    open={this.state.showPWDchange}
                    close={this.hidePWDchange}
                    ignoreOldPassword={iop}
                />

                <UserCommunities
                    dimmer='blurring'
                    size='small'
                    open={this.state.showUserCommunities}
                    close={this.hideUserCommunities}
                />

                <Modal dimmer='blurring' open={this.state.showTOU}>
                    <Modal.Header><Icon name="legal" /> {this.props.t("Terms of use")}</Modal.Header>
                    <Modal.Content scrolling >
                        <ExciteTOU />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button icon="times" color="red" onClick={this.hideModalTOU} />
                    </Modal.Actions>
                </Modal>

                <Modal dimmer='blurring' size="mini" open={this.state.showFeedback}>
                    <Modal.Header><Icon name="mail" /> {this.props.t("Share your Feedback!")}</Modal.Header>
                    <Modal.Content scrolling >
                        <p>{this.props.t('Thank you for your interest in testing the PPX Community platform.')}</p>
                        <p>{this.props.t('Please feel free to share all your feedback, bug reports, tips and ideas with us!')}</p>

                        <div>{this.props.t('We highly appreciate your support!')}</div>
                    </Modal.Content>
                    <Modal.Actions>
                        <a href="mailto:excite@porsche.de?subject=Excite%20Feedback">
                            <Button floated="left" icon="mail" color="green" onClick={this.hideModalFeedback} content="excite@porsche.de" />
                        </a>
                        <Button icon="times" color="red" onClick={this.hideModalFeedback} />
                    </Modal.Actions>
                </Modal>
                <Modal dimmer='blurring' open={this.state.openModalInvite}>
                    <InviteUser />
                    <Modal.Actions><Button icon="times" color="red" onClick={this.hideInviteModal} /></Modal.Actions>
                </Modal>
            </>
        );

    }

    private logOut = () => {
        this.props.dispatch(ActionCreators.globalState.Logout(() => this.props.history.push(config.routes.general.base)));
    }

    private toggleModal = () => {
        this.setState({ modalOpen: true });
    }


    private goToHome = () => {
        if (this.props.state.userInfo) {
            const { userInfo } = this.props.state;
            userInfo.userSettings.lastGroupId = '';
        }
        this.props.history.push(config.routes.platform.main);
    }

    private goToGroupManagement = () => {
        this.props.history.push(config.routes.general.groupsAdmin);
    }
    private goToTranslationManagement = () => {
        this.props.history.push(config.routes.general.translateAdmin);
    }
    private goToAssets = () => {
        this.props.history.push(config.routes.assets.mgt);
    }
    private goToPostManagement = () => {
        this.props.history.push(config.routes.general.postsAdmin);
    }
    private goToInvitationManagement = () => {
        this.props.history.push(config.routes.general.invitation);
    }
    private goToMassInvitationManagement = () => {
        this.props.history.push(config.routes.general.massInvitation);
    }
    private goToStatistics = () => {
        this.props.history.push(config.routes.general.stats);
    }
    private goToExciteStatistics = () => {
        this.props.history.push(config.routes.excite.stats);
    }
    private applyLngSelect = () => {
        this.props.dispatch(ActionCreators.globalState.ChangeLanguage(this.state.newLng));
        this.hideLngSelect();
    }

    private lngChanged = (newLng: string) => this.setState({ newLng });
    private showLngSelect = () => this.setState({ showLngSelect: true });
    private hideLngSelect = () => this.setState({ showLngSelect: false });
    private showPWDchange = () => this.setState({ showPWDchange: true });
    private hidePWDchange = () => this.setState({ showPWDchange: false });
    private showUserCommunities = () => this.setState({ showUserCommunities: true });
    private hideUserCommunities = () => this.setState({ showUserCommunities: false });
    private showModalTOU = () => this.setState({ showTOU: true });
    private hideModalTOU = () => this.setState({ showTOU: false });
    private showModalFeedback = () => this.setState({ showFeedback: true });
    private hideModalFeedback = () => this.setState({ showFeedback: false });

    private goToImprint = () => this.props.history.push("/imprint");

    private goToSMW = () => this.changeGroup("LANDING PAGE SMART MOBILITY", config.routes.landingPage.main);
    private goToSMWnew = () => this.changeGroup("LANDING PAGE SMART MOBILITY", config.routes.landingPage.new);
    private goToTB = () => this.changeGroup("TUBE", config.routes.tube.main);
    private goToQZ = () => this.changeGroup("QUIZ", config.routes.quiz.quizFeed);
    private goToCom = () => {
        if (!this.changeGroup("EXCITE", config.routes.community.feed))
            if (!this.changeGroup("COMMUNITY", config.routes.community.feed))
                if (!this.changeGroup("QUIZ", config.routes.community.feed))
                    this.changeGroup("SLIDESHOW", config.routes.community.feed);
    }

    private goToScorm = () => this.changeGroup("SCORM", config.routes.scorm.overview);
    private goToPRI = () => this.changeGroup("BEST PRACTICE", config.routes.bestpractice.main);
    private goToCA = () => this.changeGroup("CONTENT AGGREGATION", config.routes.contentAggregation.overview);
    private gotoPPX = () => {
        windowOpen("https://login.imaginarity.com/token/{{token}}?appId=b4555ed1-7dff-4c97-aab7-4fbc1d53eab6&route=/ppe", "_blank")
    }
    private changeGroup = (targetType: string, route: string) => {
        // const gid = getGroupId(this);
        // const group = getGroup(this, gid);
        //if ((group && group.groupType !== targetType) || !group || this.props.allowAllGroups) {
        {
            const tg = this.props.state.groups ? _.find(this.props.state.groups.elements, g => g.groupType === targetType) : undefined;
            console.log(tg);
            if (tg && this.props.state.userInfo) {
                const ui = this.props.state.userInfo;
                ui.userSettings.lastGroupId = tg.id;
                UserController.PostUserSettings({ id: ui.id, settings: ui.userSettings as UserSettingsUploadDto }, () => this.props.history.push(route + (tg ? "/" + tg.id : "")));
                return true;
            }
        }
        return false;//
    }

    private showMenu = () => this.setState({ menuOpen: true });
    private hideMenu = () => this.setState({ menuOpen: false });
    private showAdminMenu = () => this.setState({ menuAdminOpen: true });
    private hideAdminMenu = () => this.setState({ menuAdminOpen: false });
    private showInviteModal = () => this.setState({ openModalInvite: true });
    private hideInviteModal = () => this.setState({ openModalInvite: false });

    private gotoPersonalPage = () => this.setState({ redirect: true });


}

export default withRouter(translate("menu")(connect((state: IState) => ({ state }))(DefaultMainMenu)));