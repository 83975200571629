
import Axios, { AxiosRequestConfig } from 'axios';
import { BrowserInfo, CategoryDownloadDto, CommunityFeedPostDownloadDto, ContentMediaUploadDto, ContentPostContentDownloadDto, ContentPostDownloadDto, ControllerHelper, GroupDownloadDto, GroupSettingsDownloadDto, ICallErrorResponse, LanguageStringDownloadDto, MediaController, MediaDownloadDto, MediaUploadDto, NewsPostDownloadDto, NotificationDownloadDto, PorscheMomentPostDownloadDto, PostControllerTube, PostDownloadDto, QuizDownloadDto, SlideShowDownloadDto, StepContentDownloadDto, TranslationController, UserController, UserShortInfoDownloadDto, AssetController, TubePostDownloadDto } from "collaboration-service";
import LoadingImport from 'components/General/LoadingImport/LoadingImport';
import { format } from 'date-fns';
import { detect } from "detect-browser";
import { saveAs } from "file-saver";
import { LinkDto, Node, UploadMediaParametersDto } from "imaginarity-azure";
import * as _ from "lodash";
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { Icon, Image, Label, SemanticICONS } from 'semantic-ui-react';
import { config } from 'services/Config';
import { IButtonInformation } from '../components/General/Mainframe/Mainframe';
import Media, { IDownloadMedia, IEmbedMedia, IFileMedia, IStorageMedia, IURLMedia } from '../components/General/Media/Media';
import ImgI18N, { IMGTranslateFunction } from '../components/Utils/img-i18n';
import { ActionCreators } from './Actions';
import { IConnectedComponent, IState } from './Interfaces';
import { store } from './Reducer';
import MobileHelper from './MobileHelper';
import TubePost from 'components/Posts/TubePost/TubePost';
import loadImage, { LoadImageOptions, MetaData } from 'blueimp-load-image';
import { Link } from 'react-router-dom';

export function shouldShowNotification<S extends IConnectedComponent>(component: React.Component<S>, data: NotificationDownloadDto): boolean {

    if (!component.props.state.userInfo)
        return false;

    const gs = getGroupSettings(component, component.props.state.userInfo.userSettings.lastGroupId);

    if (!gs || !gs.notificationSettings)
        return false;

    const ns = gs.notificationSettings;
    let shouldShow: boolean = true;

    switch (data.notificationType) {
        case ("DeletedPost"):
            shouldShow = ns.onDelete;
            break;
        case ("CommentedPost"):
            shouldShow = ns.onCommentedPost;
            break;
        case ("LikedPost"):
            shouldShow = ns.onLikedPost;
            break;
        case ("UnlikedPost"):
            shouldShow = ns.onUnlikedPost;
            break;
        case ("BookmaredPost"):
            shouldShow = ns.onBookmarkedPost;
            break;
        case ("UnbookmarkedPost"):
            shouldShow = ns.onUnbookmarkedPost;
            break;
        case ("MentionedInPost"):
            shouldShow = ns.onMentionedInPost;
            break;
        case ("ResponsibleForPost"):
            shouldShow = ns.onResponsibleForPost;
            break;
        case ("SubordinatePosted"):
            shouldShow = ns.onSubordinatePosted;
            break;
        case ("SubordinateResponsibleForPost"):
            shouldShow = ns.onSubordinateResponsibleForPost;
            break;
        case ("SubordinateMentionedInPost"):
            shouldShow = ns.onSubordinateMentionedInPost;
            break;
        case "ChangedPostLiker":
            shouldShow = ns.onChangedLikers;
            break;
        case "ChangedPostMentioned":
            shouldShow = ns.onChangedMentionends
            break;
        case "ChangedPostResponsible":
            shouldShow = ns.onChangedResponsibles;
            break;
        case "ChangedPost":
            shouldShow = true;
            break;
        case "MentionedInComment":
            shouldShow = true;
            break;
    }
    return shouldShow;
}

export function getNotificationText(notification: NotificationDownloadDto): { icon: SemanticICONS, text: string } {
    let toRet = { icon: "", text: "" };
    switch (notification.notificationType) {
        case "BookmaredPost":
            toRet = { icon: config.icons.bookmark, text: "{{username}} bookmarked your post." };
            break;
        case "UnbookmarkedPost":
            toRet = { icon: config.icons.bookmarkEmpty, text: "{{username}} unbookmarked your post." };
            break;
        case "CommentedPost":
            toRet = { icon: config.icons.comment, text: "{{username}} added a comment to your post." };
            break;
        case "DeletedPost":
            toRet = { icon: config.icons.userDeleted, text: "{{username}} deleted your post." };
            break;
        case "LikedPost":
            toRet = { icon: config.icons.heart, text: "{{username}} liked your post." };
            break;
        case "MentionedInPost":
            toRet = { icon: config.icons.mentioned, text: "{{username}} mentioned you in a post." };
            break;
        case "MentionedInComment":
            toRet = { icon: config.icons.mentioned, text: "{{username}} mentioned you in a comment." };
            break;
        case "ResponsibleForPost":
            toRet = { icon: config.icons.responsible, text: "{{username}} added you as responsible for a post." };
            break;
        case "SubordinateMentionedInPost":
            toRet = { icon: config.icons.mentioned, text: "{{username}} was mentioned in a post." };
            break;
        case "SubordinatePosted":
            toRet = { icon: config.icons.post, text: "{{username}} posted something." };
            break;
        case "SubordinateResponsibleForPost":
            toRet = { icon: config.icons.responsible, text: "{{username}} was added as responsible for a post." };
            break;
        case "UnlikedPost":
            toRet = { icon: config.icons.heartEmpty, text: "{{username}} unliked your post." };
            break;
        case "NewPost":
            toRet = { icon: config.icons.post, text: "{{username}} posted something." };
            break;
        case "ChangedPost":
            toRet = { icon: config.icons.edit, text: "{{username}} edited your post." };
            break;
        case "RatedPost":
            toRet = { icon: config.icons.post, text: "{{username}} rated your post." };
            break;
        case "SubordinateChangedPost":
            toRet = { icon: config.icons.edit, text: "{{username}} edited a post." };
            break;
        case "ChangedPostResponsible":
            toRet = { icon: config.icons.edit, text: "{{username}} edited a post." };
            break;
        case "ChangedPostMentioned":
            toRet = { icon: config.icons.edit, text: "{{username}} edited a post." };
            break;
        case "ChangedPostLiker":
            toRet = { icon: config.icons.edit, text: "{{username}} edited a post you liked." };
            break;
        case "ChangedPostCommenter":
            toRet = { icon: config.icons.edit, text: "{{username}} edited a post you commented." };
            break;
        case "ChatNewMessage":
            toRet = { icon: config.icons.comment, text: "{{username}} wrote a chat message." };
            break;
        default:
            toRet = { icon: config.icons.stop, text: "What ever happend, {{username}} did it!" };
            break;
    }
    return toRet as { icon: SemanticICONS, text: string };
}
export function getBrowser(): BrowserInfo {
    return detect();
}

export const intersect = (arr1: any[], arr2: any[]): any[] => {
    return arr1.filter(value => -1 !== arr2.indexOf(value));
};

export const isSubset = (arr1: any[], arr2: any[]): boolean => {
    return arr1.every((val) => arr2.indexOf(val) >= 0);

};

export type PostRenderMapFunction = (post: PostDownloadDto, key?: number, handleEvent?: (type: number, data: any) => void, optionalParams?: { [key: string]: any }) => JSX.Element;
export interface PostRenderMapType {
    [key: string]: PostRenderMapFunction;
}

const CommunityFeedPost = React.lazy(() => import('components/Posts/CommunityFeedPost/CommunityFeedPost'));
const ContentPost = React.lazy(() => import('components/Posts/ContentPost/ContentPost'));
const QuizPost = React.lazy(() => import('components/Quiz/QuizPost/QuizPost'));
const NewsPost = React.lazy(() => import('components/Posts/NewsPost/NewsPost'));
const PorscheMomentPostCommunity = React.lazy(() => import('components/Posts/PorscheMomentPost/PorscheMomentPostCommunity'));
const SlideShowPost = React.lazy(() => import('components/Posts/SlideShowPost/SlideShowPost'));
export const PostRenderMap: PostRenderMapType =
{
    "Post": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><CommunityFeedPost post={p as PorscheMomentPostDownloadDto} handleEvent={h} {...op} /></React.Suspense>,
    "PorscheMomentPost": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><PorscheMomentPostCommunity post={p as PorscheMomentPostDownloadDto} handleEvent={h} {...op} /></React.Suspense>,
    "ContentPost": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><ContentPost data={p as ContentPostDownloadDto} linkSource="community" {...op} /></React.Suspense>,
    "Quiz": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><QuizPost quiz={p as QuizDownloadDto} {...op} /></React.Suspense>,
    "CommunityFeedPost": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><CommunityFeedPost post={p as CommunityFeedPostDownloadDto} key={k} handleEvent={h} {...op} /></React.Suspense>,
    "SlideShow": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><SlideShowPost data={p as SlideShowDownloadDto} {...op} /></React.Suspense>,
    "Asset3D": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><ContentPost data={p as ContentPostDownloadDto} linkSource="community" {...op} /></React.Suspense>,
    "NewsPost": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><NewsPost data={p as NewsPostDownloadDto} {...op} /></React.Suspense>,
    "TubePost": (p, k, h, op) => <React.Suspense key={k} fallback={<LoadingImport />}><TubePost post={p as TubePostDownloadDto} width={300} {...op} /></React.Suspense>,
};

export const createPost = (post: PostDownloadDto, key?: number, handleEvent?: (type: number, data: any) => void, renderMap?: PostRenderMapType, optionalParams?: { [key: string]: any }): JSX.Element => {
    const type = post.type ? post.type : "undefined";
    const map = renderMap ? renderMap : PostRenderMap;
    if (!map[type])
        return <div key={key} />;
    return map[type](post, key, handleEvent, optionalParams);
};

export const changeRenderMap = (renderMap: PostRenderMapType, postType: string, func: PostRenderMapFunction) => {
    renderMap[postType] = func;
};

export function getGroupIdFromState<S extends IConnectedComponent>(component: React.Component<S>): string | undefined {
    return component.props.state.userInfo ? component.props.state.userInfo.userSettings.lastGroupId : undefined;
}


export function getGroupId<S extends IConnectedComponent & RouteComponentProps<any>>(component: React.Component<S>): string {
    return component.props.match.params.groupId ? component.props.match.params.groupId :
        (component.props.state.userInfo ? component.props.state.userInfo.userSettings.lastGroupId : "");
}

export function getUserId<S extends IConnectedComponent & RouteComponentProps<any>>(component: React.Component<S>): string {
    return component.props.match.params.userId ? component.props.match.params.userId :
        (component.props.state.userInfo ? component.props.state.userInfo.id : "");
}

export function getUserData<S extends IConnectedComponent & RouteComponentProps<any>>(component: React.Component<S>, msg: string, startCheck?: (checking: boolean) => void): void {
    const fromLink = component.props.match.params.token !== undefined;
    if (fromLink)
        component.props.dispatch(ActionCreators.TokenReceived(component.props.match.params.token));
    if (!component.props.state.userInfo) {
        const c = startCheck ? startCheck : (s: boolean) => {
            //
        };
        c(true);
        component.props.dispatch(ActionCreators.globalState.GetUserinfo(() => c(false), () => c(false)));

    }
}

export function checkForCredentials<S extends IConnectedComponent & RouteComponentProps<any>>(component: React.Component<S>, inputUser: any, startCheck?: (checking: boolean) => void) {
    if (inputUser)
        inputUser.focus();
    if ("caches" in window && caches !== undefined) {
        caches.open("collaboration").then((cache: Cache) => {
            component.props.dispatch(ActionCreators.CreatedCache(cache));
            if (cache) {
                cache.match("/token").then(r => {
                    if (r)
                        r.text().then(token => {
                            component.props.state.client.token = token;
                            getUserData(component, "fine!", startCheck);
                        }, () => getUserData(component, "text failed", startCheck));
                    else
                        getUserData(component, "user invalid", startCheck);
                }, () => getUserData(component, "match failed", startCheck));
            }
            else {
                getUserData(component, "no cache", startCheck);
            }
        },
            () => getUserData(component, "could not open cache", startCheck));
    }
    else
        getUserData(component, "could not find cache in window", startCheck);
}
export function Caller() {
    let callerName;
    try { throw new Error(); }
    catch (e) {
        const re = /(\w+)@|at (\w+) \(/g;
        const st = e.stack;
        re.exec(st);
        const m = re.exec(st);
        if (m != null)
            callerName = m[1] || m[2];
    }
    console.log(callerName);
}

export const getCategorySymbol = (cat: CategoryDownloadDto, iconParams?: any, imgParams?: any) => {
    // const icempty = cat.icon === undefined || cat.icon === "";
    // if (icempty && cat.media && cat.media.links && cat.media.links.length > 0) {
    const link = _.find(cat.media?.links, l => l.ref === "self");
    if (link)
        return <Image src={link.uri} {...imgParams} />;
    // }
    // if (!icempty) {
    //     return <Icon name={cat.icon as SemanticICONS} {...iconParams} />;
    // }
    return null;
};

export const toLocalFullDateString = (date: Date, lng?: string): string | undefined => {
    const l = lng ? lng : ImgI18N.getInstance().language;
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(l, options);
};

export const toLocalShortDateString = (date: Date, lng?: string): string | undefined => {
    const l = lng ? lng : ImgI18N.getInstance().language;
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(l, options);
};

export function translateUsername(user: UserShortInfoDownloadDto) {
    if (!user)
        return "";
    return ImgI18N.getInstance().t("collaboration", "{{firstname}} {{secondname}}", { firstname: user.firstName, secondname: user.secondName });
}

export function translateUsernameShort(user: UserShortInfoDownloadDto) {
    if (!user)
        return "";
    return ImgI18N.getInstance().t("collaboration", "{{firstname}}", { firstname: user.firstName });
}

export const toLocalDateString = (t: IMGTranslateFunction, date: Date): string | undefined => {
    const newDate = new Date(date);
    const today = new Date();

    const diffs = (today.getTime() - newDate.getTime()) / 1000; // difference
    const diffMins = Math.round(diffs / 60);  // minutes
    const diffHrs = Math.floor(diffMins / 60); // hours
    const diffDays = Math.floor(diffHrs / 24); // days
    const diffWeeks = Math.floor(diffDays / 7); // days
    if (diffWeeks > 100000)
        return undefined;

    if (diffWeeks === 1)
        return t("{{amount}} week ago", { amount: diffWeeks });
    if (diffWeeks > 1)
        return t("{{amount}} weeks ago", { amount: diffWeeks });

    if (diffDays === 1)
        return t("{{amount}} day ago", { amount: diffDays });
    if (diffDays > 1)
        return t("{{amount}} days ago", { amount: diffDays });

    if (diffHrs === 1)
        return t("{{amount}} hour ago", { amount: diffHrs });
    if (diffHrs > 1)
        return t("{{amount}} hours ago", { amount: diffHrs });

    if (diffMins === 1)
        return t("{{amount}} minute ago", { amount: diffMins });
    if (diffMins > 1)
        return t("{{amount}} minutes ago", { amount: diffMins });

    return t("a few seconds ago");
};

export function getButton(context: RouteComponentProps<any>, func: string | (() => any), disabled?: boolean): IButtonInformation {
    if (typeof func === 'string') {
        switch (func) {
            case 'reload':
                return { buttonContent: config.icons.reload, onClick: () => context.history.push(config.routes.general.login), disabled: disabled === true };
            case 'profile':
                return { buttonContent: config.icons.user, onClick: () => context.history.push(config.routes.general.profile), disabled: disabled === true };
            case 'back':
                return { buttonContent: config.icons.back, onClick: () => context.history.goBack(), disabled: disabled === true };
        }
    } else if (typeof func === 'function') {
        return { buttonContent: config.icons.back, onClick: func };
    }
    return { buttonContent: '', onClick: () => null, disabled: true };
}

export function getHashTags(text: string): string[] {
    return getTags(text, /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm);
}

export function getMentionTags(text: string): string[] {
    return getTags(text, /[@|&]([a-zA-Z0-9._-]+)/gi);
}

function getTags(text: string, rgx: RegExp): string[] {

    const matches = [];
    let match;

    match = rgx.exec(text);
    while (match) {
        matches.push(match[1]);
        match = rgx.exec(text);
    }
    return matches;
}

export const checkPwd = (pwd?: string): boolean => {
    if (!pwd)
        return false;
    if (pwd.length < 8)
        return false;
    return true;
};

export const GetURI = (link: LinkDto): string => {
    if (_.startsWith(link.uri, 'http')) {
        return link.uri;
    } else if (_.startsWith(link.uri, './')) {
        return config.baseURL + link.uri;
    }
    return link.uri;
};

export const GetURIFromMedia = (media: MediaDownloadDto, refName?: string): string | undefined => {
    if (!media)
        return undefined;
    const r = refName ? refName : "self";
    const link = _.find(media.links, m => m.ref === r);
    return link ? link.uri : undefined;
};

export function GetContentDataForUIStep<T>(name: string, data?: StepContentDownloadDto[]): T | undefined {
    if (!data)
        return undefined;
    const mData = _.find(data, (v: StepContentDownloadDto) => v.name.toLowerCase() === name.toLowerCase());
    if (mData)
        return (mData.content) as T;
    return undefined;
}


export function GetContentMediaDataForUIStep<T>(name: string, data?: StepContentDownloadDto[]): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (!data)
            reject();
        const mData = _.find(data, (v: StepContentDownloadDto) => v.name === name);
        if (mData && mData.content && mData.content.links) {
            const state = store.getState();
            const link = state.client.canExecute(mData.content.links, "self");
            if (link) {
                state.client.execute<T>(link, undefined, undefined, undefined, 200, "text/html").then(
                    (str) => {
                        resolve(str);
                    },
                    (msg) => {
                        reject(msg);
                    });
            }
            else
                reject();
        }
        else
            reject();
    });
}

export function GetContentMediaDataAsLinkForUIStep(name: string, data?: StepContentDownloadDto[]): string | undefined {
    if (!data)
        return undefined;
    const mData = _.find(data, (v: StepContentDownloadDto) => v.name === name);
    if (mData && mData.content && mData.content.links) {
        const state = store.getState();
        const link = state.client.canExecute(mData.content.links, "self");
        if (link) {
            return link.uri;
        }
    }
    return undefined;
}

export const GetMediaForUIStep = (name: string, data?: StepContentDownloadDto[]): JSX.Element | undefined => {
    if (!data)
        return undefined;
    const mData = _.find(data, (v: StepContentDownloadDto) => v.name === name);
    const dwnlMedia: IDownloadMedia = {} as IDownloadMedia;
    let media: MediaDownloadDto = {} as MediaDownloadDto;
    if (mData) {
        media = mData.content as MediaDownloadDto;
        dwnlMedia.media = media;
    }
    return <Media media={dwnlMedia} autoPlay={true} />;
};

export function newNotifications(state: IState): boolean {
    if (!state.notifications)
        return false;
    return state.notifications.findIndex(x => !x.seen) >= 0;
}

export function getLink(links: LinkDto[] | undefined, refName: string): LinkDto | undefined {
    return _.find(links, (link: LinkDto) => link.ref === refName);
}

export interface ButtonDefinition {
    button1?: IButtonInformation;
    button2?: IButtonInformation;
    button3?: IButtonInformation;
    button4?: IButtonInformation;
    button5?: IButtonInformation;
}

const emptyDefinition: ButtonDefinition =
{
    button1: undefined,
    button2: undefined,
    button3: undefined,
    button4: undefined,
    button5: undefined
};

export function createStandardButtons<S extends RouteComponentProps<any>>(component: React.Component<S>, buttonClassName?: string): Array<IButtonInformation | undefined> {
    return [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ];
}


export function createMainframeButtons<S extends RouteComponentProps<any>>(component: React.Component<S>,
    buttonDefinition?: ButtonDefinition,
    generationFunction?: (() => IButtonInformation[]) | undefined,
    buttonClassName?: string): Array<IButtonInformation | undefined> {
    const buttons: Array<IButtonInformation | undefined> = generationFunction ? generationFunction() : createStandardButtons(component, buttonClassName);
    if (buttonDefinition) {
        let i = 0;
        _.forEach(emptyDefinition, (v, idx) => {
            const val = (buttonDefinition as any)[idx];
            if (val !== undefined)
                if (!buttons[i])
                    buttons[i] = {} as IButtonInformation;
            const b = buttons[i];
            if (val && val.renderer === undefined && b && b.renderer !== undefined)
                b.renderer = undefined;
            _.forEach(val, (p, j) => {
                if (b)
                    (b as any)[j] = p;
            });
            i++;
        });
    }
    return buttons;
}

export function getTranslatedValue<T extends { lng?: string }>(values?: T[], lang?: string): T | undefined {
    if (!values)
        return undefined;
    const lng = lang ? lang : ImgI18N.getInstance().language;
    if (lng) {
        let tmp = _.find(values, (v: T) => v.lng === lng);

        if (tmp) {
            return tmp;
        } else {
            const langReduced = lng.split('-')[0];
            tmp = _.find(values, (v: T) => v.lng === langReduced);
            if (tmp)
                return tmp;
            tmp = _.find(values, (v: T) => v.lng === "en");
            if (tmp)
                return tmp;
        }
    }
    return values[0] ? values[0] : undefined;
}

export function getTranslatedLinkForContent(contents: ContentPostContentDownloadDto[], lang?: string): string {
    const res = getTranslatedValue(contents, lang);
    if (res) {
        if (res.useContent) {
            const link = _.find(res.content.links, (l: LinkDto) => l.ref === "self");
            if (link)
                return link.uri;
        }
        else
            return res.link;
    }
    return "";
}

export function getTranslatedLinkForContentWithInfo(contents: ContentPostContentDownloadDto[], lang?: string): { link: string, useContent: boolean } | undefined {
    const res = getTranslatedValue(contents, lang);
    if (res) {
        if (res.useContent) {
            const link = _.find(res.content.links, (l: LinkDto) => l.ref === "self");
            if (link)
                return { link: link.uri, useContent: true };
        }
        else
            return { link: res.link, useContent: false };
    }
    return undefined;
}

export function tryGetTranslated(text?: LanguageStringDownloadDto[], lang?: string): string | undefined {
    if (!text)
        return '';
    const lng = lang ? lang : ImgI18N.getInstance().language;
    if (lng) {
        let tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === lng);

        if (tmp && tmp.text) {
            return tmp.text;
        } else {
            const langReduced = lng.split('-')[0];
            tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === langReduced);
            if (tmp && tmp.text)
                return tmp.text;
            tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === "en");
            if (tmp && tmp.text)
                return tmp.text;
        }
    }
    return text[0] ? text[0].text : undefined;
}

export function tryGetTranslatedLng(text?: LanguageStringDownloadDto[], lang?: string): string | undefined {
    if (!text)
        return '';
    const lng = lang ? lang : ImgI18N.getInstance().language;
    if (lng) {
        let tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === lng);

        if (tmp && tmp.text) {
            return lng;
        } else {
            const langReduced = lng.split('-')[0];
            tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === langReduced);
            if (tmp && tmp.text)
                return langReduced;
            tmp = _.find(text, (v: LanguageStringDownloadDto) => v.lng === "en");
            if (tmp && tmp.text)
                return "en";
        }
    }
    return text[0] ? text[0].lng : undefined;
}

export function getTranslated(text?: LanguageStringDownloadDto[], lang?: string, data?: any): string {
    const toRet = tryGetTranslated(text, lang);
    return toRet ? ImgI18N.getInstance().replacePlaceholders(toRet, data) : "";
}
export function getTranslatedLng(text?: LanguageStringDownloadDto[], lang?: string): string {
    const toRet = tryGetTranslatedLng(text, lang);
    return toRet ? toRet : "";
}

export function getTranslatedIndex(text?: LanguageStringDownloadDto[], lang?: string): number {
    const lng = tryGetTranslatedLng(text, lang);
    return _.findIndex(text, t => t.lng === lng);
}
export function getUserLanguage<S extends IConnectedComponent>(component: React.Component<S>): string {
    if (component.props.state.userInfo && component.props.state.userInfo.userSettings.language !== undefined && component.props.state.userInfo.userSettings.language !== "")
        return component.props.state.userInfo.userSettings.language;
    return "en";
}
export const getDiff = (s: string, diffBy: string) => s.split(diffBy).join('');

const regionMatches = (first: string, toffset: number, second: string, ooffset: number, len: number) => {
    for (let i = 0; i < len; i++) {
        const one = first[toffset + i];
        const two = second[ooffset + i];
        if (one !== two)
            return false;
    }
    return true;
};


const getData = (node: Node<string>, data: string[], sug: string[]) => {
    node.data.forEach(d => data.push(d));
    if (node.keys.length > 0)
        node.keys.forEach(v => sug.push(v));
    _.forEach(node.edges, e => {
        e.to.data.forEach(d => data.push(d));
        getData(e.to, data, sug);
    });
};


export function searchPostIds<S extends IConnectedComponent>(component: React.Component<S>, value: string): { ids: string[], sugs: string[] } {
    const toRet: string[] = [];
    const sug: string[] = [];
    //    if (value.length < 3)
    //        return { ids: toRet, sugs: toRet };
    if (component.props.state.searchTrie) {
        let curNode = component.props.state.searchTrie;
        for (let i = 0; i < value.length; ++i) {
            const ch = value[i];
            const e = curNode.edges[ch];

            if (e) {
                const label = e.label;
                const lenToMatch = Math.min(value.length - i, label.length);
                if (!regionMatches(value, i, label, 0, lenToMatch))
                    return { ids: toRet, sugs: toRet };
                if (label.length >= value.length - i) {
                    getData(e.to, toRet, sug);
                    return { ids: _.uniq(toRet), sugs: _.uniq(sug) };
                }
                curNode = e.to;
                i += lenToMatch - 1;
            }
            else
                return { ids: toRet, sugs: toRet };
        }
    }
    return { ids: toRet, sugs: toRet };
}

/* Landingpage */


export function getGroup<S extends IConnectedComponent>(component: React.Component<S>, id: string): GroupDownloadDto | undefined {
    if (component.props.state.groups)
        return _.find(component.props.state.groups.elements, (g: GroupDownloadDto) => g.id === id);
    return undefined;
}

export function getGroupSettings<S extends IConnectedComponent>(component: React.Component<S>, id: string): GroupSettingsDownloadDto | undefined {
    if (component.props.state.groups != null) {
        const group = _.find(component.props.state.groups.elements, (g: GroupDownloadDto) => g.id === id);
        if (group)
            return group.groupSettings;
    }
    return undefined;
}


export function splitText<P extends { url: string }>(text: string, UrlCard?: React.ComponentClass<P> | React.StatelessComponent<P>, urlParams?: P): JSX.Element[] {
    const delimitter = "#!^!#";
    text = text.replace(/(?:\r\n|\r|\n)/g, delimitter);
    const splits = text.split(delimitter);
    const toRet: JSX.Element[] = [];
    const co = splits.length;
    for (let i = 0; i < co; i++) {
        if (UrlCard) {
            let m: RegExpExecArray | null;
            let j = 0;
            do {
                m = URLRegexML.exec(splits[i]);
                if (m) {
                    let s = splits[i].substr(0, m.index);
                    //                    console.log("1 =>", s);
                    toRet.push(<span key={i + "-" + j + "-1"}>{s}</span>);
                    s = splits[i].substr(m.index, m[0].length);
                    //                    console.log("2 =>", s);
                    const up = { ...urlParams, url: s } as P;
                    toRet.push(<UrlCard key={i + "-" + j + "-url"} {...up} />);
                    s = splits[i].substr(m.index + m[0].length);
                    //                    console.log("3 =>", s);
                    toRet.push(<span key={i + "-" + j + "-3"}>{s}</span>);
                    //                    console.log(m);
                    j++;
                }
            } while (m);
            if (j === 0)
                toRet.push(<span key={i}>{splits[i]}</span>);
        }
        else
            toRet.push(<span key={i}>{splits[i]}</span>);
        if (i < co - 1)
            toRet.push(<br key={"br-" + i} />);
    }
    return toRet;
}
export function splitTextToPara(text: string): JSX.Element[] {
    const delimitter = "#!^!#";
    text = text.replace(/(?:\r\n|\r|\n)/g, delimitter);
    const splits = text.split(delimitter);
    const toRet: JSX.Element[] = [];
    const co = splits.length;
    for (let i = 0; i < co; i++) {
        toRet.push(<p key={i}>{splits[i]}</p>);
    }
    return toRet;
}

export interface IPasswordCheckResult {
    equal: boolean;
    lengthOk: boolean;
    strength: number;
    valid: boolean;
    label: JSX.Element;
}

export function getScoreLabel(score: number, equal: boolean, valid: boolean, t: IMGTranslateFunction) {
    const ok = valid && equal;
    const add = !equal ? " - " + t("passwords are not equal") : "";
    if (!valid)
        score = Math.min(1, score);
    switch (score) {
        case 0:
            return <Label size="mini" className='ScoreLabel0'><Icon name={ok ? "checkmark" : "x"} /> {t('very weak')}{add}</Label>;
        case 1:
            return <Label size="mini" className='ScoreLabel1'><Icon name={ok ? "checkmark" : "x"} />{t('weak')}{add}</Label>;
        case 2:
            return <Label size="mini" className='ScoreLabel2'><Icon name={ok ? "checkmark" : "x"} />{t('ok')}{add}</Label>;
        case 3:
            return <Label size="mini" className='ScoreLabel3'><Icon name={ok ? "checkmark" : "x"} />{t('good')}{add}</Label>;
        case 4:
            return <Label size="mini" className='ScoreLabel4'><Icon name={ok ? "checkmark" : "x"} />{t('strong')}{add}</Label>;
        default: return <div />;
    }
}

const pwdRegex1 = /[a-z]+/;
const pwdRegex2 = /[A-Z]+/;
const pwdRegex3 = /[0-9]+/;
const pwdRegex4 = /[^a-zA-Z0-9]+/;

export function checkPasswords(pwd1: string | undefined, pwd2: string | undefined, t: IMGTranslateFunction): IPasswordCheckResult {
    let strength1 = 0; // psswd(pwd1).score;
    strength1 += pwd1 && pwdRegex1.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex2.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex3.test(pwd1) ? 1 : 0;
    strength1 += pwd1 && pwdRegex4.test(pwd1) ? 1 : 0;

    let strength2 = 0; // psswd(pwd1).score;
    strength2 += pwd2 && pwdRegex1.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex2.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex3.test(pwd2) ? 1 : 0;
    strength2 += pwd2 && pwdRegex4.test(pwd2) ? 1 : 0;

    const strength = Math.max(strength1, strength2, 3);

    const equal = pwd1 === pwd2;
    const valid = pwd1 !== undefined && pwd1.length >= 8 && strength >= 3; // hasLowerCase + hasUpperCase + hasNonalphas + hasNumber >= 3;
    return ({
        equal,
        lengthOk: pwd1 && pwd1.length >= 8 ? true : false,
        strength,
        valid,
        label: getScoreLabel(strength, equal, valid, t)
    });
}

export function createHeadline(header?: string, split?: boolean) {
    if (!header)
        return <span />;
    const splits = header.split("excite!");
    return splits.map((v, i) => {
        return (
            <span key={"container" + i}>
                <span key={i}>{split ? splitText(v) : v}</span>
                <>
                    {i < splits.length - 1 && <span key={"excite-" + i} className="HeadlineExcite">excite!</span>}
                </>
            </span>
        );
    });
}

export function switchToProfile<S extends IConnectedComponent & RouteComponentProps>(component: React.Component<S>, id: string) {
    component.props.history.push(config.routes.community.otherprofile + "/" + id);
}

export function getLinkForGlobalMedia<S extends IConnectedComponent>(component: React.Component<S>, name: string): LinkDto | undefined {
    const media = _.find(component.props.state.globalMedia, (x: MediaDownloadDto) => x.id === name);
    return media ? getLink(media.links, "self") : undefined;
}

export function detectMobileDevice() {
    if (
        window.innerWidth <= 800 || window.innerHeight <= 800
        // navigator.userAgent.match(/Android/i)
        // || navigator.userAgent.match(/webOS/i)
        // || navigator.userAgent.match(/iPhone/i)
        // || navigator.userAgent.match(/iPad/i)
        // || navigator.userAgent.match(/iPod/i)
        // || navigator.userAgent.match(/BlackBerry/i)
        // || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
    }
    else {
        return false;
    }
}
export function detectSmallMobileDevice() {
    if (
        window.innerWidth < 768 || window.innerHeight <= 736
    ) {
        return true;
    }
    else {
        return false;
    }
}


export function checkMedia(media: MediaDownloadDto | undefined, refName?: string) {
    return media !== undefined && media.links && media.links.length > 0 &&
        (refName === undefined || (_.findIndex(media.links, v => v.ref === refName) >= 0));
}

export interface IProgressData {
    loaded?: number;
    lengthComputable: boolean;
    total?: number;
}

/*
Take care! this function always returns successfully running promises! But each promise returns "ok=true" on success and
"ok=false" on failure.
*/

export function downloadMedia<T>(media: MediaDownloadDto | undefined, userData: T, onProgress?: (progress: IProgressData) => void) {
    return new Promise<{ url: string, userData: T, ok: boolean }>(async (resolve) => {
        if (media) {
            const link = _.find(media.links, l => l.ref === "self");
            if (!link)
                resolve({ url: "", userData, ok: false });
            const headers =
            {
                "X-ZUMO-AUTH": undefined,
                "X-Forwarded-Ssl": "on"
            };
            const aConfig: AxiosRequestConfig =
            {
                url: link ? link.uri : "",
                method: "GET",
                headers,
                responseType: "blob",
                onDownloadProgress: onProgress
            };
            try {
                const response = await Axios(aConfig);
                if (response.status !== 200)
                    resolve({ url: "", userData, ok: false });
                resolve({ url: URL.createObjectURL(response.data), userData, ok: true });
            }
            catch (error) {
                resolve({ url: "", userData, ok: false });
            }
        }
        else
            resolve({ url: "", userData, ok: false });
    });
}

export const getStylePercentage = (val: string) => {
    if (val === undefined)
        return undefined;
    const rest = val.substr(0, val.length - 1);
    return parseInt(rest, 10);
};

// export const createScreenShot = (eleId?: string) => {
//     return new Promise<{ blob: Blob, url: string, data: string, width: number, height: number }>((resolve, reject) => {
//         const root = document.getElementById(eleId ? eleId : "root");
//         if (root) {
//             const width = eleId ? root.clientWidth : window.innerWidth;
//             const height = eleId ? root.clientHeight : window.innerHeight;
//             html2canvas(root, { letterRendering: true, useCORS: true, width, height }).then((canvas: HTMLCanvasElement) => {
//                 canvas.toBlob((blob) => {
//                     if (blob) {
//                         const fileForBlob: File = Object.assign(blob as any, { name: "upload.png", lastModifiedData: new Date() });
//                         resolve({ blob: fileForBlob, url: URL.createObjectURL(fileForBlob), data: canvas.toDataURL("image/png"), width, height });
//                     }
//                     else
//                         reject("coult not create to blob");
//                 }, "image/png");
//             }, (msg) => reject("html 2 canvas failed: " + msg));
//         }
//         else
//             reject("did not find element to render");
//     });
// };

/* export const screenToPdf = (eleId?: string, orientation?: "l" | "p", paperType?: string) => {
    const doc = new jsPDF(orientation, "pt", paperType);
    const p = createScreenShot(eleId);
    const w = doc.internal.pageSize.getWidth();
    const h = doc.internal.pageSize.getHeight();
    p.then(val => {
        let wi = w;
        let hi = h;
        const fact = wi / val.width;
        if (val.height * fact > h) {
            // scale by height
            wi = hi / val.height * val.width;
        }
        else {
            // scale by width
            hi = wi / val.width * val.height;
        }
        doc.addImage(val.data, "image/png", (w - wi) / 2, (h - hi) / 2, wi, hi);
        doc.save("test.pdf");
    }, (msg) => console.log(msg));
};
*/
const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

export const svgDescribeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {

    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
};

export const getTypeOfMedia = (m: IDownloadMedia | IFileMedia | IEmbedMedia | IURLMedia | IStorageMedia) => {

    if (m.hasOwnProperty("file")) {
        return "file";
    }
    else if (m.hasOwnProperty("id")) {
        // IEmbedMedia vimeo | youtube
        return "embedded";
    }
    else if (m.hasOwnProperty("url")) {
        return "url";
    }
    else {
        return "download";
    }
};
export const checkBothSetUnset = (a: any, b: any) => {
    return (a === undefined && b === undefined) ||
        (a !== undefined && b !== undefined);
};

export const mediaEqual = (m1?: IDownloadMedia | IFileMedia | IEmbedMedia | IURLMedia | IStorageMedia | undefined, m2?: IDownloadMedia | IFileMedia | IEmbedMedia | IURLMedia | IStorageMedia | undefined) => {
    if (!m1 && m2)
        return false;
    if (m1 && !m2)
        return false;
    if (!m1 && !m2)
        return true;
    if (m1 && m2) {
        const type1 = getTypeOfMedia(m1);
        const type2 = getTypeOfMedia(m2);
        if (type1 !== type2)
            return false;
        const a1: any = m1;
        const a2: any = m2;
        switch (type1) {
            case "file":
                return _.isEqual((a1 as IFileMedia).file, (a2 as IFileMedia).file);
            case "embedded":
                return (a1 as IEmbedMedia).id === (a2 as IEmbedMedia).id;
            case "url":
                return (a1 as IURLMedia).url === (a2 as IURLMedia).url;
            case "download":
                {
                    const d1 = a1 as IDownloadMedia;
                    const d2 = a2 as IDownloadMedia;
                    return d1.media && d2.media ? d1.media.reference === d2.media.reference : (d1.media === undefined && d2.media === undefined);
                }
            default:
                return false;
        }
    }
    return false;
};

export const isEmailAddress = (val?: string) => {
    if (!val)
        return false;
    // eslint-disable-next-line
    const regex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
    return regex.test(val);
};

export const calcNewDimWithAlgo = (x: number, y: number, a: number, e: number) => {
    const invA = 1.0 / a;
    const r = x / y;
    let useWidth = true;
    if (a < r)
        useWidth = true;
    else if (1.0 < r && r <= a)
        useWidth = false;
    else if (invA < r && r <= 1)
        useWidth = true;
    else
        useWidth = false;
    let x1 = x;
    let y1 = y;
    if (useWidth) {
        x1 = e;
        y1 = e / r;
    }
    else {
        y1 = e;
        x1 = e * r;
    }
    if (x1 > x && y1 > y)
        return { x, y };
    else
        return { x: x1, y: y1 };

};

export function createOneTimeToken<S extends IConnectedComponent>(component: React.Component<S>, toCall: (token: string) => void): void {
    UserController.GetOneTimeToken({}, toCall);
}

export function createLinkFor<S extends IConnectedComponent>(component: React.Component<S>, url: string, filename: string): void {
    if (component.props.state.userInfo) {
        const userInfo = component.props.state.userInfo;
        createOneTimeToken(component, (token) => {
            let linkUrl = component.props.state.client.baseUrl + url.replace("{userid}", userInfo.id);
            linkUrl = linkUrl.replace("{token}", token);
            console.log("linkUrl = ", linkUrl);
            saveAs(linkUrl, filename);
        });
    }
}

// eslint-disable-next-line
export const URLRegex: RegExp = /^http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.\{\}]+$/;
// eslint-disable-next-line
// export const URLRegexML: RegExp = /http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+/g;
// eslint-disable-next-line
export const URLRegexML: RegExp = /http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+|http:\/\/localhost:3000\/(index\.html)?#\/.*/g;

export function dispatchPromise<T>(
    component: React.Component<IConnectedComponent>,
    what: (onSuccess?: (ret: T) => void, onError?: (err: any) => void) => (dispatch: Dispatch<any>, getState: () => IState) => void
): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        component.props.dispatch(what((ret: T) => resolve(ret), (err: any) => reject(err)));
    });
}

export function addOrSetLanguage<T extends { lng: string, text: string }>(element: T[] | undefined, value: string, useLng?: string): T[] {
    const lng = useLng ? useLng : ImgI18N.getInstance().language;
    let workArray: T[] = [];
    if (!element)
        workArray = [];
    else
        workArray = element.slice();
    const idx = _.findIndex(workArray, (e: T) => e.lng === lng);
    if (idx >= 0)
        workArray[idx].text = value;
    else
        workArray.push({ lng, text: value } as T);
    return workArray;
}

export const RouteFiller = (route: string, params: { [key: string]: any }) => {
    _.forEach(params, (p, i) => {
        if (p) {
            route = route.replace("{" + i + "}", p);
            route = route.replace("{" + i + "?}", p);
        }
        else {
            route = route.replace("{" + i + "}", "");
            route = route.replace("{" + i + "?}", "");
        }
    });
    return route;
};

export const openURLLink = (url: string) => {
    const ele = document.createElement("a");
    ele.setAttribute("href", url);
    ele.style.display = "none";
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
};

export type groupType = "EXCITE" | "COMMUNITY" | "SLIDESHOW" | "LANDING PAGE SMART MOBILITY" | "TUBE" | "QUIZ" | "SCORM" | "BEST PRACTICE" | "CONTENT AGGREGATION";

export const isInGroup = (type: groupType) => {
    const state = store.getState();
    return _.findIndex(state.groups ? state.groups.elements : [], g => g.groupType === type) >= 0;
};
export const isInGroupAdmin = (type: groupType) => {
    const state = store.getState();
    const gr = state.userInfo?.groupRoles ?? [];
    if (_.findIndex(gr, gg => gg.groupId === "GLOBAL" && _.findIndex(gg.roles, r => r === "Admin" || r === "Moderator") >= 0) >= 0)
        return true;
    return _.findIndex(state.groups ? state.groups.elements : [], g =>
        g.groupType === type &&
        _.findIndex(gr, gg => gg.groupId === g.id &&
            _.findIndex(gg.roles, r => r === "Admin" || r === "Moderator") >= 0)
        >= 0
    ) >= 0;
};


export const hasRoleInGroup = (groupid: string, role: string) => {
    const state = store.getState();
    if (!state.userInfo)
        return false;
    const ui = state.userInfo;
    const group = _.find(ui.groupRoles, gr => gr.groupId === groupid);
    if (!group)
        return false;
    return _.findIndex(group.roles, r => r.toLowerCase() === role.toLowerCase()) >= 0;
};

export const groupsOfGroupTypeWithLink = (type: groupType, refName: string) => {
    const state = store.getState();
    return _.filter(state.groups ? state.groups.elements : [], g => g.groupType === type && _.find(g.links, l => l.ref === refName) !== undefined);
};

export function changeGroup(props: IConnectedComponent, gid: string) {
    const state = props.state;
    if (state.userInfo && gid !== state.userInfo.userSettings.lastGroupId) {
        const ui = state.userInfo;
        ui.userSettings.lastGroupId = gid;
        UserController.PostUserSettings({ id: ui.id, settings: ui.userSettings }, () => {
            props.dispatch(ActionCreators.ReceivedUserInfo(ui));
        });
    }
}
export function setFrontEndSetting<T>(component: React.Component<IConnectedComponent>, settingName: string, changer: (old: T | undefined) => T | undefined) {
    const state = component.props.state;
    if (state.userInfo) {
        const ui = state.userInfo;
        if (!ui.userSettings.frontEndSettings)
            ui.userSettings.frontEndSettings = {};
        const val = ui.userSettings.frontEndSettings[settingName];
        const toChg: T = val ? JSON.parse(val) : undefined;
        const toSet = changer(toChg);
        ui.userSettings.frontEndSettings[settingName] = JSON.stringify(toSet);
        UserController.PostUserSettings({ id: ui.id, settings: ui.userSettings }, (data) => component.props.dispatch(ActionCreators.ReceivedUserInfo(data)));
    }
}
export function getFrontEndSetting<T>(component: React.Component<IConnectedComponent>, settingName: string): T | undefined {
    const state = component.props.state;
    return getFrontEndSettingFromState<T>(state, settingName);
}

export function getFrontEndSettingWithoutComponent<T>(settingName: string): T | undefined {
    const state = store.getState();;
    return getFrontEndSettingFromState<T>(state, settingName);
}


export function getFrontEndSettingFromState<T>(state: IState, settingName: string): T | undefined {
    if (state.userInfo) {
        const ui = state.userInfo;
        if (!ui.userSettings.frontEndSettings)
            ui.userSettings.frontEndSettings = {};
        const val = ui.userSettings.frontEndSettings[settingName];
        if (!val)
            return undefined;
        const toRet: T = JSON.parse(val);
        return toRet;
    }
    return undefined;
}


export const isInView = (ele: any) => {
    const rect = ele.getBoundingClientRect();
    const top = rect.top;
    return top < window.innerHeight;
};

export const isMobileDevice = () => {
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
    } catch (e) {
        return false;
    }
};

export const nop = () => {
    //
};

export const formatDate = (val: string | Date | undefined, formatStr?: string) => {
    if (!val || val === "")
        return "----";
    const opt = { locale: ImgI18N.getInstance().languageDefinition.locale };
    const d = typeof val === "string" ? new Date(val) : val;
    return format(d, formatStr ? formatStr : "Pp", opt);
};

export const copyMedia = (ele?: MediaDownloadDto): MediaUploadDto => {
    if (!ele)
        return null as any as MediaUploadDto;
    return { ...ele, changed: true, copyByReference: true };
};

export const findGroupInHierarchy = (hierarchy: GroupDownloadDto[], id: string): GroupDownloadDto | undefined => {
    let toRet: GroupDownloadDto | undefined;
    _.forEach(hierarchy, g => {
        if (g.id === id) {
            toRet = g;
        }
        if (!toRet)
            toRet = findGroupInHierarchy(g.childGroups, id);
        if (toRet)
            return false;
        return true;
    });
    return toRet;
};

export const findSlideInSlideShow = (slideShow: SlideShowDownloadDto | undefined, slideId: string | undefined) => {
    if (!slideShow || !slideId)
        return undefined;
    let li = -1;
    let idx = -1;
    _.forEach(slideShow.languageSlides, (ls, i) => {
        li = i;
        idx = _.findIndex(ls.slides, s => s.id === slideId);
        if (idx >= 0)
            return false;
        return true;
    });
    if (li >= 0 && idx >= 0)
        return { languageSlidesIdx: li, slideIdx: idx };
    else
        return undefined;
};

export const getValueFromPath = (data: any, path: string | string[]) => {
    let cur = data;
    const p = _.isArray(path) ? path : [path];
    let i = 0;
    while (cur && i < p.length) {
        cur = cur[p[i++]];
    }
    return cur;
};

export const setValueFromPath = (data: any, path: string | string[], value: any) => {
    let cur = data;
    const p = _.isArray(path) ? path : [path];
    let i = 0;
    while (cur && i < p.length) {
        let nCur = cur[p[i]];
        if (!nCur) {
            nCur = {};
            cur[p[i]] = nCur;
        }
        cur = nCur;
        i++;
    }
    cur = value;
};

export function setValueFromLambda<T>(data: T, func: (obj: Required<T>) => any, value: any) {
    let cur = data;
    const path = getPath(func);
    const p = _.isArray(path) ? path : [path];
    let i = 0;
    while (cur && i < p.length) {
        let nCur = (cur as any)[p[i]];
        if (!nCur) {
            nCur = {};
            (cur as any)[p[i]] = nCur;
        }
        cur = nCur;
        i++;
    }
    cur = value;
}


export const buildDiagramDataName = (path: string | string[]) => {
    return _.isArray(path) ? path.join("->") : path;
};


const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
const ARGUMENT_NAMES = /([^\s,]+)/g;
function args(func: any) {
    const fnStr = func.toString().replace(STRIP_COMMENTS, '');
    const result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
    // eslint-disable-next-line
    const rStr = "return\\s*" + result[0] + ".([^;\}]*)";
    const findRet = new RegExp(rStr, "mg");
    const res = findRet.exec(fnStr);
    if (res)
        return res[1].split(".");
    return [];
}
export function getPath<T>(func1: (v: Required<T>) => any) {
    return args(func1);
}

export function getPath2<T, S>(func1: (v: Required<T>) => S, func2: (u: Required<S>) => any) {
    const a = args(func1);
    a.push(...args(func2));
    return a;
}

export const getUri = (linkContainer: LinkDto[] | { links: LinkDto[] } | undefined, refName?: string, undefValue?: string): string => {
    let link: LinkDto | undefined;
    const r = refName ? refName : "self";
    if (linkContainer) {
        if (_.isArray(linkContainer))
            link = _.find(linkContainer, l => l.ref === r);
        else
            link = _.find(linkContainer.links, l => l.ref === r);
    }
    return link ? link.uri : (undefValue ? undefValue : config.tmpImages.missingProfilePicture);
};

export function assign<P>(container: P, name: keyof P, value: any) {
    (container[name] as any) = value;
}

export function getArrayFromValueType<OptionType>(val: OptionType | ReadonlyArray<OptionType> | null | undefined): OptionType[] {
    return !val ? [] : (_.isArray(val) ? val : [val]);
}

export function getFirstFromValueType<OptionType>(val: OptionType | ReadonlyArray<OptionType> | null | undefined): OptionType | undefined {
    return !val ? undefined : (_.isArray(val) ? (val.length > 0 ? val[0] : undefined) : val);
}

export const updateState = <S, T>(key: keyof S, value: T) => (prevState: S): S => ({ ...prevState, [key]: value });

type KeyOfType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

export interface GetUriMethod {
    (data: { mediaType?: string; fileName?: string; }, onSuccess?: (result: UploadMediaParametersDto) => void, onError?: (reason: ICallErrorResponse) => void, onProgress?: (currentBytes: number, totalBytes: number) => void, mutex?: string): void;
};

export const putToBlobStorage = async (getUri: GetUriMethod, file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) => {
    const uploadParameters = await ControllerHelper.promise({ mediaType: file.type, fileName: file.name }, getUri);
    if (uploadParameters) {
        const f = ((window as any).imgUploadToBlobStorage) as (uploadParameters: UploadMediaParametersDto, file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) => Promise<string>;
        if (f) {
            const result = await f(uploadParameters, file, onProgress, abortSignal);
            const mediaUpload: ContentMediaUploadDto = {
                changed: true,
                reference: uploadParameters.reference,
                container: uploadParameters.container,
                copyByReference: true,
                mediaType: file.type,
                fileName: file.name,
                thumbnails: [],
            };
            return !result ? mediaUpload : undefined;
        }
        const promise = new Promise<ContentMediaUploadDto>((res, rej) => {
            MediaController.UploadFile(
                {
                    container: uploadParameters.container,
                    bytes: file,
                    fileName: file.name,
                    mediaType: file.type
                },
                res,
                rej,
                (c, t) => {
                    if (onProgress)
                        onProgress(c / t);
                }
            );
        });
        const up = await promise;
        console.log(up);
        return up;
    }
}

export async function uploadMediaToStorageForAsset(file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    try {
        var result = await putToBlobStorage(AssetController.GetUploadUri, file, onProgress, abortSignal);
        return result;
    }
    catch (e) {
        return undefined;
    }
}
export async function uploadMediaToStorageForImport(file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    try {
        var result = await putToBlobStorage(MediaController.GetUploadUriForImport, file, onProgress, abortSignal);
        return result;
    }
    catch (e) {
        return undefined;
    }
}


export async function uploadMediaToStorage(file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    try {
        var result = await putToBlobStorage(MediaController.GetUploadUri, file, onProgress, abortSignal);
        return result;
    }
    catch (e) {
        return undefined;
    }
}




export async function uploadMediaToTube(file: File, onProgress?: (progress: number) => void, abortSignal?: AbortSignal) {
    try {
        var result = await putToBlobStorage(PostControllerTube.GetUploadUri, file, onProgress, abortSignal);
        return result;
    }
    catch (e) {
        return undefined;
    }
}

export const getMediaForContent = (content?: ContentPostContentDownloadDto): IDownloadMedia | IEmbedMedia | undefined => {
    if (!content)
        return undefined;
    let media: IDownloadMedia | IEmbedMedia | undefined;
    if (content) {
        if (content.useContent) {
            media = {
                media: content.content as MediaDownloadDto,
                refName: "self"
            }
        } else {
            const yregex = /(youtube)/ig;
            const vregex = /(vimeo)/ig;
            if (yregex.test(content.link)) {
                let yId = content.link.split('v=')[1];
                const ampersandPosition = yId.indexOf('&');
                if (ampersandPosition !== -1) {
                    yId = yId.substring(0, ampersandPosition);
                }
                media = {
                    id: yId,
                    source: "youtube",
                }

            }
            if (vregex.test(content.link)) {
                const pos = content.link.lastIndexOf('/');
                let vId = content.link.substr(pos + 1);
                const ampersandPosition = vId.indexOf('&');
                if (ampersandPosition !== -1) {
                    vId = vId.substring(0, ampersandPosition);
                }
                media = {
                    id: vId,
                    source: "vimeo",
                }

            }
        }
    }
    return media;
}

export const executeOnScroll = (threshold: number, callback: () => Promise<void> | void, condition: () => boolean) => {
    let working = false;
    return async (event: React.UIEvent<HTMLDivElement>) => {
        const t = event.currentTarget;
        if (t.scrollHeight - t.scrollTop - t.clientHeight < threshold) {
            if (condition() && !working) {
                working = true;
                await callback();
                working = false;
            }
        }
    }
}

interface StateSetter<P, S> {
    (state: ((prevState: Readonly<S>, props: Readonly<P>) => (S | null)), callback?: () => void): void;
}

export const setValueHelper = <P, S, T>(component: React.Component<P, S>, selector: string | ((state: S) => T)) => (val: T) => {
    const sel = typeof selector === "string" ? [selector] : getPath<S>(selector);
    component.setState(state => {
        let i = 0;
        const s = { ...state };
        let cur = s;
        while (i + 1 < sel.length)
            cur = (cur as any)[sel[i]];
        if ((cur as any)[sel[i]] === val)
            return null;
        (cur as any)[sel[i]] = val;
        return s;
    });
}

export const textInputHelper = <P, S>(component: React.Component<P, S>, selector: string | ((state: S) => string)) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setValueHelper<P, S, string>(component, selector)(val);
}

export const textAreaHelper = <P, S>(component: React.Component<P, S>, selector: string | ((state: S) => string)) => (event: React.FormEvent<HTMLTextAreaElement>) => {
    const val = event.currentTarget.value;
    setValueHelper<P, S, string>(component, selector)(val);
}


export const getOrTranslatePostLanguageStrings = async <T extends PostDownloadDto>(from?: T, lang?: string) => {
    return new Promise<T | undefined>((res, rej) => {
        const lng = lang ? lang : ImgI18N.getInstance().language;
        if (from) {
            const hasDLng = from.descriptions === undefined || _.findIndex(from.descriptions, d => d.lng === lng) >= 0;
            const hasHLng = _.findIndex(from.descriptions, d => d.lng === lng) >= 0;
            if (!hasDLng || !hasHLng)
                TranslationController.TranslatePost({ post: from, to: lng }, t => res(t as T), rej);
            else
                res(undefined);
        }
        else
            res(undefined);
    });
}

export const getUniqueId = () => {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
}


export const windowOpen = (url: string, param?: string) => {
    const u = url.replace("{{token}}", store.getState().client.token);
    const mh = MobileHelper.getInstance();
    if (mh.iOSClient) {
        if (mh.appVersion > 1)
            MobileHelper.getInstance().openLink(u);
        else
            store.dispatch(ActionCreators.SetLinkAddress(u));
    }
    else
        window.open(u, param);
}

// export function getMediaLink<T>(data: T, func: (data: T) => MediaDownloadDto, refName?: string) {
//     const m = func(data);
//     if (m) {
//         const idx = _.findIndex(m.links, l => l.ref === (refName ? refName : "self"));
//         if (idx >= 0)
//             return m.links[idx].uri;
//     }
//     return undefined;
// }

export const byteSize = (size: number) => {
    if (size > 1024 * 1024 * 1024)
        return `${Math.floor(size / (1024 * 1024 * 1024))} GB`;
    if (size > 1024 * 1024)
        return `${Math.floor(size / (1024 * 1024))} MB`;
    if (size > 1024)
        return `${Math.floor(size / (1024))} kB`;
    return `${size} bytes`;
}

export const copyToOwnAsset = async (media: MediaDownloadDto) => {
    const asset = await ControllerHelper.singleCall({
        data: {
            media: {
                ...media,
                copyByReference: true,
                changed: true,
            },
            tags: [],
            referenceId: "",
        }
    }, AssetController.AddAsset);
    return asset;
}

export const printBytes = (bytes: number) => {
    if (bytes >= 1024 * 1024 * 1024) {
        return `${Math.floor(bytes * 10 / (1024 * 1024 * 1024)) / 10} GB`;
    }
    if (bytes >= 1024 * 1024) {
        return `${Math.floor(bytes * 10 / (1024 * 1024)) / 10} MB`;
    }
    if (bytes >= 1024) {
        return `${Math.floor(bytes * 10 / (1024)) / 10} KB`;
    }
    return `${bytes} B`;
}

export const visitGroups = (groups: GroupDownloadDto[] | undefined, visitor: (group: GroupDownloadDto) => boolean): boolean => {
    if (!groups || groups.length === 0)
        return true;
    let i = 0;
    while (i < groups.length) {
        if (!visitor(groups[i]))
            return false;
        if (!visitGroups(groups[i].childGroups, visitor))
            return false;
        i++;
    }
    return true;
}


export const loadToCanvas = (data: string | Blob | File, callback: (canvas: HTMLCanvasElement, data?: MetaData) => void, options?: LoadImageOptions) => {
    loadImage(data, (img: any, data?: MetaData) => {
        callback(img as HTMLCanvasElement, data);
    }, { ...options, canvas: true });
}

export const loadToImg = (data: string | Blob | File, callback: (canvas: HTMLImageElement, data?: MetaData) => void, options?: LoadImageOptions) => {
    loadImage(data, (img: any, data?: MetaData) => {
        callback(img as HTMLImageElement, data);
    }, options ?? {});
}



export const splitForMentionedUsers = <P extends { url: string }>(text: string, mentions: UserShortInfoDownloadDto[], i: number, UrlCard?: React.ComponentClass<P> | React.StatelessComponent<P>, urlParams?: P): JSX.Element[] | JSX.Element => {
    if (i < mentions.length) {
        const mention = mentions[i];
        const userString = mention.firstName + ' ' + mention.secondName;
        let toRet: JSX.Element[] = [];
        const splits = text.split('@' + mention.id);
        const co = splits.length;
        for (let j = 0; j < co; j++) {
            toRet = toRet.concat(splitForMentionedUsers(splits[j], mentions, i + 1, UrlCard, urlParams));
            if (j < co - 1)
                toRet.push(<Link to={config.routes.community.otherprofile + "/" + mention.id}>{userString}</Link>);
        }
        return toRet;
    }
    return splitText(text, UrlCard, urlParams);
}

export const parseTextForMentionedUsers = <P extends { url: string }>(text: string, mentions?: UserShortInfoDownloadDto[], UrlCard?: React.ComponentClass<P> | React.StatelessComponent<P>, urlParams?: P): JSX.Element | JSX.Element[] => {
    let toRet: JSX.Element[] = [];
    if (mentions) {
        const definedMentions = mentions.filter(Boolean);
        toRet = toRet.concat(splitForMentionedUsers(text, definedMentions, 0, UrlCard, urlParams));
    }
    return toRet.map((v, i) => <span key={i}>{v}</span>);
}


export const visitNodeChildren = (node: HTMLElement, visitor: (node: HTMLElement) => boolean): HTMLElement | undefined => {
    if (visitor(node))
        return node;
    for (let i = 0; i < node.children.length; i++) {
        const res = visitNodeChildren(node.children[i] as HTMLElement, visitor);
        if (res)
            return res;
    }
    return undefined;
}
